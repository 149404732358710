import React, { FC, useState, useEffect, useRef, useCallback } from 'react';
import '../style/notice.less';
import noticeIcon from '../../../../assets/images/decorate/notice.png';
import noticeWhiteIcon from '../../../../assets/images/decorate/notice-white.png';
import { SketchPicker } from 'react-color';
import { Form, Popover, Button, Switch } from 'antd'
import { Input, Tooltip, Slider, Select } from 'antd';

import { ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
const { Option } = Select;

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const Notice: FC<Props> = ({ dataField }) => {
    const [value, setValue] = useState<number>(0);
    const [timers, setTimers] = useState<Array<NodeJS.Timeout>>([]);
    const saveCallBack: any = useRef();
    const callBack = () => {
        if (value > dataField.list.length - 2) {
            setValue(0)
        } else {
            setValue(value + 1);
        }
    };
    useEffect(() => {
        saveCallBack.current = callBack;
        return () => { };
    });
    useEffect(() => {
        const tick = () => {
            saveCallBack.current();
        };
        const timer: NodeJS.Timeout = setInterval(tick, dataField.interval);
        timers.push(timer);
        setTimers(timers);
        return () => {
            clearInterval(timer);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);
    return (
        <div className="notice-container" style={
            {
                padding: `0 ${dataField.padding}px`,
            }
        }>
            <div className="notice-con" style={
                {
                    backgroundColor: dataField.bg_color,
                    borderRadius: dataField.border_radius + 'px'
                }
            }>
                <img src={dataField.iconStyle === 'white' ? noticeWhiteIcon : noticeIcon} alt="" className="icon" />
                <div className="carousel-con">
                    <div className="list" style={
                        {
                            top: -(40 * value) + 'px',
                            transitionDuration: (value === 0 ? '0s' : '0.3s')
                        }
                    }>
                        {
                            dataField.list?.map((v: any, i: number) => {
                                return <div className="item text-1" style={
                                    {
                                        color: dataField.color,
                                        fontSize: dataField.font_size
                                    }
                                } key={i} >{v.content}</div>
                            })
                        }
                    </div>
                </div>
            </div>
        </div>
    )
}

interface ItemProps {
    id: string
    index: number
    data?: any
    updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
    setShowLinkSelectionPrivate: (show: boolean) => void
    setCallerIndex: (index: number) => void
}
const Item: FC<ItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowLinkSelectionPrivate, setCallerIndex }) => {
    const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
        return (
            <Select defaultValue={defaultValue} onChange={
                (e) => {
                    data.type = e
                    updateModuleDataPrivate(data, index, '')
                }
            } className="select-after">
                <Option value="navigateTo">新窗口</Option>
                <Option value="switchTab">TAB切换</Option>
                <Option value="redirectTo">重定向</Option>
                <Option value="back">返回</Option>
            </Select>
        );
    }
    return <div className={`image-item`} >

        <div className="info">
            <div className="input-group">
                <Input placeholder="请输入通告内容" onChange={(e) => {
                    data.content = e.target.value;
                    updateModuleDataPrivate(data, index, '')
                }} value={data.content} />
                <Input.Group compact className="input-item">
                    <Button type="primary" onClick={
                        () => {
                            setCallerIndex(index)
                            setShowLinkSelectionPrivate(true,)
                        }
                    }>选择</Button>
                    <Input addonAfter={<OpenType defaultValue={data.type || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
                        data.link = e.target.value;
                        updateModuleDataPrivate(data, index, '')
                    }} value={data.link} />

                </Input.Group>
            </div>
        </div>
        <div className="img">
            <div className="operation">
                <div className="icon">{index + 1}</div>
                <div className="icon " onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveUp')
                    }
                }>
                    <Tooltip title="上移">
                        <ArrowUpOutlined />
                    </Tooltip>
                </div>

                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveDown')
                    }
                }>
                    <Tooltip title="下移">
                        <ArrowDownOutlined />
                    </Tooltip>
                </div>
                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'delete')
                    }
                }>
                    <Tooltip title="移除" >
                        <DeleteOutlined />
                    </Tooltip>
                </div>

            </div>
        </div>
    </div>
})
export const NoticeSet: FC<SetProps> = ({ dataField, id, selectedInfo, methods }) => {

    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const [callerIndex, setCallerIndex] = useState(-1)
    const updateModuleDataPrivate = useCallback(
        (item:any, index:any, type:any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.list[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.list[index]
                newDataFieldPrivate.list = newDataFieldPrivate.list.filter((v: any) => v)
            } else if (type === 'moveDown') {
                let nextIndex = index + 1, length = newDataFieldPrivate.list.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.list);
                if (nextIndex > length - 1) {
                    nextIndex = 0
                }
                nextItem = newDataFieldPrivate.list[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.list = newImageList
            } else if (type === 'moveUp') {
                let nextIndex = index - 1, length = newDataFieldPrivate.list.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.list);
                if (nextIndex < 0) {
                    nextIndex = length - 1
                }
                nextItem = newDataFieldPrivate.list[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.list = newImageList
            } else if (type === 'add') {
                if (item instanceof Array) {
                    newDataFieldPrivate.list = [...item, ...newDataFieldPrivate.list]
                } else {
                    newDataFieldPrivate.list.unshift(item)
                    newDataFieldPrivate.list = [].concat(newDataFieldPrivate.list)
                }
            }
            // newDataFieldPrivate
            // console.log('newDataFieldPrivate',newDataFieldPrivate)
            methods.updateModuleData(newDataFieldPrivate, id)
        },
        [dataFieldPrivate, methods, id]
    )
    useEffect(
        () => {
            if (!selectedInfo || !selectedInfo.path) return
            let callItem = { ...dataFieldPrivate.list[callerIndex] }
            callItem.link = selectedInfo.path
            callItem.type = selectedInfo.openType
            updateModuleDataPrivate(callItem, callerIndex, '')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    useEffect(() => {
        setDataFieldPrivate(dataField)
    }, [dataField])
    return <div className="notice-set-container">
        <Form.Item label="两侧间距" >
            <Slider max={20} value={dataField.padding} onChange={
                (e) => {
                    methods.updateModuleData({
                        ...dataField,
                        padding: e
                    }, id)
                }
            } />
        </Form.Item>

        <Form.Item label="组件圆角">
            <Slider max={15} value={dataField.border_radius} onChange={
                (e) => {
                    methods.updateModuleData({
                        ...dataField,
                        border_radius: e
                    }, id)
                }
            } />
        </Form.Item>
        <Form.Item label="切换间隔">
            <Slider max={10000} min={1000} step={500} value={dataField.interval} onChange={
                (e) => {
                    methods.updateModuleData({
                        ...dataField,
                        interval: e
                    }, id)
                }
            } />
        </Form.Item>
        <Form.Item label="字体颜色">
            <div className="color-set-cont">
                <Popover content={
                    <SketchPicker
                        color={dataField.color}
                        onChange={
                            (e: any) => {
                                methods.updateModuleData({
                                    ...dataField,
                                    color: e.hex
                                }, id)
                            }
                        }
                    />
                } title="" trigger="click">
                    <p className="color-hover" style={
                        {
                            backgroundColor: dataField.color
                        }
                    }></p>
                </Popover>
                <Button onClick={
                    () => {
                        dataField.color = '#666666'
                        methods.updateModuleData({
                            ...dataField,
                            color: '#666666'
                        }, id)
                    }
                }>默认</Button>
            </div>



        </Form.Item>
        <Form.Item label="背景颜色">
            <div className="color-set-cont">
                <Popover content={
                    <SketchPicker
                        color={dataField.bg_color}
                        onChange={
                            (e: any) => {
                                methods.updateModuleData({
                                    ...dataField,
                                    bg_color: e.hex
                                }, id)
                            }
                        }
                    />
                } title="" trigger="click">
                    <p className="color-hover" style={
                        {
                            backgroundColor: dataField.bg_color
                        }
                    }></p>
                </Popover>
                <Button onClick={
                    () => {
                        dataField.bg_color = 'transparent'
                        methods.updateModuleData({
                            ...dataField,
                            bg_color: 'transparent'
                        }, id)
                    }
                }>默认</Button>
            </div>



        </Form.Item>

        <Form.Item label="图标风格" >
            <Switch checkedChildren="White" unCheckedChildren="Dark" onChange={
                (e) => {
                    methods.updateModuleData({
                        ...dataField,
                        iconStyle: e ? 'white' : 'dark'
                    }, id)
                }
            } checked={dataField.iconStyle === 'white'} />

        </Form.Item>
        <div className="add">
            <p className="total">共{dataField.list.length}条</p>
            <Button size="small" type="primary" onClick={
                () => {
                    methods.updateModuleData({
                        ...dataField,
                        list: [
                            {
                                content: ''
                            },
                            ...dataField.list
                        ]
                    }, id)
                }
            }>新增</Button>
        </div>
        <div className="image-list">
            {dataFieldPrivate?.list.map((v: any, i: number) => {
                return <Item
                    key={i}
                    id={v.id}
                    index={i}
                    data={v}
                    updateModuleDataPrivate={updateModuleDataPrivate}
                    setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
                    setCallerIndex={setCallerIndex}
                />
            })}
        </div>
    </div>
}

export default Notice