import {BaseModel, DisableModel} from 'model/base.model';

export interface regionalRewardsModel extends BaseModel, DisableModel {
    name?: string;
    startTime?: string;
    ruleDetailMap?: any;
    levelNo?: any;
    remark?: string;
    discount?: number;
    pictures?: any;
}

export const defaultRegionalRewards: Readonly<regionalRewardsModel> = {
};
