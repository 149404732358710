import { Storage } from 'utils/storage-util';
import { regionObj } from "../delivery/delivery-edit";
import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, getRegions, reset, updateEntity } from "./region.reducer";
import { ProFormDependency, ProFormRadio, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { Region, RegionTemplateType } from "model/product/region.model";
import { Col, Form, Row, TreeSelect } from "antd";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";;

export interface RegionEditProps extends EditStateAndDispatchProps<Region>, StateProps, DispatchProps, RouteComponentProps {
}

const RegionEdit: FC<RegionEditProps> = props => {
  const {
    entity,
  } = props;
  const [treeData, setTreeData] = useState([]);
  const storeId = Storage.session.get('store-id');

  useEffect(() => {
    const xTenant = Storage.session.get("x-tenant");
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    treeData.length === 0 && getRegions(regionObj[regionKey][0]).then(res => setTreeData(res));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  return (
    <EditPage<Region>
      title="区域模版"
      {...props}
    >
      <Row>
        <Col span="7">
          <ProFormText
            width="md"
            name="templateName"
            label="模板名称"
            placeholder="请输入模板名称"
            rules={[{ required: true }]}
          />
        </Col>
        <Col span="1"></Col>
        <Col span="7">
          <ProFormRadio.Group
            rules={[{ required: true }]}
            width="md"
            name="regionTemplateType"
            options={Object.entries<string>(RegionTemplateType).map(([value, label]) => ({ value, label }))}
            label="模版类型"
          />
        </Col>
        <Col span="1"></Col>
        {
          storeId === '0' &&
          <Col span="3">
            <ProFormSwitch name="overallSituation"
              label="是否全局"
              width='sm'
              initialValue={false}
              fieldProps={{
                checkedChildren: '是',
                unCheckedChildren: '否',
              }}
            />
          </Col>
        }
        <Col span="3">
          <ProFormSwitch name="disabled"
            label="是否禁用"
            width='sm'
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span="7">
          <ProFormDependency name={['overallSituation']}>
            {({ overallSituation }) => {
              return !overallSituation && <Form.Item name={'sellerId'}>
                <SelectSearch name="sellerId" label='店铺' defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
              </Form.Item>
            }}
          </ProFormDependency>
        </Col>
      </Row>
      <Form.Item name="regions" label="区域选择" rules={[{ required: true }]}>
        <TreeSelect
          showSearch
          multiple
          treeData={treeData}
          treeCheckable
          showCheckedStrategy="SHOW_PARENT"
        // onChange={(res) => console.log(res)}
        />
      </Form.Item >
    </EditPage>);
};

const mapStateToProps = ({ region }: RootState) => ({
  entity: region.entity,
  updateSuccess: region.updateSuccess,
  updating: region.updating,
  createSuccess: region.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RegionEdit);
