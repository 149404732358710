import { FC, ReactNode, useEffect, useState } from "react";
import { Button, Form, Input, Tooltip, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import BonusListDetails from "./bonus-details";
import { deleteEntity, examineBonus, fetchWithdrawalPeriodList, getEntities, getEntity } from "./bonus.reducer";
import { RouteComponentProps } from "@reach/router";
import { BonusList, bonusListStates } from "model/bonus/bonusList.model";
import ListViewPage from "layouts/page-wraper/list-view-page";
import BonusListEdit from "./bonus-edit";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";

export interface BonusListListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const BonusListList: FC<BonusListListProps> = (props) => {
  const [newPeriodArr,setNewPeriodArr] = useState([]);

  useEffect(() => {
    fetchWithdrawalPeriodList().then(e => {
      setNewPeriodArr(e?.data?.map((e:any) => ({
        label:e.periodName,
        value:e.period,
      })));
    })
  },[props.entities]);

  const columns: ProColumns<BonusList>[] = [{
    title: '申请单id',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '会员code',
    dataIndex: 'customerCode',
    width: 100,
  }, {
    title: '会员名',
    dataIndex: 'customerName',
    width: 100,
  }, {
    title: '统计月份',
    dataIndex: 'period',
    width: 100,
  }, {
    title: '奖金金额',
    dataIndex: 'totalAmount',
    width: 100,
  }, {
    title: '到账金额',
    dataIndex: 'rewardAmount',
    width: 100,
  }, {
    title: '手续费',
    dataIndex: 'feeAmount',
    width: 100,
  }, {
    title: '连连申请单id',
    dataIndex: 'cashNo',
    width: 100,
  }, {
    title: '申请单状态',
    dataIndex: 'status',
    valueEnum: bonusListStates,
    width: 100,
  }, {
    title: '操作人',
    dataIndex: 'operator',
    width: 80,
  }, {
    title: '操作时间',
    dataIndex: 'operateTime',
    width: 100,
  },]

  const searchFields = [{
    label: '申请单id',
    name: 'id',
  }, {
    label: '授权号',
    name: 'customerCode',
  }, {
    label: '连连申请单ID',
    name: 'cashNo',
  }];

  const rowActions = [(dom: any, record: any) => {
    return <ModalForm
      title={'审核申请单'}
      trigger={<Tooltip title={'审核'} key="AuditReject"><Button type="link" size="small" >审核</Button></Tooltip>}
      modalProps={{
        destroyOnClose: true,
      }}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          ...data,
          applyId: record.id
        }
        return new Promise<boolean>((resolve, reject) => {
          examineBonus(params).then(e => {
            message.success('审核成功');
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <ProFormSelect
        width={'md'} name={'status'}
        options={Object.entries<string>(bonusListStates).map(([value, label]) => ({ value: (value), label }))}
        label="审核状态"
      />
    </ModalForm>
  }]

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        {/* <ProFormDatePickerMonth
          name={'period.YM'}
          placeholder="统计月份"
        /> */}
        <ProFormSelect
          width={250} name={'period'}
          options={newPeriodArr}
          placeholder="统计月份"
        />
        <ProFormSelect
          width={'md'} name={'status'}
          options={Object.entries<string>(bonusListStates).map(([value, label]) => ({ value: Number(value), label }))}
          placeholder="申请单状态"
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<BonusList>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<BonusListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideDeleteButton
      hideCreateButton
      hideEditButton
      rowActions={rowActions}
      editPage={(params: any) => <BonusListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ bonusList }: RootState) => ({
  entity: bonusList.entity,
  entities: bonusList.entities,
  loading: bonusList.loading,
  updateSuccess: bonusList.updateSuccess,
  createSuccess: bonusList.createSuccess,
  total: bonusList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BonusListList);