import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page";
import { createEntity, getEntity, reset, updateEntity } from "./wholesale.reducer";
import { WholesaleProduct } from "model/product/wholesale-product.model";
export interface WholesaleEditProps extends EditStateAndDispatchProps<WholesaleProduct>, StateProps, DispatchProps, RouteComponentProps {
}

const WholesaleEdit: FC<WholesaleEditProps> = props => {
  const {
    ...editProps
  } = props;

  return (
    <EditPage<WholesaleProduct>
      title="推广商品"
      {...editProps}
    >
      
    </EditPage>);
};

const mapStateToProps = ({ wholesaleProduct }: RootState) => ({
  entity: wholesaleProduct.entity,
  updateSuccess: wholesaleProduct.updateSuccess,
  updating: wholesaleProduct.updating,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WholesaleEdit);
