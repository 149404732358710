import { fetchPriceQuery } from "./product.reducer";
import { useEffect, useState } from "react";
import { Drawer, Spin } from "antd";
import './priceQuery.less'
import { internationalList } from "components/IntelFormText";
import imgNoData from '../../../assets/nodata.svg'

const RechargeModel = (props: any) => {
    let { visible, productId, onCancel } = props
    const [list, setList] = useState<any>([]);
    const [loading, setLoading] = useState(false);

    useEffect(() => {
        if (productId) {
            setLoading(true)
            fetchPriceQuery({ productId: productId }).then((e: any) => {
                setList(e.data || [])
                setLoading(false)
            }).catch(() => {
                setLoading(false)
                setList([])
            })
        }

    }, [productId])

    // 遍历数据
    const dataList = list.map((item: any, index: number) => (
        <div className={`section  ${index > 0 ? 'noborder' : ''} `} key={index}>
            <div className="left-col">
                {index === 0 ? <div className="title" >规格名称</div> : ''}
                <div className="name">{item.specName || '-'}</div>
            </div>
            <div className="right-col">

                {item.skuList.map((sonList: any, sonindex: number) => (
                    <div className="right-col-wrapper" key={sonindex}>
                        <div className="info-left">
                            <div className={`name-col  ${sonindex === 0 ? 'border-nonetop' : ''}`}>
                                {index === 0 && sonindex === 0 ? <div className="title">尺码</div> : ''}
                                <div className="name">{sonList.secondSpec || '-'}</div>
                            </div>
                            <div className={`name-col  ${sonindex === 0 ? 'border-nonetop' : ''}`}>
                                {index === 0 && sonindex === 0 ? <div className="title">销售价</div> : ''}
                                <div className="name">{sonList.retailPrice || '-'}</div>
                            </div>
                        </div>
                        <div className="info-right">
                            {sonList.details.map((grandson: any, grandsonindex: number) => (
                                <div className="info-row" key={grandsonindex}>
                                    {index === 0 && sonindex === 0 ? <div className="info-row-col">
                                        <p>{internationalList(grandson.levelName)}</p>
                                        <p className="discount">折扣价: {grandson.discountRate}</p>
                                    </div>
                                        : ''}
                                    <div className={`info-row-col`}>{grandson.purchasePrice}</div>
                                </div>
                            ))}

                        </div></div>
                ))}

            </div>
        </div >
    ))


    return (
        <Drawer title="价格明细" width={1300} placement="right" onClose={onCancel} visible={visible} className="fundDetail">
            <div className="price-wrapper">
                {dataList}
            </div>

            <Spin spinning={loading} wrapperClassName='spin'>
                {dataList.length === 0 &&
                    <div style={{display: 'flex',alignItems:'center',justifyContent:'center',marginTop: '20px'}}>
                        <img alt="" style={{width:'85px'}} src={imgNoData}></img>
                    </div>
                }</Spin>
        </Drawer>
    )
}

export default RechargeModel