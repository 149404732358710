import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import LevelDetails from "./level-details";
import { deleteEntity, getEntities, getEntity } from "./level.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import LevelEdit from "./level-edit";
import { Level } from "model/dealer/level.model";
import { internationalList } from "components/IntelFormText";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Level>[] = [{
  title: '等级名称',
  dataIndex: 'levelName',
  render: (dom, record) => internationalList(record.levelName || '')
},
{
  title: '等级编码',
  dataIndex: 'levelNo',
},
{
  title: '等级类型',
  dataIndex: 'levelTypeName'
},
{
  title: '是否对外招商',
  dataIndex: 'canBusinessEnter',
  render: (text) => {
    return text === true ? '是' : '否'
  }
},
{
  title: '是否为6S中心',
  dataIndex: 'isCenter',
  render: (text) => {
    return text === true ? '是':'否'
  }
},
{
  title: '货款账户显示名称',
  dataIndex: 'paymentType',
  render: (dom, record) => internationalList(record.paymentType || '')
},
{
  title: '排序',
  dataIndex: 'levelSort',
}];


const LevelList: FC<LevelListProps> = (props) => {
  const searchFields = [{
    label: '等级名称',
    name: 'levelName.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
          <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Level>
      columns={columns}
      hideDeleteButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<LevelDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      sort="levelSort"
      editPage={(params: any) => <LevelEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ dealerLevel }: RootState) => ({
  entity: dealerLevel.entity,
  entities: dealerLevel.entities,
  loading: dealerLevel.loading,
  updateSuccess: dealerLevel.updateSuccess,
  createSuccess: dealerLevel.createSuccess,
  total: dealerLevel.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelList);