import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AddressDetails from './address-details';
import AddressEdit from './address-edit';
import AddressList from './address-list';

const Address: React.FC<RouteComponentProps> = () => (
  <Router>
    <AddressList path="/" />
    <AddressDetails path=":id" />
    <AddressEdit path=":id/edit" />
    <AddressEdit path="new" />
  </Router>
);
export default Address;
