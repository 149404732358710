import React, {ReactNode, useEffect, useState} from "react"
import {Form, FormInstance} from "antd";
import {navigate, RouteComponentProps} from '@reach/router';
import {CrudGetAction, CrudPostAction, CrudPutAction} from 'reducers/redux-action.type';
import {BaseModel} from 'model/base.model';
import { ModalForm } from "@ant-design/pro-form";

export interface EditStateAndDispatchProps<T> {
  id?: number;
  entity: T;
  updateSuccess: boolean;
  updating: boolean;
  getEntity: CrudGetAction<T>;
  createEntity: CrudPostAction<T>;
  updateEntity: CrudPutAction<T>;
  reset: (entity?: T) => (dispatch: any) => any;
  beforeUpdateEntity?: (entity?: T) => Promise<T>;
}

export interface EditPageProps<T> extends RouteComponentProps, EditStateAndDispatchProps<T> {
  children?: ReactNode;
  title?: string;
  width?: number;
  form?: FormInstance;
}

const EditPage = <T extends BaseModel>(props: EditPageProps<T>) => {
  const { id, title, entity, getEntity, createEntity, updateEntity, updateSuccess, reset, width=800, beforeUpdateEntity, } = props;

  // 读取数据
  useEffect(() => {
    // 编辑，取对象数据
    !!id && getEntity(id);
    // 新建，重置对象
    !id && reset();
  }, [id, getEntity, reset]);

  // 显示对话框
  const [visible, setVisible] = useState(false);
  useEffect(() => {
    setVisible(true);
  }, []);

  // 修改成功，关闭对话框
  useEffect(() => {
    // 修改成功，跳转详情页
    if (updateSuccess) {
      navigate(-1).then(() => setVisible(false));
    }
  }, [updateSuccess]);

  const [form] = Form.useForm(props.form);
  // 设置form的值
  useEffect(() => {
    // eslint-disable-next-line eqeqeq
    !!entity && ((!id && !entity?.id) || (entity?.id == id)) && form.setFieldsValue(entity);
  }, [entity, form,id]);

  return (
    <ModalForm<T>
      title={!id ? `新建${title}` : `编辑${title}`}
      visible={visible}
      //preserve={false}
      modalProps={{
        destroyOnClose: true,
        onCancel: () => navigate(-1).then(() => setVisible(false)),
      }}
      width={width}
      form={form}
      onFinish={async values => {
        const newEntity = {
          ...entity,
          ...values,
        };
        const theEntity = (beforeUpdateEntity && await beforeUpdateEntity(newEntity)) || newEntity;
        !!id && await updateEntity(theEntity);
        !id && await createEntity(theEntity);
      }}
    >
      {props.children}
    </ModalForm>);
};

export default EditPage;
