import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import RewardListDetails from "./reward-details";
import { deleteEntity, getEntities, getEntity } from "./reward.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import RewardListEdit from "./reward-edit";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";
import { RewardList, orderStatus, payStatus, activityType } from "model/bonus/rewardList.model";
import { ProFormDatePicker, ProFormSelect } from "@ant-design/pro-form";

export interface RewardListListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const RewardListList: FC<RewardListListProps> = (props) => {

  const columns: ProColumns<RewardList>[] = [{
    title: '店铺id',
    dataIndex: 'storeId',
    width: 100,
  }, {
    title: '店铺名称',
    dataIndex: 'storeName',
    width: 100,
  }, {
    title: '订单编号',
    dataIndex: 'orderId',
    width: 100,
    ellipsis:true,
  },{
    title: '受益人code',
    dataIndex: 'incomeCustomerCode',
    width: 100,
  },{
    title: '受益人姓名',
    dataIndex: 'incomeCustomerName',
    width: 100,
  },{
    title: '受益人等级',
    dataIndex: 'incomeCustomerLevel',
    width: 100,
  },{
    title: '奖励类型',
    dataIndex: 'activityType',
    valueEnum: activityType,
    width: 100,
  },{
    title: '奖励金额',
    dataIndex: 'rewardAmount',
    width: 100,
  },{
    title: '下单人id',
    dataIndex: 'customerId',
    width: 100,
  },{
    title: '下单人code',
    dataIndex: 'customerCode',
    width: 100,
  },{
    title: '下单人姓名',
    dataIndex: 'customerName',
    width: 100,
  },{
    title: '下单人等级',
    dataIndex: 'customerLevel',
    width: 100,
  },{
    title: '结算状态',
    dataIndex: 'orderStatus',
    valueEnum: orderStatus,
    width: 100,
  },{
    title: '提现状态',
    dataIndex: 'payStatus',
    valueEnum: payStatus,
    width: 100,
  }]

  const searchFields = [{
    label: '订单id',
    name: 'orderId',
  }, {
    label: '经销商授权号',
    name: 'incomeCustomerCode',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="storeId" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <ProFormSelect
          width={'md'} name={'activityType'}
          options={Object.entries<string>(activityType).map(([value, label]) => ({ value: (value), label }))}
          placeholder="奖励类型"
        />
        <ProFormSelect
          width={'md'} name={'orderStatus'}
          options={Object.entries<string>(orderStatus).map(([value, label]) => ({ value: (value), label }))}
          placeholder="订单状态"
        />
        <ProFormSelect
          width={'md'} name={'payStatus'}
          options={Object.entries<string>(payStatus).map(([value, label]) => ({ value: (value), label }))}
          placeholder="是否已经结算"
        />
        <ProFormDatePicker
          name={'beginDate.YMD'}
          placeholder="开始时间"
        />
        <ProFormDatePicker
          name={'endDate.YMD'}
          placeholder="结束时间"
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<RewardList>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RewardListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideDeleteButton
      hideCreateButton
      hideActionColumn
      hideEditButton
      editPage={(params: any) => <RewardListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ rewardList }: RootState) => ({
  entity: rewardList.entity,
  entities: rewardList.entities,
  loading: rewardList.loading,
  updateSuccess: rewardList.updateSuccess,
  createSuccess: rewardList.createSuccess,
  total: rewardList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RewardListList);