import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './address.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Address } from "model/store/address.model";

export interface AddressDetailsProps extends DetailsStateAndDispatchProps<Address>,
  RouteComponentProps, StateProps, DispatchProps {
}
const AddressDetails: FC<AddressDetailsProps> = (props) => {
  return (
    <DetailsPage<Address>
      title={'退货地址'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Address>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ address }: RootState) => ({
  entity: address.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressDetails);
