import { Col, Form, Row } from "antd";
import { FC, useEffect, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity } from "./modifyDealer.reducer";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ProFormDependency, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import Title from 'antd/lib/typography/Title';
import { fetchCustomerListSelect1 } from "pages/dealer/unit/unit.reducer";



const AdvertisementList: FC<any> = (props: any) => {
    const { form, regionalOwnershipId } = props;
    const [regionalHeadPeople, setRegionalHeadPeople]: any = useState(undefined);

    useEffect(() => {

        setRegionalHeadPeople(regionalOwnershipId)
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props]);


    const columns: ProColumns<any>[] = [{
        title: '授权号',
        dataIndex: 'code',
        valueType: 'text',
      
    },
    {
        title: '姓名',
        dataIndex: 'name',
        valueType: 'text',
       
    },
    {
        title: '手机号',
        dataIndex: 'phone',
        valueType: 'text',
        
    },
    {
        title: '绑定时间',
        dataIndex: 'bindingTime',
        valueType: 'text',
        
    }];

    const additionActions = [
         <Title style={{ fontSize: '14px', lineHeight: '32px' }} level={5} className='bind-recard-title'>绑定记录</Title>
      ];



    return (
        <div className="modifyDealer">
            <Row><Col span={24}> <Title style={{ fontSize: '14px', lineHeight: '32px' }} level={5}>绑定经销商</Title></Col></Row>
            <Row className="borderBtm">
                {<>
                    <Col span={5}>
                        <ProFormSelect
                            name="test_1"
                            options={[{
                                label: "授权号搜索", value: 'account.equals',
                            }, {
                                label: "姓名搜索", value: 'name.contains',
                            }, {
                                label: "手机号搜索", value: 'phone.equals',
                            }]}
                            placeholder="搜索类型"
                        />
                    </Col>
                    <Col span={1} />
                    <Col span={8}>
                    <ProFormText
                            name="customerId"
                            disabled
                            hidden
                        />
                        <ProFormText
                            name="customerNo"
                            disabled
                            hidden
                        />
                        <ProFormText
                            name="customerName"
                            disabled
                            hidden
                        />
                        <ProFormText
                            name="customerPhone"
                            disabled
                            hidden
                        />
                        <ProFormDependency name={['test_1']}>
                            {({ test_1 }) => {
                                if (test_1 === 'account.equals') {
                                    return !!test_1 && <Form.Item name="account">
                                        <SelectSearch width={300} required={true} name={['account']}
                                            placeholder="授权码" labelValue="textname" fetchOptionObj={(option: any) => {
                                                form.setFieldsValue({
                                                    customerNo: option.account,
                                                    customerName: option.name,
                                                    customerPhone: option.phone,
                                                    customerId: option.id
                                                });
                                            }} optionKey="account" defaultParams={''} fetchFunc={fetchCustomerListSelect1} searchParams={test_1} />
                                    </Form.Item>
                                } else if (test_1 === 'name.contains') {
                                    return !!test_1 && <Form.Item name="name">
                                        <SelectSearch width={300} required={true} name="name" fetchOptionObj={(option: any) => {
                                                form.setFieldsValue({
                                                    customerNo: option.account,
                                                    customerName: option.name,
                                                    customerPhone: option.phone,
                                                    customerId: option.id
                                                });
                                            }} placeholder="姓名" labelValue="textname" defaultParams={''} fetchFunc={fetchCustomerListSelect1} searchParams={test_1} />
                                    </Form.Item>
                                } else if (test_1 === 'phone.equals') {
                                    return !!test_1 && <Form.Item name="phone">
                                        <SelectSearch width={300} required={true} name="phone" fetchOptionObj={(option: any) => {
                                                form.setFieldsValue({
                                                    customerNo: option.account,
                                                    customerName: option.name,
                                                    customerPhone: option.phone,
                                                    customerId: option.id
                                                });
                                            }} placeholder="手机号" labelValue="textname" defaultParams={''} fetchFunc={fetchCustomerListSelect1} searchParams={test_1} />
                                    </Form.Item>
                                }
                            }
                            }
                        </ProFormDependency>

                    </Col>
                    <Col span={6} />
                </>}
            </Row>
           
            <ListViewPage<any>
                scrollConfig={{ x: 600, y:380 }}
                columns={columns}
                hideEditButton={true}
                hideActionColumn={true}
                hideCreateButton={true}
                hideDeleteButton={true}
                actions={additionActions}
                filters={{ 'regionalOwnershipId.equals': regionalHeadPeople }}
                detailsIndex={false}
                {...props}
            >
                {props.children}
            </ListViewPage>
        </div>
    );
};

const mapStateToProps = ({ modifyDealer }: RootState) => ({
    entity: modifyDealer.entity,
    entities: modifyDealer.entities,
    loading: modifyDealer.loading,
    total: modifyDealer.total
});

const mapDispatchToProps = {
    getEntity,
    getEntities,
    deleteEntity,
};


export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList);