import { SealAuth, defaultSealAuth } from 'model/contract/sealAuth.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'SealAuth';
const apiUrl = '/api/yumei-mall-customer/esign/seal-auth';
const standardReducerImpl = new StandardReducerImpl<SealAuth>(entity, defaultSealAuth, apiUrl);
export type SealAuthState = StandardState<SealAuth>;
export const sealAuth = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

