import React, { FC } from 'react';
import { Input, Space } from 'antd';
import '../style/temp.less';

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const Search: FC<Props> = ({ dataField }) => {
    const { Search } = Input;
    const onSearch = (value: any) => console.log(value);
    return (
        <div className="title-text" >
            <Space direction="vertical">
                <Search
                    placeholder="请输入内容"
                    allowClear
                    enterButton="搜索"
                    size="large"
                    onSearch={onSearch}
                />
            </Space>
        </div>
    )
}

export const SearchSet: FC<SetProps> = ({ dataField, id }) => {
    const { Search } = Input;
    const onSearch = (value: any) => console.log(value);
    return (
        <div className="title-text" >
            <Space direction="vertical">
                <Search
                    placeholder="请输入内容"
                    allowClear
                    enterButton="搜索"
                    size="large"
                    onSearch={onSearch}
                />
            </Space>
        </div>
    )
}

export default Search


