import { RouteComponentProps, Router } from "@reach/router";
import SplitOrderTypeList from "./split-order-type-list";
import SplitOrderTypeDetails from "./split-order-type-details";
import SplitOrderTypeEdit from "./split-order-type-edit";

const SplitOrderType: React.FC<RouteComponentProps> = () => (
    <Router>
        <SplitOrderTypeList path="/" />
        <SplitOrderTypeDetails path=":id" />
        <SplitOrderTypeEdit path=":id/edit" />
        <SplitOrderTypeEdit path="new" />
    </Router>
);
export default SplitOrderType;