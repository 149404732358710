import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import ExamDecorate from './examDecorate';
import Course from './course'
import Topic from './topic'
import Certificate from './certificate'
import MaterialCenter from './materialCenter'
import './index.less'
const Decorate: React.FC<RouteComponentProps> = () => (
  <Router>
    <Topic path="topic/*" />
    <Course path="course/*" />
    <ExamDecorate path="examDecorate/*" />
    <Certificate path="certificate/*" />
    <MaterialCenter path="material/*" />
  </Router>
);
export default Decorate;
