import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./online.reducer";
import { ProFormDigit } from "@ant-design/pro-form";
import { OnlineClassification } from "model/product/online-classification.model";
import { Form } from "antd";
import IntelFormText from "components/IntelFormText";
export interface OnlineEditProps extends EditStateAndDispatchProps<OnlineClassification>, StateProps, DispatchProps, RouteComponentProps {
}

const OnlineEdit: FC<OnlineEditProps> = props => {
  const {
    ...editProps
  } = props;
  const [form] = Form.useForm();
  
  return (
    <EditPage<OnlineClassification>
      title="商品分类"
      form={form}
      {...editProps}
    >
      <IntelFormText
        label={'分类名称'}
        name="name"
        required={true}
        form={form}
        fieldProps={{
          maxLength: 100
        }}
        value={props.entity.name || ''}
      />
       <ProFormDigit
        name="sort"
        label="排序"
        rules={[{ required: true }]}
        min={0}
      />
    </EditPage>);
};

const mapStateToProps = ({ onlineClassification }: RootState) => ({
  entity: onlineClassification.entity,
  updateSuccess: onlineClassification.updateSuccess,
  updating: onlineClassification.updating,
  createSuccess: onlineClassification.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OnlineEdit);
