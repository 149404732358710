import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SecondSettledDetails from "./secondSettled-details";
import { deleteEntity, getEntities, getEntity } from "./secondSettled.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SecondSettledEdit from "./secondSettled-edit";import { SecondSettled, openAccountStatus, paymentCompleteStatus, silentOpened } from "model/dealer/secondSettled.model";
import { ProFormDateTimePicker } from "@ant-design/pro-form";
import { ForceUpdate } from "model/system/version.model";
import DecryptText from "../customer/detailtables/decryptText";
;

export interface SecondSettledListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<SecondSettled>[] = [{
  title: '主账号信息',
  dataIndex: 'applyCustomName',
  width: 100,
  render:(dom,record:any) => {
    return <>
    <p>{record.applyCustomName}</p>
    <p>{record.applyCustomNo}</p>
    <p>******<DecryptText title='身份证号码' id={record.id} data={record?.applyIdCard} /></p>
    </>
  },
}, {
  title: '申请人等级',
  dataIndex: 'appliedCustomName',
  width: 200,
  render:(dom,record:any) => {
    const arr = record?.applyBrandList ? record?.applyBrandList.split(',') : [];
    return <>
    {arr.map((e:any) => (<p>{e}</p>))}
    </>
  },
}, {
  title: '继承账号信息',
  dataIndex: 'appliedCustomName',
  width: 180,
  render:(dom,record:any) => {
    return <>
    <p>{record.appliedCustomName}</p>
    <p>{record.appliedCustomNo}</p>
    <p>******<DecryptText title='身份证号码' id={record.id} data={record?.appliedIdCard} /></p>
    </>
  },
}, {
  title: '继承等级',
  dataIndex: 'appliedBrandList',
  width: 180,
  render:(dom,record:any) => {
    const arr = record?.appliedBrandList ? record?.appliedBrandList.split(',') : []
    return <>
    {arr.map((e:any) => (<p>{e}</p>))}
    </>
  },
}, {
  title: '连连开户',
  dataIndex: 'silentOpened',
  valueEnum: silentOpened,
  width: 100,
},{
  title: '设置支付密码',
  dataIndex: 'openAccountStatus',
  valueEnum: openAccountStatus,
  width: 100,
},{
  title: '是否签署免密授权',
  dataIndex: 'isFreeSecretPay',
  valueEnum: ForceUpdate,
  width: 100,
},{
  title: '充值打款',
  dataIndex: 'paymentCompleteStatus',
  valueEnum: paymentCompleteStatus,
  width: 100,
},{
  title: '签约合同',
  dataIndex: 'id',
  width: 100,
  render: (dom,record) => <div style={{color:'#ee2222'}}>未完成</div>
},{
  title: '完成时间',
  width: 100,
  dataIndex: 'completeTime',
}];


const SecondSettledList: FC<SecondSettledListProps> = (props) => {
  const searchFields = [{
    label: '主账号姓名',
    name: 'applyCustomName.equals',
  }, {
    label: '主账号授权号',
    name: 'applyCustomNo.equals',
  }, {
    label: '主账号身份证号',
    name: 'applyIdCard.equals',
  }, {
    label: '主账号手机号',
    name: 'applyPhone.equals',
  }, {
    label: '继承账号授权号',
    name: 'appliedCustomNo.equals',
  }, {
    label: '继承账号姓名',
    name: 'appliedCustomName.equals',
  }, {
    label: '继承账号身份证号',
    name: 'appliedIdCard.equals',
  }, {
    label: '继承账号手机号',
    name: 'appliedPhone.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormDateTimePicker
          name="completeTime.greaterThanOrEqual"
          placeholder="完成开始时间"
        />
        <ProFormDateTimePicker
          name="completeTime.lessThanOrEqual"
          placeholder="完成结束时间"
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<SecondSettled>
      columns={columns}
      hideDeleteButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<SecondSettledDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideCreateButton
      hideActionColumn
      editPage={(params: any) => <SecondSettledEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ secondSettled }: RootState) => ({
  entity: secondSettled.entity,
  entities: secondSettled.entities,
  loading: secondSettled.loading,
  updateSuccess: secondSettled.updateSuccess,
  createSuccess: secondSettled.createSuccess,
  total: secondSettled.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SecondSettledList);