import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { Complaint, defaultComplaint } from 'model/dealer/complaint.model';
import axios from 'axios';

const entity = 'Complaint';
const apiUrl = '/api/yumei-mall-customer/complainRecord/pageComplainRecord';
const standardReducerImpl = new StandardReducerImpl<Complaint>(entity, defaultComplaint, apiUrl);
export type ComplaintState = StandardState<Complaint>;
export const complaint = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 处理投诉
export const handleComplaint = async (params:any) => {
    const requestUrl = `/api/yumei-mall-customer/complainRecord`;
    const axiosResponse = await axios.put<any>(requestUrl, params);
    return axiosResponse.data;
}
  
// 获取详情
export const getDetail = async (data:any) => {
  const requestUrl = apiUrl;
  const axiosResponse = await axios.get<any>(requestUrl + '?complainNo.equals=' + data.complainNo);
  return axiosResponse.data;
}
