import axios from 'axios';
import { defaultDealerInfo, DealerInfo } from 'model/dealer/dealerInfo.model';
import { CrudGetAllAction } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'Assessment';
const apiUrl = '/api/yumei-mall-train/activity/regionRewardUnlock';
const standardReducerImpl = new StandardReducerImpl<DealerInfo>(entity, defaultDealerInfo, apiUrl);
export type AssessmentState = StandardState<DealerInfo>;
export const assessment = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;



export const getEntities: CrudGetAllAction<any> = (params?: any, download?: boolean) => dispatch => {
  delete params.time
  return dispatch({
    type: standardReducerImpl.actionType(download ? STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST : STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(apiUrl, {
      params,
    })
  });
};

// 区域详情
export const regionItemByCode = async (data:any) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-train/activity/regionItemByCode`, { params: data });
  return axiosResponse.data;
};

