import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { Payload, PayloadResult, QueryParameter } from "reducers/redux-action.type";
import axios from "axios";
import { defaultSeller, Seller } from "model/store/seller.model";
import { Store } from 'model/store/store.model';

const entity = 'store';
const apiUrl = '/api/yumei-mall-store/seller';
const standardReducerImpl = new StandardReducerImpl<Seller>(entity, defaultSeller, apiUrl);

export type StoreState = StandardState<Seller>;
export const store = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// export const createEntity: CrudPostAction<Seller> = entity => async dispatch => {
//     return await dispatch({
//       type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
//       payload: axios.post(`${apiUrl}/store/add`, cleanEntity(entity)),
//     });
// };

// 所属支行查询
export const queryBankData = async (code: string, keywords: string) => {
  const requestUrl = `${apiUrl}/branch/${code}`;
  const res = await axios.get(requestUrl, {params:{keywords}});
  return res.data;
}

// 查询店铺名称
export const fetchStoreList = async (params?: QueryParameter) : Promise<Store[]> => {
    const paramsData = {
      'selfSeller.equals':true,
      'isCenter.equals':false,
       ...params
    };
    const axiosResponse = await axios.get<Store[]>(`${apiUrl}`, {
        params: paramsData
    });

    axiosResponse.data.map((item:any)=> {
      item.label = item.name
      item.value = parseInt(item.id)
      return item
    })
    return axiosResponse.data || [];
}

// 查询定价规则
export const fetchPriceRulesList = async (params?: any) => {
  const axiosResponse = await axios.get<Store[]>(`/api/yumei-mall-pim/pricing_rules?page=0&size=100&storeId.equals=${params?.storeId}`);
  return axiosResponse.data || [];
}

// 查询仓库
export const fetchWarehouseList = async (params?: QueryParameter) : Promise<Store[]> => {
  const axiosResponse = await axios.get<Store[]>(`/api/yumei-mall-order/order/warehouse/0`, {
      params
  });
  return axiosResponse.data || [];
}

//设置团长
export const setShopWholesale = (id: string | number, flag: boolean): Payload<Seller> | PayloadResult<Seller> => async dispatch => {
  const requestUrl = `${apiUrl}/${id}/wholesale/${flag}`;
  return await dispatch({
      type: `${entity}/${STANDARD_ACTION_TYPES.UPDATE}`,
      payload: axios.post(requestUrl),
  })
};

//company
export const queryCompanyInfo = async () => {
  const requestUrl = `/api/yumei-mall-store/company`;
  const res = await axios.get(requestUrl,{
    params: {
      page: 0,
      size: 500
    }
  });
  return res.data;
}

// 查询店铺等级
export const searchStoreLevel = async (params: any) => {
  const requestUrl = `/api/yumei-mall-store/seller/level`;
  const axiosResponse = await axios.get<any>(requestUrl, { params:{...params} });
  return axiosResponse.data || []
}