import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, message, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import MenuListDetails from "./menuList-details";
import { deleteEntity, getEntities, getEntity } from "./menuList.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import MenuListEdit from "./menuList-edit";
import { MenuList } from "model/system/menuList.model";
import { DrawerForm } from "@ant-design/pro-form";
import RolePanel from "./rolePanel";
import { putActions } from "pages/user/role/role.reducer";

export interface MenuListListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<MenuList>[] = [{
  title: 'id',
  dataIndex: 'id',
}, {
  title: '名称',
  dataIndex: 'name',
}, {
  title: '编码',
  dataIndex: 'code',
}, {
  title: '上级名称',
  dataIndex: 'parent',
  render: (text, record) => {
    return <>{!!record.parent ? record.parent.name : '-'}</>
  }
}, {
  title: '上级编码',
  dataIndex: 'parent',
  render: (text, record) => {
    return <>{!!record.parent ? record.parent.code : '-'}</>
  }
}, {
  title: '所属模块名称',
  dataIndex: 'module',
  hideInTable: true,
  render: (text, record) => {
    return <>{!!record.module ? record.module.name : '-'}</>
  }
}, {
  title: '所属模块编码',
  dataIndex: 'module',
  hideInTable: true,
  render: (text, record) => {
    return <>{!!record.module ? record.module.code : '-'}</>
  }
}];


const MenuListList: FC<MenuListListProps> = (props) => {
  const { getEntity, entity } = props;
  const [form] = Form.useForm();
  const [refreshTag, setRefreshTag] = useState(0);
  const searchFields = [{
    label: '菜单名称',
    name: 'name.contains',
  },{
    label: '父级菜单名称',
    name: 'parentMenuName.contains',
  },{
    label: '模块名称',
    name: 'moduleCode.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(text: any, record: any) => {
    return record.hasActions && <DrawerForm
      title={'权限编辑'}
      trigger={<Tooltip title={'权限'} key="AuditReject"><Button type="link" size="small" >权限</Button></Tooltip>}
      drawerProps={{
        destroyOnClose: true,
      }}
      width={'84vw'}
      form={form}
      preserve={false}
      onVisibleChange={e => {
        !!e && getEntity(record.id);
        setRefreshTag(new Date().getTime());
      }}
      onFinish={async (data: any) => {
        const arr = Object.keys(data);
        let newArr: any = [];
        arr.forEach((e) => {
          newArr.push({
            actions:data[e],
            roleId:e
          })
        })
        console.log();
        return new Promise<boolean>((resolve, reject) => {
          putActions(record.id,newArr).then(e => {
            message.success('操作成功');
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <RolePanel id={record.id} refreshTag={refreshTag} form={form} entity={entity} />
    </DrawerForm>
  }]
  return (
    <ListViewPage<MenuList>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<MenuListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      rowActions={rowActions}
      editPage={(params: any) => <MenuListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ menuList }: RootState) => ({
  entity: menuList.entity,
  entities: menuList.entities,
  loading: menuList.loading,
  updateSuccess: menuList.updateSuccess,
  createSuccess: menuList.createSuccess,
  total: menuList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(MenuListList);