import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import AssortDetails from "./assort-details";
import { deleteEntity, getEntities, getEntity } from "./assort.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import AssortEdit from "./assort-edit";
import { Assort } from "model/dealer/assort.model";
import { ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";

export interface AssortListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}



const AssortList: FC<AssortListProps> = (props) => {
  const {navigate} = props;
  const { convertEnumToOptionType } = useEnumValue();
  const linkTo = (id:any) => {
    navigate && navigate('/dealer/article?categoryId.equals=' + id);
  }
  const columns: ProColumns<Assort, 'articleStatus'>[] = [{
    title: 'id',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '分类名称',
    dataIndex: 'name',
    width: 100,
  }, {
    title: '排序值',
    dataIndex: 'sort',
    width: 100,
  },  {
    title: '绑定文章数',
    dataIndex: 'articleNum',
    width: 100,
    render:(dom,record) => {
      return <p onClick={() => linkTo(record.id)} style={{color:'#1890ff', cursor:"pointer"}}>{record.articleNum || 0}</p>
    }
  },{
    title: '创建时间',
    dataIndex: 'createdDate',
    width: 100,
  }, {
    title: '分类状态',
    dataIndex: 'status',
    valueType: 'articleStatus',
    width: 100,
  }, {
    title: '备注',
    dataIndex: 'remark',
    width: 200,
    ellipsis:true,
  }];
  const searchFields = [{
    label: '分类名称',
    name: 'name.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="status.equals"
          options={convertEnumToOptionType('articleStatus')}
          placeholder="分类状态"
        />
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Assort, 'articleStatus'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<AssortDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      sort={''}
      deleteTitle="删除后该分类下所有文章均会删除，请确认已移除相关文章后在操作!"
      editPage={(params: any) => <AssortEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ assort }: RootState) => ({
  entity: assort.entity,
  entities: assort.entities,
  loading: assort.loading,
  updateSuccess: assort.updateSuccess,
  createSuccess: assort.createSuccess,
  total: assort.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssortList);