import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'reducers/action-type.util';

import { EnumValue } from 'model/base.model';

export const ACTION_TYPES = {
  FETCH_ENUM_LIST: 'metadata/FETCH_ENUM_LIST',
};

export interface Map<T> {
  [index: string]: T;
}

const initialState = {
  loading: false,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  values: {} as Map<EnumValue[]>,
};

export type MetaDataState = Readonly<typeof initialState>;

// Reducer
export const metaData = (state: MetaDataState = initialState, action: any): MetaDataState => {
  switch (action.type) {
    case REQUEST(ACTION_TYPES.FETCH_ENUM_LIST):
      return {
        ...state,
        loading: true,
        // errorMessage: '',
      };
    case FAILURE(ACTION_TYPES.FETCH_ENUM_LIST):
      return {
        ...state,
        errorMessage: action.payload,
        loading: false,
      };
    case SUCCESS(ACTION_TYPES.FETCH_ENUM_LIST):
      return {
        ...state,
        loading: false,
        values: { ...(state?.values || {}), ...action.payload.data },
      };
    default:
      return state;
  }
};

export const getEnumObjectList = () => (dispatch: any) => {

  const requestUrl = '/api/yumei-mall-store/meta-data/enums';
  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get(requestUrl),
  });

  // dispatch({
  //   type: ACTION_TYPES.FETCH_ENUM_LIST,
  //   payload: axios.get('/api/yumei-mall-bonus/meta-data/enums'),
  // });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-common/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-tenant/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-post/meta-data/enums'),
  });

  // dispatch({
  //   type: ACTION_TYPES.FETCH_ENUM_LIST,
  //   payload: axios.get('/api/yumei-mall-tag-task/meta-data/enums'),
  // });

  // dispatch({
  //   type: ACTION_TYPES.FETCH_ENUM_LIST,
  //   payload: axios.get('/api/yumei-mall-market/meta-data/enums'),
  // });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-pay/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-pim/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-purchase/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-message/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-train/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-promotion/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-customer/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-post/meta-data/enums'),
  });

  dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-logistics/meta-data/enums'),
  });

  return dispatch({
    type: ACTION_TYPES.FETCH_ENUM_LIST,
    payload: axios.get('/api/yumei-mall-promotion/meta-data/enums'),
  });
  
};

