import React, { FC, ReactNode, useState } from "react"
import { connect } from "react-redux";
import { deleteEntity, getEntities, getEntity, importStock } from './stock.reducer';
import { RootState } from "reducers";
import { RouteComponentProps } from "@reach/router";
import { ProColumns } from "@ant-design/pro-table";
import SkuDetails from './stock-details';
import { Button, Dropdown, Form, Input, Menu, Space, Image } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { ColumnsProductStatus } from 'model/product/product.model';
import { DownOutlined } from "@ant-design/icons";
import ImportDataDialog from "upload/import-data-dialog";
import { useEnumValue } from "common/enum/use-enum-value";
import { Stock } from "model/product/stock.model";
import Authorized from "common/auth/authorized";
import ListViewPage from "layouts/page-wraper/list-view-page";
import StockEdit from "./stock-edit";
import { internationalList } from "components/IntelFormText";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";

export interface StockListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const columns: ProColumns<Stock>[] = [{
  title: '商品信息',
  dataIndex: ['product', 'mainPicture'],
  valueType: 'image',
  width: 400,
  hideInDescriptions: true,
  render: ((dom: any, record: any) =>
    <Space className="product-list-picture" style={{width: 'auto'}}>
      <Image src={internationalList(record.product.mainPicture || '')} />
      <ul>
        <li className="title">{internationalList(record?.product?.name || '')}</li>
        <li>商品编码：<span>{record?.product?.code}</span></li>
        <li>外部商品编码：<span>{record?.product?.externalCode}</span></li>
      </ul>
    </Space>
  )
}, {
  title: 'Spu Id',
  dataIndex: ['product', 'id'],
  valueType: 'text',
}, {
  title: 'Sku Id',
  dataIndex: ['sku', 'id'],
  valueType: 'text',
}, {
  title: 'Sku Code',
  dataIndex: ['sku', 'code'],
  valueType: 'text',
}, {
  title: '批发商',
  dataIndex: ['shopName'],
  valueType: 'text',
  width: 150,
}, {
  title: '商品规格',
  valueType: 'text',
  width: 150,
  render: (dom, record) => {
    const specs = record.sku && record.sku.specs;
    return <>{specs?.map(res => <p style={{ marginBottom: '0' }}>{res.k + ":" + res.v + "；"}</p>)}</>
  },
}, {
  title: '可售库存',
  dataIndex: 'quantity',
  valueType: 'digit',
  hideInSearch: true,
  width: 80,
}];

const downloadColumns: ProColumns<Stock>[] = [{
  title: '商品名称',
  dataIndex: 'name',
}, {
  title: '商品id',
  dataIndex: 'id',
}, {
  title: '商家id',
  dataIndex: 'sellerId',
}, {
  title: 'SPU编码',
  dataIndex: 'spuCode',
}, {
  title: 'skuId',
  dataIndex: 'skuId',
}, {
  title: 'skuCode',
  dataIndex: 'skuCode',//////////
}, {
  title: '品牌',
  dataIndex: "brandName",
}, {
  title: '商品类目',
  dataIndex: "categoryName",
}, {
  title: '商品规格',
  dataIndex: 'specs',
}, {
  title: '售价',
  dataIndex: 'retailPrice',
}, {
  title: '成本价',
  dataIndex: 'costPrice',
}, {
  title: '状态',
  dataIndex: 'status',
  valueEnum: ColumnsProductStatus,
}, {
  title: '可售库存',
  dataIndex: 'quantity',
}, {
  title: '每人限购数量',
  dataIndex: 'maxPerPerson',
}, {
  title: '每单限购数量',
  dataIndex: 'maxPerOrder',
}, {
  title: '商品标签',
  dataIndex: 'tags',
}];


const uploadStockColumns: ProColumns<Stock>[] = [{
  title: 'skuId',
  dataIndex: 'skuId',
}, {
  title: '可售库存',
  dataIndex: 'quantity',
}, {
  title: '批发商id',
  dataIndex: 'wholesaleShopId',
}];

const StockList: FC<StockListProps> = (props) => {
  const { getEnumValueList } = useEnumValue();
  const [whetherRequest, setWhetherRequest] = useState(true);

  const searchFields: SearchField[] = [{
    label: '商品名称',
    name: 'productName.contains',
  }, {
    label: 'SkuId',
    name: 'skuId.equals',
    dataType: 'number'
  }, {
    label: 'SkuCode',
    name: 'skuCode.equals',
    dataType: 'number'
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const uploadMenu = (
    <Menu>
      <Menu.Item key="uploadPrice">
        <ImportDataDialog columns={uploadStockColumns}
          title="库存修改(绝对调整)"
          uploadParams={{ adjustmentType: 'Absolute' }}
          uploadEntity={props.importStock}
          fieldListErrors={props.fieldListErrors}
        />
      </Menu.Item>
      <Menu.Item key="uploadStock">
        <ImportDataDialog columns={uploadStockColumns}
          title="库存修改(数量加减)"
          uploadParams={{ adjustmentType: 'Related' }}
          uploadEntity={props.importStock}
          fieldListErrors={props.fieldListErrors}
        />
      </Menu.Item>
    </Menu>
  );

  const exportSkuData = (data: Stock[], tags: any) => {
    let entities: Stock[] = [];
    data.forEach((res: any) => {
      let specsText = ``;
      res?.sku?.specs?.forEach((specs: any) => {
        specsText += specs.k + `:` + specs.v + `;`;
      });
      let tagsCopy = tags.productTag ? tags.productTag.concat() : [];
      let tagsText = '';
      tagsCopy.forEach((e: any) => {
        res?.product?.tags.forEach((item: any) => {
          item === e.name && (tagsText === "" ? tagsText = e.desc + ';' : tagsText = tagsText + e.desc + ';')
        })
      });
      let arr = {
        skuId: res?.sku?.id,//
        skuCode: res?.sku?.code,//
        id: res?.product?.id,
        spuCode: res?.product?.code,
        name: internationalList(res?.product?.name || ''),
        brandName: internationalList(res?.product?.brand?.brandName || ''),
        categoryName: internationalList(res?.product?.category?.name || ''),
        specs: specsText,//规格
        retailPrice: res?.sku?.retailPrice,//售价
        costPrice: res?.sku?.costPrice,//成本价
        status: res?.product?.status,
        quantity: res?.quantity,//库存
        sellerId: res?.sellerId,//商家id
        maxPerPerson: res?.sku?.maxPerPerson,
        maxPerOrder: res?.sku?.maxPerOrder,
        tags: tagsText
      };
      entities.push(arr);
    });
    return entities;
  }

  const additionActions = [
    <Authorized authority='batchUpload'>
      <Dropdown overlay={uploadMenu}>
        <Button>
          批量上传 <DownOutlined />
        </Button>
      </Dropdown>
    </Authorized>,
  ];

  return (
    <ListViewPage<Stock>
      columns={columns}
      downloadColumns={downloadColumns}
      downloadEntities={exportSkuData(props.entities, {
        productTag: getEnumValueList('productTag')
      })}
      downloadAllEntities={exportSkuData}
      downloadAllEntitiesParams={{
        productTag: getEnumValueList('productTag')
      }}
      quickFilterPanel={quickFilterPanel}
      detailPage={<SkuDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      hideDeleteButton
      actions={additionActions}
      editDisabled={(row: Stock) => row?.sku?.status === 0 ? true : false}
      showDownload
      showSearchButton
      disableDefaultRequest={whetherRequest}
      changeRequestState={setWhetherRequest} 
      editPage={(params: any) => <StockEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ stock }: RootState) => ({
  entity: stock.entity,
  entities: stock.entities,
  loading: stock.loading,
  updateSuccess: stock.updateSuccess,
  total: stock.total,
  fieldListErrors: stock.fieldListErrors,
  createSuccess: stock.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  importStock
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StockList);