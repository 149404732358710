import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import MaterialList from './material-list';
import MaterialDetails from "./material-details";
import MaterialEdit from "./material-edit";
import './index.less'

const Material: React.FC<RouteComponentProps> = () => (
    <Router>
        <MaterialList path = "/"/>
        <MaterialDetails path = ":id"/>
        <MaterialEdit path=":id/edit"/>
        <MaterialEdit path = "new"/>
    </Router>
);
export default Material;
