import axios from 'axios';
import { defaultDealerInfo, DealerInfo } from 'model/dealer/dealerInfo.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'DealerInfo';
const apiUrl = '/api/yumei-mall-customer/customer/additional';
const standardReducerImpl = new StandardReducerImpl<DealerInfo>(entity, defaultDealerInfo, apiUrl);
export type DealerInfoState = StandardState<DealerInfo>;
export const dealerInfo = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 经营类型
export const  orgTypeMap:any = {
  Company: '企业',
  PersonCompany: "个体工商户"
}

// 资料类型
export const  typeMap = {
  'PSN': '个人',
  'ORG': "企业"
}

// 开户状态
export const  silentOpenMap = {
  '0': '待激活',
  '1': "申请中",
  '2': '审核成功',
  '3': "审核失败",
}


// 证件类型
export const  credentialsTypeMap:any = {
  CRED_PSN_CH_IDCARD: '中国大陆居民身份证',
  CRED_PSN_CH_HONGKONG: "香港来往大陆通行证 (回乡证）",
  CRED_PSN_CH_MACAO: "澳门来往大陆通行证 (回乡证）",
  CRED_PSN_CH_TWCARD: "台湾来往大陆通行证(台胞证)",
  CRED_PSN_PASSPORT: "护照"
}

export const fetchLLBalance = async (id?: any) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/customer/additional/${id}/balance`);
  return axiosResponse.data;
};

export const CancelAccount = async (id?: any) => {
  const axiosResponse = await axios.post<any>(`/api/yumei-mall-customer/customer/additional/${id}/account/cancellation`);
  return axiosResponse.data;
};


export const fetchPicture = async (url?: any) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-common/oss/public/url?private-url=${url}`);
  return axiosResponse.data;
};


// 查询换绑银行卡和销户权限
export const getPaymentAuthWhitelist = async (data?: any) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-pay/lian-lian/account/getPaymentAuthWhitelist/${data.id}`);
  return axiosResponse.data;
};

// 添加权限
export const addAccount = async (data:any): Promise<any[]> => {
  return await axios.post(`/api/yumei-mall-pay/lian-lian/account/save`, data);
}


// 取消权限
export const cancelAccount = async (data:any): Promise<any[]> => {
  return await axios.post(`/api/yumei-mall-pay/lian-lian/account/delete`, data);
}


// 银行卡变更记录
export const getPaymentAccountBankcardReginfo = async (params?: any) => {
  return await axios.get<any>(`/api/yumei-mall-pay/lian-lian/account/getPaymentAccountBankcardReginfo/${params.code}`);
}

// 变更核验
export const isVerification = async (data:any): Promise<any> => {
  return await axios.post(`/api/yumei-mall-pay/lian-lian/account/getPaymentAccountBankcardReginfo/isVerification`, data);
}


// 修改账户资料
export const modifylianlianAccount = async (data:any): Promise<any> => {
  return await axios.put(`/api/yumei-mall-customer/customer/additional/${data.id}/modify-lian-lian-account`, data);
}

