/**
 * Ant Design Pro v4 use `@ant-design/pro-layout` to handle Layout.
 * You can view component api by:
 * https://github.com/ant-design/ant-design-pro-layout
 */
import ProLayout, { RouteContext } from '@ant-design/pro-layout';
import React, { useContext, useEffect, useState } from 'react';
import defaultSettings from './default-settings';
import { RouteComponentProps, Link } from '@reach/router';
import RightContent from './right-content';
import { BasicLayoutProps as ProLayoutProps } from '@ant-design/pro-layout/es/BasicLayout';
import { MenuDataItem } from '@ant-design/pro-layout/es/typings';
import menu from 'layouts/menu';
import { HeaderViewProps } from '@ant-design/pro-layout/es/Header';
import { MenuFoldOutlined, MenuUnfoldOutlined } from '@ant-design/icons/lib';
import { Breadcrumb, Button, Space } from 'antd';
import { AuthContext } from '../common/auth/authorized-route';
import './basic-layout.less';
import { Storage } from 'utils/storage-util';
import SwitchTenant from './switchTenant/swichtenant'
import { getTenantList } from './switchTenant/swichtenant.reducer';
import switchImg from '../assets/switch.png';
import { getMenuList, MenusItem } from 'common/auth/authentication';
import { createFromIconfontCN } from "@ant-design/icons";
const CustomIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_2978551_u97e99m2q0m.js", // 在 iconfont.cn 上生成
});

export interface BasicLayoutProps
  extends ProLayoutProps,
  Omit<RouteComponentProps, 'location'> {
}

// saber:saber_secret 平台 wholesale:wholesale  团长   seller:seller 商家
const BasicLayout: React.FC<BasicLayoutProps> = (props) => {
  const [switchTenantOpen, setSwitchTenantOpen] = useState(false);
  const [menuList, setMenuList] = useState([]);

  // 获取租户
  const getTent = () => {
    getTenantList().then((e: any) => {
      let data = e.map((item: any) => ({
        value: item?.id,
        label: item?.name,
        tenantPlatform: item.tenantPlatform
      }))

      if (data.length === 1) {
        Storage.session.set("x-tenant", data[0].value);
        Storage.session.set("x-tenant-data", data[0] || []);
        const tenantRequest = (Storage.session.get("x-tenant-data")) || [];
        defaultSettings.title = tenantRequest.label || '商城';
      } else if (data.length > 1) {
        setSwitchTenantOpen(true)
      }
    });
  }
  useEffect(() => {
    const accountClient = Storage.session.get("x-tenant");
    getMenuList().then(e => {
      setMenuList(e.map((item: any) => {
        return {
          path: item.menuRoute,
          name: item.menuName,
          icon: <CustomIcon type="icon-huiyuanzhongxin" />,
          children: item.children.concat().map((s: any) => {
            return {
              path: s.menuCode,
              name: s.menuName,
            }
          })
        }
      }))
    })
    if (!accountClient) {
      Storage.session.set("x-tenant", 'merrige');
      // getTent()
    } else {
      const tenantRequest = (Storage.session.get("x-tenant-data")) || [];
      defaultSettings.title = tenantRequest.label || '商城';
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const headerContentRender = (props: HeaderViewProps) => (
    <RouteContext.Consumer>
      {({ collapsed, breadcrumb, isMobile }) =>
        <Space>
          {!isMobile &&
            <Button
              type="text"
              size="large"
              icon={collapsed ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
              onClick={() => props.onCollapse && props.onCollapse(!collapsed)}
            />}
          <Breadcrumb {...breadcrumb} />
        </Space>}
    </RouteContext.Consumer>);
  return (
    <>
      {menuList && <ProLayout
        menuHeaderRender={(logo, title) => (
          <div className="menu-header" onClick={() => setSwitchTenantOpen(true)} >
            {/* <div className="img">{logo}</div> */}
            <div className="title">{title}</div>

            {/* 切换租户 */}
            <div className='switch-tenant'><img title='切换租户' style={{ width: '26px', height: 'auto' }} src={switchImg} alt="" /> </div>
            <SwitchTenant visible={switchTenantOpen} onCancel={() => {
              setSwitchTenantOpen(false)
            }} onSuccess={(title: string) => {
              defaultSettings.title = title || '商城';
            }} />

          </div>
        )}
        menuItemRender={(menuItemProps, defaultDom) => {
          if (menuItemProps.isUrl || !menuItemProps.path) {
            return defaultDom;
          }
          return <Link to={menuItemProps.path}>{defaultDom}</Link>;
        }}
        menuDataRender={() => menuList}
        headerContentRender={headerContentRender}
        rightContentRender={() => <RightContent />}
        style={{
          maxHeight: '100vh',
        }}
        disableContentMargin={true}
        // splitMenus={true}
        {...props}
        {...defaultSettings}
      >
        {props.children}
      </ProLayout>}
    </>);
};

export default BasicLayout;
