import { BaseModel } from "../base.model";

export interface Achievement extends BaseModel {
  platform?: string;
  id?: number;
  name?: string;
  storeId?: number;
  achievementDiscount?: number;
  ignored?: boolean;
  computeEducation?: boolean;
  special?: boolean;
  config?: Config;
  bonusCategory?: string;
  bonusSubCategory?: string;
  bonusTipType?: string;
  calculatePoint?: boolean;
}

interface Config {
  bonusComputeStrategy?: string;
  a?: number;
  b?: number;
  c?: number;
  d?: number;
  e?: number;
  f?: number;
  f1?: number;
  familyReturnAmount?: number;
  parentReturnAmount?: number;
  regionReturnAmount?: number;
  selfReturnAmount?: number;
  computeType?: number;
  distributorAllocateRatio?: number;
  familyRatio?: number;
  parentRatio?: number;
  regionRatio?: number;
  selfRatio?: number;
}

export const bonusComputeStrategy = {
  'Default': '默认费率',
  'FixedAmount': '固定费率',
  'DistributorRatio': '(平台统购价-结算价)*分配给代理的比例',
}

export const computeType = {
  1: '秒杀商品，实付款金额固定比例返点',
  2: '毛利方式，平台统购价-结算价的方式计算返点'
}

export const defaultAchievement: Readonly<Achievement> = {};