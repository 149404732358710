import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ContractSignDetails from './sign-details';
import ContractSignEdit from './sign-edit';
import ContractSignList from './sign-list';

const ContractSign: React.FC<RouteComponentProps> = () => (
  <Router>
    <ContractSignList path="/" />
    <ContractSignDetails path=":id" />
    <ContractSignEdit path=":id/edit" />
    <ContractSignEdit path="new" />
  </Router>
);
export default ContractSign;
