import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from './wallet.reducer';
import { Wallet } from "model/customerCore/wallet.model";
import { ProFormText } from "@ant-design/pro-form";

export interface WalletEditProps extends EditStateAndDispatchProps<Wallet>, StateProps, DispatchProps, RouteComponentProps {
}

const WalletEdit: FC<WalletEditProps> = props => {
  const { entity } = props;
  return (
    <EditPage<any>
      title="钱包"
      {...props}
      entity={entity}
    >
      <ProFormText
            name="userId"
            label="用户id"
          />
   
        {entity.accountType}
       

    </EditPage>);
};

const mapStateToProps = ({ wallet }: RootState) => ({
  entity: wallet.entity,
  updateSuccess: wallet.updateSuccess,
  updating: wallet.updating,
  createSuccess: wallet.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WalletEdit);
