import { createStore, applyMiddleware } from 'redux';
import promiseMiddleware from 'redux-promise-middleware';
import thunkMiddleware from 'redux-thunk';
import reducer, { RootState } from '../reducers';
import errorMiddleware from './error-middleware';
import notificationMiddleware from './notification-middleware';
import loggerMiddleware from './logger-middleware';
import { loadingBarMiddleware } from 'react-redux-loading-bar';
import {composeWithDevTools} from 'redux-devtools-extension';

const defaultMiddlewares = [
  thunkMiddleware,
  errorMiddleware,
  notificationMiddleware,
  promiseMiddleware,
  loadingBarMiddleware(),
  loggerMiddleware,
];
const composedMiddlewares = (middlewares: any) => {
  return composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares));
  // return process.env.NODE_ENV === 'development'
  //     ? composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares))
  //     : composeWithDevTools(applyMiddleware(...defaultMiddlewares, ...middlewares));
}

const initialize = (initialState?: RootState, middlewares = []) => createStore(reducer, initialState, composedMiddlewares(middlewares));

export default initialize;
