
import axios from 'axios';
import { defaultGroup, Group } from 'model/customerCore/group.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'group';
const apiUrl = '/api/yumei-mall-customer/tag/group';
const standardReducerImpl = new StandardReducerImpl<Group>(entity, defaultGroup, apiUrl);

export type GroupState = StandardState<Group>;
export const group = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchCustomerGroupList = async (params?: any) => {
  const data = await axios.get<any>(apiUrl, { params:{...params} });
  return data.data;
}