import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import EquityDetails from './equity-details';
import EquityEdit from './equity-edit';
import EquityList from './equity-list';

const Equity: React.FC<RouteComponentProps> = () => (
  <Router>
    <EquityList path="/" />
    <EquityDetails path=":id" />
    <EquityEdit path=":id/edit" />
    <EquityEdit path="new" />
  </Router>
);
export default Equity;
