import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form, Row, Col, Switch, message } from "antd";
import { createEntity, getEntity, reset, updateEntity, getPaymentAuthWhitelist, addAccount, cancelAccount } from './dealerInfo.reducer';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Level } from "model/dealer/level.model";
import { ProFormDatePicker, ProFormText } from "@ant-design/pro-form";
import { DealerInfo } from 'model/dealer/dealerInfo.model';
import { searchSafeCode } from "../customer/detailtables/decryptText";

export interface DealerInfoEditProps extends EditStateAndDispatchProps<DealerInfo>, StateProps, DispatchProps, RouteComponentProps {
}

const LevelEdit: FC<DealerInfoEditProps> = props => {
    const { entity } = props;
    const [form] = Form.useForm();
    const [entityCopy, setEntityCopy] = useState<any>({});
    let [bindCardChange, setbindCardChange] = useState<any>(false);
    let [logout, setLogout] = useState<any>(false);
    useEffect(() => {
        getBrandList();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props])

    useEffect(() => {
        if (!!entity.id) {
            const entityCopy = JSON.parse(JSON.stringify(entity));
            searchSafeCode(entity.id || '', entity.mobile || '').then(e => {
                entityCopy.mobile = e;
                searchSafeCode(entity.id || '', entity.customerIdCardNum || '').then(e => {
                    entityCopy.customerIdCardNum = e;
                    setEntityCopy(entityCopy);
                });
            });
        }
    }, [entity]);

    // 获取品牌列表
    const getBrandList = () => {
        if (props.entity.code) {
            getPaymentAuthWhitelist({
                id: props.entity.code
            }).then((e) => {
                let array = e
                var typeList = Array.from(array, ({ type }) => type);
                if (typeList.length > 0) {
                    setbindCardChange(typeList.includes('1'))
                    setLogout(typeList.includes('2'))
                }
            }).catch((e) => {

            })
        }
    }

    const chagessq = (type: any, caozuo: any) => {
        let name = type === '1' ? '换绑银行卡' : type === '2' ? '销户' : type === '3' ? '6s强制认证' : ''
        if (caozuo) {
            addAccount({
                code: props.entity.code,
                type: type
            }).then((e) => {
                message.success(`${name}已开启`);
            }).catch((e) => {
                message.error(e);
            })
        } else {
            cancelAccount({
                code: props.entity.code,
                type: type
            }).then((e) => {
                message.success(`${name}已关闭`);
            }).catch((e) => {

            })
        }
    }
    return (
        <EditPage<Level>
            title="经销商资料"
            form={form}
            {...props}
            submitButtonDisabled={true}
            entity={entityCopy}
        >
            <Row>
                <Col span={11}>
                    <ProFormText
                        name="realName"
                        label="真实姓名"
                    />
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <ProFormText
                        name="mobile"
                        label="手机号"
                    />
                </Col>
            </Row>

            <Row>
                <Col span={11}>
                    <ProFormText
                        name="phoneRegion"
                        label="区号"
                    />
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <ProFormText
                        name="email"
                        label="邮箱"
                    />
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <ProFormText
                        name="customerIdCardNum"
                        label="身份证号码"
                    />
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <ProFormText
                        name="bankCardNum"
                        label="银行卡号"
                    />
                </Col>
            </Row>

            <Row>
                <Col span={11}>
                    <ProFormText
                        name="creditCode"
                        label="社会统一信用代码"
                    />
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <ProFormText
                        name="orgName"
                        label="企业名称"
                    />
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <ProFormText
                        name="bankName"
                        label="开户行名称"
                    />
                </Col>
                <Col span={1} />
                <Col span={11}>
                    <ProFormText
                        name="bankCode"
                        label="开户行编码"
                    />
                </Col>
            </Row>

            <Row>
                <Col span={4}>
                    <ProFormDatePicker
                        label='营业期限到期时间'
                        placeholder='选择日期'
                        name='tradeExpiredDate'
                        width='md'
                    />
                </Col>

                <Col span={1} />
                <Col span={3}>
                    <Form.Item name={['bindCardChange']} label='是否开启换绑银行卡'>
                        <Switch checked={bindCardChange} onChange={(e) => {
                            setbindCardChange(e)
                            chagessq('1', e)

                        }} />
                    </Form.Item>
                </Col>
                <Col span={1} />
                <Col span={3}>
                    <Form.Item name={['logout']} label='是否开启销户'>
                        <Switch checked={logout} onChange={(e) => {
                            setLogout(e)
                            chagessq('2', e)
                        }} />
                    </Form.Item>
                </Col>
                <Col span={1} />
            </Row>
        </EditPage>);
};

const mapStateToProps = ({ dealerInfo }: RootState) => ({
    entity: dealerInfo.entity,
    updateSuccess: dealerInfo.updateSuccess,
    updating: dealerInfo.updating,
    createSuccess: dealerInfo.createSuccess
});

const mapDispatchToProps = {
    getEntity,
    createEntity,
    updateEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(LevelEdit);
