import axios from 'axios';
import { TrainingList, defaultTrainingList } from 'model/dealerStore/trainingList.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'TrainingList';
const apiUrl = '/api/yumei-mall-store/train-apply';
const standardReducerImpl = new StandardReducerImpl<TrainingList>(entity, defaultTrainingList, apiUrl);
export type TrainingListState = StandardState<TrainingList>;
export const trainingList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 审核
export const auditRefuse = async (entity:any) => {
    const requestUrl = `${apiUrl}/${entity.id}/audit`;
    const axiosResponse = await axios.post<any>(requestUrl, entity);
    return axiosResponse;
  }

  // 处理状态
export const statusMap:any = {
  Processed: '已处理',
  Untreated: "未处理"
}

