import {FC, useEffect} from 'react';
import React from 'react';
import {connect} from 'react-redux';
import { RootState } from 'reducers';
import { EnumValue } from 'model/base.model';
import {getEnumObjectList} from 'reducers/metadata.reducer';
import { EnumValueContext } from './enum-value-context';
import {OptionsType} from 'components/model/form.model';

export interface EnumValueProviderProps extends StateProps, DispatchProps {
    children: any;
}

export const EnumValueProvider: FC<EnumValueProviderProps> = ({
                                                                children,
                                                                metaData,
                                                                getEnumObjectList}) => {
  const {values} = metaData;
  // const [enumValues, setEnumValues] = useState<{[index: string]: EnumValue[]}>({});
  useEffect(() => {
    getEnumObjectList();
  }, [getEnumObjectList]);

  return (
    <EnumValueContext.Provider
      value={{
        enumTypeNames: Object.keys(values),

        getEnumValueList: (enumType: string): EnumValue[] => values[enumType] || [],

        getEnumValueDesc: (enumType: string, value: string): string =>
          (values[enumType] || []).find(enumValue => enumValue.name === value)?.desc || '',

        convertEnumToOptionType: (enumType: string): OptionsType => (values[enumType] || []).map(enumValue => ({
          value: enumValue.name,
          label: enumValue.desc || '',
        }))
      }}
    >
      {children}
    </EnumValueContext.Provider>);
};

const mapStateToProps = ({ metaData }: RootState) => ({
  metaData,
});

const mapDispatchToProps = {
  getEnumObjectList,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EnumValueProvider);
