import {BaseModel, DisableModel} from 'model/base.model';

export interface ContractTemplate extends BaseModel, DisableModel {
  id?: number | string;
  componentMap?: any;
  levelName?: string;
  storeName?: string;
  partyaSignAreas?: any;
  partybSignAreas?: any;
}

export const defaultContractTemplate: Readonly<ContractTemplate> = {
};
