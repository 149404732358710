import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import Rewardconfig from './rewardconfig';
import './index.less'

const Market: React.FC<RouteComponentProps> = () => (
  <Router>
    <Rewardconfig path="rewardconfig/*" />
  </Router>
);
export default Market;
