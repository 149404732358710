import { Button, Form, Input, message, Image } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { FC, ReactNode, useState } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity, auditRefuse } from "./salonReimbursmt.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ModalForm, ProFormRadio, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";

export interface salonReimbursmtListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const SalonReimbursmtList: FC<salonReimbursmtListProps> = (props) => {
  let [okText, setOkText] = useState<any>('确认');
  const [refreshFlag, setRefreshFlag] = useState(0);

  const columns: ProColumns<any>[] = [{
    title: '会议编号',
    dataIndex: 'activityNo',
    width: 90
  }, {
    title: '创建人姓名',
    dataIndex: 'createBy',
    ellipsis: true,
    width: 70
  },
  {
    title: '创建人授权号',
    dataIndex: 'applyPeopleNo',
    width: 70
  }, {
    title: '会议类型',
    dataIndex: 'activityType',
    render: (dom, record: any) => {
      return record.activityType === 1 ? '沙龙会' : record.activityType === 2 ? '招商会' : ''
    }
  }, {
    title: '会议名称',
    ellipsis: true,
    dataIndex: 'activityTitle',
  }, {
    title: '签到时间',
    dataIndex: 'signStartTime',
    render: (dom, record: any) => {
      return record.signStartTime + '-' + record.signEndTime
    }
  }, {
    title: '开始时间',
    dataIndex: 'orderNo',
    render: (dom, record: any) => {
      return record.activityStartTime + '-' + record.activityEndTime
    }
  }, {
    title: '签到地点',
    ellipsis: true,
    dataIndex: 'signAddress',
    width: 50
  },
  {
    title: '报名人数',
    dataIndex: 'currentSignNum',
    width: 50,
    render: (dom, record: any) => {
      return record.currentSignNum + '/' + record.signPeopleNum
    }
  }, {
    title: '报销状态',
    dataIndex: 'type',
    width: 50,
    render: (dom, record: any) => {
      return record.reimbursementExamineStatus === 1 ? '待审核' : record.reimbursementExamineStatus === 2 ? '审核通过' : record.reimbursementExamineStatus === 3 ? '审核拒绝' : '未报销'
    }
  },
  {
    title: '报销图片',
    dataIndex: 'activityImgs',
    width: 120,
    render: (text: any, row: any) => {
      if (row.examineText) {
        let dataList = JSON.parse(row.examineText).imgs.split(',')
        return <div className='query-order-name'>
          {dataList.map((item: any) => {
            return <Image style={{ width: '30px', height: 'auto', marginRight: '10px' }} src={item || ''} ></Image>
          })}

        </div>

      }
    }
  },
  {
    title: '合计总业绩',
    dataIndex: 'payTime',
    render: (text: any, row: any) => {
      if (row.examineText) {
        let examineText = JSON.parse(row.examineText)
        console.log(examineText)
        let total = 0
        if (examineText) {
          total = parseFloat(examineText.cashOrderAmount) + parseFloat(examineText.cashRechargeAchievementAmount) + parseFloat(examineText.cashRechargeAmount) + parseFloat(examineText.customerNoNum)
        }
        return total || ''
      }
    },
    width: 80
  }, {
    title: '申请时间',
    dataIndex: 'createdDate',
    width: 80
  }
  ];

  const searchFields: SearchField[] = [
    {
      label: '会议编号',
      name: 'activityNo'
    }, {
      label: '创建人姓名',
      name: 'name',
    }, {
      label: '创建人授权号',
      name: 'customerNo',
    }
  ];

  // 搜索
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={[
            {
              label: "待审核",
              value: 1,
            }, {
              label: "审核通过",
              value: 2,
            },
            {
              label: "审核拒绝",
              value: 3,
            }
          ]}
          name="reimbursementState"
          label='审核状态'
          placeholder='审核状态'
        />
      </Input.Group>
    </Form.Item>
  );


  const rowActions = [(dom: any, record: any) => {
    // record.reimbursementExamineStatus == 1 &&
    return record.reimbursementExamineStatus === 1 && <ModalForm
      title={'审核'}
      width={500}
      modalProps={{
        'okText': okText
      }}
      trigger={<Button type="link" size="small" >审核</Button>}
      // disabled={record.state === 2}
      preserve={false}
      onFinish={async (data: any) => {
        console.log(data)
        const params = {
          id: record.examineId,
          ...data
        }
        return new Promise<boolean>((resolve, reject) => {
          auditRefuse(params).then(e => {
            setRefreshFlag(new Date().getTime());
            message.success('处理成功');
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >

      <ProFormRadio.Group
        label="处理状态"
        name={'status'}
        width='md'
        fieldProps={{
          onChange: (e: any) => {
            setOkText(e.target.label)

          }
        }}
        rules={[{ required: true }]}
        options={[
          {
            label: '审核通过',
            value: 1,
          }, {
            label: '审核拒绝',
            value: 2,
          }
        ]}
      />
      {okText === '审核拒绝' && <ProFormTextArea
        name="reason"
        allowClear={true}
        label="审核备注"
      />}
    </ModalForm>
  }]


  return (
    <div className="salonReimbursmt">
      <ListViewPage
        sort=""
        rowActions={rowActions}
        refreshFlag={refreshFlag}
        actionColumnWidth={60}
        columns={columns}
        hideEditButton
        permanentParams={{ 'activityState': 6 }}
        hideDeleteButton
        hideCreateButton
        quickFilterPanel={quickFilterPanel}
        detailsIndex={false}
        {...props}
      >
        {props.children}
      </ListViewPage>
    </div>
  );
};

const mapStateToProps = ({ salonReimbursmt }: RootState) => ({
  entity: salonReimbursmt.entity,
  entities: salonReimbursmt.entities,
  loading: salonReimbursmt.loading,
  updateSuccess: salonReimbursmt.updateSuccess,
  createSuccess: salonReimbursmt.createSuccess,
  total: salonReimbursmt.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SalonReimbursmtList);