import { ModalForm } from "@ant-design/pro-form";
import { Form } from "antd";
import ProTable from "@ant-design/pro-table";
import { internationalList } from "components/IntelFormText";
import { useEffect, useState } from "react";

const RechargeModel = (props: any) => {
    let { paymentDetails, visible, onCancel, orderId } = props;
    const [newDetails, setNewDetails] = useState([]);

    useEffect(() => {
        let repeatCount = 1;
        let startIndex = 0;
        let arr: any = [];
        paymentDetails.forEach((element: any) => {
            element.payDetail.forEach((item: any, index: number) => {
                arr.push({
                    ...item,
                    parent: {
                        ...element
                    }
                })
            });
        });
        arr.forEach((item: any, index: number) => {
            if (index < arr.length - 1 && item.parent.id === arr[index + 1].parent.id) {
                arr[index].rowSpan  = 0;
                repeatCount += 1;
            } else {
                arr[startIndex].rowSpan = repeatCount;
                startIndex = index + 1;
                repeatCount = 1;
            }
        });
        setNewDetails(arr);
    }, [paymentDetails]);

    const [form] = Form.useForm();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    const columns: any = [{
        title: '商品名称',
        dataIndex: 'skuCode',
        width: 200,
        render: (text: any, row: any, index: number) => { 
            return {
                children: <div className='query-order-name'>
                    <img alt='商品图片' style={{ width: '60px', height: 'auto', marginRight: '10px', marginLeft: '10px' }} src={internationalList(row?.parent.picture || '')} />
                    <div style={{ display: 'flex', justifyItems: 'left', flexDirection: 'column', textAlign: 'left' }}>
                        <p>{internationalList(row.parent.productName || '')}</p>
                        <p>
                            规格编码：{row.parent.skuCode}
                        </p>
                    </div>
                </div>,
                props: {
                    rowSpan: row.rowSpan ? row.rowSpan : 0,
                    colSpan: 1,
                }
            };
        },
    }, {
        title: '款色码',
        dataIndex: ['parent','skuCode'],
        width: 100,
        render: (text: any, row: any) => {
            return {
                children: text,
                props: {
                    rowSpan: row.rowSpan ? row.rowSpan : 0,
                    colSpan: 1,
                }
            }
        }
    }, {
        title: '零售价',
        width: 100,
        dataIndex:['parent','originPrice'],
        render: (text: any, row: any) => {
            return {
                children: text,
                props: {
                    rowSpan: row.rowSpan ? row.rowSpan : 0,
                    colSpan: 1,
                }
            }
        }
    }, {
        title: '实际单价',
        width: 100,
        dataIndex: ['parent','actualPrice'],
        render: (text: any, row: any) => {
            return {
                children: text,
                props: {
                    rowSpan: row.rowSpan ? row.rowSpan : 0,
                    colSpan: 1,
                }
            }
        }
    }, {
        title: '数量',
        dataIndex: ['parent','quantity'],
        width: 100,
        render: (text: any, row: any) => {
            return {
                children: text,
                props: {
                    rowSpan: row.rowSpan ? row.rowSpan : 0,
                    colSpan: 1,
                }
            }
        }
    },
        {
            title: '货款类型',
            dataIndex: 'payDetail',
            width: 100,
            key: 'fromTypeName',
            render: (text: any, record: any) => {
                return {
                    children: <>{`${internationalList(record.fromTypeName)} - ${internationalList(record.toTypeName)}`}</>,
                    props: {
                        props: {
                            rowSpan: record.rowSpan ? record.rowSpan : 0,
                            colSpan: 1,
                        }
                    }
                }
            }
        },
        {
            title: '货款对象',
            dataIndex: 'belongStoreName',
            width: 100,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div>
                                {item.belongStoreName}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }, {
            title: '钱包折扣',
            dataIndex: 'discount',
            width: 100,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {item.discount}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }, {
            title: '扣款金额',
            dataIndex: 'payGoodsAmount',
            width: 100,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {item.payGoodsAmount}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }, {
            title: '赠送货款抵扣',
            dataIndex: 'payGiftAmount',
            width: 140,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {item.payGiftAmount}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }, {
            title: '拆分件数',
            dataIndex: 'divideQuantity',
            width: 100,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div>
                                {item.divideQuantity}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }, {
            title: '折合原价小计',
            dataIndex: 'deductionAmount',
            width: 140,
            render: (text: any, record: any) => {
                return {
                    children: record.parent.payDetail && record.parent.payDetail.map((item: any, indexIn: number) => {
                        return <div
                            key={item.skuId + '' + indexIn}
                            style={{
                                borderBottom: indexIn === record.parent.payDetail.length - 1 ? '' : '1px solid #f0f0f0',
                            }}
                        >
                            <div
                                style={{
                                    width: '100%',
                                    height: '40px',
                                    justifyContent: 'center',
                                    display: 'flex',
                                    alignItems: 'center'
                                }}
                            >
                                {item.deductionAmount}
                            </div>
                        </div>

                    }),
                    props: {
                       rowSpan: record.rowSpan ? record.rowSpan : 0,
                        colSpan: 1,
                    }
                }
            }
        }
    ]

    return (
        <ModalForm
            className="payDetailDialog"
            title={`单号:${orderId}-货款明细`}
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            modalProps={{
                footer: false
            }}
            width={1200}
            form={form}
            {...formItemLayout}
            layout='horizontal'
            preserve={false}
        >
            <div className="order-money">
                <div className="money-left">订单金额:</div>
                <div className="money-right"> <span> {paymentDetails[0]?.totalPayAmount ? paymentDetails[0]?.totalPayAmount : 0}  </span>元 </div>
            </div>
            <ProTable
                className="payTable"
                bordered
                options={false}
                search={false}
                columns={columns}
                dataSource={newDetails}
                pagination={false}
            />

        </ModalForm>
    )
}

export default RechargeModel