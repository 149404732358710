import { BaseModel } from "../base.model";

export interface RewardList extends BaseModel {

}

export const activityType = {
    'RecommendType': '推荐奖',
    'RegionType': '区域奖',
    'Recommend6sType': '推荐6s分红',
}

export const orderStatus = {
    '1': '待结算',
    '2': '已结算',
    '3': '已退款',
}
export const payStatus = {
    '1': '已提现',
    '2': '未提现',
}
export const defaultRewardList: Readonly<RewardList> = {};