import { BaseModel, DisableModel } from 'model/base.model';

export interface Coupon extends BaseModel, DisableModel {
    useTime?: {
        type?: string;
        days?: number;
        startTime?: string;
        endTime?: string;
    },
    receiveRange?: any;
    useTimeSE?: any;
    receiveTime?: any;
    receiveStartTime?: any;
    receiveEndTime?: any;
    showStatus?: number;
    receiveNumber?: number;
}

export const CouponShowStatus = {
    1: '未开始', 2: '进行中', 3: '已结束', 4: '已作废'
}

export const couponUserListStatus = {
    '0': '未使用', '1': '已使用', '-1': '已作废'
}

export const defaultCoupon: Readonly<Coupon> = {
};
