import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import MenuListDetails from './menuList-details';
import MenuListEdit from './menuList-edit';
import MenuListList from './menuList-list';

const MenuList: React.FC<RouteComponentProps> = () => (
  <Router>
    <MenuListList path="/" />
    <MenuListDetails path=":id" />
    <MenuListEdit path=":id/edit" />
    <MenuListEdit path="new" />
  </Router>
);
export default MenuList;
