import { SendOutGoods, defaultSendOutGoods } from 'model/scanCodeOrder/sendOutGoods.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'SendOutGoods';
const apiUrl = '/api/yumei-mall-shipment/shipment/pageShipment';
const standardReducerImpl = new StandardReducerImpl<SendOutGoods>(entity, defaultSendOutGoods, apiUrl);
export type SendOutGoodsState = StandardState<SendOutGoods>;
export const sendOutGoods = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;