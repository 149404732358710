import { FC, ReactNode, useState } from "react"
import { connect } from "react-redux";
import { deleteEntity, getEntities, getEntity, importTstEntities, importPrice, upUnderPim, scheduledPim, deleteEntitys, refreshSerch, upperLowerShelvesPim, refreshStructure, releaseInventory } from './product.reducer';
import { RootState } from "reducers";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import ProductDetails from './product-details';
import { Button, Dropdown, Form, Input, Menu, Modal, Popconfirm, Space, message, Tabs, Row, Col, Tag, Tooltip, InputNumber, Drawer, Image } from "antd";
import { Item, Product, ProductStatus } from "model/product/product.model";
import ImportDataDialog from "../../../upload/import-data-dialog";
import { CheckOutlined, ClockCircleOutlined, CloseCircleOutlined, CloseOutlined, DollarOutlined, DownOutlined, ProfileOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined, WarningOutlined } from "@ant-design/icons";
import { ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormRadio, ProFormSwitch, ProFormTextArea, ProFormSelect } from "@ant-design/pro-form";
import TabPane from "@ant-design/pro-card/es/components/TabPane";
import { fetchBrandList } from "../brand/brand.reducer";
import moment from "moment";
import { importStock, searchSkuList } from "../stock/stock.reducer";
import { useEnumValue } from "common/enum/use-enum-value";
import ProductEdit from './product-edit';
import Authorized from "common/auth/authorized";
import { internationalList } from "components/IntelFormText";
import { Achievement, bonusComputeStrategy, computeType } from "model/product/achievement.model";
import { ForceUpdate } from "model/system/version.model";
import PriceQueryDialog from './priceQueryDialog'
import { fetchStoreList } from "pages/dealer/unit/unit.reducer"; import SelectSearch from "components/select-search";
import SearchBox from "components/search-box";
import defaultPim from '../../../assets/defaultPim.png';


export interface ProductListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

export const computeStrategy = (config: any) => {
  return <>
    {
      config?.bonusComputeStrategy === 'Default' && <div>
        <p>A档返点：{config?.a}</p>
        <p>B档返点：{config?.b}</p>
        <p>C档返点：{config?.c}</p>
        <p>D档返点：{config?.d}</p>
        <p>E档返点：{config?.e}</p>
        <p>F档返点：{config?.f}</p>
        <p>F1档返点：{config?.f1}</p>
      </div>
    }
    {
      config?.bonusComputeStrategy === 'FixedAmount' && <div>
        <p>个人固定返点金额：{config?.selfReturnAmount}</p>
        <p>上级固定返点金额：{config?.parentReturnAmount}</p>
        <p>家族固定返点金额：{config?.familyReturnAmount}</p>
        <p>大区固定返点金额：{config?.regionReturnAmount}</p>
      </div>
    }
    {
      config?.bonusComputeStrategy === 'DistributorRatio' && <div>
        <p>计算类型：{Object.entries<string>(computeType).map(([value, label]) => ({ value: Number(value), label })).filter(e => e.value === config?.computeType)[0].label}</p>
        <p>分配给代理计算比例：{config?.distributorAllocateRatio}</p>
        <p>个人返点比例：{config?.selfRatio}</p>
        <p>上级返点比例：{config?.parentRatio}</p>
        <p>大区返点比例：{config?.regionRatio}</p>
        <p>家族返点比例：{config?.familyRatio}</p>
      </div>
    }
  </>
};

interface skuList {
  total: number;
  data: never[];
}

export interface Stock {
  skuId?: string | number;
  quantity?: number;
}

export interface BonusProductEditPro {
  id?: string | number;
  showModal?: string;
  productId?: string | number;
}

const downloadColumns: ProColumns<Product, 'productStatus'>[] = [{
  title: '商品名称',
  dataIndex: 'name',
}, {
  title: '商品id',
  dataIndex: 'id',
}, {
  title: '商家id',
  dataIndex: 'sellerId',
}, {
  title: '商品编码',
  dataIndex: 'spuCode',
}, {
  title: 'skuId',
  dataIndex: 'skuId',
}, {
  title: 'skuCode',
  dataIndex: 'skuCode',
}, {
  title: '品牌',
  dataIndex: 'brandName',
}, {
  title: '商品类目',
  dataIndex: 'categoryName',
}, {
  title: '商品规格',
  dataIndex: 'specs',
}, {
  title: '售价',
  dataIndex: 'retailPrice',
}, {
  title: '成本价',
  dataIndex: 'costPrice',
}, {
  title: '每人限购数量',
  dataIndex: 'maxPerPerson',
}, {
  title: '每单限购数量',
  dataIndex: 'maxPerOrder',
}, {
  title: '商品标签',
  dataIndex: 'tags',
}, {
  title: '区域模版',
  dataIndex: 'region',
}, {
  title: '上架时间',
  dataIndex: 'onScheduled',
}, {
  title: '下架时间',
  dataIndex: 'offScheduled',
}, {
  title: '状态',
  dataIndex: 'status',
  // valueEnum: ColumnsProductStatus,
  // valueType: 'productStatus',
}];
const uploadPriceColumns: ProColumns<Product>[] = [{
  title: 'skuId',
  dataIndex: 'skuId',
}, {
  title: '售价',
  dataIndex: 'retailPrice',
}];

export const bonusProductColumns: ProColumns<Achievement, 'bonusCategory'>[] = [{
  title: '业绩名称',
  dataIndex: 'name',
}, {
  title: '业绩折扣',
  dataIndex: 'achievementDiscount',
}, {
  title: '奖金分类',
  dataIndex: 'bonusCategory',
  valueType: 'bonusCategory',
}, {
  title: '奖金子分类',
  dataIndex: 'bonusSubCategory',
}, {
  title: '奖金提示类型',
  dataIndex: 'bonusTipType',
}, {
  title: '是否忽略计算奖金',
  dataIndex: 'ignored',
  valueEnum: ForceUpdate
}, {
  title: '是否计算教育奖',
  dataIndex: 'computeEducation',
  valueEnum: ForceUpdate
}, {
  title: '是否特供',
  dataIndex: 'special',
  valueEnum: ForceUpdate
}, {
  title: '奖金策略',
  dataIndex: ['config', "bonusComputeStrategy"],
  valueEnum: bonusComputeStrategy
}, {
  title: '奖金明细',
  dataIndex: 'config',
  render: (dom, record) => computeStrategy(record.config)
}];


const ProductList: FC<ProductListProps> = (props) => {
  const { upUnderPim, scheduledPim, importStock, deleteEntitys } = props;
  const [status, setStatus] = useState<string>('0');
  const [bonusModal, setBonusModal] = useState(false);
  // const [bonusData, setBonusData] = useState<Achievement[]>([]);
  const [detailShow, SetDetailShow] = useState(false);
  const [detailId, SetDetailId] = useState('');
  const [skuList, setSkuList] = useState<skuList>({ total: 0, data: [] });
  const [createSku, setCreateSku] = useState<any[]>([]);
  const [upperLowerShelvesIds, setUpperLowerShelvesIds] = useState([]);
  let [key, setKey] = useState(0);
  const [detailText, SetDetailText] = useState('');
  const { convertEnumToOptionType, getEnumValueList } = useEnumValue();
  const uploadTstColumns: ProColumns<Product>[] = [{
    title: '商品品牌',
    dataIndex: 'brand',
    valueType: 'text',
    width: 350
  }, {
    title: '商品编码',
    dataIndex: 'skuCode',
    valueType: 'text',
    width: 300
  }, {
    title: '商品名称',
    dataIndex: 'title',
    valueType: 'text',
    width: 750,
  }, {
    title: '单位',
    dataIndex: 'unitName',
    valueType: 'text',
    width: 250
  }, {
    title: '重量',
    dataIndex: 'weight',
    width: 250
  }, {
    title: '商品规格',
    dataIndex: 'specText',
    valueType: 'text',
    width: 750
  }, {
    title: '库存',
    dataIndex: 'sellerStockNum',
    valueType: 'text',
    width: 250
  }, {
    title: '划线价',
    dataIndex: 'originPrice',
    valueType: 'digit',
    width: 260
  }, {
    title: '销售价',
    dataIndex: 'retailPrice',
    valueType: 'digit',
    width: 250
  }];

  const newUploadTstColumns = uploadTstColumns.concat(convertEnumToOptionType('productTag').map(e => ({
    title: e.label,
    dataIndex: e.value,
    valueType: 'text',
    hideInTable: true,
  }
  )));

  const columns: ProColumns<Product, 'productStatus'>[] = [{
    title: '商品信息',
    dataIndex: 'mainPicture',
    valueType: 'image',
    width: 300,
    hideInDescriptions: true,
    render: ((dom: any, record: any) => {
      return <Space className="product-list-picture product-list-picture-pim">
        <Image src={internationalList(record.mainPicture || '') || defaultPim} />
        <ul>
          <li><span className="product-name" onClick={() => { SetDetailShow(true); SetDetailText(new Date() + ''); SetDetailId(record.id); }}>{internationalList(record.name || '')}</span></li>
          {record?.tags && record?.tags.length > 0 && <li>{record.tags.map((e: string) => {
            const arr = convertEnumToOptionType('productTag').filter((t: any) => e === t.value);
            return arr.length > 0 ? <Tag key={arr[0].label} style={{ marginBottom: '4px' }} color="green">{arr[0].label}</Tag> : '';
          })}</li>}
          <li>ID：<span>{record.id}</span></li>
        </ul>
      </Space>
    }
    )
  }, {
    title: 'SPU编码',
    hideInDescriptions: true,
    dataIndex: 'code',
    width: 130
  }, {
    title: 'Sku编码',
    hideInDescriptions: true,
    width: 100,
    render: ((dom: any, record: Product) =>
      <Tooltip placement="top" title={
        <ul className="product-id-ul-Tooltip">
          {record?.items && record.items.map((e: Item) => {
            return <li><span>{e?.code}</span></li>
          })}
        </ul>
      } key="SkuCode">
        <ul className="product-id-ul">
          {record?.items && record.items.map((e: Item, index: number) => {
            return index < 3 && <li><span>{e?.code}</span></li>
          })}
        </ul>
      </Tooltip>
    )
  }, {
    title: '商家',
    hideInDescriptions: true,
    dataIndex: 'sellerName',
    valueType: 'text',
    width: 100,
  }, {
    title: '商品品牌',
    hideInDescriptions: true,
    dataIndex: ['brand', 'brandName',],
    width: 100,
    render: (dom, record) => <>{internationalList(record.brand?.brandName || '')}</>
  }, {
    title: '区域模版',
    hideInTable: true,
    dataIndex: ['regionTemplate', 'templateName'],
  }, {
    title: '平台商品分类',
    dataIndex: ['classify', 'name'],
    hideInTable: true,
    width: 100,
    valueType: 'text',
    render: (dom, record) => <>{internationalList(record.classify?.name || '')}</>
  }, {
    title: '商品类目',
    dataIndex: ['category', 'name'],
    width: 100,
    valueType: 'text',
    render: (dom, record) => <>{internationalList(record.category?.name || '')}</>
  },
  {
    title: '商品类型',
    dataIndex: 'productType',
    width: 100,
    valueType: 'text',
    render: (text, record) => <>{text === 'Normal' ? '正常商品' : '相关商品-辅料'}</>
  },
  {
    title: '销售状态',
    dataIndex: 'status',
    valueType: 'productStatus',
    width: 70,
  }, {
    title: '上架时间',
    valueType: 'text',
    dataIndex: 'onSaleTime',
    sorter: true,
    width: 120,
    render: (text, record) => {
      return record.onScheduled
    }
  }, {
    title: '下架时间',
    valueType: 'text',
    dataIndex: 'offSaleTime',
    sorter: true,
    width: 120,
    render: (text, record) => {
      return record.offScheduled
    }
  }, {
    title: '修改时间',
    dataIndex: 'lastModifiedDate',
    width: 100,
    sorter: true,
    render: (text, record) => record.lastModifiedDate
  },];

  const editSkuColumns: ProColumns<Item>[] = [{
    title: '规格',
    valueType: 'text',
    render: (text, record) => {
      let specsText = ``;
      if (record?.specs?.length === 1) {
        specsText = record?.specs[0].k + `:` + record.specs[0].v;
      } else {
        record?.specs?.forEach((specs: any) => {
          specsText += specs?.k + `:` + specs?.v + `;`;
        })
      }
      return <>{specsText}</>
    }
  }, {
    title: '售价',
    dataIndex: 'retailPrice',
    valueType: 'text',
  }, {
    title: '库存',
    render: (text, record) => {
      const localId = record.id;
      const farArr: any = skuList.data.filter((e: any) => e?.sku?.id === localId);
      return farArr.length > 0 ? <InputNumber disabled={record.status === 0} min={0} defaultValue={farArr[0].quantity} onChange={(changeNum) => {
        // 修改
        const newSku = createSku.concat();
        setCreateSku(newSku.map((e: any) => {
          e.skuId === localId && (e.quantity = changeNum);
          return e
        }));
      }} /> : <InputNumber min={0} disabled={record.status === 0} defaultValue={0} onChange={(changeNum) => {
        // 新建
        let newSku = createSku.concat();
        newSku[0].skuId === '0' && (newSku = []);
        newSku.length > 0 ? (newSku.filter(e => e.skuId === localId).length > 0 ? setCreateSku(newSku.map((e: any) => {
          e.skuId === localId && (e.quantity = changeNum);
          return e
        })) : setCreateSku(newSku.concat([{
          quantity: changeNum,
          skuId: localId
        }]))) : setCreateSku([{
          quantity: changeNum,
          skuId: localId
        }]);
      }} />
    }
  }]

  const exportData = (data: Product[]) => {
    let entities: Product[] = [];
    data.forEach((res: any) => {
      let tagsCopy = getEnumValueList('productTag').concat();
      const statusText = getEnumValueList('productStatus').filter(e => e.name === res?.status)[0]?.desc;

      let tagsText = '';
      tagsCopy.forEach(e => {
        res?.tags.forEach((item: any) => {
          item === e.name && (tagsText === "" ? tagsText = e.desc + ';' : tagsText = tagsText + e.desc + ';')
        })
      });
      res.items?.forEach((skus: any) => {
        let specsText = ``;
        if (skus?.specs?.length === 1) {
          specsText = skus?.specs[0].k + `:` + skus.specs[0].v;
        } else {
          skus?.specs?.forEach((specs: any) => {
            specsText += specs?.k + `:` + specs?.v + `;`;
          })
        }
        let arr = {
          skuId: skus?.id,//
          skuCode: skus?.code,//
          maxPerPerson: skus?.maxPerPerson,
          maxPerOrder: skus?.maxPerOrder,
          id: res?.id,
          spuCode: res?.code,
          name: res?.name,
          brandName: res?.brand?.brandName,
          categoryName: res?.category?.name,
          specs: specsText,//规格
          retailPrice: skus?.retailPrice,//售价
          costPrice: skus?.costPrice,//成本价
          status: statusText,
          sellerId: res?.sellerId,//商家id
          tags: tagsText as any,
          region: res?.regionTemplate?.templateName,
          onScheduled: res.onScheduled,
          offScheduled: res.offScheduled,
        };
        entities.push(arr);
      })
    });
    return entities;
  }

  // const searchFields = [{
  //   label: '商品名称',
  //   name: 'name.contains',
  // }, {
  //   label: 'SPU编码',
  //   name: 'code.equals',
  // }, {
  //   label: '商品id',
  //   name: 'id.equals',
  // }, {
  //   label: 'SKU编码',
  //   name: 'skuCode.equals',
  // }];

  // const deletePim = (id?: string | number) => {
  //   confirm({
  //     title: '确定要删除吗',
  //     icon: <WarningOutlined />,
  //     onOk() {
  //       deleteEntity(id);
  //     },
  //     onCancel() {
  //       // console.log('Cancel');
  //     },
  //   });
  // };

  // 查询价格
  const queryPrices = (dom: ReactNode, record: any) => {
    return <>
      <Authorized authority='priceInquiry'>
        <Tooltip title="查询价格">
          <Button
            type="link" size="small"
            onClick={() => {
              showModal(record.id)
            }}
            icon={<DollarOutlined />}
          ></Button>
        </Tooltip>
      </Authorized>
    </>
  };


  // 编辑库存
  const editInventory = (dom: ReactNode, record: any) => {
    return <>
      <Authorized authority='editStock'>
        <ModalForm
          title={internationalList(record.name || '')}
          trigger={<Tooltip title={'编辑库存'} key="sku"><Button type="link" size="small" icon={<ProfileOutlined />}></Button></Tooltip>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onVisibleChange={visible => {
            if (visible) {
              searchSkuList({ 'productId.equals': record.id }).then((res: any) => {
                setSkuList({
                  total: parseInt(res.headers['x-total-count'], 10),
                  data: res.data,
                });
                const createSkuDefault = record.items.concat().map((e: any, index: number) => {
                  const quantity = res.data.length > 0 ? (res.data.concat().filter((dataItem: any) => dataItem?.sku?.id === e.id)?.[0]?.quantity || 0) : 0;
                  return {
                    quantity,
                    skuId: e.id
                  }
                });
                setCreateSku(createSkuDefault);
              });
              // setSpec(record?.specList[0]); //暂存spec 编辑库存展示图片用
            } else {
              setSkuList({ total: 0, data: [] });
              setCreateSku([]);
            }
          }}
          onFinish={async (data) => {
            importStock(createSku, { adjustmentType: 'Absolute' });
            return true;
          }}
        >
          {createSku.length > 0 && <ProTable
            search={false}
            toolBarRender={false}
            pagination={false}
            dataSource={record?.items}
            className="product-skuItem"
            rowClassName={e => e.status === 0 ? 'disable' : ''}
            columns={editSkuColumns}
          />}
        </ModalForm>
      </Authorized>
    </>
  };

  const rowActionsMore = [
    (dom: ReactNode, record: any) => {
      let type: boolean = false;
      ['PENDING', 'SELLING', 'SHELF_DOWN', 'SOLD_OUT'].indexOf(record.status) > -1 && (type = true);
      return <Dropdown
        trigger={['click']}
        overlay={
          <Menu>
            <Authorized authority='productOnOff'>
              {type && <Menu.Item key="audit">
                <Popconfirm
                  key="audit"
                  placement="topRight"
                  title={record.status === 'SELLING' ? '你确定要下架吗？' : '你确定要上架吗？'}
                  onConfirm={() => {
                    record.status !== 'SELLING' ?
                      upUnderPim({ status: 1, spuIds: [record.id] }) : upUnderPim({ status: 0, spuIds: [record.id] });
                  }}
                >
                  <Button
                    type="link" size="small"
                    danger={record.status === 'SELLING' ? true : false}
                    icon={record.status === 'SELLING' ? <VerticalAlignBottomOutlined /> : <VerticalAlignTopOutlined />}
                  >{record.status === 'SELLING' ? '下架商品' : '上架商品'}
                  </Button>
                </Popconfirm>
              </Menu.Item>}
              {type && record.status === 'SOLD_OUT' && <Menu.Item key="audit">
                <Popconfirm
                  key="audit"
                  placement="topRight"
                  title={'你确定要下架吗？'}
                  onConfirm={() => { upUnderPim({ status: 0, spuIds: [record.id] }) }}
                >
                  <Button
                    type="link" size="small"
                    danger
                    icon={<VerticalAlignBottomOutlined />}
                  >下架商品</Button>
                </Popconfirm>
              </Menu.Item>}
              {type && <Menu.Item key="date">
                <ModalForm
                  trigger={<Button type="link" size="small" icon={<ClockCircleOutlined />} >{record.status === 'SELLING' ? '定时下架' : '定时上下架'}</Button>}
                  title="商品定时上下架"
                  onFinish={async (res) => {
                    scheduledPim({
                      onDateTime: res.onDateTime,
                      offDateTime: res.offDateTime,
                      productIds: [record.id],
                      type: 1
                    });
                    return true;
                  }}
                  modalProps={{
                    destroyOnClose: true,
                  }}
                >
                  <Row>
                    <Col span="12">
                      <ProFormDateTimePicker
                        fieldProps={{
                          format: 'yyyy-MM-DD HH:mm:00',
                          showTime: { format: 'yyyy-MM-DD HH:mm:00' }
                        }}
                        rules={[{ required: record.status !== 'SELLING' && record.status !== 'TIMING_SELLING' }]}
                        disabled={record.status === 'SELLING' ? true : false}
                        width="md"
                        name='onDateTime'
                        label="定时上架时间" />
                    </Col>
                    <Col span="12">
                      <ProFormDateTimePicker
                        fieldProps={{
                          format: 'yyyy-MM-DD HH:mm:00',
                          showTime: { format: 'yyyy-MM-DD HH:mm:00' }
                        }}
                        rules={[
                          ({ getFieldValue }) => ({
                            validator(_, value) {
                              if (value && moment(getFieldValue('onDateTime')) >= value) {
                                return Promise.reject(new Error('定时下架时间不能小于上架时间'));
                              }
                              return Promise.resolve();
                            }
                          }),
                        ]} width="md" name='offDateTime' label="定时下架时间" />
                    </Col>
                  </Row>
                </ModalForm>
              </Menu.Item>}
              {record.status === 'TIMING_SELLING' && <Menu.Item key="clearDate-on">
                <Button type="link" size="small"
                  icon={<CloseOutlined />}
                  onClick={async () => { scheduledPim({ productIds: [record.id], type: 2, status: 1 }); }}
                >取消定时上架</Button>
              </Menu.Item>}
              {(record.offScheduled && record.status === 'SELLING') && <Menu.Item key="clearDate-off">

                <Button type="link" size="small"
                  icon={<CloseCircleOutlined />}
                  onClick={async () => { scheduledPim({ productIds: [record.id], type: 2, status: 0 }) }}
                >取消定时下架</Button>
              </Menu.Item>}
            </Authorized>
            {/* <Authorized authority='performanceTemplate'>
              <Menu.Item key="bonus-look">
                <Button
                  type="link" size="small"
                  size="middle"
                  onClick={() => {
                    if (record.achievementTemplate) {
                      setBonusData([record.achievementTemplate]);
                      setBonusModal(true);
                    } else {
                      message.info('此商品暂未设置业绩模版');
                    }
                  }}
                  icon={<MoneyCollectOutlined />}
                >业绩模版</Button>
              </Menu.Item>
            </Authorized> */}

            {/* <Authorized authority='deleteProduct'>
              <Menu.Item key="delete-item">
                <Button
                  danger
                  type="link" size="small"
                  icon={<DeleteOutlined />}
                  onClick={() => deletePim(record.id)}
                >删除商品</Button>
              </Menu.Item>
            </Authorized> */}
          </Menu>
        }
      >
        <Button size="small" type='link'>更多...</Button>
      </Dropdown>
    }];


  // 查询价格明细所需的商品id
  const [qPriceproductId, setQPriceproductId]: any = useState({});
  const [open, setOpen] = useState(false);
  // 打开查询价格
  const showModal = async (id: number) => {
    setQPriceproductId(id)
    setKey(++key)
    setOpen(true);
  };
  // 关闭查询价格
  const hideModal = () => {
    setOpen(false);
  };

  const upperLowerShelves = <ModalForm
    title={'商品批量上/下架'}
    trigger={<Button type="text">批量上下架</Button>}
    modalProps={{
      destroyOnClose: true,
    }}
    preserve={false}
    onFinish={async (data) => {
      const productIds = data.idsType === '0' ? data.productIds.split(',').filter((e: string) => e !== '') : upperLowerShelvesIds.concat().map((e: { id: string }) => e.id);
      if (!data.timing) {
        upUnderPim({ status: Number(data.type), spuIds: productIds, })
      } else {
        upperLowerShelvesPim({
          onDateTime: data.onDateTime,
          offDateTime: data.offDateTime,
          productIds,
          type: 1
        }).then(e => {
          message.success('设置成功');
        }).catch(error => {
          message.error(error?.response?.data?.title || 'error');
        });
      };
      setUpperLowerShelvesIds([]);
      return true;
    }}
  >
    <ProFormSwitch
      name={'timing'}
      label="定时"
      initialValue={false}
      fieldProps={{
        checkedChildren: '是',
        unCheckedChildren: '否',
      }}
    />
    <ProFormDependency name={['timing']}>
      {({ timing }) => {
        return timing ? <Space size={40}>
          <ProFormDateTimePicker
            width="md"
            name='onDateTime'
            label="定时上架时间"
          />
          <ProFormDateTimePicker
            width="md"
            name='offDateTime'
            label="定时下架时间"
          />
        </Space> : <ProFormRadio.Group
          name="type"
          radioType="button"
          label="上下架类型"
          initialValue={'1'}
          options={[{ value: '1', label: '上架' }, { value: '0', label: '下架' }]}
        />
      }
      }
    </ProFormDependency>
    <ProFormRadio.Group
      name="idsType"
      radioType="button"
      label="商品ids"
      initialValue={'0'}
      options={[{ value: '0', label: '手动录入' }, { value: '1', label: '批量上传' }]}
    />
    <ProFormDependency name={['idsType']}>
      {({ idsType }) => {
        return idsType === '0' ? <ProFormTextArea
          name='productIds'
          extra={'批量粘贴商品Id到下面，以","分割。例如：1430438289763639297,1455712100170674178'}
        /> : <>
          <ImportDataDialog
            columns={[{
              title: '商品id',
              dataIndex: 'id',
              valueType: 'text',
            }]}
            title="表格上传"
            uploadEntity={(e: any) => {
              setUpperLowerShelvesIds(e || []);
              return true;
            }}
            fieldListErrors={[]}
          />
          {upperLowerShelvesIds.length > 0 && <Tag color={'green'} icon={<CheckOutlined />}>已上传</Tag>}
        </>
      }}
    </ProFormDependency>
  </ModalForm>

  const searchFields = [{
    label: '商品名称',
    name: 'name.contains',
  },{
    label: 'SPU编码',
    name: 'code.contains',
  },{
    label: '商品id',
    name: 'id.equals',
  },{
    label: 'SKU编码',
    name: 'skuCode.contains',
  }];


  const quickFilterPanel = (
    <div style={{ 'display': 'flex', 'alignItems': 'center' }}>
      <Form.Item>
        <Input.Group compact>
          <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
          <div className="wd10"></div>
          <ProFormSelect
            name="productType.equals"
            width="sm"
            placeholder="请选择商品类型"
            options={[{
              label: '正常商品',
              value: 'Normal'
            }, {
              label: '相关商品-辅料',
              value: 'Related'
            }]}
          />
          <SelectSearch required={false} name="brandId.equals" placeholder="品牌" labelValue="brandName" defaultParams='' fetchFunc={fetchBrandList} searchParams='name.contains' />
          <SearchBox
            fields={searchFields}
          />
        </Input.Group>
      </Form.Item></div>);

  const moreOperation = <Menu>
    <Authorized authority='RefreshData'>
      <Menu.Item key="dataStructure">
        <Button onClick={() => {
          refreshStructure();
          message.success('刷新已触发');
        }} type="text">刷新数据结构</Button>
      </Menu.Item>
    </Authorized>
    <Menu.Item key="searchStructure">
      <Button onClick={() => {
        refreshSerch()
        message.success('刷新已触发');
      }} type="text">刷新搜索数据</Button>
    </Menu.Item>
    <Menu.Item key="upperLowerShelves">
      {upperLowerShelves}
    </Menu.Item>
    {newUploadTstColumns && <Menu.Item key="uploadTst">
      <ImportDataDialog columns={newUploadTstColumns}
        width='2700px'
        title="商品上传"
        downLoadUrl="https://images-test.merrige.cn/public/template/%E5%95%86%E5%93%81%E5%AF%BC%E5%85%A5%E6%A8%A1%E6%9D%BFV1.xlsx"
        uploadEntity={props.importTstEntities}
        uploadParams={convertEnumToOptionType('productTag')}
        fieldListErrors={props.fieldListErrors?.fieldListErrors}
      />
    </Menu.Item>}
    <Menu.Item key="uploadPrice">
      <ImportDataDialog columns={uploadPriceColumns}
        title="价格修改"
        uploadEntity={props.importPrice}
        fieldListErrors={props.fieldListErrors}
      />
    </Menu.Item>
  </Menu>;
  

  const additionActions = [
    <Space className="status-filter">
      <Tabs defaultActiveKey={status} onChange={status => setStatus(status)} activeKey={status}>
        {Object.entries<string>(ProductStatus).map(([value, label]) => <TabPane tab={label} key={value}></TabPane>)}
      </Tabs>
    </Space>,

    <Authorized authority='productMore'>
      <Dropdown overlay={moreOperation}>
        <Button>
          更多操作 <DownOutlined />
        </Button>
      </Dropdown>
    </Authorized>,
  ];
  const tableAlertOptionRender = (res: any) => {
    const Ids = res.selectedRowKeys;
    return (
      <Space size={16}>
        <Authorized authority='BatchReleaseOfInventory'>
          {
            <ModalForm
              trigger={<Button className="operation-btn" type="primary">批量释放库存</Button>}
              title='批量释放库存'
              modalProps={{
                destroyOnClose: true,
              }}
              onFinish={async (e) => {
                res.onCleanSelected();
                return new Promise<boolean>((resolve, reject) => {
                  releaseInventory({
                    releaseInventoryTime: e.releaseInventoryTime,
                    productIds: Ids,
                  }).then((data: any) => {
                    message.success('操作成功');
                    resolve(true);
                  }).catch(err => {
                    message.error(err.response.data.title);
                    reject(false);
                  })
                });
              }}
            >
              <Row>
                <Col span="12">
                  <ProFormDateTimePicker width="md" name='releaseInventoryTime' label="时间" />
                </Col>
              </Row>
            </ModalForm>
          }
          {(status === '10' || status === '50') && <Button type="primary"
            className="operation-btn"
            onClick={async () => {
              await upUnderPim({ status: 1, spuIds: Ids });
              res.onCleanSelected();
            }}
          >批量上架</Button>}
          {
            (status === '10' || status === '50' || status === '30') &&
            <ModalForm
              trigger={<Button className="operation-btn" type="primary">{status === '30' ? '批量定时下架' : "批量定时上下架"}</Button>}
              title={status === '30' ? '商品批量定时下架' : "商品批量定时上下架"}
              modalProps={{
                destroyOnClose: true,
              }}
              onFinish={async (e) => {
                res.onCleanSelected();
                scheduledPim({
                  onDateTime: e.onDateTime,
                  offDateTime: e.offDateTime,
                  productIds: Ids,
                  type: 1
                });
                return true;
              }}
            >
              <Row>
                <Col span="12">
                  <ProFormDateTimePicker disabled={status === '30' ? true : false} width="md" name='onDateTime' label="定时上架时间" />
                </Col>
                <Col span="12">
                  <ProFormDateTimePicker width="md" name='offDateTime' label="定时下架时间" />
                </Col>
              </Row>
            </ModalForm>

          }
          {
            status === '25' &&
            <Button type="primary" className="operation-btn"
              onClick={async () => { scheduledPim({ productIds: Ids, type: 2, status: 1 }); res.onCleanSelected(); }}
            >批量取消定时上架</Button>
          }
          {
            status === '30' && <Button type="primary" className="operation-btn"
              onClick={async () => {
                await upUnderPim({ status: 0, spuIds: Ids })
                res.onCleanSelected();
              }}
            >批量下架</Button>
          }
          <Popconfirm
            title="你确定要批量删除所选数据吗？"
            icon={<WarningOutlined />}
            onConfirm={async () => {
              await deleteEntitys(Ids);
              res.onCleanSelected();
            }}
          >
            <Button type="primary"
              className="operation-btn"
            >批量删除</Button>
          </Popconfirm>
        </Authorized>
      </Space>
    );
  }

  return (
    <>
      <PriceQueryDialog productId={qPriceproductId} key={key} visible={open} onCancel={() => {
        hideModal();
      }} />
      <ListViewPage<Product, 'productStatus'>
        drawerWidth={1000}
        actionColumnWidth={140}
        showCopyButton
        columns={columns}
        downloadColumns={downloadColumns}
        downloadEntities={exportData(props.entities)}
        downloadAllEntities={exportData}
        key="id"
        quickFilterPanel={quickFilterPanel}
        detailsIndex={false}
        actions={additionActions}
        showSearchButton
        tableAlertOptionRender={tableAlertOptionRender}
        scrollConfig={{ x: 1500 }}
        rowSelection={{
          type: 'checkbox',
        }}
        showDownload
        hideDeleteButton
        rowActions={[queryPrices, editInventory]}
        rowActionsMore={rowActionsMore}
        filters={{ 'status.equals': status ? (status === '0' ? '' : status) : '10' }}
        sort='lastModifiedDate,desc' 
        editPage={(params: any) => <ProductEdit copy={params.copy} id={params.id} text={params.text} />}
        {...props}
      >
        {props.children}
        <Modal title='业绩模版详情' visible={bonusModal}
          footer={[<Button key="back" onClick={() => setBonusModal(false)}>关闭</Button>]}
          onCancel={() => setBonusModal(false)}
          width={1200}
        >
          <ProTable<Achievement, Record<string, any>, 'bonusCategory'>
            search={false}
            toolBarRender={false}
            pagination={false}
            // dataSource={[bonusData]}
            dataSource={[]}
            columns={bonusProductColumns}
          />
        </Modal>
        <Drawer
          visible={detailShow}
          onClose={() => SetDetailShow(false)}
          width={1000}
        >
          <ProductDetails detailText={detailText} id={detailId} columns={columns} />
        </Drawer>
      </ListViewPage>
    </>
  );
};


const mapStateToProps = ({ product }: RootState) => ({
  entity: product.entity,
  entities: product.entities,
  loading: product.loading,
  updateSuccess: product.updateSuccess,
  total: product.total,
  fieldListErrors: product.fieldListErrors,
  createSuccess: product.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  importTstEntities,
  upUnderPim,
  importPrice,
  scheduledPim,
  importStock,
  deleteEntitys,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductList);

