import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPost } from "model/video/post.model";
import { CrudGetAction, CrudGetAllAction, CrudPostAction, CrudPutAction } from "reducers/redux-action.type";
import axios from "axios";
import { cleanEntity } from 'utils/entity-utils';

const entity = 'material';
const apiUrl = '';

const standardReducerImpl = new StandardReducerImpl<any>(entity, defaultPost, apiUrl);
export type MaterialState = StandardState<any>;
export const material = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
// export const getEntity = standardReducerImpl.getEntity;
// export const createEntity = standardReducerImpl.createEntity;
//export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const getEntity: CrudGetAction<any> = (id:any, togetherParams:any) => { 
  let apiUrl = `/api/source-material/findSourceMaterialById/${id}`
  const requestUrl = (apiUrl as any)?.detailUrl ? `${(apiUrl as any).detailUrl + togetherParams}` : `${apiUrl}/${id}`;
  return {
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
    payload: axios.get<any>(requestUrl),
  };
};


export const getEntities: CrudGetAllAction<any> = (params?: any, download?: boolean) => dispatch => {
  return dispatch({
    type: standardReducerImpl.actionType(download ? STANDARD_ACTION_TYPES.FETCH_DOWNLOAD_LIST : STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>('/api/yumei-mall-content/source-material/findAll', {
      params,
    })
  });
};

export const createEntity: CrudPostAction<any> = entity => async dispatch => {
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.post('/api/yumei-mall-content/source-material/source-material-add', cleanEntity(entity)),
  });
};

export const updateEntity: CrudPutAction<any> = (entity) => async dispatch => {
  let apiUrl = '/api/yumei-mall-content/source-material/source-material-update'
  const requestUrl = (apiUrl as any)?.editUrl ? `${(apiUrl as any).editUrl + (entity as any).updateEntityParams}` : `${apiUrl}/${entity.id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.put(requestUrl, cleanEntity(entity)),
  })
};