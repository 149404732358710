import {BaseModel, DisableModel} from 'model/base.model';

export interface marginRulesModel extends BaseModel, DisableModel {
  id?: number | string;
  levelCashDeposit?: any

}

export const defaultMarginRules: Readonly<marginRulesModel> = {
};
