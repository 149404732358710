import {BaseModel, DisableModel} from 'model/base.model';

export interface FlowTemplate extends BaseModel, DisableModel {
  id?: number | string;
  bankName?: string;
  bankNameIndex?: Array<number>;
  bankAccountIndex?: Array<number>;
  receiptAmountIndex?: Array<number>;
  arrivalDateFormat?: Array<number>;
  counterpartIndex?: Array<number>;
  flowNoIndex?: Array<number>;
  useDescriptionIndex?: Array<number>;
  hasDealWithIndex?: Array<number> | string;
  remarkIndex?: Array<number>;
  otherBankIndex?: Array<number>;
}

export const defaultFlowTemplate: Readonly<FlowTemplate> = {
};
