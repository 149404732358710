import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import BankStatementDetails from './bankStatement-details';
import BankStatementEdit from './bankStatement-edit';
import BankStatementList from './bankStatement-list';
import './index.less'

const BankStatement: React.FC<RouteComponentProps> = () => (
  <Router>
    <BankStatementList path="/" />
    <BankStatementDetails path=":id" />
    <BankStatementEdit path=":id/edit" />
    <BankStatementEdit path="new" />
  </Router>
);
export default BankStatement;
