import React, { FC, ReactNode } from "react";
import { Button, Form, Input, Popconfirm } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox, { SearchField } from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./group-details";
import { deleteEntity, getEntities, getEntity, updateEntity } from "./group.reducer";
import { RouteComponentProps } from "@reach/router";
import GroupEdit from './group-edit';
import { Group } from "model/customerCore/group.model";
import { ProFormSelect } from "@ant-design/pro-form";

export interface GroupListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const GroupList: FC<GroupListProps> = (props) => {

  const { updateEntity } = props;

  const columns: ProColumns<Group>[] = [{
    dataIndex: 'id',
    title: 'id',
    width: 20,
  }, {
    dataIndex: 'name',
    title: '标签组名称',
  }, {
    dataIndex: 'reverseTag',
    title: '是否反向标签',
    render: (text) => text ? '是' : '否'
  }, {
    dataIndex: 'enabled',
    title: '状态',
    render: (text) => text ? '启用' : '禁用'
  }]

  const searchFields: SearchField[] = [{
    label: '标签组名称',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          showSearch
          placeholder='状态'
          width="md"
          name="enabled.equals"
          options={[{
            value: 'true',
            label: '启用'
          }, {
            value: 'false',
            label: '禁用'
          }]}
        />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: Group): ReactNode => (
    record.id &&
    <Popconfirm title={"你确定要" + (record.enabled ? '禁用' : '启用') + "这个标签组吗？"} key="activate" onConfirm={() => updateEntity({
      ...record,
      enabled: !record.enabled
    })}>
      <Button type="link" size="small" >{record.enabled ? '禁用' : '启用'}</Button>
    </Popconfirm>
  )];


  return (
    <ListViewPage<Group>
      columns={columns}
      rowActions={rowActions}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={1}
      editPage={(params) => <GroupEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ group }: RootState) => ({
  entity: group.entity,
  entities: group.entities,
  loading: group.loading,
  updateSuccess: group.updateSuccess,
  total: group.total,
  createSuccess: group.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(GroupList)