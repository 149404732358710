import {BaseModel} from "../base.model";

export interface Article extends BaseModel {
    content?: string;
}
export const isHelpStatus = {
    'SHOW': '展示',
    'HIDE': '隐藏'
}

export const showPlace = {
    'HOT': '热门问题',
    'RESTS': '其他位置'
}
export const defaultArticle:  Readonly<Article> = {};