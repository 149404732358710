import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./summary.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SummaryList } from "model/bonus/summaryList.model";
export interface SummaryListEditProps extends EditStateAndDispatchProps<SummaryList>, StateProps, DispatchProps, RouteComponentProps {
}

const SummaryListEdit: FC<SummaryListEditProps> = props => {

  return (
    <EditPage<SummaryList>
      title="品牌"
      {...props}
    >

    </EditPage>);
};

const mapStateToProps = ({ summaryList }: RootState) => ({
  entity: summaryList.entity,
  updateSuccess: summaryList.updateSuccess,
  updating: summaryList.updating,
  createSuccess: summaryList.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SummaryListEdit);
