import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./sign.reducer";
import { Form} from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ContractSign } from "model/contract/sign.model";
;
export interface ContractSignEditProps extends EditStateAndDispatchProps<ContractSign>, StateProps, DispatchProps, RouteComponentProps {
}

const ContractSignEdit: FC<ContractSignEditProps> = props => {
  const {
    ...editProps
  } = props;

  const [form] = Form.useForm();


  return (
    <EditPage<ContractSign>
      title=""
      form={form}
      {...editProps}
    >
    </EditPage>);
};

const mapStateToProps = ({ contractSign }: RootState) => ({
  entity: contractSign.entity,
  updateSuccess: contractSign.updateSuccess,
  updating: contractSign.updating,
  createSuccess: contractSign.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractSignEdit);
