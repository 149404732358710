import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { Version, defaultVersion } from "model/system/version.model";
import { CrudPutAction } from 'reducers/redux-action.type';
import axios from 'axios';

interface versionActivate {
  id?: string | number;
  state?: string;
}

const entity = 'version';
const apiUrl = '/api/yumei-mall-common/app/version';
const standardReducerImpl = new StandardReducerImpl<Version>(entity, defaultVersion, apiUrl);
export type VersionState = StandardState<Version>;
export const version = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const activate: CrudPutAction<versionActivate> = (data: versionActivate) => async dispatch => {
  const requestUrl = `${apiUrl}/${data.id}/${data.state}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl),
  });
};

export interface UploadImgUrl {
  bucket?: string;
  folder?: string;
  versionCode?: string;
  category?: string;
}