import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './return-package.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { ReturnPackage } from "model/procure/return-package.model";

export interface ReturnPackageDetailsProps extends DetailsStateAndDispatchProps<ReturnPackage, 'receiveRefundExchangeType'>,
  RouteComponentProps, StateProps, DispatchProps {
}
const ReturnPackageDetails: FC<ReturnPackageDetailsProps> = (props) => {
  return (
    <DetailsPage<ReturnPackage, 'receiveRefundExchangeType'>
      title={'包裹详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<ReturnPackage, 'receiveRefundExchangeType'>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ orderReturnPackage }: RootState) => ({
  entity: orderReturnPackage.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPackageDetails);
