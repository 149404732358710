import React, { FC, ReactNode, useEffect, useState } from 'react';
import { Button, Form, Tooltip, message } from 'antd';
// import { debounce } from 'lodash';
import { DownOutlined, SearchOutlined } from "@ant-design/icons";
import { Store } from 'rc-field-form/es/interface';
import { FilterCriteria } from 'components/model/share.model';
import { LightFilter } from '@ant-design/pro-form';

export interface QuickFilterProps {
  hasAdvance: boolean;
  filters?: FilterCriteria;
  onFilterChange?: (filters: FilterCriteria) => void;
  onCollapseAdvanceFilter?: (collapse: boolean) => void;
  children?: ReactNode;
  showSearchButton?: boolean;
  loading?: boolean;
  mustFilter?: boolean;
  mustFilterArr?: string[];
  tabsSearchParams?: any;
  showResetButton?: boolean;
  onValuesChange?: (filters: any) => void;
}

const QuickFilter: FC<QuickFilterProps> = (props) => {

  const {
    children,
    filters,
    hasAdvance,
    onFilterChange,
    onValuesChange,
    onCollapseAdvanceFilter,
    showResetButton,
    loading,
    mustFilter,
    mustFilterArr,
    tabsSearchParams,
  } = props;
  const [form] = Form.useForm();

  const [collapse, setCollapse] = useState(true);
  const initialValues = {
    ...filters,
  };

  useEffect(() => {
    const ignoreArr = ['page', 'size', 'sort'];
    const newArr = tabsSearchParams ? Object.keys(tabsSearchParams).filter(e => ignoreArr.indexOf(e) === -1) : [];
    if (newArr && (newArr.length > 0)) {
      newArr.forEach((key: string) => {
        form.setFieldsValue({
          [key]: tabsSearchParams[key]
        })
      });
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tabsSearchParams]);

  const [historyFilters, setHistoryFilters] = useState<FilterCriteria>(filters ?? {});

  const handleValuesChange = (changedValues: Store, allValues: Store, SearchStatus?: boolean) => {
    if (!loading) {
      const { search, ...restFilters } = allValues;
      // 合并过滤值。
      const newKeys = Object.keys(restFilters);
      Object.keys(historyFilters).forEach(key => {
        if (newKeys.indexOf(key) >= 0) {
          // 有值，重新设置值。
          historyFilters[key] = restFilters[key] || ''
        } else {
          // 没有值，被清空。
          historyFilters[key] = '';
        }
      })

      if (onFilterChange) {
        onFilterChange({ ...restFilters, ...historyFilters, ...search });
      };

      // 保持新的过滤值。
      setHistoryFilters({ ...restFilters, ...historyFilters });
    }
  };

  return (
    <div style={{ display: 'flex', justifyContent: 'space-between' }}>
      <LightFilter
        initialValues={initialValues}
        form={form}
        onValuesChange={(e)=> {
          const formDate = form.getFieldsValue();
          console.log(formDate)
          if(onValuesChange) {
            onValuesChange(formDate)
          }
          console.log('哈哈哈哈哈哈')
        }}
      >
        {children}
      </LightFilter>
      <Button type='primary' onClick={() => {
        const formDate = form.getFieldsValue();
        const newFormDate = { ...formDate, ...formDate.search }
        if (mustFilter && mustFilterArr) {
          const newArr = [];
          mustFilterArr.forEach(e => {
            if (newFormDate[e] !== '' && newFormDate[e] !== undefined) {
              handleValuesChange({}, form.getFieldsValue(), true);
            } else {
              newArr.push(0);
              if (newArr.length === mustFilterArr.length) {
                message.error('必须输入筛选条件！');
              };
            };
          })
        } else {
          handleValuesChange({}, form.getFieldsValue(), true);
        };
      }} icon={<SearchOutlined />}>搜索</Button>
      {showResetButton && <Button onClick={() => {
        handleValuesChange({}, {}, true)
        setTimeout(() => {
          form.resetFields();
        }, 300)


      }}>重置</Button>
      }
      {hasAdvance &&
        <Tooltip key="advanced-filter" title={`${collapse ? '打开' : '收起'}高级过滤器面板`}>
          <Button type="link" size="small" onClick={() => {
            if (onCollapseAdvanceFilter) {
              onCollapseAdvanceFilter(!collapse);
            };
            setCollapse(!collapse);
          }}>
            {collapse ? '更多' : '收起'}
            <DownOutlined
              style={{
                marginLeft: '0.5em',
                transition: '0.3s all',
                transform: `rotate(${collapse ? 0 : 0.5}turn)`,
              }}
            />
          </Button>
        </Tooltip>}
    </div>
  );
};

export default QuickFilter;
