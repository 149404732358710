import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./assort.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Assort } from "model/dealer/assort.model";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { isHelpStatus } from "model/dealer/article.model";
export interface AssortEditProps extends EditStateAndDispatchProps<Assort>, StateProps, DispatchProps, RouteComponentProps {
}

const AssortEdit: FC<AssortEditProps> = props => {
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  return (
    <EditPage<Assort>
      title="分类"
      form={form}
      {...props}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            width="md"
            name="name"
            label="分类名称"
            rules={[{ required: true }]}
            fieldProps={{
              maxLength: 10
            }}
            extra="最多输入10个文字"
          /></Col><Col span={1} />
        <Col span={7}>
          <ProFormDigit
            width="md"
            name="sort"
            label="排序值"
            initialValue={0}
            fieldProps={{ precision: 0 }}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            name="status"
            label="分类状态"
            initialValue={'SHOW'}
            options={convertEnumToOptionType('articleStatus')}
            placeholder="分类状态"
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            name="isHelpStatus"
            label="是否展示在帮助中心"
            initialValue={'HIDE'}
            width="md"
            options={Object.entries<string>(isHelpStatus).map(([value, label]) => ({ value: (value), label }))}
          />
        </Col><Col span={1} />
        <ProFormDependency name={['isHelpStatus']}>
          {({ isHelpStatus }) => {
            return isHelpStatus === 'SHOW' && <>
              <Col span={7}>
                <ProFormDigit
                  width="md"
                  name="helpSort"
                  label="帮助中心排序值"
                />
              </Col><Col span={1} />
            </>
          }}
        </ProFormDependency>

        <Col span={24}>
          <ProFormTextArea
            name="remark"
            label="备注"
            fieldProps={{
              maxLength: 100
            }}
            extra="最多输入100个文字"
          />
        </Col><Col span={1} />
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ assort }: RootState) => ({
  entity: assort.entity,
  updateSuccess: assort.updateSuccess,
  updating: assort.updating,
  createSuccess: assort.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AssortEdit);
