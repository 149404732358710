import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import CertificateList from './certificate-list';
import CertificateDetails from "./certificate-details";
import CertificateEdit from "./certificate-edit";
import './index.less'

const Course: React.FC<RouteComponentProps> = () => (
    <Router>
        <CertificateList path = "/"/>
        <CertificateDetails path = ":id"/>
        <CertificateEdit path = ":id/edit"/>
        <CertificateEdit path = "new"/>
    </Router>
);
export default Course;
