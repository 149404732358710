import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './category.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { VideoCategory } from "model/video/video-category";

export interface VideoCategoryDetailsProps extends DetailsStateAndDispatchProps<VideoCategory, 'categoryGroup'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const VideoCategoryDetails: FC<VideoCategoryDetailsProps> = (props: any) => {

  return (
    <DetailsPage<VideoCategory, 'categoryGroup'>
      title='话题详情'
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<VideoCategory, 'categoryGroup'>
        column={1}
        columns={props.columns}
        dataSource={props.entity}
      >
      </ProDescriptions>
    </DetailsPage>);
};

const mapStateToProps = ({ videoCategory }: RootState) => ({
  entity: videoCategory.entity,
});

const mapDispatchToProps = {
  getEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoCategoryDetails);
