import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./route.reducer";
import { PayeeEnterprise } from "model/system/payee-enterprise.model";
import { Route } from "model/system/route.model";
import { Col, Row } from "antd";
import { ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
export interface RouteEditProps extends EditStateAndDispatchProps<Route>, StateProps, DispatchProps, RouteComponentProps {
}

const RouteEdit: FC<RouteEditProps> = props => {
  const { convertEnumToOptionType } = useEnumValue();
  return (
    <EditPage<PayeeEnterprise>
      title="主体"
      {...props}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            name="keyName"
            label="键名"
            width="md"
            rules={[{
              required: true,
            }]}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={7}>
          <ProFormSelect
            name="keyType"
            label="键类型"
            width={'xl'}
            options={convertEnumToOptionType('keyDataType') || []}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={7}>
          <ProFormSwitch
            name='nativePage'
            label="是否原生页面"
            initialValue={false}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={23}>
          <ProFormText
            name="pageUrl"
            label="页面地址"
            width="md"
            rules={[{
              required: true,
            }]}
          />
        </Col>
        <Col span={1}></Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ route }: RootState) => ({
  entity: route.entity,
  updateSuccess: route.updateSuccess,
  updating: route.updating,
  createSuccess: route.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RouteEdit);
