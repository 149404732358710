import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RouteDetails from './route-details';
import RouteEdit from './route-edit';
import RouteList from './routelist';

const Route: React.FC<RouteComponentProps> = () => (
  <Router>
    <RouteList path="/" />
    <RouteDetails path=":id" />
    <RouteEdit path=":id/edit" />
    <RouteEdit path="new" />
  </Router>
);
export default Route;
