import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import BrandDetails from './brand-details';
import BrandEdit from './brand-edit';
import BrandList from './brand-list';

const Brand: React.FC<RouteComponentProps> = () => (
  <Router>
    <BrandList path="/" />
    <BrandDetails path=":id" />
    <BrandEdit path=":id/edit" />
    <BrandEdit path="new" />
  </Router>
);
export default Brand;
