import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './online.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { OnlineClassification } from "model/product/online-classification.model";

export interface OnlineDetailsProps extends DetailsStateAndDispatchProps<OnlineClassification>,
  RouteComponentProps, StateProps, DispatchProps {
}
const OnlineDetails: FC<OnlineDetailsProps> = (props) => {
  return (
    <DetailsPage<OnlineClassification>
      title={'网点商品分类详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<OnlineClassification>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ onlineClassification }: RootState) => ({
  entity: onlineClassification.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(OnlineDetails);
