import { isPromise } from 'utils/promise-utils';
import { message, Modal} from 'antd';
import {array} from 'js-md5';

// const addErrorAlert = (message, key?, data?) => {
//   message.error(message);
// };

const addErrorAlert = (messageText: any, key?: string, data?: any, messageContent?: string) => {
    if (messageText instanceof array) {
        messageText = messageText.toLocaleString();
    };
    if(messageText && messageContent && messageContent !== ''){
      Modal.error({
        title: messageText,
        content: messageContent,
        okText: '关闭'
      });
    }else{
      messageText && typeof(messageText) === 'string' && message.error(messageText);
    }
    
};

const notification = () => (next: any) => (action: any) => {
  // If not a promise, continue on
  if (!isPromise(action.payload)) {
    return next(action);
  }

  /**
   *
   * The notification middleware serves to dispatch the initial pending promise to
   * the promise middleware, but adds a `then` and `catch.
   */
  return next(action)
    .then((response: any) => {
      if (action.meta && action.meta.successMessage) {
        message.success(action.meta.successMessage);
      } else if (response && response.action && response.action.payload && response.action.payload.headers) {
        const headers = response.action.payload.headers;
        let alert: string | null = null;
        Object.entries<string>(headers).forEach(([k, v]) => {
          if (k.toLowerCase().endsWith('app-alert')) {
            alert = v;
          }
        });
        if (alert) {
          message.success(decodeURI(alert));
        }
      }else if (response.success) {
        message.success(response.msg);
      }
      return Promise.resolve(response);
    })
    .catch((error: any) => {
      if (action.meta && action.meta.errorMessage) {
        message.error(action.meta.errorMessage);
      } else if (error && error.response) {
        const response = error.response;
        const data = response.data;
        if (!(response.status === 401 && (error.message === ''))) {
          let i;
          switch (response.status) {
            // connection refused, server not reachable
            case 0:
              addErrorAlert('Server not reachable', 'error.server.not.reachable');
              break;

            case 400: {
              const headers = Object.entries<string>(response.headers);
              let errorHeader: string | null = null;
              let entityKey: string | null = null;
              headers.forEach(([k, v]) => {
                if (k.toLowerCase().endsWith('app-error')) {
                  errorHeader = v;
                } else if (k.toLowerCase().endsWith('app-params')) {
                  entityKey = v;
                }
              });

              if (data !== '' && data.title) {
                addErrorAlert(data.title, data.errorKey, data.data, data.message || '');
              } else if (data !== '' && data.fieldErrors) {
                const fieldErrors = data.fieldErrors;
                for (i = 0; i < fieldErrors.length; i++) {
                  const fieldError = fieldErrors[i];
                  if (['Min', 'Max', 'DecimalMin', 'DecimalMax'].includes(fieldError.message)) {
                    fieldError.message = 'Size';
                  }
                  // convert 'something[14].other[4].id' to 'something[].other[].id' so translations can be written to it
                  const convertedField = fieldError.field.replace(/\[\d*\]/g, '[]');
                  const fieldName = convertedField.charAt(0).toUpperCase() + convertedField.slice(1);
                  addErrorAlert(`Error on field "${fieldName}"`, `error.${fieldError.message}`, { fieldName });
                }
              } else if (data !== '' && data.message) {
                addErrorAlert(data.message, data.message, data.params);
              } else if (data !== '' && data.error_description) {
                addErrorAlert(data.error_description, data.error, data.params);
              } else if (!data.success) { 
                addErrorAlert(data.msg);
              } else if (errorHeader) {
                const entityName = entityKey;
                addErrorAlert(errorHeader, errorHeader, { entityName });
              } else {
                addErrorAlert(data);
              }
              break;
            }
            case 404:
              addErrorAlert('Not found', 'error.url.not.found');
              break;
            case 409:
              break;
            default:
              if (data !== '' && data.detail) {
                addErrorAlert(data.detail);
              } else if (data !== '' && data.message) {
                addErrorAlert(data.message);
              } else if (data && data.msg) {
                addErrorAlert(data.msg);
              } else if (data && data.error_description) {
                addErrorAlert(data.error_description);
              } else {
                addErrorAlert(data);
              }
          }
        }
      } else if (error && error.message) {
        message.error(error.message);
      } else {
        message.error('Unknown error!');
      }
      return Promise.reject(error);
    });
};

export default notification;
