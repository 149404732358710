import { FC, ReactNode, useEffect, useState } from "react"
import { Product, SkuItem } from 'model/product/product.model';
import { RouteComponentProps } from '@reach/router';
import { AddRedeem, DeleteRedeem, fetchRedeemList, fetchTagGroupIds, getEntity, scheduledPim, upUnderPim } from './product.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Carousel, Col, Row, Image, Button, Popconfirm, Menu, Dropdown, Tag, message } from "antd";
import { Base64 } from "js-base64";
import Title from "antd/lib/typography/Title";
import mobileBg from '../../../assets/images/mobile-bg.jpg'
import { ClockCircleOutlined, MoneyCollectOutlined, QrcodeOutlined, VerticalAlignBottomOutlined, VerticalAlignTopOutlined } from "@ant-design/icons";
import { ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormDigit, ProFormSwitch, ProFormTextArea } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { bonusProductColumns } from "./product-list";
import ProductEdit from './product-edit';
import Authorized from "common/auth/authorized";
import { internationalDetails, internationalList } from "components/IntelFormText";
import { fetchDeliveryList } from "../delivery/delivery.reducer";
import { Achievement } from "model/product/achievement.model";
import AuditModalForm from "pages/system/audit/audit-modal-form";
import { useParamGeneral } from "components/useParamGenera";
import ExportDataDialog from "export/export-data-dialog";
import { fetchLevelList } from "pages/customerCore/level/level.reducer";

export interface ProductDetailsProps extends DetailsStateAndDispatchProps<Product, 'productStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
  id?: string | number;
  editPage?: (params: any) => ReactNode;
  hideOperation?: boolean;
  detailText?: string;
}

const ProductDetails: FC<ProductDetailsProps> = (props) => {
  const { id, entity, scheduledPim, upUnderPim, columns, hideOperation = false, detailText } = props;
  const [carouselData, setCarouselData] = useState<string[]>([]);
  const [detailData, setDetailData] = useState([]);
  const [bonusData, setBonusData] = useState<Achievement[]>([]);
  const [deliveryTemplateName, setDeliveryTemplateName] = useState('');
  const { convertEnumToOptionType, getEnumValueList } = useEnumValue();
  const [redeemParams, setRedeemParams] = useState('');
  const { currency } = useParamGeneral();
  const [redeemData, setRedeemData] = useState<any>([]);
  const [productData, setProductData] = useState<any>({});

  //下载全部
  const exportData = async () => {
    let page = 0;
    let size = 500;
    let allData: any[] = [];
    let total = 0;

    do {
      const params: any = {
        page,
        size,
        id: entity.id
      };
      const getData: any = await fetchRedeemList(params);
      const newData = getData.data.content.map((e: any) => ({
        ...e,
        issued: e.issued ? '已使用' : '未使用'
      }));
      total = getData.data.totalElements;
      allData = allData.concat(newData);
      ++page;
    } while (page * size < total);
    return allData
  };

  useEffect(() => {
    detailText && setRedeemParams(detailText);
    if (id) {
      props.getEntity(id);
      //用户标签组
      entity.tagGroupIds && entity.tagGroupIds.length > 0 && fetchTagGroupIds({ 'id.in': entity.tagGroupIds }).then(e => {
        setProductData({ ...productData, tagGroupIds: e })
      })
      //会员级别
      entity.customerLevelNo && fetchLevelList({ 'levelNo.equals': entity.customerLevelNo }).then(e => {
        setProductData({ ...productData, customerLevelNo: e })
      })
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, detailText]);

  const redeemColumns: ProColumns<any>[] = [{
    title: '订单id',
    dataIndex: 'orderId',
  }, {
    title: '兑换码',
    dataIndex: 'redeemCode',
  }, {
    title: '发放时间',
    dataIndex: 'issuedTime',
  }, {
    title: '状态',
    dataIndex: 'issued',
    render: (e: any) => e ? <Tag color="#eee">已使用</Tag> : <Tag color="green">未使用</Tag>
  }];

  const detailsColumns: ProColumns<Product, 'productStatus'>[] = [{
    render: (text: any, record: any) => {
      const video = internationalList(entity.video || '');
      return <div className="details-columns-box">
        <div className="details-columns-one">
          <Carousel autoplay dots className="product-carousel">
            {carouselData.map(item => <div className="carousel-box"><Image src={item} alt={item} /></div>)}
            {(video && video.length > 0) && <video src={video} controls />}
          </Carousel>
        </div>
        <div className="product-details-picture">
          <ul>
            <li style={{ marginBottom: '10px' }}><span className="product-name" dangerouslySetInnerHTML={{ __html: internationalDetails(record.name) }}></span></li>
            {record?.tags?.length > 0 && <li>{record.tags.map((e: string) => {
              const arr = convertEnumToOptionType('productTag').filter((t: any) => e === t.value);
              return arr.length > 0 ? <Tag key={arr[0].label} style={{ marginBottom: '4px' }} color="green">{arr[0].label}</Tag> : '';
            })}</li>}
          </ul>
        </div>
        <div className="product-details-picture">
          <ul className="product-id-ul">
            <li>ID：<span>{record.id}</span></li>
            <li>编码：<span>{record.code}</span></li>
            <li>兑换所需积分：<span>{record.pointPrice}</span></li>
          </ul>
        </div>
        <div className="product-details-picture">
          <ul className="product-id-ul">
            <li>商家：<span>{record.sellerName}</span></li>
            <li>品牌：<span>{internationalList(entity.brand?.brandName || '')}</span></li>
            <li>减库存方式：<span>{record.deductStock === 1 ? '拍下减库存' : '付款减库存'}</span></li>
            <li>订单未付款自动取消时间(秒)：<span>{record.autoCancelTime}</span></li>
          </ul>
        </div>
      </div>
    }
  }];

  const detailsBottomColumns: ProColumns<Product, 'DeliveryType'>[] = [{
    title: '免邮金额',
    dataIndex: 'freeFreightOrderAmount'
  },{
    title: '商品描述',
    dataIndex: 'subtitle',
    renderText: (text: string) => {
      return <span dangerouslySetInnerHTML={{ __html: internationalDetails(text) }}></span>
    }
  }, {
    title: '商品卖点',
    dataIndex: 'feature',
    renderText: (text: string) => {
      return <span dangerouslySetInnerHTML={{ __html: internationalDetails(text) }}></span>
    }
  }, {
    title: '是否包邮',
    dataIndex: 'freeShipping',
    render: (dom: any, record: any) => dom ? '是（包邮金额：' + currency?.symbol + ' ' + record.freeFreightOrderAmount + '）' : '否'
  }, {
    title: '是否虚拟商品',
    dataIndex: 'virtualProduct',
    render: (dom: any) => dom ? '是' : '否'
  },{
    title: '虚拟类型',
    dataIndex: 'virtualProductType',
    render: (text, record) => {
      return record.virtualProduct ? getEnumValueList('virtualProductType').filter(e => String(e.name) === String(text))?.[0]?.desc : '-'
    }
  }, {
    title: '是否组合商品',
    dataIndex: 'bundle',
    render: (dom: any) => dom ? '是' : '否'
  }, {
    title: '配送方式',
    dataIndex: 'deliveryType',
    render: (text, record) => {
      return getEnumValueList('DeliveryType').filter((e: any) => String(e.code) === String(text))?.[0]?.desc
    }
  }];

  const showDetail = (res: any) => {
    const detail = internationalList(res || '') || [];
    if (detail && detail.length > 0) {
      const details: any = Base64.decode(detail); // 解码
      let detailsArr = details.match(/<img.*?>/g);
      let imgSrc = detailsArr ? detailsArr.map((url: any) => url.match(/\ssrc=['"](.*?)['"]/)[1]) : [];
      setDetailData(imgSrc);
    }
  };

  const getDeliveryTemplateName = (id: string, storeId: string) => {
    deliveryTemplateName !== '-' && fetchDeliveryList({ 'id.equals': id, 'storeId.equals': '' }).then(e => {
      e.length > 0 ? setDeliveryTemplateName(e[0].name) : setDeliveryTemplateName('-');
    })
  };

  useEffect(() => {
    setDetailData([]);
    setDeliveryTemplateName('');
    if (entity.id) {
      (entity.deliveryTemplateId && entity.sellerId) ? getDeliveryTemplateName(entity.deliveryTemplateId, entity.sellerId) : setDeliveryTemplateName('-');
      const mainPicture = internationalList(entity.mainPicture || '');
      const picturesArr = internationalList(entity.pictures || '') || [];
      const pictures = (picturesArr.length > 0 && picturesArr.split(',')) || [];
      const carouselData = ([mainPicture] || []).concat(pictures).filter((e: any) => e !== '');
      setCarouselData(carouselData);
      showDetail(entity.detail)
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const skuColumns: ProColumns<SkuItem>[] = [{
    title: 'SkuId',
    dataIndex: 'id',
    valueType: 'text',
    copyable: true,
  }, {
    title: 'SkuCode',
    dataIndex: 'code',
    valueType: 'text',
    copyable: true,
  }, {
    title: '销售价',
    dataIndex: 'retailPrice',
    key: 'retailPrice',
    valueType: 'text',
  }, {
    title: '市场价',
    dataIndex: 'originPrice',
    key: 'originPrice',
    valueType: 'text',
  }, {
    title: '起订倍数',
    dataIndex: 'minOrderQuantity',
    key: 'minOrderQuantity',
    valueType: 'text',
  }, {
    title: '成本价',
    dataIndex: 'costPrice',
    key: 'costPrice',
    valueType: 'text',
  }, {
    title: '重量(单位kg)',
    dataIndex: 'weight',
    key: 'weight',
    valueType: 'text',
  }, {
    title: '销量',
    dataIndex: 'soldNum',
    key: 'soldNum',
    valueType: 'text',
  }, {
    title: '规格',
    dataIndex: 'specs',
    key: 'specs',
    valueType: 'text',
    render: (text, record: SkuItem) => record.specs && record.specs.map((tag: any) => <div>{tag.k}:{tag.v}</div>),
  },
  //  {
  //   title: '每人限购数量',
  //   dataIndex: 'maxPerPerson',
  //   key: 'maxPerPerson',
  //   valueType: 'digit',
  //   render: (text, record: SkuItem) => record.maxPerPerson && record.maxPerPerson > 0 ? record.maxPerPerson : '不限',
  //   hideInTable: true,
  // },
  // {
  //   title: 'code限购数量',
  //   dataIndex: 'maxPerPersonCode',
  //   key: 'maxPerPersonCode',
  //   valueType: 'digit',
  //   render: (text, record: SkuItem) => record.maxPerPersonCode && record.maxPerPersonCode > 0 ? record.maxPerPersonCode : '不限',
  // }, {
  //   title: '每单限购数量',
  //   dataIndex: 'maxPerOrder',
  //   key: 'maxPerOrder',
  //   valueType: 'digit',
  //   render: (text, record: SkuItem) => record.maxPerOrder && record.maxPerOrder > 0 ? record.maxPerOrder : '不限'
  // }, {
  //   title: '兑换所需庭豆',
  //   dataIndex: 'pointPrice',
  //   key: 'pointPrice',
  //   valueType: 'text',
  // }, {
  //   title: '每月最大领取数量',
  //   dataIndex: 'maxPerPersonMonth',
  //   key: 'maxPerPersonMonth',
  //   valueType: 'text',
  // }
  ];

  const operations = <Menu>
    <Authorized authority='viewProductBonuses'>
      <Menu.Item key="seeBonus">
        <ModalForm
          trigger={<Button type="text" size="middle" icon={<MoneyCollectOutlined />} onClick={() => {
            entity && setBonusData([entity.achievementTemplate || {}]);
          }} >查看商品奖金</Button>}
          title="查看商品奖金"
          onFinish={async (res) => { return true; }}
          width={1000}
          modalProps={{
            destroyOnClose: true,
          }}
        >
          <ProTable<Achievement, Record<string, any>, 'bonusCategory'>
            search={false}
            toolBarRender={false}
            pagination={false}
            dataSource={bonusData}
            columns={bonusProductColumns}
          />
        </ModalForm>
      </Menu.Item>
    </Authorized>
    <Authorized authority='scheduledLoadingAndUnloading'>
      <Menu.Item key="pimTime">
        <ModalForm
          trigger={<Button type="text" size="middle" icon={<ClockCircleOutlined />} >{entity.status === 'SELLING' ? '定时下架' : '定时上下架'}</Button>}
          title="商品定时上下架"
          onFinish={async (res) => {
            scheduledPim({
              onDateTime: res.onDateTime,
              offDateTime: res.offDateTime,
              productIds: [entity.id],
              type: 1
            });
            return true;
          }}
          modalProps={{
            destroyOnClose: true,
          }}
        >
          <Row>
            <Col span="12">
              <ProFormDateTimePicker disabled={entity.status === 'SELLING' ? true : false} width="md" name='onDateTime' label="定时上架时间" />
            </Col>
            <Col span="12">
              <ProFormDateTimePicker width="md" name='offDateTime' label="定时下架时间" />
            </Col>
          </Row>
        </ModalForm>
      </Menu.Item>
    </Authorized>
    <Menu.Item key="seeBonus">
      <ModalForm
        trigger={<Button type="text" size="middle" icon={<QrcodeOutlined />}>商品兑换码</Button>}
        title="兑换码"
        onFinish={async (res) => {
          return true;
        }}
        width={1000}
        modalProps={{
          destroyOnClose: true,
        }}
      >
        {entity.id && <ProTable<any>
          search={false}
          toolbar={{
            actions: [<ModalForm
              trigger={<Button size="middle">新增兑换码</Button>}
              title="新增兑换码"
              onFinish={async (res: any) => {
                const newData = { id: entity.id, ...res };
                if (newData.imports) {
                  const newCodes = newData.codes.split(',');
                  newData.codes = newCodes;
                  newData.quantity = newCodes.length;
                };
                delete newData.imports;
                AddRedeem(newData).then(e => {
                  setRedeemParams(String(new Date()));
                  message.success('批量新增成功');
                }).catch(e => message.error(e?.response?.data?.message || '批量新增失败'));
                return true;
              }}
              modalProps={{
                destroyOnClose: true,
              }}
            >
              <ProFormSwitch
                label="导入"
                name={'imports'}
                initialValue={false}
                fieldProps={{
                  checkedChildren: '是',
                  unCheckedChildren: '否'
                }}
              />
              <ProFormDependency name={['imports']}>
                {(({ imports }) => {
                  return !imports ? <ProFormDigit rules={[{ required: true }]} name="quantity" label="数量" /> : <ProFormTextArea extra="券码之间，请用“,”隔开！" rules={[{ required: true }]} name="codes" label="兑换码" />;
                })}
              </ProFormDependency>
            </ModalForm>, <Button onClick={() => {
              DeleteRedeem({ id: entity.id }).then(e => {
                message.success('清除兑换码成功');
                setRedeemParams(String(new Date()));
              }).catch(e => message.error(e?.response?.data?.message || '清除兑换码失败'));
            }}>清除商品兑换码</Button>, <ExportDataDialog
              columns={redeemColumns}
              dataSource={redeemData}
              exportData={exportData}
            />]
          }}
          params={{ id: entity.id, changeText: redeemParams }}
          postData={(data) => {
            const newData = data.length > 0 ? data.concat().map((e: any) => ({
              ...e,
              issued: e.issued ? '已使用' : '未使用'
            })) : [];
            setRedeemData(newData);
            return data;
          }}
          request={async (params) => {
            const result = await fetchRedeemList({
              page: (params?.current && params?.current - 1) || 0,
              size: params?.pageSize || 20,
              sort: 'lastModifiedDate,desc',
              ...params
            });
            return {
              success: true,
              total: result.data.totalElements || 0,
              data: result.data.content,
            };
          }}
          columns={redeemColumns}
        />}
      </ModalForm>
    </Menu.Item>
  </Menu>;
  const operation = [
    (['PENDING', 'SELLING', 'SHELF_DOWN'].indexOf(String(entity.status)) > -1) &&
    <Authorized authority='productOnOff'>
      <Popconfirm
        key="audit"
        placement="topRight"
        title={entity.status === 'SELLING' ? '你确定要下架吗？' : '你确定要上架吗？'}
        onConfirm={() => {
          entity.status !== 'SELLING' ? upUnderPim({ status: 1, spuIds: [String(entity.id)] }) : upUnderPim({ status: 0, spuIds: [String(entity.id)] });
        }}
      >
        <Button
          type="default"
          size="middle"
          danger={entity.status === 'SELLING' ? true : false}
          icon={entity.status === 'SELLING' ? <VerticalAlignBottomOutlined /> : <VerticalAlignTopOutlined />}
        >{entity.status === 'SELLING' ? '下架商品' : '上架商品'}</Button>
      </Popconfirm>
    </Authorized>,
    <AuditModalForm rowId={entity.id + ''} className='Product' />,
    <Authorized authority='productMore'>
      <Dropdown overlay={operations}>
        <Button >更多...</Button>
      </Dropdown>
    </Authorized>
  ];

  return (
    <DetailsPage<Product, 'productStatus'>
      title={props.entity.id}
      className="product-details"
      // hideEdit={hideOperation ? true : (authorities && !hasPermission(authorities, 'W_product')) ||
      //   ['TIMING_SELLING', 'SELLING', 'SOLD_OUT'].indexOf(props?.entity?.status || '') !== -1}
      operation={!hideOperation ? [operation] : []}
      editPage={(params) => <ProductEdit id={params?.id} text={params?.text} />}
      {...props}
    >
      <ProDescriptions<Product, 'productStatus'>
        columns={detailsColumns as any}
        dataSource={props.entity}
        column={1}
      />
      <ProDescriptions<Product, 'productStatus'>
        columns={columns as any}
        dataSource={props.entity}
        column={3}
      />
      <p className="delivery-template"><h4>运费模版：</h4>{deliveryTemplateName}</p>
      <ProDescriptions<Product, 'DeliveryType'>
        columns={detailsBottomColumns as any}
        dataSource={props.entity}
        column={3}
      />


      {/* <p className="product-list"><strong>用户标签组</strong>：{productData.tagGroupIds && productData.tagGroupIds.map((e: any) => <Tag>{e.name}</Tag>)}</p> */}
      <p className="product-list"><strong>会员级别</strong>：{productData.customerLevelNo && productData.customerLevelNo[0].levelName}</p>
      {internationalList(entity.banner || '') && <Title style={{ marginTop: '20px' }} level={5}>商品banner图</Title>}
      {internationalList(entity.banner || '') && <Image src={internationalList(entity.banner || '')} />}
      <Title level={4} className="sku-title">规格列表</Title>
      <ProTable<SkuItem>
        search={false}
        toolBarRender={false}
        columns={skuColumns}
        className='product-skuItem'
        rowClassName={e => e.status === 0 ? 'disable' : ''}
        dataSource={entity.items}
        pagination={{
          pageSize: 500,
          total: entity.items?.length || 0,
          hideOnSinglePage: true
        }}
      />
      {detailData.length > 0 &&
        <>
          <Title style={{ marginTop: '20px' }} level={4}>详情展示</Title>
          <div className="details-detail-img-box">
            <img className="mobile-img" src={mobileBg} alt="" />
            <ul className="details-detail-img">
              {detailData.map(src => <li><img src={src} alt='img' /></li>)}
            </ul>
          </div>
        </>}
    </DetailsPage>);
};

const mapStateToProps = ({ product, authentication }: RootState) => ({
  entity: product.entity,
  updateSuccess: product.updateSuccess
});

const mapDispatchToProps = {
  getEntity,
  scheduledPim,
  upUnderPim
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetails);
