import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultOrderCancel, OrderCancel } from 'model/procure/order-cancel.model';
import axios from 'axios';

const entity = 'order-cancel';
const apiUrl = '/api/yumei-mall-purchase/purchase-order/cancel-reason';
const standardReducerImpl = new StandardReducerImpl<OrderCancel>(entity, defaultOrderCancel, apiUrl);
export type OrderCancelState = StandardState<OrderCancel>;
export const orderCancel = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchOrderCancelList = async (params?: any): Promise<OrderCancel[]> => {
  const paramsData = {
    size: 20,
    ...params
  };
  const axiosResponse = await axios.get(`${apiUrl}`, { params: { ...paramsData } });
  return axiosResponse.data || [];
}