import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './secondSettled.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { SecondSettled } from "model/dealer/secondSettled.model";

export interface SecondSettledDetailsProps extends DetailsStateAndDispatchProps<SecondSettled>,
  RouteComponentProps, StateProps, DispatchProps {
}
const SecondSettledDetails: FC<SecondSettledDetailsProps> = (props) => {
  return (
    <DetailsPage<SecondSettled>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<SecondSettled>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ secondSettled }: RootState) => ({
  entity: secondSettled.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SecondSettledDetails);
