import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import './index.less';
import Contract from './list';
import ContractSign from './sign';
import AuthFlow from './authFlow';
import SealAuth from './sealAuth';


const ContractManage: React.FC<RouteComponentProps> = () => (
    <Router>
        <Contract path="list/*" />
        <ContractSign path="sign/*" />
        <AuthFlow path="auth-flow/*" />
        <SealAuth path="seal-auth/*" />
    </Router>
);
export default ContractManage;
