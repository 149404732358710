import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import TenantList from './tenant-list';
import TenantDetails from "./tenant-details";
import TenantEdit from "./tenant-edit";

const Tenant: React.FC<RouteComponentProps> = () => (
    <Router>
        <TenantList path = "/"/>
        <TenantDetails path = ":id"/>
        <TenantEdit path = ":id/edit"/>
        <TenantEdit path = "new"/>
    </Router>
);
export default Tenant;
