import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { defaultTopic, Topic } from 'model/video/topic.model';
import { CrudPutAction } from 'reducers/redux-action.type';
import axios from 'axios';

const entity = 'topic';
const apiUrl = '/api/yumei-mall-post/post/topic';
const standardReducerImpl = new StandardReducerImpl<Topic>(entity, defaultTopic, apiUrl);
export type TopicState = StandardState<Topic>
export const topic = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const changeEnabled: CrudPutAction<Topic> = entity => async dispatch => {
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(`${apiUrl}/${entity.id}/enabled/${entity.isEnable}`),
  });
};


export {
    apiUrl,
};
