import {BaseModel} from "../base.model";

export interface SecondSettled extends BaseModel {

}

export const silentOpened = {
    0:'未提交', 1: '审核中', 2: '成功', 3: '失败'
}

export const openAccountStatus:any = {
    '0':'未激活', '1': '激活中', '2': '激活失败', '3': '已激活', '': '-'
}

export const paymentCompleteStatus = {
    1: '未完成', 2: '已完成'
}

export const defaultSecondSettled:  Readonly<SecondSettled> = {};