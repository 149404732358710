import { FC} from "react"
import { RouteComponentProps } from '@reach/router';
import {  getEntity } from './advertisement.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Level } from "model/customerCore/level.model";

export interface LevelDetailsProps extends DetailsStateAndDispatchProps<Level>,
  RouteComponentProps, StateProps, DispatchProps {
}
const PricingRulesDetails: FC<LevelDetailsProps> = (props) => {

  return (
    <DetailsPage<Level>
      title={'广告位详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Level>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ level }: RootState) => ({
  entity: level.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PricingRulesDetails);
