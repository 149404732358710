import ProForm from '@ant-design/pro-form';
import { Button, Input, List, Space, message } from 'antd';
import { useEffect, useState } from 'react';

interface TencentMapProps {
    onChange?: any;//回调
    data?: {
        signAddress?: string;
        location?: number[];
    };//初始值
}

const TencentMap = (props: TencentMapProps) => {
    const { onChange, data } = props;
    const [searchText, setSearchText] = useState('');
    const [resultList, setResultList] = useState([]);
    const [addressKey, setAddressKey] = useState('');
    const [selectedObj, setSelectedObj] = useState<any>({});
    
    useEffect(() => {
        if (data && !!data.signAddress && data.location &&  (data.location.length > 1)) {
            adMap({
                type: 'addMarker',
                propsCenter: [data.location[0], data.location[1]],
                signAddress: data.signAddress,
            });
            setSelectedObj({
                useCenter: [data.location[0], data.location[1]],
                signAddress: data.signAddress,
            });
        }else{
            setSelectedObj({});
            setResultList([]);
            setSearchText('');
            setAddressKey('');
            adMap({});
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [data])

    const adMap = (props: {
        type?: 'addMarker' | '',
        propsCenter?: number[],
        signAddress?: string,
    }) => {
        const { type, propsCenter, signAddress } = props;
        const center:any = [116.43, 39.92];
        const useCenter = propsCenter ? propsCenter : center;
        //初始化地图
        var map = new AMap.Map("container", {
            center: useCenter, //设置地图中心点
            // resizeEnable: true, //地图初始化加载定位到当前城市
            zoom: 15, //设置当前显示级别
            expandZoomRange: true, //开启显示范围设置
            // zooms: [7, 20], //最小显示级别为7，最大显示级别为20
            // center: [116.43, 39.92],
            viewMode: "3D", //这里特别注意，设置为3D则其它地区不显示
            zoomEnable: true, //是否可以缩放地图
            resizeEnable: true,
        });

        if (type === 'addMarker') {
            onChange({
                useCenter: [useCenter[0], useCenter[1]],
                signAddress,
            });
            setSelectedObj({
                useCenter: [useCenter[0], useCenter[1]],
                signAddress,
            });
            // 创建标记
            const marker = new AMap.Marker({
                position: new AMap.LngLat(useCenter[0], useCenter[1]), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            });
            // 将创建的点标记添加到已有的地图实例：
            map.add(marker);
        };


        // 点击事件
        var clickHandler = (e: any) => { 

            // const marker = new AMap.Marker({
            //     position: new AMap.LngLat(e.lnglat.getLng(), e.lnglat.getLat()), // 经纬度对象，也可以是经纬度构成的一维数组[116.39, 39.9]
            // })
            // map.add(marker);

            getPositionByLonLats(e.lnglat.getLng(), e.lnglat.getLat());

        };
        map.on("click", clickHandler);

        // 根据经纬度获得详细地址
        var getPositionByLonLats = (lng: any, lat: any) => {
            const lnglatXY = [lng, lat]; // 地图上所标点的坐标
            var geocoder = new (AMap as any).Geocoder({
                radius: 10, //范围，默认：500
            });
            geocoder.getAddress(lnglatXY, (status: any, result: any) => {
                if (status === "complete" && result.regeocode) {
                    var address = result.regeocode.formattedAddress;
                    adMap({
                        type: 'addMarker',
                        propsCenter: [lng, lat],
                        signAddress: address,
                    });
                } else {
                    message.error("根据经纬度查询地址失败");
                }
            });
        };
    };

    const centerSearch = (searchText?: string) => {
        AMap.service(["AMap.PlaceSearch", "AMap.Autocomplete"], () => {
            //构造地点查询类
            var placeSearch = new (AMap as any).PlaceSearch({
                type: "汽车服务|餐饮服务|购物服务|生活服务|体育休闲服务|医疗保健服务|住宿服务|风景名胜|商务住宅|政府机构及社会团体|科教文化服务|交通设施服务|金融保险服务|公司企业|地名地址信息", // 兴趣点类别
                pageSize: 30, // 单页显示结果条数
                pageIndex: 1, // 页码
                city: "全国", // 兴趣点城市
                autoFitView: true, // 是否自动调整地图视野使绘制的 Marker点都处于视口的可见范围
            });
            placeSearch.search(searchText, function (status: any, result: any) {
                //查询成功时，result 即对应匹配的 POI 信息 
                if (result?.info === 'OK') {
                    message.success('查询成功');
                    setResultList(result?.poiList?.pois)
                } else {
                    message.error('查询失败，请联系管理员');
                };
            })
        });
    };

    return <div className='mapBox'>
        {selectedObj?.useCenter?.[0]  && <div className='topBox'>
            <p>当前选择地址：{selectedObj.signAddress}</p>
            <p>经纬度：{selectedObj?.useCenter?.[0]} 、 {selectedObj?.useCenter?.[1]}</p>
        </div>}
        <div className="map" id="container" />
        <div className='searchBox'>
            <ProForm.Item name={'searchText'} label='查询条件'>
                <Space.Compact style={{ width: '300px' }}>
                    <Input onChange={(e) => {
                        const value = e.target.value || '';
                        setSearchText(value);
                    }} width={'small'} placeholder='请输入查询条件' />
                    <Button type="primary" onClick={() => {
                        centerSearch(searchText)
                    }}>搜索</Button>
                </Space.Compact>
            </ProForm.Item>
            <List
                header={false}
                footer={false}
                bordered
                dataSource={resultList}
                renderItem={(item: any) => (
                    <div className={`listItemBox ${addressKey === item.id ? 'listItemBoxSelected' : ''}`} onClick={() => {
                        adMap({
                            type: 'addMarker',
                            propsCenter: [item.location.lng, item.location.lat],
                            signAddress: item.address,
                        });
                        setAddressKey(item.id)
                    }}>
                        <p className='listName'>{item.name}</p>
                        <p className='listType'>{item.type}</p>
                        <p className='listAddress'>{item.address}</p>
                    </div>
                )}
            />
        </div>
    </div>
}

export default TencentMap;