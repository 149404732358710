import { FC, useEffect, useState } from 'react';
import Field from '@ant-design/pro-field';
import { message, Space, Descriptions, Button, Modal, Spin } from 'antd';
import { ProFieldFCMode } from "@ant-design/pro-provider";
import { queryParamGroupForm, updateParamGroupForm } from './param-group.reducer';
import { RouteComponentProps } from "@reach/router";
import ProForm, {
    ProFormDigit, ProFormGroup, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea
} from '@ant-design/pro-form';
import { ParamGroupForms } from 'model/system/param.model';
import { isString } from 'lodash';
import { EditOutlined } from '@ant-design/icons';
import { Storage } from 'utils/storage-util';
const { confirm } = Modal;

interface ParamGroupFormProps extends RouteComponentProps {
    id: string | number
}

// eslint-disable-next-line @typescript-eslint/no-redeclare
const ParamGroupList: FC<ParamGroupFormProps> = props => {
    const { id } = props;
    const [loading, setLoading] = useState(false);
    const [state, setState] = useState<ProFieldFCMode>('read');
    const [paramGroupForm, setParamGroupForm] = useState([]);
    const isBooleanTypeVal = (value: string) => value === 'true' ? '是' : (value === 'false' ? '否' : value)
    // + `${!!item.defaultValue? ( ': '+ isBooleanTypeVal(item.defaultValue) ) : ''}`
    const FieldrenderFormItem = (props: { item: ParamGroupForms }) => {
        const { item } = props;
        const itemParam = {
            extra: item.remark,
            label: item.paramName,
            name: item.paramKey,
            initialValue: item.paramValue,
            // width: 500,
            // rules: [{required: true, message: item.paramName +'必填'}],
            // tooltip: item.remark,
            fieldProps: {
                defaultValue: item.paramValue
            }
        }

        return <>
            {['Text', 'LongText'].indexOf(item.dataType) > -1 && !item.allowedValues &&
                <ProFormText width={420} {...itemParam} />
            }
            {['MultiLine'].indexOf(item.dataType) > -1 &&
                <ProFormTextArea {...itemParam} />
            }
            {['Integer', 'Float'].indexOf(item.dataType) > -1 &&
                <ProFormDigit {...itemParam}
                    width='sm'
                    min={item.minValue}
                    max={item.maxValue}
                    fieldProps={{ precision: item.dataType === 'Integer' ? 0 : 2 }} />
            }
            {!!item.allowedValues &&
                <ProFormSelect {...itemParam}
                    width={item.dataType === 'List' ? 400 : 200}
                    initialValue={item.paramValue?.split(',')}
                    mode={item.dataType === 'List' ? 'multiple' : undefined}
                    options={item.allowedValues} />
            }
            {['Boolean'].indexOf(item.dataType) > -1 &&
                <ProFormSwitch {...itemParam} width={50}
                    initialValue={item.paramValue === 'true' ? true : item.paramValue === 'false' ? false : true}
                    fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否',
                        defaultChecked: item.paramValue === 'true'
                    }} />
            }
        </>

    }

    useEffect(() => {
        const getParamGroupForm = async () => {
            setLoading(true);
            const res = await queryParamGroupForm(id);
            if (res) {
                setParamGroupForm(res)
                setLoading(false)
            }
        }
        id && getParamGroupForm();
    }, [id, state])

    return (
        <>
            {
                paramGroupForm.length > 0 &&
                <>
                    <Space className='param-group'>
                        {state === 'read' && <Button type="link" onClick={() => setState('edit')}>修改<EditOutlined /></Button>}
                    </Space>
                    <ProForm
                        layout='vertical'
                        name="paramGroupForm"
                        onFinish={async (value) => {
                            Object.entries(value).map(item => {
                                const itemVal = isString(item[1]) ? item[1] : item[1] + ''
                                return value[item[0]] = itemVal
                            })
                            confirm({
                                title: '确定要提交吗？',
                                onOk() {
                                    updateParamGroupForm(id, value)
                                        .then(data => {
                                            id === 'general' && Storage.session.set("param-general", data)
                                            message.success('提交成功');
                                            setState('read');
                                        })
                                        .catch(err => message.error('提交失败'));
                                }
                            });

                            return true
                        }}
                        submitter={{
                            submitButtonProps: { hidden: true },
                            resetButtonProps: { hidden: true },
                            render: (props, doms) => {
                                return [
                                    state === 'edit' && <Button type="primary" key="submit" onClick={() => props.form?.submit?.()}>提交</Button>,
                                    state === 'edit' && <Button type="default" key="close" onClick={() => setState('read')}>取消</Button>,
                                    ...doms
                                ];
                            }
                        }}
                    >
                        {state === 'edit' &&
                            <ProFormGroup direction='vertical'>
                                {paramGroupForm && paramGroupForm.map((item: ParamGroupForms) =>
                                    <Space>
                                        <FieldrenderFormItem item={item} />
                                        <span style={{ color: '#a5a5a5' }}>{!!item.defaultValue ? ('(默认：' + isBooleanTypeVal(item.defaultValue)) + ')' : ''}</span>
                                    </Space>)
                                }
                            </ProFormGroup>
                        }
                        {state === 'read' &&
                            <Descriptions>
                                {
                                    paramGroupForm && paramGroupForm.map((item: ParamGroupForms) =>
                                        <Descriptions.Item label={item.paramName} style={{ display: 'flex' }}>
                                            <Field text={isBooleanTypeVal(item.paramValue || item.defaultValue || '')}
                                                key={item.paramKey} mode={state} />
                                        </Descriptions.Item>
                                    )
                                }
                            </Descriptions>
                        }

                    </ProForm>
                </>
            }
            {
                paramGroupForm.length === 0 && loading && <div className="example">
                    <Spin tip="Loading..."></Spin>
                </div>
            }
        </>
    );
};

export default ParamGroupList;