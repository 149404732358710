import { FC, ReactNode} from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ContractTemplateDetails from "./contractTemplate-details";
import { deleteEntity, getEntities, getEntity } from "./contractTemplate.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ContractTemplateEdit from "./contractTemplate-edit";
import { ContractTemplate } from "model/contractAdmi/contractTemplate.model";

export interface ContractTemplateListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const ContractTemplateList: FC<ContractTemplateListProps> = (props) => {

  const columns: ProColumns<ContractTemplate, 'contractType'>[] = [{
    title: 'id',
    dataIndex: 'id'
  },{
    title: '标题',
    dataIndex: 'title'
  },{
    title: '类型',
    dataIndex: 'type',
    valueType: 'contractType',
  },{
    title: '商家名称',
    dataIndex: 'storeName'
  },{
    title: '商家id',
    dataIndex: 'storeId',
    hideInTable: true,
  },{
    title: '等级名称',
    dataIndex: 'levelName'
  },{
    title: '等级id',
    dataIndex: 'levelId',
    hideInTable: true,
  },{
    title: 'e签宝模板id',
    dataIndex: 'docTemplateId',
    hideInDescriptions:true,
    ellipsis:true,
  },{
    title: 'e签宝文件id',
    dataIndex: 'fileId',
    hideInDescriptions:true,
    ellipsis:true,
  },{
    title: '下载路径',
    dataIndex: 'fileDownloadUrl',
    hideInDescriptions:true,
    ellipsis:true,
  }]

  const searchFields = [{
    label: '标题',
    name: 'title.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<ContractTemplate,'contractType'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ContractTemplateDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      hideDeleteButton
      editPage={(params: any) => <ContractTemplateEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ contractTemplate }: RootState) => ({
  entity: contractTemplate.entity,
  entities: contractTemplate.entities,
  loading: contractTemplate.loading,
  updateSuccess: contractTemplate.updateSuccess,
  createSuccess: contractTemplate.createSuccess,
  total: contractTemplate.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractTemplateList);