import React, { useState, useEffect, FC } from 'react'
import { Form, Input, Modal, Select } from 'antd';
import { queryExpressList, updateLogistics } from '../purchase-order/order.reducer';
import { Order } from 'model/procure/order.model';
const { Option } = Select;

export interface OrderDetailsUpdatePackagepProps {
  packageEntity: any,
  visible: boolean,
  onCancel: Function,
  orderEntity?: Order;
  getEntity?: any;
}

// 修改物流
const OrderDetailsUpdatePackage: FC<OrderDetailsUpdatePackagepProps> = (props) => {
  const { packageEntity, visible, onCancel, orderEntity, getEntity } = props;
  const [form] = Form.useForm();
  const [expressListData, setExpressListData] = useState<[]>([]); //快递列表

  useEffect(() => {
    const expressFun = async () => {
      const res = await queryExpressList();
      res && setExpressListData(res);
      res && form.setFieldsValue({ ...packageEntity, expressId: res.concat().filter((e: any) => e.expressNo === packageEntity?.expressNo)[0]?.expressCode, logisticCode: packageEntity?.expressNo, mobile: orderEntity?.address?.mobile || '' });
    }
    visible && expressListData.length === 0 && expressFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <Modal
      width={480}
      title='修改物流信息'
      visible={visible}
      onCancel={() => onCancel()}
      onOk={() => {
        form.validateFields().then(async values => {
          form.resetFields();
          let params = {
            ...values,
            packageId: packageEntity?.id
          };
          await updateLogistics(params);
          getEntity();
          onCancel();
        }).catch(info => {
          // console.log('Failed:', info);
        });
      }}
    >
      <Form
        labelAlign='right'
        form={form}
        name='form_stock_modal'

      >
        <Form.Item
          label='物流公司'
          name='expressCode'
          rules={[{ required: true }]}>
          <Select style={{ width: 200 }} placeholder='请选择' showSearch onChange={(e, option: any) => {
            form.setFieldsValue({ expressName: option?.children });
          }} optionFilterProp="children">
            {expressListData && expressListData.map((item: any) => (
              <Option key={item.id} value={item.expressCode}>{item.expressName}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item name="expressName" hidden>
          <Input placeholder='请输入物流公司名称' />
        </Form.Item>
        <Form.Item
          label='快递单号'
          name='logisticCode'
          rules={[{ required: true }]}>
          <Input placeholder='请输入单号' />
        </Form.Item>
        <Form.Item
          label='手机号'
          name='mobile'
          hidden
        >
          <Input disabled placeholder='请输入手机号' />
        </Form.Item>
      </Form>
    </Modal>
  )
}


export default OrderDetailsUpdatePackage;