import ProForm, { ProFormDependency, ProFormList, ProFormSelect, ProFormText, ProFormTextArea } from '@ant-design/pro-form';
import { Col, Form, Row, message } from 'antd';
import Title from 'antd/lib/typography/Title';
import SelectSearch from 'components/select-search';
import { fetchCustomerListSelect } from 'pages/dealer/unit/unit.reducer';
import { useEffect, useState } from 'react';
import { getBalance } from './trainList.reducer';

interface ParticipationControlProps {
    id?: string | number;
    form?: any;
    record?: any;
    meetId?: any;
}

const ParticipationControl = (props: ParticipationControlProps) => {

    const { form, record, meetId } = props;
    const [balance, setBalance] = useState(0);

    useEffect(() => { 
        form.setFieldsValue({ 'test_1': 'name.contains' })
        if (record && form) {
            fetchBalance(record.customerId);
            form.setFieldsValue({ checkRooms: record.checkRooms, id: record.id, meetId, customerCode: record.customerCode, remark: record.remark });
        } else {
            form.setFieldsValue({ checkRooms: [{ sex: null, 'test_1': 'name.contains' }] })
        };

        meetId && form.setFieldsValue({ meetId })

    }, [record, form, meetId]);

    const fetchBalance = (id: any) => {
        getBalance(id).then(e => {
            setBalance(e.data);
            message.success('培训费余额查询成功');
        }).catch(error => {
            message.error(error?.response?.data?.title);
        });
    };

    return (
        <>
            <ProFormText
                name="id"
                disabled
                hidden
            />
            <ProFormText
                name="meetId"
                disabled
                hidden
            />
            <ProFormText
                name="customerCode"
                disabled
                hidden
            />
            <Row>
                {!record && <>
                    <Col span={4}> <Title style={{ fontSize: '14px', lineHeight: '32px' }} level={5}>选择经销商</Title></Col>
                    <Col span={5}>
                        <ProFormSelect
                            name="test_1"
                            options={[{
                                label: "授权id搜索", value: 'account.equals',
                            }, {
                                label: "姓名搜索", value: 'name.contains',
                            }]}
                            placeholder="搜索类型"
                        />
                    </Col>
                    <Col span={1} />
                    <Col span={8}>
                        <ProFormDependency name={['test_1']}>
                            {({ test_1 }) => {
                                return !!test_1 && <Form.Item name="test_2">
                                    <SelectSearch width={300} required={true} name="test_2" fetchOptionObj={(option: any) => {
                                        const data = form.getFieldsValue();
                                        fetchBalance(option.id);
                                        form.setFieldsValue({
                                            checkRooms: data.checkRooms.concat().map((item: any, index: 0) => ({
                                                name: index === 0 ? option.name : item.name,
                                                phone: index === 0 ? option.phone : item.phone,
                                            }))
                                        });
                                        form.setFieldsValue({
                                            customerCode: option.account
                                        });
                                    }} placeholder="请输入" labelValue="name" defaultParams='' fetchFunc={fetchCustomerListSelect} searchParams={test_1} />
                                </Form.Item>
                            }
                            }
                        </ProFormDependency>

                    </Col>
                    <Col span={6} />
                </>}
                <Col span={4}>
                    <div className='list_titleBox'>
                        <Title style={{ fontSize: '14px', lineHeight: '32px' }} level={5}>参会经销商</Title>
                        <Title style={{ fontSize: '14px', lineHeight: '32px', marginTop: '79px' }} level={5}>携带人员</Title>
                        <p className='xian'></p>
                    </div>
                </Col>
                <Col span={20}>
                    <ProFormList
                        label=""
                        name={'checkRooms'}
                        creatorButtonProps={{
                            creatorButtonText: '添加参会人员',
                        }}
                        initialValue={[{ sex: 2 }]}
                        actionRender={(field, action, defaultActionDom) => { 
                            return field.fieldKey === 0 ? [] : [defaultActionDom[1]];
                        }}
                    >
                        {(meta, itemIndex, action) => <ProForm.Group size={10}>
                            <Row>
                                {/* {
                                    itemIndex === 0 ? <>

                                        <Col span={3}>
                                            <ProFormText
                                                name="name"
                                                label={"经销商姓名"}
                                                disabled
                                            />
                                        </Col>
                                        <Col span={2}>
                                            <Button style={{ marginTop: '30px' }} type="primary" onClick={() => setVisible(true)}>选择</Button>
                                        </Col>
                                        <Col span={1}></Col>

                                    </> : <>
                                        <Col span={5}>
                                            <ProFormText
                                                name="name"
                                                label={" 携带人员姓名"}
                                            />
                                        </Col>
                                        <Col span={1}></Col>
                                    </>
                                } */}
                                <Col span={5}>
                                    <ProFormText
                                        name="name"
                                        placeholder={itemIndex === 0 ? '经销商姓名' : '携带人员姓名'}
                                        disabled={itemIndex === 0 ? true : false}
                                        rules={[{ required: true }]}
                                    />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}>
                                    <ProFormSelect
                                        name="sex"
                                        options={[{
                                            label: "男", value: 1,
                                        }, {
                                            label: "女", value: 2,
                                        }]}
                                        placeholder="性别"
                                    />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}>
                                    <ProFormText
                                        name="phone"
                                        disabled={itemIndex === 0 ? true : false}
                                        placeholder="手机号"
                                        rules={[{ required: true }]}
                                    />
                                </Col>
                                <Col span={1}></Col>
                                <Col span={5}>
                                    <ProFormText
                                        name="roomNo"
                                        placeholder="房间号"
                                    />
                                </Col>
                                {itemIndex === 0 && <Col span={24}>
                                    <div className='balanceBox'>培训费余额：{balance}</div>
                                </Col>}
                            </Row>
                        </ProForm.Group>}
                    </ProFormList>
                </Col>
                <Col span={4}><Title style={{ fontSize: '14px', lineHeight: '22px' }} level={5}>备注</Title></Col>
                <Col span={20}>
                    <ProFormTextArea
                        name='remark'
                        placeholder='请输入备注信息'
                    />
                </Col>
            </Row >
        </>)
}

export default ParticipationControl;