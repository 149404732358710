import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Tooltip, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SpecDetails from "./spec-details";
import { deleteEntity, editSpec, getEntities, getEntity } from "./spec.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SpecEdit from "./spec-edit";
import { Spec } from "model/product/spec.model";
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import { EditOutlined } from "@ant-design/icons";

export interface SpecListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Spec>[] = [{
  title: 'id',
  dataIndex: 'id',
  width: 100,
}, {
  title: '商品名称',
  dataIndex: 'spuName',
  ellipsis: true,
  width: 100,
}, {
  title: '商品编码',
  dataIndex: 'spuCode',
  width: 100,
}, {
  title: '规格名',
  dataIndex: 'specKey',
  width: 100,
}, {
  title: '规格值',
  dataIndex: 'specValue',
  width: 100,
}];


const SpecList: FC<SpecListProps> = (props) => {
  const [refreshFlag, setRefreshFlag] = useState(0)
  const searchFields = [{
    label: '商品名称',
    name: 'spuName.equals',
  }, {
    label: '商品编码',
    name: 'spuCode.equals',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <ModalForm
      title={'编辑商品规格'}
      trigger={<Tooltip title={'编辑'} key="edit"><Button type="link" size="small" ><EditOutlined /></Button></Tooltip>}
      modalProps={{
        destroyOnClose: true,
      }}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          ...data,
          id:record.id
        }
        return new Promise<boolean>((resolve, reject) => {
          editSpec(params).then(e => {
            message.success('操作成功');
            setRefreshFlag(new Date().getTime())
            resolve(true);
          }).catch(error => {
            message.error(error?.response?.data?.title);
            resolve(false);
          })
        });
      }}
    >
      <p className="specP"><span>商品名称</span>：{record.spuName}</p>
      <p className="specP"><span>商品编码</span>：{record.spuCode}</p>
      <p className="specP specPB"><span>规格名</span>：{record.specKey}</p>
      <ProFormText
        name="specValue"
        label="规格值"
        initialValue={record.specValue || null}
      />
      {/* <ProFormDigit
        name="sort"
        label="排序"
        initialValue={record.sort || null}
      /> */}
    </ModalForm>
  }]

  return (
    <ListViewPage<Spec>
      columns={columns}
      hideDeleteButton
      refreshFlag={refreshFlag}
      hideEditButton
      hideCreateButton
      quickFilterPanel={quickFilterPanel}
      detailPage={<SpecDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      rowActions={rowActions}
      sort="id,desc"
      editPage={(params: any) => <SpecEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ spec }: RootState) => ({
  entity: spec.entity,
  entities: spec.entities,
  loading: spec.loading,
  updateSuccess: spec.updateSuccess,
  createSuccess: spec.createSuccess,
  total: spec.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SpecList);