import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import {
  CrudPutAction,
  CrudUploadAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { Page } from "model/page.model";
import { defaultStock, Stock } from 'model/product/stock.model';

const entity = 'stock';
const apiUrl = '/api/yumei-mall-pim/stock';
const standardReducerImpl = new StandardReducerImpl<Stock>(entity, defaultStock, apiUrl);
export type StockState = StandardState<Stock>;
export const stock = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
//新增库存
export const createEntity: CrudPutAction<Stock> = (entity: any) => async dispatch => {
  //Absolute("手工绝对调整") Related("手工加减")
  const requestUrl = `${apiUrl}/batch/quantity`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, {
      adjustmentType: 'Absolute', stockList: [{
        quantity: entity.quantity,
        skuId: entity.sku?.id,
        wholesaleShopId: entity.shopId,
      }]
    }),
  });
};
// export const updateEntity = standardReducerImpl.updateEntity;
//单个修改库存
export const updateEntity: CrudPutAction<Stock> = (entity: any) => async dispatch => {
  const requestUrl = `${apiUrl}/batch/quantity`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, {
      adjustmentType: entity.adjustmentType,
      stockList: [{
        quantity: entity.quantity,
        skuId: entity.sku?.id,
        wholesaleShopId: entity.shopId,
      }],
    }),
  });
};
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//批量修改库存
export const importStock: CrudUploadAction<Stock> = (stockList: any[], params: any) => async dispatch => {
  const requestUrl = `${apiUrl}/batch/quantity`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, { stockList, ...params }),
  });
};

export const cancelStock: CrudUploadAction<any> = (skuIds: any[], params: any) => async dispatch => {
  const requestUrl = `${apiUrl}/cancel`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, { skuIds, ...params }),
  });
};

export const searchSkuList = async (params?: QueryParameter): Promise<Page<Stock>> => {
  const {
    page = 0,
    size = 500,
    ...rest
  } = params || {};

  return await axios.get<any>(apiUrl, {
    params: {
      size,
      page,
      ...rest
    }
  });
};

export {
  apiUrl,
};