import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import RetailDetails from "./retail-details";
import { deleteEntity, getEntities, getEntity } from "./retail.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import RetailEdit from "./retail-edit";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { Retail } from "model/scanCodeOrder/retail.model";
import { sendOutGoodsRowActions } from "../sendOutGoods/sendOutGoods-list";

export interface RetailListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const RetailList: FC<RetailListProps> = (props) => {

  const columns: ProColumns<Retail>[] = [{
    title: '订单号',
    dataIndex: 'shipmentNo',
    width: 200,
  }, {
    title: '收货人姓名',
    dataIndex: 'receiveNodeName',
    width: 200,
  }, {
    title: '收货人手机号',
    dataIndex: 'receiveMobile',
    width: 200,
  }, {
    title: '下单时间',
    dataIndex: 'shipTime',
    width: 200,
  }, {
    title: '物流码',
    dataIndex: '',
    render: (dom, record) => {
      return <>{record.shipmentLine.concat().map((e: any) => {
        return <>{e.itemUniqueNo.map((item: any) => {
          return <p>{item.scode}</p>
        })}</>
      })}</>
    },
    width: 200,
  }];


  const searchFields = [{
    label: '订单号',
    name: 'shipmentNo.equals',
  },{
    label: '物流码',
    name: 'text.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch placeholder='店铺' required={false} name="storeId.equals" width="md" labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Retail>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RetailDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      togetherParams={{ 'shipmentType.equals': 'Sale' }}
      hideCreateButton
      hideDeleteButton
      hideEditButton
      rowActions={sendOutGoodsRowActions}
      editPage={(params: any) => <RetailEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ retail }: RootState) => ({
  entity: retail.entity,
  entities: retail.entities,
  loading: retail.loading,
  updateSuccess: retail.updateSuccess,
  createSuccess: retail.createSuccess,
  total: retail.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RetailList);