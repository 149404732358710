import moment, {Moment} from 'moment';

import {
  APP_LOCAL_DATE_FORMAT,
  APP_LOCAL_DATETIME_FORMAT,
  APP_LOCAL_DATETIME_FORMAT_Z, APP_LOCAL_MONTH_FORMAT, APP_LOCAL_QUARTER_FORMAT,
  APP_LOCAL_YEAR_FORMAT,
  APP_TIMESTAMP_FORMAT,
  APP_LOCAL_TIME_FORMAT,
} from 'config/constants';

export const convertDateTimeFromServer = (date: any) => (date ? moment(date).format(APP_LOCAL_DATETIME_FORMAT) : null);

// export const convertDateToServer = (date: Moment) => (date ? date.format(APP_LOCAL_DATE_FORMAT) : '');

export const convertDateToServer = (date: Moment, format?: string) => ((date && date.format(format || APP_LOCAL_DATE_FORMAT)) || undefined);

export const convertDateTimeToServer = (date: any) => (date ? moment(date, APP_LOCAL_DATETIME_FORMAT_Z).toDate() : null);

export const displayDefaultDateTime = () => moment().startOf('day').format(APP_LOCAL_DATETIME_FORMAT);

export const getValuePropsForDate = (dateString: any, format?: string): any => {
  return (dateString && ({value: moment(dateString, format)})) || dateString;
};
export const getValuePropsForYear = (dateString: any): any => getValuePropsForDate(dateString, APP_LOCAL_YEAR_FORMAT);
export const getValuePropsForMonth = (dateString: any): any => getValuePropsForDate(dateString, APP_LOCAL_MONTH_FORMAT);
export const getValuePropsForQuarter = (dateString: any): any => getValuePropsForDate(dateString, APP_LOCAL_QUARTER_FORMAT);
export const getValuePropsForDateTime = (dateString: any): any => getValuePropsForDate(dateString,   APP_LOCAL_DATETIME_FORMAT,
  );

export const getValueFromEventForDate = (value: any): any => convertDateToServer(value);
export const getValueFromEventForDateTime = (value: any): any => convertDateToServer(value, APP_LOCAL_DATETIME_FORMAT);
export const getValueFromEventForYear = (value: any): any => convertDateToServer(value, APP_LOCAL_YEAR_FORMAT);
export const getValueFromEventForMonth = (value: any): any => convertDateToServer(value, APP_LOCAL_MONTH_FORMAT);
export const getValueFromEventForQuarter = (value: any): any => convertDateToServer(value, APP_LOCAL_QUARTER_FORMAT);

export const isDateFormat = (value: string):boolean => moment(value, APP_LOCAL_DATE_FORMAT, true).isValid();
export const isDateTimeFormat = (value: string):boolean => moment(value, APP_TIMESTAMP_FORMAT, true).isValid();
export const getFormatExcelDate = (value: Date): string => moment(value).add(1, 'days').format(APP_LOCAL_DATE_FORMAT);
export const getFormatExcelDateTime = (value: Date): string => moment(value).add(1, 'minutes').format(APP_TIMESTAMP_FORMAT);
export const getFormatReactTableTime = (value: Date): string => moment(value).format(APP_LOCAL_DATETIME_FORMAT_Z);
export const isTimeFormat = (value: string):boolean => moment(value, APP_LOCAL_TIME_FORMAT, true).isValid();


