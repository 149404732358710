import { BaseModel } from "../base.model";

export interface SummaryList extends BaseModel {

}

export const SummaryStatus = {
    '1': '未发放',
    '2': '审核中',
    '3：': '已发放',
}

export const defaultSummaryList: Readonly<SummaryList> = {};