import axios from 'axios';
import COS from 'cos-js-sdk-v5';
import { message } from 'antd';
import TcVod from 'vod-js-sdk-v6';
import OSS from "ali-oss";

function authorizationParam(cosInfo: any) {
  return {
    TmpSecretId: cosInfo.data.accessKeyId || '',
    TmpSecretKey: cosInfo.data.accessKeySecret || '',
    SecurityToken: cosInfo.data.securityToken || '',
    StartTime: Math.round((new Date().getTime()) / 1000),
    ExpiredTime: cosInfo.data.expiredTime || '',
  }
}

interface CosInfo {
  bucket: string;
  folder?: string;
  region: string;
  accessKeyId: string;
  accessKeySecret: string;
  securityToken: string;
  expiredTime: string;
  domainName?: string;
}

/**
 * 阿里云上传图片
 * @param fileObject 上传File对象
 * @param category   公共/私有 默认公共  private   public
 * @param fileUrl    文件夹名称/图片名称
 * @returns 
 */
export const uploadImage = async (fileObject: any, fileUrl: string, category = 'public') => {
  // category取值: public private
  // const suffix = fileObject.name.substring(fileObject.name.lastIndexOf(".")); //文件后缀
  const cosInfo = await axios.get<any>(`/api/yumei-mall-common/sts/aliyun/${category}`, {}); 
  if (cosInfo.data != null) {
    const OssClient = new OSS({
      accessKeyId: cosInfo.data.accessKeyId,
      accessKeySecret: cosInfo.data.accessKeySecret,
      stsToken: cosInfo.data.SecurityToken,
      region: cosInfo.data.region,
      bucket: cosInfo.data.bucket,
      refreshSTSTokenInterval: 3000,
      // domainName: cosInfo.data.domainName || 'http://images-test.merrige.cn',
    });
    
    let putUrl = category === 'public' ?  `${category}/${fileUrl}` : `${fileUrl}`
    const {
      url
    } = await OssClient.put(
      putUrl,
      fileObject
    );
    return cosInfo.data.domainName + url.split('.com')[1];
  }
};



/**
 * 阿里云上传视频
 * @param fileObject 上传File对象
 * @param category   公共/私有 默认公共
 * @param fileUrl    文件夹名称/图片名称
 * @returns 
 */
export const aliyuploadVideo = async (fileObject: any, fileUrl: string, category: 'public' | 'private' = 'public') => {


  const cosInfo = await axios.get<any>(`/api/yumei-mall-common/sts/aliyun/${category}`, {}); 
  if (cosInfo.data != null) {
    const OssClient = new OSS({
      accessKeyId: cosInfo.data.accessKeyId,
      accessKeySecret: cosInfo.data.accessKeySecret,
      stsToken: cosInfo.data.SecurityToken,
      region: cosInfo.data.region,
      bucket: cosInfo.data.bucket,
      refreshSTSTokenInterval: 3000,
      // domainName: cosInfo.data.domainName || 'http://images-test.merrige.cn',
    });

    const options:any = {
      // 获取分片上传进度、断点和返回值。
      progress: (p:any, cpt:any, res:any) => {
        console.log(p);
      },
      // 设置并发上传的分片数量。
      parallel: 4,
      // 设置分片大小。默认值为1 MB，最小值为100 KB。
      partSize: 20 * 1024 * 1024,
      headers: {
        Expires: 1000000,
      },
      // 自定义元数据，通过HeadObject接口可以获取Object的元数据。
      // meta: { year: 2020, people: "test" },
      mime: "text/plain",
    };

    const {name} = await OssClient.multipartUpload(
      `${category}/${fileUrl}`,
      fileObject,
      options
    );
    return cosInfo.data.domainName + '/' + name;
  }
};

export const httpOrHttps = (url: string) => {
  let name = url.split('://')[1];
  return name.substr(name.indexOf('/'));
}


/**
 * 腾讯云获取图片（category 私有）
 * @param imageUrl  图片路径
 * @returns 
 */
export const readOnlyImage = async (imageUrl: string) => {
  const cosInfo = await axios.get<CosInfo>(`/api/tbt-mall-common/sts/tencent/private`, {});
  const imageName = httpOrHttps(imageUrl);
  if (cosInfo.data != null) {
    const cos = new COS({
      getAuthorization: (options, callback) => callback(authorizationParam(cosInfo))
    });
    return new Promise<string>((resolve, reject) => {
      cos.getObjectUrl({
        Bucket: cosInfo?.data?.bucket || '',
        Region: cosInfo?.data?.region || '',
        Key: decodeURI(imageName), // 读取的图片名
      }, (err: any, data: any) => {
        if (err) {
          message.error(err);
        } else {
          data && resolve(data.Url);
        }
      });
    });
  }
}

//**
//腾讯云上传视频
export const uploadVideo = async (fileObject: any) => {
  return new Promise<void>((resolve, reject) => {
    const signature = () => new Promise<string>((resolve, reject) => {
      axios.get<any>(`/api/tbt-mall-common/vod/signature?classId=895114&procedure=CutCoverPreset`).then(res => resolve(res.data.value))
    });
    const tcVod = new TcVod({
      getSignature: signature // 前文中所述的获取上传签名的函数
    })
    const uploader = tcVod.upload({
      mediaFile: fileObject, // 媒体文件（视频或音频或图片），类型为 File
    });
    uploader.done().then(res => resolve(res)).catch(err => '')
  });
}