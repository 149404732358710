import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SecondSettledDetails from './secondSettled-details';
import SecondSettledEdit from './secondSettled-edit';
import SecondSettledList from './secondSettled-list';

const SecondSettled: React.FC<RouteComponentProps> = () => (
  <Router>
    <SecondSettledList path="/" />
    <SecondSettledDetails path=":id" />
    <SecondSettledEdit path=":id/edit" />
    <SecondSettledEdit path="new" />
  </Router>
);
export default SecondSettled;
