import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './contractTemplate.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { ContractTemplate } from "model/contractAdmi/contractTemplate.model";
import ProTable from "@ant-design/pro-table";
import Title from "antd/lib/typography/Title";
import { Col, Row } from "antd";

export interface ContractTemplateDetailsProps extends DetailsStateAndDispatchProps<ContractTemplate, 'contractType'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const partyABSignAreasColumns = [{
  title: '页码',
  dataIndex: 'pageNum'
}, {
  title: 'X坐标',
  dataIndex: 'x'
}, {
  title: 'Y坐标',
  dataIndex: 'y'
}];

const componentMapColumns = [{
  title: '控件key值(唯一)',
  dataIndex: 'title'
}, {
  title: '控件id',
  dataIndex: 'componentId'
}, {
  title: '控件名称',
  dataIndex: 'componentName'
}];

const ContractTemplateDetails: FC<ContractTemplateDetailsProps> = (props) => {

  const { entity } = props;
  const [componentMapData, setComponentMapData] = useState<any>([]);

  useEffect(() => {
    const newComponentMap: any = [];
    const newKeys = entity.componentMap ? Object.keys(entity.componentMap) : [];
    newKeys.forEach((e, index) => {
      newComponentMap[index] = {
        title: e,
        componentId: entity.componentMap[e].componentId,
        componentName: entity.componentMap[e].componentName
      }
    });
    setComponentMapData(newComponentMap);
  }, [entity]);

  return (
    <DetailsPage<ContractTemplate, 'contractType'>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<ContractTemplate, 'contractType'>
        columns={props.columns as any}
        dataSource={props.entity}
        column={4}
      />
      <Row>
        <Col span={12}>
          <Title level={5} >甲方</Title>
          <ProTable<any>
            columns={partyABSignAreasColumns}
            rowKey="id"
            dataSource={props.entity?.partyaSignAreas || []}
            options={false}
            search={false}
            pagination={false}
          />
        </Col>
        <Col span={24} />
        <Col span={12}>
          <Title level={5} >乙方</Title>
          <ProTable<any>
            columns={partyABSignAreasColumns}
            rowKey="id"
            dataSource={props.entity?.partybSignAreas || []}
            options={false}
            search={false}
            pagination={false}
          />
        </Col>
        <Col span={24} />
        <Col span={12}>
          <Title level={5} >控件信息</Title>
          <ProTable<any>
            columns={componentMapColumns}
            rowKey="id"
            dataSource={componentMapData}
            options={false}
            search={false}
            pagination={false}
          />
        </Col>
      </Row>


    </DetailsPage>);
};

const mapStateToProps = ({ contractTemplate }: RootState) => ({
  entity: contractTemplate.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractTemplateDetails);
