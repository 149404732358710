import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import BrandLevelDetails from './brandLevel-details';
import BrandLevelEdit from './brandLevel-edit';
import BrandLevelList from './brandLevel-list';

const DealerBrandLevel: React.FC<RouteComponentProps> = () => (
  <Router>
    <BrandLevelList path="/" />
    <BrandLevelDetails path=":id" />
    <BrandLevelEdit path=":id/edit" />
    <BrandLevelEdit path="new" />
  </Router>
);
export default DealerBrandLevel;
