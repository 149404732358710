import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import {
  CrudGetAllAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { Classification, defaultClassification } from 'model/product/classification.model';


const entity = 'classification';
const apiUrl = '/api/yumei-mall-pim/product/classification';
const standardReducerImpl = new StandardReducerImpl<Classification>(entity, defaultClassification, apiUrl);
export type ClassificationState = StandardState<Classification>;
export const classification = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
export const getEntities: CrudGetAllAction<Classification> = (params?: QueryParameter) => dispatch => {
  const paramsData = {
    current: params && Number(params.page) + 1,
    ...params
  };
  const requestUrl = `/api/yumei-mall-pim/product/classification/tree/all`;
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(requestUrl, {
      params: paramsData
    })
  });
};
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchClassificationList = async (params?: QueryParameter) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-pim/product/classification/tree`, { params: { ...params, size: 500 } });
  return axiosResponse.data;
}

export const fetchStoreClassificationList = async (params?: any) => {
  const axiosResponse = await axios.get<any>(`/api/yumei-mall-pim/product/classification/tree`, {params: {sellerIds:params}});
  return axiosResponse.data;
}