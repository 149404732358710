import { FC, ReactNode } from "react";
import { Button, Form, Input, Popconfirm, Tooltip } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import PackageDetails from "./return-package-details";
import { abandon, deleteEntity, getEntities, getEntity } from "./return-package.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import PackageEdit from "./return-package-edit";
import { ModalForm, ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { ReceivingLocation, ReturnPackage, ReturnPackageOrderType } from "model/procure/return-package.model";
import { ForceUpdate } from "model/system/version.model";
import { ReturnPackagePimColumns } from "./return-package-pim-details";


export interface ReturnPackageListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

export const columns: ProColumns<ReturnPackage, 'receiveRefundExchangeType'>[] = [{
  title: '退货原始单号',
  dataIndex: 'orderSn',
  width: 120,
}, {
  title: '退货订单号',
  dataIndex: 'returnOrderSn',
  width: 120,
}, {
  title: '单号类型',
  dataIndex: 'orderSnType',
  width: 100,
  valueEnum: ReturnPackageOrderType
}, {
  title: '收退换类型',
  dataIndex: 'receiveRefundExchangeType',
  width: 100,
  valueType: 'receiveRefundExchangeType'
}, {
  title: '收货地点',
  width: 100,
  dataIndex: 'receivingLocation',
}, {
  title: '退换人姓名',
  width: 120,
  dataIndex: 'returnPackageName',
}, {
  title: '退换人电话',
  width: 120,
  dataIndex: 'phone',
}, {
  title: 'Code',
  width: 100,
  dataIndex: 'belongTstCode',
}, {
  title: '运单号',
  width: 100,
  dataIndex: 'expressNo',
}, {
  title: '虚拟包裹',
  width: 100,
  dataIndex: 'virtualPackage',
  render: (text, record) => record.virtualPackage ? '是' : '否'
}, {
  title: '关联',
  width: 80,
  dataIndex: 'association',
  render: (text, record) => record.association ? '是' : '否'
}, {
  title: '创建日期',
  width: 100,
  dataIndex: 'createdDate',
}, {
  title: '创建人',
  width: 100,
  dataIndex: 'operator',
}];

const ReturnPackageList: FC<ReturnPackageListProps> = (props) => {
  const { abandon } = props;
  const searchFields = [{
    label: '退货原始单号',
    name: 'orderSn.equals',
  }, {
    label: '退货人姓名',
    name: 'returnPackageName.equals',
  }, {
    label: 'Code',
    name: 'belongTstCode.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormDateTimePicker
          name="createdDate.greaterThanOrEqual"
          placeholder="创建开始时间"
        />
        <ProFormDateTimePicker
          name="createdDate.lessThanOrEqual"
          placeholder="创建结束时间"
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: ReturnPackage) => {
    return <ModalForm
      title="商品明细"
      width={1000}
      trigger={<Tooltip title={'商品明细'} key="sku"><Button type="link" size="small">明细</Button></Tooltip>}
      preserve={false}
      onFinish={async (data) => {
        return true;
      }}
    >
      <ProTable<any>
        className="express-table"
        columns={ReturnPackagePimColumns}
        rowKey="id"
        dataSource={record.packageProductDetails}
        pagination={false}
        options={false}
        search={false}
      />
    </ModalForm>
  }, (dom: any, record: ReturnPackage) => {
    return <Button type="link" size="small">审核</Button>
  }, (dom: any, record: ReturnPackage) => {
    return <Popconfirm title="你确定要作废当前数据吗？" key="abandon" onConfirm={() => abandon(record.id as string)}>
      <Button disabled={record?.cancel === true} type="link" size="small">作废</Button>
    </Popconfirm>
  }];

  const exportData = (resData?: any) => {
    let orderData: ReturnPackage[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((e: any) => {
      e.packageProductDetails && e.packageProductDetails.forEach((item: any) => {
        let reasonArr = item.reason ? item.reason.split(',') : ['', ''];
        let arr = {
          ...e,
          virtualPackage: e.virtualPackage ? '是' : '否',
          association: e.association ? '是' : '否',
          skuCode: item.skuCode,
          productName: item.productName,
          quantity: item.quantity,
          rejectsQuantity: item.rejectsQuantity,
          reason: reasonArr[0],
          reasonSon: reasonArr[1],
          validityType: item.validityType,
          produceDate: item.produceDate,
          dueDate: item.dueDate,
          sourceCode: item.sourceCode,
          productRemark: item.productRemark,
        }
        orderData.push(arr);
      })
    });
    return orderData;
  };

  const exportPimData = (resData?: any) => {
    let orderData: ReturnPackage[] = [];
    const exportOrderData = resData ? resData : props.entities;
    exportOrderData.forEach((e: any) => {
      e.packageProductDetails && e.packageProductDetails.forEach((item: any) => {
        let reasonArr = item.reason ? item.reason.split(',') : ['', ''];
        let arr = {
          skuCode: item.skuCode,
          productName: item.productName,
          quantity: item.quantity,
          rejectsQuantity: item.rejectsQuantity,
          reason: reasonArr[0],
          reasonSon: reasonArr[1],
          validityType: item.validityType,
          produceDate: item.produceDate,
          dueDate: item.dueDate,
          sourceCode: item.sourceCode,
          productRemark: item.productRemark,
        }
        orderData.push(arr as any);
      })
    });
    return orderData;
  };

  const advanceFilterPanel = (
    <div style={{ width: '100%' }}>
      <Input.Group compact style={{ display: 'flex', justifyContent: 'flex-end', flexWrap: 'wrap' }}>
        <ProFormSelect
          placeholder="收货地点"
          name={'receivingLocation.equals'}
          width="sm"
          options={Object.entries(ReceivingLocation).map(([key, value]) => ({ value: String(key), label: value }))}
        />
        <ProFormSelect
          placeholder="关联"
          name={'association.equals'}
          width="sm"
          options={Object.entries(ForceUpdate).map(([key, value]) => ({ value: String(key), label: value }))}
        />
        <ProFormSelect
          placeholder="虚拟包裹"
          name={'virtualPackage.equals'}
          width="sm"
          options={Object.entries(ForceUpdate).map(([key, value]) => ({ value: String(key), label: value }))}
        />
        <ProFormSelect
          placeholder="作废"
          name={'cancel.equals'}
          width="sm"
          options={Object.entries(ForceUpdate).map(([key, value]) => ({ value: String(key), label: value }))}
        />
      </Input.Group>
    </div>
  )



  return (
    <ListViewPage<ReturnPackage, 'receiveRefundExchangeType'>
      columns={columns}
      showDownload={true}
      rowActions={rowActions}
      downloadColumns={(ReturnPackagePimColumns as any).filter((e: any) => e.title !== '退回原因').concat([{
        title: '退回原因（一级）',
        dataIndex: 'reason'
      }, {
        title: '退回原因（二级）',
        dataIndex: 'reasonSon'
      }])}
      downloadEntities={exportPimData()}
      downloadAllEntities={exportPimData}
      downloadBtnName="下载订单商品明细"
      editDisabled={(record: ReturnPackage) => record.cancel || false}
      scroll={{ x: 1400 }}
      downloadAllBtnName={'下载全部订单商品明细'}
      advanceFilterPanel={advanceFilterPanel}
      quickFilterPanel={quickFilterPanel}
      downMoreOperateColumns={columns.concat(ReturnPackagePimColumns as any).filter((e: any) => e.title !== '退回原因').concat([{
        title: '退回原因（一级）',
        dataIndex: 'reason'
      }, {
        title: '退回原因（二级）',
        dataIndex: 'reasonSon'
      }])}
      downMoreOperateExportData={exportData}
      downMoreOperateDataSource={exportData()}
      detailPage={<PackageDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideDeleteButton
      editPage={(params: any) => <PackageEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ orderReturnPackage }: RootState) => ({
  entity: orderReturnPackage.entity,
  entities: orderReturnPackage.entities,
  loading: orderReturnPackage.loading,
  updateSuccess: orderReturnPackage.updateSuccess,
  createSuccess: orderReturnPackage.createSuccess,
  total: orderReturnPackage.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  abandon,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ReturnPackageList);