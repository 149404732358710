import { FC, ReactNode, useEffect, useState } from "react";
import { Form, Input, Image, Tag, message, Tooltip, Popconfirm, Cascader } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./post-details";
import { deleteEntity, getEntities, getEntity, updateHasShow, changeOnline, statusMap, getMaterialCategory } from "./post.reducer";
import { RouteComponentProps } from "@reach/router";
import { Post } from "model/video/post.model";
import { ProFormDateTimePicker, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import PostEdit from "./post-edit";
import { internationalList } from "components/IntelFormText";
import { WarningOutlined } from "@ant-design/icons";

export interface PostListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns<Post, 'categoryGroup'>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 60
  },
  {
    title: '封面',
    dataIndex: 'pictures',
    width: 100,
    hideInDescriptions: true,
    render: ((res, record) => {
      const picture = record.pictures && record.pictures[0] && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.pictures[0].pictureUrl} />;
      const video = record.videos && record.videos[0] && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.videos[0].coverUrl} />;
      return record.publicityType && record.publicityType === 'Pictures' ? picture : video
    })
  },
  // {
  //   title: '标题',
  //   dataIndex: 'title',
  //   render: (dom, record) => internationalList(record.title || '')
  // },
  {
    title: '素材分类',
    dataIndex: ['category', 'name'],
    valueType: 'text',
    width: 100,
    render: (dom, record) => internationalList(record.category.name || '')
  }, {
    title: '发布人昵称',
    dataIndex: 'pubNickName',
    valueType: 'text',
    width: 100,
    render: (dom, record) => internationalList(record.pubNickName || '')
  }, {
    title: '关联商品',
    dataIndex: 'productCount',
    width: 100,
    valueType: 'text'
  }, {
    title: '素材文案',
    dataIndex: 'content',
    valueType: 'text',
    width: 200,
    ellipsis: true,
    render: (dom, record) => {
      let data = internationalList(record.content || '')
      return <Tooltip
        title={<div dangerouslySetInnerHTML={{ __html: internationalList(record.content || '') || '' }} />}
        trigger="hover"
      >  <div dangerouslySetInnerHTML={{ __html: data?.slice(0, 20) || '' }}></div></Tooltip>
    }
  }, {
    title: '素材类型',
    dataIndex: 'publicityType',
    valueType: 'text',
    width: 100,
    render: (dom) => {
      return dom === 'Pictures' ? '图片' : '视频'
    }
  }, {
    title: '状态',
    dataIndex: 'status',
    valueType: 'text',
    width: 100,
    render: (dom) => {
      let data = statusMap.find((item: any) => item.value === dom)
      return data?.label || ''
    }
  }, {
    title: '是否定时发布',
    dataIndex: 'isScheduled',
    valueType: 'text',
    width: 120,
    render: (dom) => {
      return <Tag color={dom === true ? '#1890ff' : '#f50'}>{dom === true ? '是' : '否'}</Tag>
    }
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
    width: 200,
    valueType: 'dateTime'
  }, {
    title: '发布时间',
    dataIndex: 'pushTime',
    width: 200,
    valueType: 'text'
  }, {
    title: '排序',
    dataIndex: 'sort',
    width: 80,
    valueType: 'text'
  }];


const PostList: FC<PostListProps> = (props) => {
  let [key, setKey] = useState(0)
  const [categoryList, setCategoryList] = useState<any>([]);
  // 上下线
  const onlineButtonRender: any = (dom: ReactNode, record: any) => {
    return <Popconfirm
      title={(record.status === 'Wait' || record.status === 'Offline') ? '您是否确认上线该条素材' : '您是否确认下线该条素材'}
      onConfirm={() => {
        statusChange(record)
      }}
      icon={<WarningOutlined />}
      okText="确认"
      cancelText="关闭"
    >
      <Tag color={(record.status === 'Wait' || record.status === 'Offline') ? '#1890ff' : '#f50'} style={{ cursor: 'pointer' }}  >{(record.status === 'Wait' || record.status === 'Offline') ? '上线' : '下线'}</Tag>
    </Popconfirm>
  };

  const statusChange = (record: any) => {
    let istrue = (record.status === 'Wait' || record.status === 'Offline') ? true : false
    changeOnline(record, istrue).then((e: any) => {
      message.success('操作成功')
      setKey(++key);
    }).catch((e: any) => {
      message.error(e.response.data.title);
    })
  }

  // 重写素材分类
  const recategoryList = (children: any) => {
    if (children && children.length > 0) {
      const newChildren:any = [];
      children.forEach((item: any) => {
        newChildren.push({
          ...item,
          name: internationalList(item?.name),
          value: item.id,
          label: internationalList(item?.name),
        })
      });
      return newChildren
    }
  }

  // 获取素材分类列表
  const getMaterialCategoryList = async () => {
    getMaterialCategory().then((data) => {
      let listData = data.map((item: any) => ({
        ...item,
        value: item.id,
        label: internationalList(item?.name),
        name: internationalList(item?.name),
        children: recategoryList(item.children)
      }))
      setCategoryList(listData);
    })
  }

  useEffect(() => {
    getMaterialCategoryList();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <Form.Item name='categoryId.equals'>
          <Cascader key={'id'} options={categoryList} placeholder="请选择素材分类" fieldNames={{ label: 'name', value: 'id' }} />
        </Form.Item>
        <div className="wd10"></div>
        <ProFormSelect
          options={statusMap}
          name={['status.equals']}
          placeholder="状态"
        />
        <div className="wd10" />
        <ProFormSelect
          name="type.equals"
          options={[{
            label: '图片',
            value: 'Pictures'
          }, {
            label: '视频',
            value: 'Video'
          }]}
          placeholder="素材类型"
        />
        <div className="wd10"></div>
        <ProFormDateTimePicker
          name="pushTime.greaterThanOrEqual"
          placeholder="发布开始时间"
        />
        <ProFormDateTimePicker
          name="pushTime.lessThanOrEqual"
          placeholder="发布结束时间"
        />
        <div className="wd10"></div>
        <ProFormText
          name={['productId.equals']}
          placeholder="商品id"
        />
        <div className="wd10"></div>
        <ProFormText
          name={['pubNickName.equals']}
          placeholder="发布人"
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<Post, 'categoryGroup'>
      key={'id'}
      refreshFlag={key}
      scrollConfig={{ x: 1200 }}
      columns={columns}
      rowActions={[onlineButtonRender]}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      showResetButton={true}
      sort='sort,lastModifiedDate,desc'
      createButtonText='添加素材' 
      editPage={(params) => <PostEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ post }: RootState) => ({
  entity: post.entity,
  entities: post.entities,
  loading: post.loading,
  updateSuccess: post.updateSuccess,
  createSuccess: post.createSuccess,
  total: post.total
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateHasShow
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostList);