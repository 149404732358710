import { RouteComponentProps, Router } from "@reach/router";
import ProductList from './product-list';
import React from 'react';
import ProductDetails from './product-details';
import ProductEdit from './product-edit';

const Product: React.FC<RouteComponentProps> = () => (
  <Router>
    <ProductList path="/" />
    <ProductDetails path=":id" />
    <ProductEdit path=":id/edit" />
    <ProductEdit path="/new" />
  </Router>
);
export default Product;
