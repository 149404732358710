import { MenuDataItem } from "@ant-design/pro-layout/es/typings";
import { createFromIconfontCN } from "@ant-design/icons";

const CustomIcon = createFromIconfontCN({
  scriptUrl: "//at.alicdn.com/t/c/font_2978551_u97e99m2q0m.js", // 在 iconfont.cn 上生成
});
const menu: MenuDataItem[] = [
  {
    path: "/decorate",
    name: "页面装修",
    hideInMenu: false,
    icon: <CustomIcon type="icon-huiyuanzhongxin" />,
    children: [
      {
        path: "platform",
        name: "平台页面",
        hideChildrenInMenu: true,
        children: [
          {
            path: ":id?/edit",
            name: "编辑",
          },
          {
            path: "new",
            name: "新增",
          },
        ],
      }
    ],
  },
  // {
  //   path: "/examMng",
  //   name: "考试管理",
  //   hideInMenu: false,
  //   icon: <CustomIcon type="icon-huiyuanzhongxin" />,
  //   children: [
  //     {
  //       path: "topic",
  //       name: "专题列表",
  //       authority: "R_miro_page",
  //       hideChildrenInMenu: true,
  //       children: [
  //         {
  //           path: ":id?/edit",
  //           name: "编辑",
  //         },
  //         {
  //           path: "new",
  //           name: "新增",
  //         },
  //       ],
  //     },
  //     {
  //       path: "course",
  //       name: "课程列表",
  //       authority: "R_miro_page",
  //       hideChildrenInMenu: true,
  //       children: [
  //         {
  //           path: ":id?/edit",
  //           name: "编辑",
  //         },
  //         {
  //           path: "new",
  //           name: "新增",
  //         },
  //       ],
  //     },

  //     {
  //       path: "examDecorate",
  //       name: "考试页面",
  //       authority: "R_miro_page",
  //       hideChildrenInMenu: true,
  //       children: [
  //         {
  //           path: ":id?/edit",
  //           name: "编辑",
  //         },
  //         {
  //           path: "new",
  //           name: "新增",
  //         },
  //       ],
  //     },

  //     {
  //       path: "certificate",
  //       name: "证书管理",
  //       authority: "R_miro_page",
  //       hideChildrenInMenu: true,
  //       children: [
  //         {
  //           path: ":id?/edit",
  //           name: "编辑",
  //         },
  //         {
  //           path: "new",
  //           name: "新增",
  //         },
  //       ],
  //     },


  //     {
  //       path: "material",
  //       name: "素材中心",
  //       authority: "R_miro_page",
  //       hideChildrenInMenu: true,
  //       children: [
  //         {
  //           path: ":id?/edit",
  //           name: "编辑",
  //         },
  //         {
  //           path: "new",
  //           name: "新增",
  //         },
  //       ],
  //     },
  //   ],
  // },
  {
    path: "/customerCore",
    name: "客户中心",
    hideInMenu: false,
    icon: <CustomIcon type="icon-huiyuanzhongxin" />,
    children: [
      {
        path: "customer",
        name: "客户列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "level",
        name: "会员级别",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "equity",
        name: "会员权益",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "wechatbind",
        name: "微信绑定",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "wallet",
        name: "钱包列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "tag",
        name: "客户标签",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "tagTask",
        name: "标签任务",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "group",
        name: "客户标签组",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/dealer",
    name: "经销商管理",
    hideInMenu: false,
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "customer",
        name: "经销商列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "direct-ascent",
        name: "直升权益",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "relationshipTree",
        name: "经销商关系树",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      // {
      //   path: "relationTree",
      //   name: "经销商关系管理",
      //   hideChildrenInMenu: true,
      //   children: [
      //     {
      //       path: "edit",
      //       name: "编辑",
      //       hideInMenu: true,
      //     },
      //   ],
      // },
      {
        path: "unit",
        name: "经销商审核",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "level",
        name: "经销商等级",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "brand-level",
        name: "经销商品牌等级",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "info",
        name: "账户资料",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "evaluation",
        name: "拜访评价",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "second-review",
        name: "美二代审核",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "second-settled",
        name: "美二代入驻",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "assort",
        name: "文章分类",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "article",
        name: "经销商文章",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "complaint",
        name: "投诉列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/pim",
    name: "商品管理",
    hideInMenu: false,
    icon: <CustomIcon type="icon-shangpinguanli" />,
    children: [
      {
        path: "product",
        name: "商品列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "spec",
        name: "规格列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "stock",
        name: "商品库存",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "pricingRules",
        name: "定价规则",
        hideChildrenInMenu: true,
        hideInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "wholesale-product",
        name: "批发商商品",
        hideChildrenInMenu: true,
        hideInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "brand",
        name: "商品品牌",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "category",
        name: "商品类目",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      // {
      //   path: 'unit',
      //   name: '品牌授权',
      //   hideChildrenInMenu: true,
      //   children: [{
      //     path: 'edit',
      //     name: '编辑',
      //     hideInMenu: true,
      //   }]
      // },
      // {
      //   path: 'unit',
      //   name: '商品单位',
      //   hideChildrenInMenu: true,
      //   children: [{
      //     path: 'edit',
      //     name: '编辑',
      //     hideInMenu: true,
      //   }]
      // },
      {
        path: "delivery",
        name: "运费模板",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      // {
      //   path: 'achievement',
      //   name: '商品业绩模版',
      //   hideChildrenInMenu: true,
      //   children: [{
      //     path: 'edit',
      //     name: '编辑',
      //     hideInMenu: true,
      //   }]
      // },
      //  {
      //   path: 'classification',
      //   name: '平台商品分类',
      //   hideChildrenInMenu: true,
      //   children: [{
      //     path: 'edit',
      //     name: '编辑',
      //     hideInMenu: true,
      //   }]
      // },
      {
        path: "online-classification",
        name: "商品分类",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "region",
        name: "销售/禁售区域",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: '/paymng',
    name: '支付管理',
    hideInMenu: false,
    icon: <CustomIcon type="icon-shangpinguanli" />,
    children: [{
      path: 'payorder',
      name: '支付订单',
      hideChildrenInMenu: true,
      children: [{
        path: 'edit',
        name: '编辑',
        hideInMenu: true,
      }]
    }]
  },
  {
    path: '/market',
    name: '营销管理',
    hideInMenu: false,
    icon: <CustomIcon type="icon-shangpinguanli" />,
    children: [{
      path: 'advertisement',
      name: '广告位',
      hideChildrenInMenu: true,
      children: [{
        path: 'edit',
        name: '编辑',
        hideInMenu: true,
      }]
    }, {
      path: 'coupon',
      name: '优惠券',
      hideChildrenInMenu: true,
      children: [{
        path: 'edit',
        name: '编辑',
        hideInMenu: true,
      }]
    }]
  },{
    path: '/promotion',
    name: '促销中心',
    hideInMenu: false,
    icon: <CustomIcon type="icon-shangpinguanli" />,
    children: [{
      path: 'activity',
      name: '促销活动',
      hideChildrenInMenu: true,
      children: [{
        path: 'edit',
        name: '编辑',
        hideInMenu: true,
      }]
    }]
  },
  {
    path: '/video',
    name: '代理素材',
    hideInMenu: false,
    icon: <CustomIcon type="icon-shipin" />,
    children: [{
      path: 'post',
      name: '素材列表',
      hideChildrenInMenu: true
    },
    // {
    //   path: 'topic',
    //   name: '短视频/图文-话题',
    //   hideChildrenInMenu: true
    // }, 
    {
      path: 'category',
      name: '分类列表',
      hideChildrenInMenu: true
    }, {
      path: 'publisher',
      name: '发布用户',
      hideChildrenInMenu: true
    }],
  },

  {
    path: "/bonus",
    name: "奖金管理",
    // hideInMenu: true,
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "list",
        name: "提现审核列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "reward",
        name: "会员奖励详情",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "summary",
        name: "奖励汇总",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "withdrawal",
        name: "公司账号提现",
        hideChildrenInMenu: true,
        // hideInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: 'assessmentDetails',
        name: '区域考核明细',
        hideChildrenInMenu: true,
        authority: 'R_assessmentDetails',
        children: [{
          path: 'edit',
          name: '编辑',
          hideInMenu: true,
        }]
      }
    ],
  },
  {
    path: "/contract",
    name: "电子合同",
    // hideInMenu: true,
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "list",
        name: "合同模板管理",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "sign",
        name: "合同签署管理",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "auth-flow",
        name: "合同认证授权",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "seal-auth",
        name: "合同印章授权",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/train",
    name: "培训会",
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "train-list",
        name: "培训会列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "salonApplication",
        name: "沙龙申请",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "salonReimbursmt",
        name: "申请报销",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      }, {
        path: "videoCheckIn",
        name: "视频号打卡",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/scanCodeOrder",
    name: "扫码订单",
    // hideInMenu: true,
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "sendOutGoods",
        name: "扫码调货",
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "local",
        name: "6S扫码调货",
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "retail",
        name: "扫码零售",
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "exchange",
        name: "扫码换货",
        // hideInMenu: true,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: 'exchangeGoods',
        name: '换货商品',
        hideChildrenInMenu: true,
        // hideInMenu:true,
        children: [{
          path: 'edit',
          name: '编辑',
          hideInMenu: true,
        }]
      },
      {
        path: 'returnGoods',
        name: '退回商品',
        hideChildrenInMenu: true,
        // hideInMenu:true,
        children: [{
          path: 'edit',
          name: '编辑',
          hideInMenu: true,
        }]
      },
    ],
  },
  {
    path: "/procure",
    name: "采购管理",
    hideInMenu: false,
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "sale-order",
        name: "销售订单",
        hideInMenu: false,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "purchase-order",
        name: "采购订单",
        hideInMenu: false,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "wholesale-order",
        name: "批发订单",
        hideInMenu: false,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "order-cancel",
        name: "订单取消原因",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "order-after-sales",
        name: "售后退款原因",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "afterSales",
        name: "售后订单",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "refund",
        name: "退款申请单",
        hideInMenu:false,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/financial",
    name: "财务管理",
    icon: <CustomIcon type="icon-dingdan" />,
    children: [
      {
        path: "flowTemplate",
        hideChildrenInMenu: true,
        name: "银行流水模板",
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "bankStatement",
        hideChildrenInMenu: true,
        name: "银行流水",
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "fund",
        name: "资金列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/store",
    name: "店铺管理",
    hideInMenu: false,
    icon: <CustomIcon type="icon-dianpu" />,
    children: [
      {
        path: "seller",
        name: "商家列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "address",
        name: "退货地址管理",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "marginRules",
        name: "保证金规则",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/dealerStore",
    name: "门店管理",
    icon: <CustomIcon type="icon-huiyuanzhongxin" />,
    children: [
      {
        path: "physicalStores",
        name: "实体店",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "excellentStore",
        name: "优秀实体店",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "lifePavilion",
        name: "生活馆",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "trainingList",
        name: "申请培训列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/contract-admin",
    name: "合同管理",
    hideInMenu: true,
    icon: <CustomIcon type="icon-huiyuanzhongxin" />,
    children: [
      {
        path: "contract-template",
        name: "合同模板",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/user",
    name: "用户管理",
    icon: <CustomIcon type="icon-huiyuanzhongxin" />,
    children: [
      {
        path: "permission",
        name: "权限",
        hideInMenu:true,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "role",
        name: "角色",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "user",
        name: "用户列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "client",
        name: "客户端管理",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "module",
        name: "模块",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "resources",
        name: "资源",
        hideInMenu:true,
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
  {
    path: "/system",
    name: "系统管理",
    icon: <CustomIcon type="icon-setting" />,
    children: [
      {
        path: "menu",
        name: "菜单列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "tenant",
        name: "租户列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "expressCompany",
        name: "快递公司",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "audit",
        name: "审计日志",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "message",
        name: "异常消息",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "param-group",
        name: "参数配置",
        hideChildrenInMenu: true,
      },
      {
        path: "payee-enterprise",
        name: "收款主体",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "route",
        name: "AI搜索路由",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "pay",
        name: "支付方式",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "rate",
        name: "汇率列表",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
      {
        path: "app-version",
        name: "App版本管理",
        hideChildrenInMenu: true,
        children: [
          {
            path: "edit",
            name: "编辑",
            hideInMenu: true,
          },
        ],
      },
    ],
  },
];

export default menu;
