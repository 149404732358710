import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ParamGroup from "./param-group";

const AppParam: React.FC<RouteComponentProps> = () => (
  <Router>
    <ParamGroup path='/' />
  </Router>
);
export default AppParam;
