import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import VideoCheckInList from './videoCheckIn-list';
import './index.less'

const videoCheckIn: React.FC<RouteComponentProps> = () => (
  <Router>
    <VideoCheckInList path="/" />
  </Router>
);
export default videoCheckIn;
