import { FC, memo, useState, } from 'react';
import { Button, Checkbox, Form, Input, message, Select } from 'antd';
import '../style/single_choice_question.less';
import { DeleteOutlined, PlusOutlined } from '@ant-design/icons';
import TextArea from 'antd/lib/input/TextArea';
export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const TitleText: FC<Props> = ({ dataField }) => {
    const [sequence] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'])
   
    return (
        <div className={`sequence-container ${dataField.pageStyle}`} >
            <p className="title">{dataField.title || '请输入标题'}({dataField.questionScore || '请选择分值'}分)</p>
            <Checkbox.Group  className='radio-group'>
            {dataField.testquestions && dataField.testquestions.map((item: any, index: any) => {
                // return <div className="xx-options"> <span>{sequence[index]}、</span>  {item || '请输入选项'}</div>
               return <Checkbox value={item} key={index}><div className="xx-options"> <span>{sequence[index]}、</span>  {item || '请输入选项'}</div></Checkbox>
            })}
            </Checkbox.Group>
             <p className="review-title">正确答案: {dataField.answer || '请选择正确答案'}</p>
             <p className="review-title">答案解析: {dataField.analysis || '请输入答案解析'}</p>
        </div>
    )
}

export const MultipleChoiceQuestions: FC<SetProps> = memo(({ dataField, id, methods }) => {
    const [sequence] = useState(['A', 'B', 'C', 'D', 'E', 'F', 'G', 'H', 'I', 'J'])
    return (
        <div className="title-text-set" >
            <Form.Item label="标题" >
                <Input.Group compact>
                    <Input onChange={
                        (e) => {
                            let value = e.target.value
                            methods.updateModuleData({
                                ...dataField,
                                title: value
                            }, id)
                        }
                    } value={dataField.title} placeholder="请输入标题" />

                </Input.Group>
            </Form.Item>
            <Form.Item label="标题分值" >
                <Input.Group compact>
                    <Input onChange={
                        (e) => {
                            let value = e.target.value
                            methods.updateModuleData({
                                ...dataField,
                                questionScore: value
                            }, id)
                        }
                    } value={dataField.questionScore} placeholder="请输入标题分值" />

                </Input.Group>
            </Form.Item>
            <div className='option-wrapper'>
                <div className='option-title'>选项</div>
                <Button type="primary" size='small' shape="circle" icon={<PlusOutlined />} onClick={() => {
                    if (dataField.testquestions.length === 10) {
                        message.error('选项不能大于10个');
                        return false
                    }
                    let dataList = [...dataField.testquestions]
                    dataList.push('')
                    methods.updateModuleData({
                        ...dataField,
                        testquestions: dataList
                    }, id)
                }} />
            </div>
            {
                dataField.testquestions.map((item: any, index: any) => {
                    return (
                        <div className='options-row'>
                            <div className='sequence-name'>{sequence[index]}</div>
                            <Input onChange={
                                (e) => {
                                    let value = e.target.value
                                    let date = dataField.testquestions
                                    date[index] = value
                                    methods.updateModuleData({
                                        ...dataField,
                                        testquestions: date
                                    }, id)
                                }
                            } value={item} placeholder="请输入选项" />
                            <DeleteOutlined onClick={() => {
                                let dataList = [...dataField.testquestions]
                                dataList.splice(index, 1)
                                methods.updateModuleData({
                                    ...dataField,
                                    testquestions: dataList
                                }, id)
                            }} style={{ fontSize: '16px', color: '#f40', cursor: 'pointer', marginLeft: '10px' }} />

                        </div>
                    )
                })
            }
            <div className='right-key'>
                <div className='answer-title'>正确答案</div>
                <Select placeholder='请选择正确答案' mode="multiple" value={dataField.answer} onChange={(e: any) => {
                    console.log(e)
                    let value = e
                    methods.updateModuleData({
                        ...dataField,
                        answer: value
                    }, id)
                }}>
                    {
                        sequence?.map((item, index) => {
                            return <Select.Option value={item} key={index}>{item}</Select.Option>
                        })
                    }
                </Select>
            </div>

            <div className='right-key'>
                <div className='answer-title'>答案解析</div>
                <TextArea onChange={
                    (e) => {
                        let value = e.target.value
                        methods.updateModuleData({
                            ...dataField,
                            analysis: value
                        }, id)
                    }
                } value={dataField.analysis} placeholder="请输入答案解析" /></div>
        </div>
    )
})

export default TitleText

