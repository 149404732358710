import { FC, ReactNode } from "react";
import { Button, Col, Form, Row, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./category-details";
import { deleteEntity, getEntities, getEntity, updateEntity, createEntity } from "./category.reducer";
import { RouteComponentProps } from "@reach/router";
import { VideoCategory } from "model/video/video-category";
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import Authorized from "common/auth/authorized";
import { PlusOutlined } from "@ant-design/icons";
import CategoryEdit from "./category-edit";
import IntelFormText, { internationalList } from "components/IntelFormText";

export interface VideoCategoryListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

const VideoCategoryList: FC<VideoCategoryListProps> = (props) => {
  const [form] = Form.useForm();
  const columns: ProColumns<VideoCategory, 'categoryGroup'>[] = [{
    title: '分类名称',
    dataIndex: 'name',
    render: (dom, record) => internationalList(record.name || '')
  }, {
    title: '排序',
    dataIndex: 'sort',
  }];

  return (<>
    <ListViewPage<VideoCategory, 'categoryGroup'>
      columns={columns}
      togetherParams={{ 'level.equals': 1 }}
      rowKey='id'
      hideDeleteButton
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      rowActions={[
        (dom: ReactNode, record: VideoCategory) => record.children && <Authorized authority='addSubclass'><ModalForm
          title={internationalList(record.name || '')}
          trigger={<Tooltip title={'新增子类'} key="AuditReject"><Button type="link" icon={<PlusOutlined />} /></Tooltip>}
          modalProps={{
            destroyOnClose: true,
          }}
          form={form}
          preserve={false}
          onFinish={async (data: any) => {
            if (data.id) {
              props.updateEntity({ ...data, level: 2, parentCategory: { id: String(record.id) } });
            } else {
              props.createEntity({ ...data, level: 2, parentCategory: { id: String(record.id) } });
            }
            return true;
          }}
        >
          <Row>
            <Col span={11}>
              <IntelFormText
                label="子类名称"
                name="name"
                form={form}
                required={true}
                fieldProps={{
                  maxLength: 100
                }}
                value={props.entity.name || ''}
              />
            </Col>
            <Col span={1} />
            <Col span={11}>
              <ProFormText
                name="sort"
                label="排序"
              />
            </Col>
          </Row>
        </ModalForm></Authorized>,
      ]}
      editPage={(params: any) => <CategoryEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  </>);
};

const mapStateToProps = ({ videoCategory }: RootState) => ({
  entity: videoCategory.entity,
  entities: videoCategory.entities,
  loading: videoCategory.loading,
  updateSuccess: videoCategory.updateSuccess,
  total: videoCategory.total,
  createSuccess: videoCategory.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateEntity,
  createEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VideoCategoryList);