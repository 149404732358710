import axios from 'axios';
import { defaultWallet, Wallet } from 'model/customerCore/wallet.model';
import { StandardReducerImpl, StandardState ,STANDARD_ACTION_TYPES} from 'reducers/standard.reducer';
import { CrudPutAction } from 'reducers/redux-action.type';
const entity = 'wallet';
const apiUrl = '/api/yumei-mall-customer/wallet';
const standardReducerImpl = new StandardReducerImpl<Wallet>(entity, defaultWallet, apiUrl);

export type WalletState = StandardState<Wallet>;
export const wallet = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const distributeWallet = async (data: any) => {
  const requestUrl = `/api/yumei-mall-customer/wallet/fluctuate`;
  return await axios.post<any>(requestUrl, data);
}

export const fetchWalletDetailed = async (params: any) => {
  const requestUrl = `/api/yumei-mall-customer/wallet/transaction`;
  return await axios.get<any>(requestUrl, {params});
}


// 查询钱包类型
export const walletAccountTypeList = async (params?: any) : Promise<any[]> => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-customer/meta-data/enums`, {
      params: params
  });
  return axiosResponse.data || [];
}

// 调整
export const adjustApi: CrudPutAction<any> = entity => async dispatch => {
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(apiUrl, entity),
  });
};
