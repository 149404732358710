import { BaseModel } from '../base.model';

export interface Address extends BaseModel {
    sellerId?: string; //店铺id
    consignee?: string; //收件人
    country?: string; //国家
    province?: string; //省份
    city?: string; //城市
    district?: string; //县区
    mergeName?: string; //省市区名称
    address?: string; //详细地址
    zipcode?: string; //邮政编码
    mobile?: string; //手机
    isDefault?: number; //是否默认  0否 1是
}

export const defaultAddress: Readonly<Address> = {};