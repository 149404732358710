import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from "axios";
import { Brand, defaultBrand } from 'model/product/brand.model';
import { internationalList } from 'components/IntelFormText';

const entity = 'brand';
const apiUrl = '/api/yumei-mall-pim/brand';
const standardReducerImpl = new StandardReducerImpl<Brand>(entity, defaultBrand, apiUrl);
export type BrandState = StandardState<Brand>;
export const brand = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchBrandList = async (params?: any): Promise<Brand[]> => {
  const paramsData = {
    size: 10000,
    ...params
  };
  const axiosResponse = await axios.get(apiUrl, { params: { ...paramsData } });
  const data = axiosResponse.data.map((re: any) => ({
    ...re,
    brandName: internationalList(re?.brandName || '')
  }));

  return data || [];
}

export const fetchStoreBrandList = async (params?: any): Promise<Brand[]> => {
  const paramsData = {
    size: 20,
    ...params
  };
  const axiosResponse = await axios.get(`${apiUrl}/store/${params.storeId}`, { params: { ...paramsData } });
  const data = axiosResponse.data.map((re: any) => ({
    ...re,
    brandName: internationalList(re?.brandName || '')
  }));
  return data || [];
}

export const increaseStoreBrand = async (params: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/store`;
  return await axios.post(requestUrl, { ...params })
}

export const fetchTestData = async (data: any) => {
  const requestUrl = `/testApiList`;
  return await axios.post(requestUrl, data)
}

export const editTestData = async (data: any) => {
  const requestUrl = `/testApiSave`;
  return await axios.post(requestUrl, data)
}

export const delTestData = async (data: any) => {
  const requestUrl = `/testApiDel`;
  return await axios.post(requestUrl, data)
}

// 获取所有品牌
export const getBrandAll = async () => {
  const requestUrl = `/api/yumei-mall-pim/brand/all`;
  let axiosResponse = await axios.get(requestUrl)
  const data = axiosResponse.data.map((re: any) => ({
    ...re,
    brandName: internationalList(re?.brandName || ''),
    brandId: re.id,
    label: internationalList(re?.brandName || ''),
    value: re.id
  }));
  return data || [];
}

// 获取所有品牌
export const fetchSearchBrandList = async (params: any) => {
  console.log(params)
  const requestUrl = `/api/yumei-mall-pim/brand`;
  let axiosResponse = await axios.get(requestUrl, { params});
  return axiosResponse.data || [];
}