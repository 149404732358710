import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import ExpressList from './express-list';
import ExpressDetails from "./express-details";
import ExpressEdit from "./express-edit";

const Express: React.FC<RouteComponentProps> = () => (
    <Router>
        <ExpressList path = "/"/>
        <ExpressDetails path = ":id"/>
        <ExpressEdit path = ":id/edit"/>
        <ExpressEdit path = "new"/>
    </Router>
);
export default Express;
