import { FC, ReactNode, useState } from "react";
import { Form, Input, Space, Image, Tag, Popconfirm, Button, message, Tooltip } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ExchangeGoodsDetails from "./exchangeGoods-details";
import { deleteEntity, getEntities, getEntity, modifyExchangeGoods } from "./exchangeGoods.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ExchangeGoodsEdit from "./exchangeGoods-edit";
import {ExchangeGoods } from "model/product/exchangeGoods.model";
import { internationalList } from "components/IntelFormText";
import { useEnumValue } from "common/enum/use-enum-value";
import defaultPim from '../../../assets/defaultPim.png';
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { DollarOutlined, WarningOutlined } from "@ant-design/icons";
import { searchProductList } from "../../pim/product/product.reducer";
import ProductSelectionForm from "../../pim/product/product-selection-form";
import PriceQueryDialog from "../../pim/product/priceQueryDialog";

export interface ExchangeGoodsListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}



const ExchangeGoodsList: FC<ExchangeGoodsListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const [refreshFlag, setRefreshFlag] = useState(0);

  const columns: ProColumns<ExchangeGoods, 'productStatus'>[] = [{
    title: '商品信息',
    dataIndex: 'mainPicture',
    valueType: 'image',
    width: 300,
    hideInDescriptions: true,
    render: ((dom: any, record: any) => {
      return <Space className="product-list-picture product-list-picture-pim">
        <Image src={internationalList(record.mainPicture || '') || defaultPim} />
        <ul>
          <li><span className="product-name">{internationalList(record.name || '')}</span></li>
          {record?.tags && record?.tags.length > 0 && <li>{record.tags.map((e: string) => {
            const arr = convertEnumToOptionType('productTag').filter((t: any) => e === t.value);
            return arr.length > 0 ? <Tag key={arr[0].label} style={{ marginBottom: '4px' }} color="green">{arr[0].label}</Tag> : '';
          })}</li>}
          <li>SPU编码：<span>{record.code}</span></li>
        </ul>
      </Space>
    }
    )
  }, {
    title: 'SPU编码',
    hideInDescriptions: true,
    dataIndex: 'code',
    width: 130
  }, {
    title: 'Sku编码',
    hideInDescriptions: true,
    width: 100,
    render: ((dom: any, record: any) =>
      <Tooltip placement="top" title={
        <ul className="product-id-ul-Tooltip">
          {record?.items && record.items.map((e: any) => {
            return <li><span>{e?.code}</span></li>
          })}
        </ul>
      } key="SkuCode">
        <ul className="product-id-ul">
          {record?.items && record.items.map((e: any, index: number) => {
            return index < 3 && <li><span>{e?.code}</span></li>
          })}
        </ul>
      </Tooltip>
    )
  }, {
    title: '商家',
    hideInDescriptions: true,
    dataIndex: 'sellerName',
    valueType: 'text',
    width: 100,
  }, {
    title: '商品品牌',
    hideInDescriptions: true,
    dataIndex: ['brand', 'brandName',],
    width: 100,
    render: (dom, record: any) => <>{internationalList(record.brand?.brandName || '')}</>
  }, {
    title: '销售状态',
    dataIndex: 'status',
    width: 120,
    valueType: 'productStatus',
  }];

  // 查询价格明细所需的商品id
  const [qPriceproductId, setQPriceproductId]: any = useState({});
  const [open, setOpen] = useState(false);
  const [key, setKey] = useState(0);
  // 打开查询价格
  const showModal = async (id: number) => {
    setQPriceproductId(id)
    setKey(new Date().getTime())
    setOpen(true);
  };
  // 关闭查询价格
  const hideModal = () => {
    setOpen(false);
  };

  const searchFields = [{
    label: '商品名称',
    name: 'name.equals',
  },{
    label: 'SPU编码',
    name: 'code.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />

        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: any) => {
    return <Popconfirm
      title="你确定要将此商品移出换货列表吗？"
      icon={<WarningOutlined />}
      onConfirm={() => {
        modifyExchangeGoods(false, [Number(record.id)]).then((e: any) => {
          message.success('操作成功');
          setRefreshFlag(new Date().getTime());
        }).catch((error: any) => {
          message.error(error?.response?.data?.detail);
        })
      }}
    >
      <Button type="link" danger>移出</Button>
    </Popconfirm>
  }, (dom: ReactNode, record: any) => {
    return <Tooltip title="查询价格">
      <Button
        type="link" size="small"
        onClick={() => {
          showModal(record.id)
        }}
        icon={<DollarOutlined />}
      ></Button>
    </Tooltip>
  }];

  const additionActions = [
    <ProductSelectionForm
      trigger="新增换货商品"
      multiple={true}
      requestParams={{}}
      fetchFunc={searchProductList}
      onFinish={(productIds) => {
        return new Promise<boolean>((resolve, reject) => {
          modifyExchangeGoods(true, productIds.map(e => Number(e))).then((e: any) => {
            message.success('操作成功');
            resolve(true);
            setRefreshFlag(new Date().getTime());
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    />
  ];

  return (
    <ListViewPage<ExchangeGoods, 'productStatus'>
      columns={columns}
      hideDeleteButton
      actions={additionActions}
      quickFilterPanel={quickFilterPanel}
      rowActions={rowActions}
      detailPage={<ExchangeGoodsDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      sort="createdDate,desc"
      refreshFlag={refreshFlag}
      hideEditButton
      permanentParams={{ tag: 'ExchangeableProduct' }}
      hideCreateButton
      editPage={(params: any) => <ExchangeGoodsEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
      <PriceQueryDialog productId={qPriceproductId} key={key} visible={open} onCancel={() => {
        hideModal();
      }} />
    </ListViewPage>
  );
};

const mapStateToProps = ({ exchangeGoods }: RootState) => ({
  entity: exchangeGoods.entity,
  entities: exchangeGoods.entities,
  loading: exchangeGoods.loading,
  updateSuccess: exchangeGoods.updateSuccess,
  createSuccess: exchangeGoods.createSuccess,
  total: exchangeGoods.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeGoodsList);