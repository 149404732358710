import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { CrudGetAllAction, QueryParameter } from "reducers/redux-action.type";
import axios from "axios";
import { defaultOnlineClassification, OnlineClassification } from 'model/product/online-classification.model';

const entity = 'onlineClassification';
const apiUrl = '/api/yumei-mall-pim/product/classification';
const standardReducerImpl = new StandardReducerImpl<OnlineClassification>(entity, defaultOnlineClassification, apiUrl);
export type OnlineClassificationState = StandardState<OnlineClassification>;
export const onlineClassification = standardReducerImpl.buildStandardReducer();
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntityLevelChange;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const getEntities: CrudGetAllAction<any> = (params?: QueryParameter) => dispatch => {
  const paramsData = {
    current: params && Number(params.page) + 1,
    ...params
  };
  const requestUrl = `/api/yumei-mall-pim/product/classification/tree`;
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(requestUrl, {
      params: paramsData
    })
  });
};

//网店商品分类
export const onlineClassifyIdData = async (params?: QueryParameter) => {
  const axiosResponse = await axios.get<any>(apiUrl, { params: { ...params, size: 100 } });
  return axiosResponse.data;
}