import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import OnlineDetails from './online-details';
import OnlineEdit from './online-edit';
import OnlineList from './online-list';

const Online: React.FC<RouteComponentProps> = () => (
  <Router>
    <OnlineList path="/" />
    <OnlineDetails path=":id" />
    <OnlineEdit path=":id/edit" />
    <OnlineEdit path="new" />
  </Router>
);
export default Online;
