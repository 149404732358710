import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RefundDetails from './refund-details';
import RefundEdit from './refund-edit';
import RefundList from './refund-list';

const Refund: React.FC<RouteComponentProps> = () => (
  <Router>
    <RefundList path="/" />
    <RefundDetails path=":id" />
    <RefundEdit path=":id/edit" />
    <RefundEdit path="new" />
  </Router>
);
export default Refund;
