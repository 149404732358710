import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SealAuthDetails from './sealAuth-details';
import SealAuthEdit from './sealAuth-edit';
import SealAuthList from './sealAuth-list';

const SealAuth: React.FC<RouteComponentProps> = () => (
  <Router>
    <SealAuthList path="/" />
    <SealAuthDetails path=":id" />
    <SealAuthEdit path=":id/edit" />
    <SealAuthEdit path="new" />
  </Router>
);
export default SealAuth;
