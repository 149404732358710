
import axios from 'axios';
import { defaultTag, Tag } from 'model/customerCore/tag.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'tag';
const apiUrl = '/api/yumei-mall-customer/customer/tag';
const standardReducerImpl = new StandardReducerImpl<Tag>(entity, defaultTag, apiUrl);

export type TagState = StandardState<Tag>;
export const tag = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
// export const createEntity: CrudPostAction<Tag> = entity =>  async dispatch => {
//   const requestUrl = `${apiUrl}/batch`;
//   return await dispatch({
//       type: `${entity}/${STANDARD_ACTION_TYPES.CREATE}`,
//       payload: axios.post(requestUrl, {...entity}),
//   });
// };
export const createEntitys = async (entity:any)  => {
  const requestUrl = `${apiUrl}/batch`;
  return await axios.post<any>(requestUrl, {...entity});
};
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;