import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./complaint.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Complaint } from "model/dealer/complaint.model";
export interface ComplaintEditProps extends EditStateAndDispatchProps<Complaint>, StateProps, DispatchProps, RouteComponentProps {
}

const ComplaintEdit: FC<ComplaintEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<Complaint>
      title="编辑"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ complaint }: RootState) => ({
  entity: complaint.entity,
  updateSuccess: complaint.updateSuccess,
  updating: complaint.updating,
  createSuccess: complaint.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ComplaintEdit);
