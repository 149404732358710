import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Form} from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./directAscent.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { DirectAscent } from "model/dealer/directAscent.model";
export interface DirectAscentEditProps extends EditStateAndDispatchProps<DirectAscent>, StateProps, DispatchProps, RouteComponentProps {
}

const DirectAscentEdit: FC<DirectAscentEditProps> = props => {
  const [form] = Form.useForm();
  return (
    <EditPage<DirectAscent>
      title="编辑"
      form={form}
      {...props}
    >
    </EditPage>);
};

const mapStateToProps = ({ directAscent }: RootState) => ({
  entity: directAscent.entity,
  updateSuccess: directAscent.updateSuccess,
  updating: directAscent.updating,
  createSuccess: directAscent.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DirectAscentEdit);
