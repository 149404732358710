import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PayDetails from './pay-details';
import PayEdit from './pay-edit';
import PayList from './pay-list';

const Pay: React.FC<RouteComponentProps> = () => (
  <Router>
    <PayList path="/" />
    <PayDetails path=":id" />
    <PayEdit path=":id/edit" />
    <PayEdit path="new" />
  </Router>
);
export default Pay;
