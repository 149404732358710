import { useState } from "react";
import { Alert, Button, message, Steps, Tooltip, Upload } from 'antd';
import { ModalProps } from 'antd/es/modal';
import { ModalForm } from '@ant-design/pro-form';
import { InboxOutlined, UploadOutlined, VerticalAlignBottomOutlined } from '@ant-design/icons/lib';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { CrudUploadAction } from 'reducers/redux-action.type';
import { isDate, isNumber } from "lodash";
import {
  getFormatExcelDate,
  getFormatExcelDateTime,
  getFormatReactTableTime,
  isDateFormat,
  isDateTimeFormat,
  isTimeFormat
} from "utils/date-utils";
import * as XLSX from 'xlsx';
import { nanoid } from 'nanoid'
import { BaseModel } from '../model/base.model';
import './import-data-dialog.less'
import { CloseCircleOutlined } from "@ant-design/icons";
import { Excel } from "antd-table-saveas-excel";
import { IExcelColumn } from "antd-table-saveas-excel/app";

const { Step } = Steps;
const { Dragger } = Upload;

export interface ImportDataDialogProps<T extends BaseModel, ValueType = "text"> extends ModalProps {
  columns?: ProColumns<T, ValueType>[];
  entityName?: string;
  errorMessage?: string;
  fieldListErrors?: any[];
  uploadEntity?: CrudUploadAction<T> | any;
  uploadSuccess?: boolean;
  title?: string;
  uploadParams?: any;
  width?: string;
  ButtonStyle?: any;
  buttonType?: "text" | "link" | "ghost" | "default" | "primary" | "dashed";
  remarks?: any;
  downLoadUrl?: string;
}

const ImportDataDialog = <T extends BaseModel, ValueType = 'text'>(props: ImportDataDialogProps<T, ValueType>) => {
  //props-columns
  const {
    columns = [],
    // entityName = '',
    uploadEntity,
    // uploadSuccess,
    fieldListErrors,
    uploadParams,
    width,
    buttonType = 'text',
    ButtonStyle,
    remarks,
    downLoadUrl
  } = props;
  //Table-data
  const [tableData, setTableData] = useState<T[]>([]);
  //Steps-current
  const [stepsNum, setStepsNum] = useState(0);
  //Alert-description
  const [errorMessage, setErrorMessage] = useState('');
  //Alert-noItem
  const [noItem, setNoItem] = useState('');
  //Button-submit-disabled
  const [submitDisabled, setSubmitDisabled] = useState(true);
  //Button-submit-loading
  const [submitLoading, setSubmitLoading] = useState(false);
  //ModalForm-ModalForm
  const [visible, setVisible] = useState(false);
  // const [filedErrorMessage, setFiledErrorMessage] = useState('');
  // if(fieldListErrors!=null&&fieldListErrors!=undefined){
  //   setFiledErrorMessage('测试数据')
  // }

  // useEffect(() => {
  //   uploadSuccess && message.success('导入成功') && setVisible(false)
  // }, [uploadSuccess]);

  //Dragger-props
  const propsDragger = {
    name: 'file',
    accept: ".xlsx,.xls,.txt",
    multiple: true,
    onChange: onUploadFilesChange,
    showUploadList: false,
  };

  function clearData() {
    setTableData([]);
    setStepsNum(0);
    setErrorMessage('');
    setSubmitDisabled(false);
    setSubmitLoading(false);
  }

  //Dragger-上传-加载文件
  function onUploadFilesChange(file: any) {

    // 通过FileReader对象读取文件
    const fileReader = new FileReader();
    const fileType = file.file.originFileObj.type;
    fileReader.onload = event => {
      try {
        const { result }: any = event.target;
        let data: any;
        if (fileType === 'text/plain') {
          data = JSON.parse(result).map((e: any) => ({ ...e, [columns[0].title + '']: e.name }));
        } else {
          // 以二进制流方式读取得到整份excel表格对象
          const workbook = XLSX.read(result, { type: 'binary', cellDates: true });
          // 存储获取到的数据\
          let tempData: [] = [];
          data = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[workbook.SheetNames[0]], { range: 0 }));
          // 遍历每张工作表进行读取（这里默认只读取第一张表）
          // for (const sheet in workbook.Sheets) {
          //     let tempData: [] = [];
          //     if (workbook.Sheets.hasOwnProperty(sheet)) {
          //         // 利用 sheet_to_json 方法将 excel 转成 json 数据
          //         // 最终获取到并且格式化后的 json 数据
          //         // data[sheet] 多张表格
          //         data = tempData.concat(XLSX.utils.sheet_to_json(workbook.Sheets[sheet]));
          //     }
          // }
        };
        if (data.length === 0) {
          throw new Error("数据为空! ");
        }
        // 加载数据
        setStepsNum(1)
        loadData(data, fileType);
      } catch (e: any) {
        setTableData([])
        setStepsNum(0)
        setNoItem('')
        setSubmitDisabled(true)
        setErrorMessage((e as any)?.message)
      }
    }
    //读取
    fileType === 'text/plain' ? fileReader.readAsText(file.file.originFileObj) : fileReader.readAsBinaryString(file.file.originFileObj);
  }

  //Table-验证数据格式
  function loadData(data: any, fileType: string) {
    try {
      let NoTitle: string[] = [];
      let NoRow: string[] = [];
      const content: T[] = fileType === 'text/plain' ? data : data.map((item: any, index: number) => {
        let newData = {} as any;
        const titles: string[] = Object.keys(item);
        titles.forEach(title => {
          const column = columns.find(col => title === col.title);
          // 匹配到对应列，并且dataIndex有效。
          // 比如：dataIndex=[model, code], 对应的对象应该为data.model.code
          let nowItem = item[title]
          switch (column?.valueType) {
            case 'date':
              if (isDateFormat(nowItem)) {
                isDate(nowItem) && (nowItem = getFormatExcelDate(nowItem))
              } else {
                NoRow = [...NoRow, title]
              }
              break;
            case 'dateTime':
              if (isDateTimeFormat(nowItem)) {
                isDate(nowItem) && (nowItem = getFormatExcelDateTime(nowItem))
              } else {
                NoRow = [...NoRow, title]
              }
              break;
            case 'time':
              if (isTimeFormat(nowItem)) {
                isDate(nowItem) &&
                  (nowItem = getFormatReactTableTime(nowItem))
                // (nowItem = "1899-12-30T"+nowItem);
              } else {
                NoRow = [...NoRow, title]
              }
              break;
            case 'digit':
              if (!isNumber(nowItem)) {
                //去除点 在判断是否都是数字
                !(/^\d+$/.test(nowItem = nowItem.replace(/\./g, ''))) && (NoRow = [...NoRow, title])
              }
              break;
            case undefined:
              nowItem = nowItem.toString();
              break;
          }
          if (column?.dataIndex?.toString() !== undefined) {
            if (typeof (column?.dataIndex) === 'string') {
              newData[column?.dataIndex?.toString()] = nowItem;
            } else {
              const dataIndex = column.dataIndex as string[];
              let dataText = { [dataIndex[dataIndex.length - 1]]: nowItem };
              for (let i = dataIndex.length - 2; i > 0; i--) {
                dataText = { [dataIndex[i]]: dataText };
              };
              newData = {
                ...newData,
                [dataIndex[0]]: {
                  ...newData[dataIndex[0]],
                  ...dataText
                }
              };
            }
          };
          if (column?.valueEnum) {
            const text = Object.fromEntries(Object.entries<any>(column.valueEnum).filter(([value, label]) => value === nowItem))[String(nowItem)];
            text !== '' && (newData[String(column.dataIndex)] = text);
          }
        });
        return newData;
      });
      // 验证数据格式
      if (NoTitle.length > 0) {
        setStepsNum(1)
        setNoItem(NoTitle.join(', '))
        throw new Error("验证表头不符合，有部分表头没找到：");
      } else if (NoRow.length > 0) {
        setStepsNum(2)
        setNoItem(Array.from(new Set(NoRow)).join(', '))
        throw new Error("验证数据不符合，部分列的类型不对：");
      } else {
        //成功
        setStepsNum(3)
        setErrorMessage('')
        setSubmitLoading(false)
        setSubmitDisabled(false)
        setTableData((content as []))
      }

    } catch (e: any) {
      setTableData([])
      setSubmitDisabled(true)
      setErrorMessage((e as any)?.message)
    }
  }


  //ModalForm-确认上传
  async function uploadFinish() {
    if (tableData && tableData.length > 0) {
      const result = uploadEntity && await uploadEntity(tableData, uploadParams);
      // result && message.success('导入成功') && setVisible(false);
      result && setVisible(false);
    } else {
      message.error('请上传需要导入的文件')
    }
  }

  const errorColumn: ProColumns<T, ValueType> = {
    title: fieldListErrors && "错误信息",
    width: 100,
    render: (dom, record, index) => {
      if (fieldListErrors instanceof Array) {
        const rowError = fieldListErrors?.filter((res: any) => res.rowIndex - 1 === index);
        if (rowError) {
          const title = rowError[0] && rowError[0].fieldErrors.map((res: any) => <p style={{ marginBottom: '8px' }}>{res.message}</p>);
          return (
            title && <Tooltip placement="topLeft" title={title}>
              <Button style={{ padding: '0' }} type="link" size="small"><CloseCircleOutlined className="svgColor" />详情</Button>
            </Tooltip>
          );
        } else {
          return <></>;
        }
      } else {
        return <></>;
      }


    }
  };

  const findRowClassName = (list: any, index: any): string => {
    if (fieldListErrors && fieldListErrors instanceof Array) {
      const errorScreen = fieldListErrors.filter((res: any) => {
        return res.rowIndex - 1 === index
      })
      errorScreen.map((res: any) => {
        res.fieldErrors.map((errorList: any) => {
          columns.forEach((re: any, index) => {
            if (re.dataIndex === errorList.field) {
              columns[index].className = 'tableTh';
            }
          });
          return errorList;
        })
        return res;
      });
      setStepsNum(0);
      if (errorScreen.length === 0) {
        return ''
      } else {
        return 'errorRow'
      }
    } else {
      return ''
    }

  }

  //下载模板
  const exportErrorColumn: ProColumns<any> = {
    title: '错误信息',
    dataIndex: 'errorMsg',
    valueType: 'text',
  };
  function exportTemplate(res: 'wrong' | 'masterPlate') {
    const excel = new Excel();
    if(res === 'wrong'){
      //下载错误信息
      excel.addSheet(`包含错误数据的表格`)
      .addColumns([exportErrorColumn].concat(columns as any) as IExcelColumn[])
      .addDataSource(tableData.map((e: any, index: number) => {
        const rowError = fieldListErrors?.filter((res: any) => res.rowIndex - 1 === index);
        if (rowError) {
          let errorMsg = '';
          rowError[0] && rowError[0].fieldErrors.forEach((res: any) => {
            errorMsg += res.message + ';========================'
          });
          e.errorMsg = errorMsg;
        };
        return e;
      }))
      .saveAs(`错误.xlsx`);
    }else if(res === 'masterPlate'){
      //下载模版
      excel.addSheet(`导入模版`)
      .addColumns(columns as IExcelColumn[])
      .addDataSource([])
      .saveAs(`导入模版.xlsx`);
    }
    
  }

  return (
    // <Menu>
    //   <Menu.Item key="上传" >
    <ModalForm
      title="上传"
      width={1000}
      visible={visible}
      modalProps={{
        onCancel: () => setVisible(false),
      }}
      trigger={<Button style={ButtonStyle} type={buttonType || 'text'} onClick={() => {
        clearData();
        setVisible(true);
      }} icon={<UploadOutlined />}>
        {props.title || '上传'}
      </Button>}
      submitter={{
        render: (props, defaultDome) => {
          return [
            defaultDome[0],
            <Button
              key="确定"
              type="primary"
              loading={submitLoading}
              disabled={submitDisabled}
              onClick={() => uploadFinish()}
            >确定</Button>
          ];
        },
      }}
    >
      <Steps current={stepsNum} status="error">
        <Step title="加载文件" />
        <Step title="验证表头" />
        <Step title="验证数据" />
      </Steps>
      <div className="margin10"></div>
      <Dragger {...propsDragger}>
        <p className="ant-upload-drag-icon"><InboxOutlined /></p>
        <p className="ant-upload-text">单击或拖动文件到此区域进行上传</p>
        <p className="ant-upload-hint">支持.xlsx/.xls/.txt文件</p>
      </Dragger>
      <div className="margin10"></div>
      {errorMessage &&
        <Alert
          message={`${errorMessage} ${noItem}`}
          type="error"
          showIcon
        />}
      <div className="margin10"></div>
      {remarks && remarks}
      <div style={{ display: 'flex', margin: '20px 0' }}>
        <Button
          key="export"
          type="primary"
          icon={<VerticalAlignBottomOutlined />}
          onClick={() => downLoadUrl ? (window.location.href = downLoadUrl) : exportTemplate('masterPlate')}
        >下载导入文件模版</Button>
      </div>
      <div className="upload-ProTable-box">
        <ProTable<T, Record<string, any>, ValueType>
          style={{ width: width }}
          rowKey={() => nanoid()}
          columns={[errorColumn].concat(columns)}
          dataSource={tableData}
          options={false}
          search={false}
          form={{ span: 8 }}
          pagination={{ pageSize: 20, total: tableData.length }}
          rowClassName={findRowClassName}
        />
      </div>
      {(fieldListErrors && fieldListErrors.length > 0) && <div style={{ display: 'flex', justifyContent: "flex-end", marginTop: '20px' }}>
        <Button
          key="export"
          type="primary"
          icon={<VerticalAlignBottomOutlined />}
          onClick={() => exportTemplate('wrong')}
        >导出Excel（包含错误信息）</Button>
      </div>}
    </ModalForm>
  );
};
export default ImportDataDialog;
