import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import Platform from './platform';
import './index.less'
const Decorate: React.FC<RouteComponentProps> = () => (
  <Router>
    <Platform path="platform/*" />
  </Router>
);
export default Decorate;
