import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import FlowTemplateDetail from "./flowTemplate-details";
import { deleteEntity, getEntities, getEntity } from "./flowTemplate.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import FlowTemplateEdit from "./flowTemplate-edit";
import { FlowTemplate } from "model/financial/flowTemplate.model";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<FlowTemplate>[] = [{
  title: '银行名称',
  dataIndex: 'bankName'
},
// {
//   title: '银行名称',
//   dataIndex: 'bankNameIndex',
//   render: (text) => {
//     return <div style={{fontFamily:'emoji'}}>{text}</div>
//   }
// }
];


const FlowTemplateList: FC<LevelListProps> = (props) => {
  const searchFields = [{
    label: '银行名称',
    name: 'bankName.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<FlowTemplate>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<FlowTemplateDetail navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <FlowTemplateEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ flowTemplate }: RootState) => ({
  entity: flowTemplate.entity,
  entities: flowTemplate.entities,
  loading: flowTemplate.loading,
  updateSuccess: flowTemplate.updateSuccess,
  createSuccess: flowTemplate.createSuccess,
  total: flowTemplate.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FlowTemplateList);