import { ModalForm, ModalFormProps } from '@ant-design/pro-form';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { Spin } from 'antd';
import { Item, Spec, SpecDefineTuple, SpecValueItem } from 'model/product/product.model';
import React, { FC, useCallback, useEffect, useState } from 'react';

export interface SkuSpecGeneratorFormProps extends ModalFormProps<SpecValueRow[]> {
    skuList?: Item[];
    specListData: SpecDefineTuple[];//specList 总数据
    disabledTag?: boolean;
}

export type SpecValueRow = { [index: string]: SpecValueItem } & { skuId?: string; id: string; specs?: Spec[] };

const SkuSpecGeneratorForm: FC<SkuSpecGeneratorFormProps> = (props) => {
    const SPEC_COLUMN_PREFIX = 'property_';

    const { skuList, specListData, onFinish, ...modalFormProps } = props;

    const [dataSource, setDataSource] = useState<SpecValueRow[]>();

    useEffect(() => {
        const rows: SpecValueRow[] = [{ id: '', specs: [] } as SpecValueRow];
        specListData.filter(spec => !!spec.title).forEach(spec => {
            const specRows = spec.specValueList?.flatMap(specValue => rows.map(row => {
                const newRow = {
                    ...row,
                    id: row.id + '-' + specValue?.specValue || 0,
                    specs: [...row?.specs || [], {
                        k_id: spec.id,
                        k: spec.title,
                        v_id: specValue.id,
                        v: specValue.specValue
                    }]
                } as SpecValueRow;

                newRow[`${SPEC_COLUMN_PREFIX}${spec.title!}`] = specValue;

                return newRow;
            })) || [];

            rows.splice(0, rows.length);
            rows.push(...specRows)
        });

        // 标记已经存在sku的数据。
        skuList?.forEach(sku => {
            rows.forEach(row => {
                if (sku.specs?.every(skuSpec => row[`${SPEC_COLUMN_PREFIX}${skuSpec?.k || ''}`]?.specValue === skuSpec.v)) {
                    row.skuId = sku.id || row.id;
                }
            });
        });

        setDataSource(rows);
    }, [specListData, skuList]);


    const buildColumns = useCallback((): ProColumns<SpecValueRow>[] => {
        return specListData.map(spec => ({
            title: spec.title,
            key: `${SPEC_COLUMN_PREFIX}${spec.title}`,
            dataIndex: `${SPEC_COLUMN_PREFIX}${spec.title}`,
            render: (dom, entity) => entity[`${SPEC_COLUMN_PREFIX}${spec.title}`]?.specValue,
        }
        )) as ProColumns<SpecValueRow>[];
    }, [specListData]);

    const [selectedRows, setSelectedRows] = useState<SpecValueRow[]>([]);

    return (
        <ModalForm<SpecValueRow[]>
            {...modalFormProps}
            onFinish={async () => {
                onFinish?.(selectedRows?.filter(item => !item.skuId));
                setSelectedRows([]);
                return true;
            }}
        >
            <Spin spinning={props.disabledTag} tip="Loading">
                <ProTable<SpecValueRow>
                    rowKey="id"
                    search={false}
                    pagination={false}
                    options={false}
                    columns={buildColumns()}
                    dataSource={dataSource}
                    rowSelection={{
                        type: 'checkbox',
                        selectedRowKeys: selectedRows?.map(row => row.id),
                        onChange: (newSelectedRowKeys: React.Key[], newSelectedRows) => setSelectedRows(newSelectedRows),
                        getCheckboxProps: (record => ({
                            disabled: !!record?.skuId
                        }))
                    }}
                >

                </ProTable>
            </Spin>
        </ModalForm>);
}

export default SkuSpecGeneratorForm;