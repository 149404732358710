import { BaseModel } from "../base.model";

export interface Version extends BaseModel {
  changeLog?: string;
  downloadUrl?: string;
  forceUpdate?: boolean;
  platform?: string;
  publishTime?: string;
  versionCode?: number;
  versionName?: string;
  disabled?: boolean;
}

export const Platform = {
  Android: '安卓',
  IOS: '苹果'
}

export const ForceUpdate = {
  true: '是',
  false: '否'
}

export const TFModel = {
  true: '有',
  false: '无'
}

export const StringBoolean = {
  'true': '是',
  'false': '否'
}

export const defaultVersion: Readonly<Version> = {
};