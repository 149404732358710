import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import UnusualMessageDetails from "./message-details";
// import UnusualMessageEdit from "./message-edit";
import { deleteEntity, getEntities, getEntity, ignoreEntity, resetEntity, updateEntity } from "./message.reducer";
import { RouteComponentProps } from "@reach/router";
import { UnusualMessage } from "model/system/unusual-message.model";
import { Button, Form, Input, Tooltip } from "antd";
import SearchBox from "components/search-box";
import { ModalForm, ProFormTextArea } from "@ant-design/pro-form";
import { EditOutlined } from "@ant-design/icons";

export interface UnusualMessageListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<UnusualMessage>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
  width: 100,
}, {
  title: '消息内容',
  dataIndex: 'payload',
  valueType: 'text',
  width: 120,
  hideInTable: true,
}, {
  title: '消息id',
  dataIndex: 'messageId',
  valueType: 'text',
  width: 100,
}, {
  title: '消息版本',
  dataIndex: 'messageVersion',
  valueType: 'text',
  width: 100,
}, {
  title: '原因',
  dataIndex: 'reason',
  valueType: 'text',
  width: 120,
}, {
  title: '最后异常时间',
  dataIndex: 'lastOccurTime',
  valueType: 'text',
  width: 120,
}, {
  title: '上次修改日期',
  dataIndex: 'lastModifiedDate',
  valueType: 'text',
  width: 120,
}, {
  title: '首次发生日期',
  dataIndex: 'firstOccurTime',
  valueType: 'text',
  width: 120,
}, {
  title: '创建日期',
  dataIndex: 'createdDate',
  valueType: 'text',
  width: 120,
}, {
  title: '消息内容类型',
  dataIndex: 'contentType',
  valueType: 'text',
  width: 120,
}, {
  title: '队列',
  dataIndex: 'queue',
  valueType: 'text',
  width: 100,
}, {
  title: '服务',
  dataIndex: 'serviceName',
  valueType: 'text',
  width: 130,
}, {
  title: '状态',
  dataIndex: 'status',
  valueType: 'text',
  width: 130,
}, {
  title: '租户id',
  dataIndex: 'tenantId',
  valueType: 'text',
  width: 100,
}, {
  title: '版本',
  dataIndex: 'version',
  valueType: 'text',
  width: 80,
}];

const searchFields = [{
  label: 'id',
  name: 'id.equals',
}];

const UnusualMessageList: FC<UnusualMessageListProps> = (props) => {

  const [form] = Form.useForm();
  const { updateEntity, resetEntity, ignoreEntity, } = props;

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: ReactNode, record: any) => <ModalForm
    title={'修改消息内容'}
    trigger={<Tooltip title={'修改消息内容'} key="sku"><Button type="link" icon={<EditOutlined />} /></Tooltip>}
    modalProps={{
      destroyOnClose: true,
    }}
    preserve={false}
    form={form}
    onVisibleChange={(e) => e ? form.setFieldsValue({ payload: record.payload }) : form.resetFields()}
    onFinish={async (data) => {
      updateEntity({ ...record, payload: data.payload });
      return true;
    }}
  >
    <ProFormTextArea label='消息内容' name="payload" />
  </ModalForm>,
  (dom: ReactNode, record: any) => <Tooltip title={'重置当前异常消息'} key="sku"><Button onClick={() => resetEntity(record.id)} type="link">重置</Button></Tooltip>,
  (dom: ReactNode, record: any) => <Tooltip title={'忽略当前异常消息'} key="sku"><Button onClick={() => ignoreEntity(record.id)} type="link">忽略</Button></Tooltip>
  ];

  return (
    <ListViewPage<UnusualMessage>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      scrollConfig={{ x: 1500 }}
      detailsIndex={false}
      hideEditButton
      hideDeleteButton
      hideCreateButton
      rowActions={rowActions}
      detailPage={<UnusualMessageDetails navigate={props.navigate} columns={columns} />}
      // editPage={(params) => <UnusualMessageEdit id={params.id} text={params.text}/>}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ unusualMessage }: RootState) => ({
  entity: unusualMessage.entity,
  entities: unusualMessage.entities,
  loading: unusualMessage.loading,
  updateSuccess: unusualMessage.updateSuccess,
  createSuccess: unusualMessage.createSuccess,
  total: unusualMessage.total,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateEntity,
  resetEntity,
  ignoreEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UnusualMessageList);