import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import UserDetails from './user-details';
import UserEdit from './user-edit';
import UserList from './user-list';

const User: React.FC<RouteComponentProps> = () => (
  <Router>
    <UserList path="/" />
    <UserDetails path=":id" />
    <UserEdit path=":id/edit" />
    <UserEdit path="new" />
  </Router>
);
export default User;
