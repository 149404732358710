import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './refund.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Refund } from "model/procure/refund.model";
import { useParamGeneral } from "components/useParamGenera";
import { internationalList } from "components/IntelFormText";
import Title from "antd/lib/typography/Title";
import ProTable from "@ant-design/pro-table";

export interface RefundDetailsProps extends DetailsStateAndDispatchProps<Refund, 'pruchaseRefundStatus' | 'purchaseRefundSource'>,
  RouteComponentProps, StateProps, DispatchProps {
}
const RefundDetails: FC<RefundDetailsProps> = (props) => {
  const { entity } = props;
  const { currency } = useParamGeneral();
  const columns: any = [{
    title: '商品名称',
    dataIndex: '',
    width: 200,
    fixed: 'left',
    render: (text: any, row: any) => {
      return <div className='query-order-name'>
        <img alt='商品图片' style={{ width: '60px', height: 'auto', marginRight: '10px' }} src={internationalList(row?.orderLine?.picture || '')} />
        <div>
          <p>{internationalList(row?.orderLine?.productName || '')}</p>
          <p>
            规格编码：{row?.orderLine?.skuCode}
          </p>
        </div>
      </div>
    }
  }, {
    title: '颜色/尺码',
    dataIndex: '',
    width: 200,
    render: (text: any, row: any) => {
      return row?.orderLine?.specName?.map((v: any) => {
        return <><p>{v.k}: {v.v}</p></>
      })
    }
  }, {
    title: '商品单价',
    dataIndex: 'memberPrice',
    width: 200,
    render: (text: any, row: any) => {
      return currency?.symbol + ' ' + row?.orderLine?.memberPrice
    }
  }, {
    title: '数量',
    width: 200,
    dataIndex: ['orderLine','quantity'],
  }, {
    title: '取消数量',
    width: 200,
    dataIndex: ['orderLine','cancelledQuantity'],
  }, {
    title: '支付金额',
    dataIndex: 'payAmount',
    width: 200,
  }, {
    title: '应退货款',
    dataIndex: 'refundGoodsAmount',
    width: 200,
  }, {
    title: '应退赠送货款',
    dataIndex: 'refundGiftAmount',
    width: 200,
  }, {
    title: '应退金额',
    dataIndex: 'refundAmount',
    width: 200,
  }, {
    title: '实际应退金额',
    dataIndex: 'actualRefundAmount',
    width: 200,
  }, {
    title: '实际应退赠送货款',
    dataIndex: 'actualRefundGiftAmount',
    width: 200,
  }, {
    title: '实际应退货款',
    dataIndex: 'actualRefundGoodsAmount',
    width: 200,
  }];
  return (
    <DetailsPage<Refund, 'pruchaseRefundStatus' | 'purchaseRefundSource'>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Refund>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>
      <Title level={5}>商品信息</Title>
      <ProTable
        className='order-details-table'
        columns={columns}
        rowKey="id"
        dataSource={entity?.refundApplicationDetails || []}
        // scroll={{ x: 800 }}
        pagination={false}
        options={false}
        search={false}
      />

    </DetailsPage>);
};

const mapStateToProps = ({ refund }: RootState) => ({
  entity: refund.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RefundDetails);
