import React, { ReactNode, useEffect, useState } from "react"
import { Form, FormInstance } from "antd";
import { RouteComponentProps } from '@reach/router';
import { CrudCopyGetAction, CrudGetAction, CrudPostAction, CrudPutAction } from 'reducers/redux-action.type';
import { BaseModel } from 'model/base.model';
import { DrawerForm } from "@ant-design/pro-form";

export interface EditStateAndDispatchProps<T> {
  id?: number | string;
  entity: T;
  updateSuccess: boolean;
  createSuccess: boolean;
  updating: boolean;
  text?: string;
  copy?: boolean;
  containIdCreat?: boolean;//为true时：有id的value 执行创建
  getEntity: CrudGetAction<T>;
  createEntity: CrudPostAction<T>;
  updateEntity: CrudPutAction<T>;
  reset: (entity?: T) => (dispatch: any) => any;
  copyEntity?: CrudCopyGetAction<T>;
  beforeUpdateEntity?: (entity?: T) => Promise<T>;
  submitter?: Submitter;
}

interface Submitter {
  submitButtonType?: "text" | "link" | "ghost" | "primary" | "default" | "dashed";
  submitText?: string;
  dom?: (props?: any) => React.ReactNode
}

export interface EditPageProps<T> extends RouteComponentProps, EditStateAndDispatchProps<T> {
  children?: ReactNode;
  title?: string;
  width?: number;
  cancelText?: string;
  form?: FormInstance;
  ignoreArr?: string[];
  submitButtonDisabled?: boolean;
}

const EditPage = <T extends BaseModel>(props: EditPageProps<T>) => {
  const { id, width, submitButtonDisabled = false, cancelText = '取消', title, entity, text, updateSuccess, createSuccess, ignoreArr, getEntity, copyEntity, copy, createEntity, updateEntity, reset, beforeUpdateEntity, submitter, containIdCreat = false } = props;
  const [form] = Form.useForm(props.form);
  const [visible, setVisible] = useState(false);

  useEffect(() => {
    text && setVisible(true);
  }, [text])

  // 读取数据
  useEffect(() => {
    // 编辑，取对象数据
    !!id && !copy && getEntity(id);
    // 复制，取对象数据
    !!id && !!copy && !!copyEntity && copyEntity(id, ignoreArr);
    // 新建，重置对象
    !id && reset() && form.resetFields();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [id, getEntity, reset, copyEntity, copy, form, text]);

  // 修改成功，关闭对话框
  useEffect(() => {
    // 关闭弹框/重置表单
    // console.log(updateSuccess, createSuccess, visible)
    if (updateSuccess || createSuccess || visible === false) {
      setVisible(false);
      reset() && form.resetFields();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [updateSuccess, createSuccess, visible]);

  // 设置form的值
  useEffect(() => {
    if (!id || String(entity?.id) === String(id) || copy) {
      // 刷新form的field.
      form.resetFields();
      form.setFieldsValue(entity);
    }
  }, [entity, form, id, copy, text]);

  return (
    <DrawerForm<T>
      title={!id ? `新建${title}` : `编辑${title}`}
      visible={visible}
      //preserve={false}
      // drawerForm={{
      //   onCancel: () =>  setVisible(false)
      // }}
      onVisibleChange={(e) => !e && setVisible(false)}
      submitter={{
        submitButtonProps: {
          type: submitter?.submitButtonType ? submitter.submitButtonType : 'primary',
          disabled: submitButtonDisabled,
        },
        searchConfig: {
          submitText: submitter?.submitText ? submitter.submitText : '确认',
          resetText: cancelText,
        },
        render: (props, defaultDoms) => {
          return [
            ...defaultDoms,
            submitter && submitter.dom && submitter.dom(props),
          ];
        },
      }}
      width={ width ? width :'84vw'}
      form={form}
      // omitNil={false}//是否去掉表单中的 Null 和 undefined
      onFinish={async values => {
        const { id, ...restEntity } = entity;
        const newEntity = {
          ...restEntity,
          ...values,
          id: copy ? '' : (values?.id ? values?.id : id)
        };
        const theEntity = (beforeUpdateEntity && await beforeUpdateEntity(newEntity)) || newEntity;
        !!theEntity.id && ((copy || containIdCreat) ? await createEntity(theEntity) : await updateEntity(theEntity));
        !theEntity.id && await createEntity(theEntity);
        return true;
      }}
    >
      {props.children}
    </DrawerForm>
  );
};

export default EditPage;
