import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AdvertisementDetails from './advertisement-details';
import AdvertisementEdit from './advertisement-edit';
import AdvertisementList from './advertisement-list';
import './index.less'

const Advertisement: React.FC<RouteComponentProps> = () => (
  <Router>
    <AdvertisementList path="/" />
    <AdvertisementDetails path=":id" />
    <AdvertisementEdit path=":id/edit" />
    <AdvertisementEdit path="new" />
  </Router>
);
export default Advertisement;
