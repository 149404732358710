import { Router, RouteComponentProps } from "@reach/router";
import WholesaleOrderList from './wholesale-orders-list';
import React from 'react';

const WholesaleOrder: React.FC<RouteComponentProps> = () => (
  <Router>
    <WholesaleOrderList path="/" />
  </Router>
);
export default WholesaleOrder;
