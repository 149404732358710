import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { Form, Image, Upload, Input, Button, Tooltip, Select, message } from 'antd';
import { PlusOutlined, FormOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import '../style/goods-cube-fill.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
const { Option } = Select;
export interface Props {
    dataField: {
        imgList: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const GoodsCubeFill: FC<Props> = ({ dataField }) => {
    // const [imageList,setImageList] = useState([]);
    const [selectedItem, setSelectedItem] = useState(({} as any))



    useEffect(() => {
        let selectedStyle = dataField.styleList.filter((v: any) => v.id === dataField.selected)
        if (selectedStyle && selectedStyle[0]) {
            setSelectedItem(selectedStyle[0])
        }

    }, [dataField, setSelectedItem])
    const ImageList: FC<{ list: any[], imgList: any[] }> = ({ list, imgList }) => {
        if (!list) {
            return <div className="a"></div>
        }
        return <>
            {
                list.map((v: any, i: any) => {
                    let imgItem = imgList[i]
                    return <div className="img" style={
                        {
                            width: `${v.width}px`,
                            height: `${v.height}px`,
                        }
                    }>
                        <Image preview={false} src={(imgItem && imgItem.imgUrl) || holderImg} />
                    </div>
                })
            }
        </>
    }
    return (
        <div className="goods-cube-fill-container" >

            {
                (dataField.selected.indexOf('column') !== -1) && selectedItem && selectedItem.position && dataField.imgList && <div className={`column ${dataField.selected}`}>
                    <ImageList list={selectedItem.position.top} imgList={dataField.imgList} />
                </div>
            }
            {
                (dataField.selected.indexOf('horizontal') !== -1) && selectedItem && selectedItem.position && selectedItem.position.left && <div className={`horizontal ${dataField.selected}`}>
                    <div className="left">
                        <ImageList list={selectedItem.position.left} imgList={dataField.imgList} />
                    </div>
                    <div className="right">
                        <ImageList list={selectedItem.position.right} imgList={dataField.imgList.slice(selectedItem.position.left.length)} />
                    </div>
                </div>
            }
            {
                (dataField.selected.indexOf('vertical') !== -1) && selectedItem && selectedItem.position && selectedItem.position.top && <div className={`vertical ${dataField.selected}`}>
                    <div className="top">
                        <ImageList list={selectedItem.position.top} imgList={dataField.imgList} />
                    </div>
                    <div className="bottom">
                        <ImageList list={selectedItem.position.bottom} imgList={dataField.imgList.slice(selectedItem.position.top.length)} />
                    </div>
                </div>
            }

        </div>
    )
}

interface ImageItemProps {
    id: string
    index: number
    data?: any
    updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
    setShowLinkSelectionPrivate: (show: boolean) => void
    setCallerIndex: (index: number) => void
}

const ImageItem: FC<ImageItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowLinkSelectionPrivate, setCallerIndex }) => {
    const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
        return (
            <Select defaultValue={defaultValue} onChange={
                (e) => {
                    data.type = e
                    updateModuleDataPrivate(data, index, '')
                }
            } className="select-after">
                <Option value="navigateTo">新窗口</Option>
                <Option value="switchTab">TAB切换</Option>
                <Option value="redirectTo">重定向</Option>
                <Option value="back">返回</Option>
            </Select>
        );
    }
    const uploadProps = {
        multiple: false,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                updateModuleDataPrivate({
                    ...data,
                    'imgUrl': res
                }, index, '')
            }).catch(() => {message.error('图片上传失败')});
        },
    };
    return <div className={`image-item`} >
        <div className="img">
            <Upload {...uploadProps}>
                <div className="change-img" >
                    <Tooltip title="更换">
                        <FormOutlined />
                    </Tooltip>
                </div>
            </Upload>

            <div className="operation">
                <div className="icon">{index + 1}</div>
                <div className="icon " onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveUp')
                    }
                }>
                    <Tooltip title="上移">
                        <ArrowUpOutlined />
                    </Tooltip>
                </div>

                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'moveDown')
                    }
                }>
                    <Tooltip title="下移">
                        <ArrowDownOutlined />
                    </Tooltip>
                </div>
                <div className="icon" onClick={
                    () => {
                        updateModuleDataPrivate(data, index, 'delete')
                    }
                }>
                    <Tooltip title="移除" >
                        <DeleteOutlined />
                    </Tooltip>
                </div>

            </div>
            <Image preview={true} src={data.imgUrl + '?imageView2/2/w/600'}></Image></div>
        <div className="info">
            <div className="input-group">
                <Input.Group compact className="input-item">
                    <Button type="primary" onClick={
                        () => {
                            setCallerIndex(index)
                            setShowLinkSelectionPrivate(true,)
                        }
                    }>选择</Button>
                    <Input addonAfter={<OpenType defaultValue={data.type || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
                        data.link = e.target.value;
                        updateModuleDataPrivate(data, index, '')
                    }} value={data.link} />

                </Input.Group>
            </div>
        </div>
    </div>
})
export const GoodsCubeFillSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, }) => {

    const { Dragger } = Upload;
    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const [callerIndex, setCallerIndex] = useState(-1)
    const updateModuleDataPrivate = useCallback(
        (item: any, index: any, type: any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.imgList[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.imgList[index]
                newDataFieldPrivate.imgList = newDataFieldPrivate.imgList.filter((v: any) => v)
            } else if (type === 'moveDown') {
                let nextIndex = index + 1, length = newDataFieldPrivate.imgList.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.imgList);
                if (nextIndex > length - 1) {
                    nextIndex = 0
                }
                nextItem = newDataFieldPrivate.imgList[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.imgList = newImageList
            } else if (type === 'moveUp') {
                let nextIndex = index - 1, length = newDataFieldPrivate.imgList.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.imgList);
                if (nextIndex < 0) {
                    nextIndex = length - 1
                }
                nextItem = newDataFieldPrivate.imgList[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.imgList = newImageList
            } else if (type === 'add') {
                if (item instanceof Array) {
                    newDataFieldPrivate.imgList = [...item, ...newDataFieldPrivate.imgList]
                } else {
                    newDataFieldPrivate.imgList.unshift(item)
                    newDataFieldPrivate.imgList = [].concat(newDataFieldPrivate.imgList)
                }
            }
            // newDataFieldPrivate
            // console.log('newDataFieldPrivate',newDataFieldPrivate)
            methods.updateModuleData(newDataFieldPrivate, id)
        },
        [dataFieldPrivate, methods, id]
    )
    useEffect(
        () => {
            if (!selectedInfo || !selectedInfo.path) return
            let callItem = { ...dataFieldPrivate.imgList[callerIndex] }
            callItem.link = selectedInfo.path
            callItem.type = selectedInfo.openType
            updateModuleDataPrivate(callItem, callerIndex, '')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    useEffect(() => {
        setDataFieldPrivate(dataField)
    }, [dataField])
    const DraggerUploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                let imageItem = { "imgUrl": res, "link": "", "title": "" }
                updateModuleDataPrivate(imageItem, 0, 'add')
            }).catch(() => {message.error('图片上传失败')});
        },

    };
    const BlockList: FC<{ list: any[] }> = ({ list }) => {
        return <>
            {
                list.map((v: any, i: number) => {
                    return <div className="block" style={
                        {
                            width: `${(v.width / 4).toFixed(0)}px`,
                            height: `${(v.height / 4).toFixed(0)}px`,
                        }
                    }></div>
                })
            }
        </>
    }
    const getStyleItem = (item: any) => {
        return <div className={`item-container ${dataField.selected === item.id ? 'selected' : ''}`}>
            {
                item.type === 'column' && <div className={`column ${item.id}`}>
                    {
                        <BlockList list={item.position.top} />
                    }
                </div>
            }
            {
                item.type === 'horizontal' && <div className={`horizontal ${item.id}`}>
                    <div className="left">
                        {
                            <BlockList list={item.position.left} />
                        }
                    </div>
                    <div className="right">
                        {
                            <BlockList list={item.position.right} />
                        }
                    </div>
                </div>
            }
            {
                item.type === 'vertical' && <div className={`vertical ${item.id}`}>
                    <div className="top">
                        {
                            <BlockList list={item.position.top} />
                        }
                    </div>
                    <div className="bottom">
                        {
                            <BlockList list={item.position.bottom} />
                        }
                    </div>
                </div>
            }

        </div>
    }
    const getSelectedName = () => {
        let selectedStyle = dataField.styleList.filter((v: any) => v.id === dataField.selected)
        if (selectedStyle && selectedStyle[0]) {
            return selectedStyle[0].name
        }
    }
    return (
        <div className="goods-cube-fill-set" >

            <div className="set-form">



                <Form.Item label="布局" >
                    <p className="text">{
                        getSelectedName()
                    }</p>
                </Form.Item>
                <div className="style-list-con">
                    <div className="list-con">
                        {
                            dataField.styleList.map((v: any, i: number) => {
                                return <Tooltip title={v.name} key={i}>
                                    <div className="list-item" onClick={
                                        () => {
                                            methods.updateModuleData({
                                                ...dataField,
                                                selected: v.id
                                            }, id)
                                        }
                                    }>
                                        {
                                            getStyleItem(v)
                                        }
                                    </div>
                                </Tooltip>


                            })
                        }
                    </div>
                </div>
            </div>
            <div className="dragger-con">
                <Dragger {...DraggerUploadProps} className="add-area">
                    <p className="desc">
                        <PlusOutlined className="icon" />
                    </p>
                    <p className="desc">点击/拖放以新增图片</p>
                </Dragger>
            </div>
            <div className="image-list">
                {dataFieldPrivate?.imgList.map((v: any, i: number) => {
                    return v.type !== 'config' && <ImageItem
                        key={i}
                        id={v.id}
                        index={i}
                        data={v}
                        updateModuleDataPrivate={updateModuleDataPrivate}
                        setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
                        // selectedInfoPrivate={selectedInfoPrivate}
                        // callerIndex={callerIndex}
                        setCallerIndex={setCallerIndex}
                    />
                })}
            </div>

        </div>
    )
})

export default GoodsCubeFill