import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import VersionDetails from "./version-details";
import { activate, deleteEntity, getEntities, getEntity } from "./version.reducer";
import { RouteComponentProps } from "@reach/router";
import { ForceUpdate, Version } from "model/system/version.model";
import Popconfirm from "antd/lib/popconfirm";
import { Button, Form, Input, Tag } from "antd";
import { ProFormRadio } from "@ant-design/pro-form";
import Authorized from "common/auth/authorized";
import VersionEdit from "./version-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";


export interface VersionListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Version>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
  width: 80
}, {
  title: '版本名称',
  dataIndex: 'versionName',
  valueType: 'text',
  width: 100
}, {
  title: '版本号',
  dataIndex: 'versionCode',
  valueType: 'digit',
  width: 100
}, {
  title: '更新内容',
  dataIndex: 'changeLog',
  valueType: 'text',
  width: 120
}, {
  title: '是否强制更新',
  dataIndex: 'forceUpdate',
  valueType: 'text',
  valueEnum: ForceUpdate,
  width: 100
}, {
  title: 'app平台',
  dataIndex: 'platform',
  valueType: 'text',
  width: 120
}, {
  title: '发布时间',
  dataIndex: 'publishTime',
  valueType: 'dateTime',
  width: 120,
}, {
  title: '下载地址',
  dataIndex: 'downloadUrl',
  valueType: 'text',
  width: 150,
  ellipsis: true,
  copyable: true,
}, {
  title: '状态',
  dataIndex: 'disabled',
  width: 60,
  render: (text, record) => record.disabled ? <Tag color="red">禁用</Tag> : <Tag color="green">激活</Tag>
}];



const VersionList: FC<VersionListProps> = (props) => {
  const { activate } = props;
  const activateButtonRender = (dom: ReactNode, record: Version): ReactNode => (
    record.id &&
    <Authorized authority='ActivateAndDisable'>
      <Popconfirm title={'你确定要' + (record.disabled ? '激活' : '禁用') + '这个版本吗？'} key="activate"
        onConfirm={() => activate({ id: record.id, state: record?.disabled ? 'activate' : 'deactivate' })}
      >
        <Button type="link">{record.disabled ? '激活' : '禁用'}</Button>
      </Popconfirm>
    </Authorized>
  );

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormRadio.Group
          name="disabled.equals"
          label=""
          radioType="button"
          options={[{ label: '全部', value: '' }, { label: '激活', value: 'false' }, { label: '禁用', value: 'true' }]}
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<Version>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailsIndex={1}
      detailPage={<VersionDetails navigate={props.navigate} columns={columns} />}
      hideDeleteButton
      // hideEditButton
      rowActions={[activateButtonRender]}
      editPage={(params: any) => <VersionEdit id={params.id} text={params.text} />} 
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ version }: RootState) => ({
  entity: version.entity,
  entities: version.entities,
  loading: version.loading,
  updateSuccess: version.updateSuccess,
  createSuccess: version.createSuccess,
  total: version.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  activate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(VersionList);