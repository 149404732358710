import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PricingRulesDetails from './pricingRules-details';
import PricingRulesEdit from './pricingRules-edit';
import PricingRulesList from './pricingRules-list';
import './index.less'

const PricingRules: React.FC<RouteComponentProps> = () => (
  <Router>
    <PricingRulesList path="/" />
    <PricingRulesDetails path=":id" />
    <PricingRulesEdit path=":id/edit" />
    <PricingRulesEdit path="new" />
  </Router>
);
export default PricingRules;
