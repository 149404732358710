import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPoint, Point } from 'model/product/point.model';

const entity = 'Point';
const apiUrl = '/api/yumei-mall-pim/product/point/mapping';
const standardReducerImpl = new StandardReducerImpl<Point>(entity, defaultPoint, apiUrl);
export type PointState = StandardState<Point>;
export const point = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;