import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ExchangeDetails from './exchange-details';
import ExchangeEdit from './exchange-edit';
import ExchangeList from './exchange-list';

const Exchange: React.FC<RouteComponentProps> = () => (
  <Router>
    <ExchangeList path="/" />
    <ExchangeDetails path=":id" />
    <ExchangeEdit path=":id/edit" />
    <ExchangeEdit path="new" />
  </Router>
);
export default Exchange;
