import {BaseModel, DisableModel} from 'model/base.model';

export interface Fund extends BaseModel, DisableModel {
  id?: number | string;
  accountTypeName?: string;
  amount?: number;
  availableAmount?: string;
  customerId?: number;
  fromId?: number;
  fromName?: string;
  frozenAmount?: string;
  giftAmount?: string;
  fromType?: string;
  toStoreName?: string;
  paymentType?: string;
  toStoreId?: number;
  userId?: number;
  operationSequencenumber?: any
  isRewarded?: number

}

export const isRewarded  = {
  '1':'是',
  '0':'否'
}

export const defaultFund: Readonly<Fund> = {
};
