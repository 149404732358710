import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPost } from "model/video/post.model";
import { CrudDeleteAction, CrudGetAction, CrudPostAction, CrudPutAction } from 'reducers/redux-action.type';
import axios from 'axios';
import { cleanEntity } from 'utils/entity-utils';

const entity = 'topic';
const apiUrl = '/api/yumei-mall-content/certificate/findCertificateByPage';

const standardReducerImpl = new StandardReducerImpl<any>(entity, defaultPost, apiUrl);
export type ExamTopicState = StandardState<any>;
export const examtopic = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const reset = standardReducerImpl.reset;


// 获取全部证书
export const getAllCertificate = async (): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-content/certificate/findCertificateByPage`, { params: { page: 0, size: 10000 } });
    return axiosResponse.data || [];
}
  


// 查询详情
export const getEntity: CrudGetAction<any> = (id: any, togetherParams: any) => {
    let apiUrl = `/api/yumei-mall-content/certificate/findCertificateById/${id}`
    const requestUrl = (apiUrl as any)?.detailUrl ? `${(apiUrl as any).detailUrl + togetherParams}` : `${apiUrl}`;
    return {
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
        payload: axios.get<any>(requestUrl),
    };
};


// 添加
export const createEntity: CrudPostAction<any> = entity => async dispatch => {
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
        payload: axios.post('/api/yumei-mall-content/certificate/addCertificate', cleanEntity(entity)),
    });
};

// 修改
export const updateEntity: CrudPutAction<any> = (entity) => async dispatch => {
    let apiUrl = '/api/yumei-mall-content/certificate/updateCertificate'
    const requestUrl = (apiUrl as any)?.editUrl ? `${(apiUrl as any).editUrl + (entity as any).updateEntityParams}` : `${apiUrl}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl, cleanEntity(entity)),
    })
};

// 删除
export const deleteEntity: CrudDeleteAction<any> = (id, remove, deleteEntityParams) => async dispatch => {
     let apiUrl = '/api/yumei-mall-content/certificate/delCertificate'
    const requestUrl = (apiUrl as any)?.deleteUrl ? `${(apiUrl as any).deleteUrl + deleteEntityParams}` : `${apiUrl}/${id}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE),
        payload: axios.delete(requestUrl, {
            params: { remove, id }
        }),
    });
};

