import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { BonusList, defaultBonusList } from 'model/bonus/bonusList.model';
import axios from 'axios';

const entity = 'Bonus';
const apiUrl = '/api/yumei-mall-finance/customer/finance/cashapplylist';
const standardReducerImpl = new StandardReducerImpl<BonusList>(entity, defaultBonusList, apiUrl);
export type BonusListState = StandardState<BonusList>;
export const bonusList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const examineBonus = async (params: any) => {
    const result = await axios.put<any>(`/api/yumei-mall-finance/customer/finance/cashapplycheck?applyId=${params.applyId}&status=${params.status}`);
    return result.data;
  }

  export const fetchWithdrawalPeriodList = async () => {
    const result = await axios.post<any>(`/api/yumei-mall-finance/front/customer/finance/withdrawlPeriodListStage`);
    return result.data;
  }