import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import AddressDetails from "./address-details";
import { deleteEntity, getEntities, getEntity } from "./address.reducer";
import { RouteComponentProps } from "@reach/router";
import { Address } from "model/store/address.model";
import AddressEdit from './address-edit';
import { fetchStoreList } from "../store/store.reducer";
import SelectSearch from "components/select-search";

export interface AddressListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Address>[] = [{
  title: '收件人',
  dataIndex: 'consignee',
  valueType: 'text',
}, {
  title: '商家',
  dataIndex: 'sellerName',
  valueType: 'text',
}, {
  title: '手机',
  dataIndex: 'mobile',
  valueType: 'text',
}, {
  title: '收件地址',
  dataIndex: 'mergeName',
  valueType: 'text',
  width: 150,
}, {
  title: '详细地址',
  dataIndex: 'address',
  valueType: 'text',
  width: 200,
}, {
  title: '邮政编码',
  dataIndex: 'zipcode',
  valueType: 'text',
}, {
  title: '默认地址',
  dataIndex: 'isDefault',
  valueType: 'text',
  render: (text, record) => record.isDefault === 0 ? '否' : '是',
}]
const AddressList: FC<AddressListProps> = (props) => {

  const searchFields = [{
    label: '手机号',
    name: 'mobile.equals',
  }, {
    label: '收件人',
    name: 'consignee.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="sellerId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Address>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<AddressDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <AddressEdit id={params.id} text={params.text}/>} 
      detailsIndex={1}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ address }: RootState) => ({
  entity: address.entity,
  entities: address.entities,
  loading: address.loading,
  updateSuccess: address.updateSuccess,
  createSuccess: address.createSuccess,
  total: address.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressList);