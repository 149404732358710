import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import UnitDetails from './unit-details';
import UnitEdit from './unit-edit';
import UnitList from './unit-list';

const Unit: React.FC<RouteComponentProps> = () => (
  <Router>
    <UnitList path="/" />
    <UnitDetails path=":id" />
    <UnitEdit path=":id/edit" />
    <UnitEdit path="new" />
  </Router>
);
export default Unit;
