import { FC, Key, ReactElement, useState } from 'react'
import { Product } from 'model/product/product.model';
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { Button, message, Tag } from 'antd';
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import { PlusOutlined } from "@ant-design/icons";
import { fetchAllSonCourse } from '../course/course.reducer';


export interface ProductSelectionFormProps {
  trigger?: string;
  triggerRender?: ReactElement;
  name?: string;
  multiple?: boolean;
  hideSearch?: boolean;
  editSku?: boolean;
  productIds?: string[];
  requestParams?: any;
  fetchFunc?: (params: any) => any;
  onFinish: (result: any[]) => void;
  scrollConfig?: any;
}

const ProductSelectionForm: FC<ProductSelectionFormProps> = (props) => {
  const {
    trigger,
    onFinish,
    triggerRender,
    multiple = true,
    scrollConfig = {},
    requestParams = {}
  } = props;

  const columns: ProColumns[] = [
    {
      title: 'ID',
      dataIndex: 'id',
      width: 140,
      hideInSearch: true
    },
    {
      title: '课程标题',
      dataIndex: 'title',
      valueType: 'text',
      width: 150,
      ellipsis: true
    },

    {
      title: '课程描述',
      dataIndex: 'content',
      valueType: 'text',
      width: 150,
      ellipsis: true,
      hideInSearch: true
    },
    {
      title: '及格分数',
      dataIndex: 'passScore',
      valueType: 'text',
      width: 150,
      ellipsis: true,
      hideInSearch: true
    },
    {
      title: '是否需要考试',
      dataIndex: 'isExamination',
      width: 120,
      hideInSearch: true,
      render: (dom, record) => {
        return <Tag color={record.isExamination === 1 ? '#1890ff' : '#f50'}>{record.isExamination === 1 ? '需要' : '不需要'}</Tag>
      }
    }, {
      title: '状态',
      dataIndex: 'state',
      width: 120,
      hideInSearch: true,
      render: (dom, record) => {
        return <Tag color={record.state === 1 ? '#1890ff' : '#f50'}>{record.state === 1 ? '使用中' : '闲置'}</Tag>
      }
    }, {
      title: '序号',
      dataIndex: 'sort',
      width: 120,
      hideInSearch: true,
      render: (dom, record) => {
        return <ProFormText
          initialValue={record.sort}
          fieldProps={{
            onChange: (e) => {
              record.sort = e.target.value
              let chageIndex = selectedRows.findIndex((item) => item.id == record.id)
              if (chageIndex > -1) {
                selectedRows[chageIndex].sort = e.target.value
                setSelectedRows(selectedRows)
              }
            }
          }}
        />
      }
    }];

  const [selectedRowKeys, setSelectedRowKeys] = useState<Key[]>([]);
  const [selectedRows, setSelectedRows] = useState<any[]>([]);

  return (
    <ModalForm<Product[]>
      title="选择课程"
      width={1200}
      trigger={triggerRender ||
        <Button type="primary">
          <PlusOutlined />
          {trigger}
        </Button>
      }
      onVisibleChange={(e) => {
        setSelectedRowKeys([]);
        setSelectedRows([]);
      }}
      modalProps={{
        destroyOnClose: true,
      }}
      onFinish={async () => {
        let data = selectedRows
        let has = data.filter((item)=> (item.sort === ''|| !item.sort))
        if(selectedRows.length === 0) {
          message.error('请选择课程')
          return false
      }
        if(has.length > 0) {
            message.error('序号不能为空')
            return false
        }
        let result = selectedRows.map((item:any)=> {
             return {
              lessonSonId: item.id,
              sort: item.sort
             }
        })
        return onFinish(result || [])
      }}
    >
      <ProTable<Product, Record<string, any>>
        className="selectPimTable"
        rowKey="id"
        scroll={scrollConfig}
        dateFormatter="string"
        columns={columns}
        rowSelection={{
          type: multiple ? 'checkbox' : 'radio',
          selectedRowKeys,
          onChange: (selectedRowKeysKey: Key[], selectedRows) => {
            setSelectedRowKeys(selectedRowKeysKey);
            setSelectedRows(selectedRows);
            console.log(selectedRowKeysKey)
            console.log(selectedRows)
          },
        }}
        pagination={{
          showQuickJumper: true,
        }}
        request={async (params: any) => {
          const filterObj = {
            ...params,
            ...requestParams,
          };
          const result: any = await fetchAllSonCourse({
            ...filterObj,
            page: params?.current - 1 || 0,
            size: params?.pageSize || 20,
          })
          return {
            success: true,
            total: result.headers?.['x-total-count'],
            data: result.data,
          };
        }}
      />
    </ModalForm>
  );
};

export default ProductSelectionForm;