import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./brand.reducer";
import { Brand } from "model/product/brand.model";
import { uploadImage } from "utils/file-utils";
import { Col, Form, Row, message } from "antd";
import UploadImgOne from "components/upload-img/upload-img-one"
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import IntelFormText from "components/IntelFormText";
import { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";;
export interface BrandEditProps extends EditStateAndDispatchProps<Brand>, StateProps, DispatchProps, RouteComponentProps {
}

const BrandEdit: FC<BrandEditProps> = props => {
  const {
    ...editProps
  } = props;

  const [form] = Form.useForm();

  const uploadImg = (res?: Brand) => {
    return new Promise<Brand>((resolve, reject) => {
      if (res) {
        const logo: any = res && res.logo?.concat();
        if (res.logo && logo.length > 0) {
          if (logo[0].originFileObj) {
            //logo需上传
            const newDate = new Date().getTime();
            uploadImage(logo[0].originFileObj, 'product/img-' + newDate).then(async imageName => {
              res.logo = imageName;
              resolve(res);
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            // logo已上传且未修改
            res.logo = logo[0].url;
            resolve(res)
          }
        } else {
          //未上传logo
          res.logo = '';
          resolve(res)
        }
      }
    })
  }


  return (
    <EditPage<Brand>
      title="品牌"
      beforeUpdateEntity={uploadImg}
      form={form}
      {...editProps}
    >
      <Form.Item name="logo" label="品牌LOGO" >
        <UploadImgOne
          listType="picture-card"
          extra="建议尺寸：800*800像素, 最多上传一张, 不超过2M."
          title="上传"
        />
      </Form.Item>
      <Row>

        <Col span={11}>
          <IntelFormText
            label={'品牌名称'}
            name="brandName"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 100
            }}
            value={props.entity.brandName || ''}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          <ProFormText
            label={'品牌编码'}
            name="brandCode"
            rules={[{ required: true }]}
            fieldProps={{
              maxLength: 100
            }}
          />
        </Col>
        <Col span={1} />
        <Col span={11}>
          <ProFormSelect label="店铺" rules={[{ required: true }]} name="storeId" placeholder="店铺" request={fetchStoreList} />
        </Col>
        <Col span={1} />
        <Col span={12}>
          <IntelFormText
            type='textArea'
            label="品牌介绍"
            width={240}
            name='introduction'
            value={props.entity?.introduction || ''}
            form={form}

          />
        </Col>
      </Row>
      <Row>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ brand }: RootState) => ({
  entity: brand.entity,
  updateSuccess: brand.updateSuccess,
  updating: brand.updating,
  createSuccess: brand.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(BrandEdit);
