import { useEffect, useState } from "react";
import ProTable from "@ant-design/pro-table";
import { Button, Space, Spin, Tabs, Tooltip, Form, message } from "antd";
import { regionalOwnershipResource, storeList, findByCondition } from './rewardconfig.reducer'
import { addRegionOwnerRecordDto } from './modifyDealer.reducer'
import TabPane from "antd/lib/tabs/TabPane";
import { navigate } from '@reach/router';
import { ModalForm } from "@ant-design/pro-form";
import ModifyDealer from './modifyDealer'
import SearchBox from "components/search-box";
import { setTimeout } from "timers";

const AdvertisementList: any = () => {
  const [form] = Form.useForm();
  const [randomText, setRandomText] = useState(1);
  let [key, setkey]: any = useState(0);
  let [serachData, setSerachData]: any = useState({});
  let [lastClickRow, setLastClickRow]: any = useState({});
  const [data, setData]: any[] = useState([]);
  const [whether, setWhether]: any[] = useState(false);

  const [bank, setBank]: any = useState('1');
  const [spinning, setSpinning]: any = useState(false);


  const columns: any[] = [{
    title: '区域',
    dataIndex: 'provinceName',
    render: ((dom: any, record: any) => {
      return record?.levelId === 1 ? record.provinceName : record?.levelId === 2 ? record.cityName : record?.levelId === 3 ? record.districtName : ''
    })

  }, {
    title: '区域类型',
    dataIndex: 'name',
    render: (text: any, record: any) => {
      return record?.levelId === 1 ? '省级' : record?.levelId === 2 ? '市级' : record?.levelId === 3 ? '区级' : ''
    }

  },
  {
    title: '区域经销商',
    dataIndex: 'name'
  },
  {
    title: '区域人数',
    dataIndex: 'regionNum'
  },
  {
    title: '操作',
    dataIndex: 'regionNum',
    render: (text: any, record: any) => {

      return record?.levelId === 3 ?
        <><Button type="link" onClick={() => {
          localStorage.setItem('dealer', JSON.stringify(record))
          navigate('/rewardMng/rewardconfig/dealerList')
        }} >
          经销商
        </Button>
          <ModalForm
            title="修改经销商"
            width={1000}
            trigger={
              <Tooltip title="修改经销商">
                <Button type="link" onClick={() => setRandomText(new Date().getTime())}>修改</Button>
              </Tooltip>
            }
            preserve={false}
            form={form}
            onFinish={async (data: any) => {
              return new Promise<boolean>((resolve, reject) => {
                if (!data.customerNo) {
                  message.error('请选择绑定的经销商');
                  resolve(false);
                  return false
                }
                let dataparams = {
                  "regionalOwnershipId": record.id,
                  "customerId": data.customerId,
                  "customerNo": data.customerNo,
                  "customerName": data.customerName,
                  "customerPhone": data.customerPhone,
                  "beforeCustomerNo": record.customerNo,
                  "beforeCustomerName": record.name,
                  "beforeCustomerPhone": record.phone
                }
                addRegionOwnerRecordDto(dataparams).then(e => {
                  message.success('修改成功');
                  funC(lastClickRow, 2)
                  record.name = data.customerName
                  resolve(true);
                }).catch(error => {
                  message.error(error?.response?.data?.title);
                  resolve(false);
                })
              });
            }}
          >
            <ModifyDealer key={randomText} regionalOwnershipId={record.id} form={form} />
          </ModalForm>
        </>
        : ''
    }
  }
  ];

  // 根据条件查询数据
  const fetchFindByCondition = async () => {
    setSpinning(true)
    let result: any = await findByCondition({
      'bank.equals': bank === 9 ? 10 : bank,
      "levelId.equals": 1,
      ...serachData
    });

    if (result) {
      setData(result || []);
      setTimeout(() => {
        setSpinning(false)
        setkey(++key)
      }, 1000)


    }

  };


  // 获取第一级别的数据
  const fetchFirstLevelData = async () => {
    let result = await regionalOwnershipResource({
      'bank.equals': bank === 9 ? 10 : bank,
      "levelId.equals": 1
    });
    result = result.map((item: any) => {
      return {
        ...item,
        key: Math.random(),
        children: [{
          "id": 0
        }]
      }
    });
    setkey(++key)
    setData(result);
  };

  const funC = (record: any, levelId: any) => {
    return new Promise<void>((resolve, reject) => {
      regionalOwnershipResource({
        'bank.equals': bank,
        "levelId.equals": record.levelId < 3 ? record.levelId + 1 : '',
        "provinceCode.equals": record.provinceCode,
        "cityCode.equals": record.cityCode || undefined

      }).then((secondLevelData) => {
        console.log(data)
        const newSecondLevelData = secondLevelData.map((e: any) => ({
          ...e,
          children: [{ id: '0' }]
        }));
        const newData = data.map((item: any) => {
          return {
            ...item,
            children: levelId === 1 ? (item.id === record.id ? newSecondLevelData : item.children || []) : (item.code === record.provinceCode ? (item.children.map((ss: any) => {
              return {
                ...ss,
                children: ss.id === record.id ? secondLevelData : ss.children
              }
            })) : item.children || [])
          }
        });

        setData(newData);
      })
    })
  };

  const additionActions = [
    <Space className="status-filter">
      <Tabs defaultActiveKey={bank} onChange={item => {
        console.log(item)
        setBank(item)
      }} activeKey={bank}>
        {storeList.map((item: any) => <TabPane tab={item.label} key={item.value}></TabPane>)}
      </Tabs>
    </Space>
  ];

  useEffect(() => {
    if (whether) {
      fetchFindByCondition()
    } else {
      fetchFirstLevelData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [bank, whether]);

  useEffect(() => {
    if (!serachData["code.equals"] && !serachData["phone.equals"] && !serachData["name.equals"]) {
      fetchFirstLevelData();
      setWhether(false)
    } else {
      setWhether(true)
      fetchFindByCondition();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [serachData]);


  const searchFields: any[] = [{
    label: '授权号',
    name: 'code.equals',
  }, {
    label: '手机号',
    name: 'phone.equals',
  }, {
    label: '姓名',
    name: 'name.equals',
  }]

  const onFinish = (values: any) => {
    console.log(values)
    const filteredArr = {
      "phone.equals": values["phone.equals"] === '' ? undefined : values["phone.equals"],
      'name.equals': values["name.equals"] === '' ? undefined : values["name.equals"],
      "code.equals": values["code.equals"] === '' ? undefined : values["code.equals"]

    }
    setSerachData(filteredArr)
  }

  return (
    <Spin spinning={spinning}>
      <div className="search-wrapper">
        <Form form={form} name="horizontal_login" layout="inline" onFinish={onFinish}>
          <SearchBox
            key={key}
            width={300}
            fields={searchFields}
          />
          <Form.Item label=" " colon={false}>
            <Button type="primary" htmlType="submit">
              搜索
            </Button>
            <Button
              className="ml10"
              onClick={() => {
                form.resetFields();
                onFinish({})
                setkey(++key)
              }}
            >
              重置
            </Button>
          </Form.Item>
        </Form>
      </div>
      {
        <ProTable
          search={false}
          columns={columns}
          toolbar={{
            actions: additionActions
          }}
          key={key}
          dataSource={data}
          rowKey='id'
          pagination={false}
          expandable={{
            onExpand: (expanded, record) => {
              if (!whether) {
                setLastClickRow(record)
                funC(record, record.levelId);
              }
            },
            defaultExpandAllRows: whether
          }}
        />
      }
    </Spin>
  );
};

export default AdvertisementList