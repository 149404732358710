
import React, { useEffect, useState } from "react";
import { Editor, Toolbar } from '@wangeditor/editor-for-react'
import { IDomEditor, IEditorConfig, IToolbarConfig } from '@wangeditor/editor'
import '@wangeditor/editor/dist/css/style.css'
import { message } from "antd";
import { uploadImage } from "utils/file-utils";
interface EditorControlProps {
    value: string;
    onChange: any;
}
const EditorControl = (props: EditorControlProps) => {
    const { value, onChange } = props;
    const [editor, setEditor] = useState<IDomEditor | null>(null);
    // 编辑器内容
    const [html, setHtml] = useState('<></>');
    // 工具栏配置
    const toolbarConfig: Partial<IToolbarConfig> = {};
    const editorConfig: Partial<IEditorConfig> = {
        placeholder: '请输入内容...',
        MENU_CONF: {
            'uploadImage': {
                // 自定义上传
                async customUpload(file: File, insertFn: any) {  // TS 语法
                    // file 即选中的文件
                    // 自己实现上传，并得到图片 url alt href
                    // 最后插入图片
                    const newDate = new Date().getTime();
                    uploadImage(file, 'product/img-' + newDate + '.png').then(imageName => {
                        message.success('图片上传成功');
                        insertFn(imageName);
                    }).catch(() => { message.error('图片上传失败'); });
                }
            }
        }
    };

    useEffect(() => {
        onChange(editor);
        return () => {
            if (editor == null) return
            editor.destroy()
            setEditor(null)
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [editor]);

    useEffect(() => {
        if(editor){
            editor.destroy()
            setEditor(null)
        }
        value && setHtml(value);
        !value && setHtml('<></>');
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value]);

    return (
        <div className="editor-box--1">
            <Toolbar
                editor={editor}
                defaultConfig={toolbarConfig}
                mode="default"
                style={{ borderBottom: '1px solid #ccc' }}
            />
            <Editor
                defaultConfig={editorConfig}
                value={html}
                onCreated={setEditor}
                onChange={editor => setHtml(editor.getHtml())}
                mode="default"
                style={{ height: '500px', overflowY: 'hidden' }}
            />
        </div>
    );
};

export default EditorControl;
