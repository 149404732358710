import axios from 'axios';

const apiUrl = '/api/yumei-mall-common/param';

export const queryParamGroup = async () => {
const requestUrl = `${apiUrl}/group`;
  const res = await axios.get(requestUrl);
  return res.data;
}

export const queryParamGroupForm = async (id: string | number) => {
  const requestUrl = `${apiUrl}/group/${id}`;
    const res = await axios.get(requestUrl);
    return res.data;
}

export const updateParamGroupForm = async (id: string | number, params: any) => {
  const requestUrl = `${apiUrl}/group/${id}`;
    const res = await axios.post(requestUrl, params);
    return res.data;
}

export const queryParamGeneral = async () => {
  const requestUrl = `${apiUrl}/group/general`;
    const res = await axios.get(requestUrl);
    return res.data;
}