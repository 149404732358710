import axios from 'axios';
import { defaultDelivery, Delivery } from 'model/product/delivery.model';
import { CrudPutAction } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'delivery';
const apiUrl = '/api/yumei-mall-pim/ship/charge/template';
const standardReducerImpl = new StandardReducerImpl<Delivery>(entity, defaultDelivery, apiUrl);
export type DeliveryState = StandardState<Delivery>;
export const delivery = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

//运费模版
export const fetchDeliveryList = async (params?: any) => {
    const axiosResponse = await axios.get<any>(`${apiUrl}`, { params: { ...params } });
    return axiosResponse.data;
}

//启用禁用
export const activate: CrudPutAction<any> = entity => async dispatch => {
    const requestUrl = `${apiUrl}/${entity.id}/${entity.type}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl),
    });
};