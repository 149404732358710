import React, { FC, memo, } from 'react';
import { Form, Input, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import '../style/title_text.less';
// import styles from  '../style/title_text.less';
export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const TitleText: FC<Props> = ({ dataField }) => {
    return (
        <div className={`title-text-container ${dataField.pageStyle}`} >
            <p className="title">{dataField.title || '请输入标题'}</p>
            {dataField.desc && <p className="desc" style={
                {
                    color: dataField.descColor
                }
            }>{dataField.desc || ''}</p>}
        </div>
    )
}

export const TitleTextSet: FC<SetProps> = memo(({ dataField, id, methods }) => {
    return (
        <div className="title-text-set" >
            <Form.Item label="标题" >

                <Input.Group compact>
                    <Input style={{ width: '80%' }} onChange={
                        (e) => {
                            let value = e.target.value
                            // console.log(e)
                            methods.updateModuleData({
                                ...dataField,
                                title: value
                            }, id)
                        }
                    } value={dataField.title} placeholder="请输入标题" />

                </Input.Group>
            </Form.Item>
            <Form.Item label="描述" >
                <Input.Group compact>
                    <Input style={{ width: '80%' }} onChange={
                        (e) => {
                            let value = e.target.value
                            // console.log(e)
                            methods.updateModuleData({
                                ...dataField,
                                desc: value
                            }, id)
                        }
                    } value={dataField.desc} placeholder="请输入描述" />
                    <Popover content={
                        <SketchPicker
                            color={dataField.descColor}
                            onChange={
                                (e: any) => {
                                    methods.updateModuleData({
                                        ...dataField,
                                        descColor: e.hex
                                    }, id)
                                }
                            }
                        />
                    } title="" trigger="click">
                        <p className="color-hover" style={
                            {
                                backgroundColor: dataField.descColor
                            }
                        }></p>
                    </Popover>

                </Input.Group>

            </Form.Item>


        </div>
    )
})

export default TitleText

