import React, { ReactNode } from 'react';
import { Form } from 'antd';
import { debounce } from 'lodash';
import {FilterCriteria} from 'components/model/share.model';

export interface AdvanceFilterProps {
  filters?: FilterCriteria;
  // onFilterChange?: (filter: {[string: string]: React.ReactText[] | null}) => void;
  onFilterChange?: (filter: FilterCriteria) => void;
  children?: ReactNode;
}

const AdvanceFilter = (props: AdvanceFilterProps) => {

  const {
    onFilterChange,
    filters,
    children,
  } = props;

  const initialValues = {
    ...filters,
  };

  const handleValuesChange = (changedValues: any, allValues: any) => {
    onFilterChange && onFilterChange(allValues);
  }

  return (
    <Form
      labelAlign="left"
      layout="inline"
      // labelCol={{span: 6}}
      initialValues={initialValues}
      onValuesChange={debounce(handleValuesChange, 500)}
    >
      {children}
    </Form>
  );
}

export default AdvanceFilter;
