import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { useDrop } from 'react-dnd';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import update from 'immutability-helper';
import { LeftOutlined, DeleteOutlined, CopyOutlined, CloseCircleOutlined } from '@ant-design/icons'
import { Button, Image } from 'antd';
import './common.less';
import mobileBg from '../../../assets/images/mobile-bg.jpg'
import { updatePageConfig, updateInsertPositionId, updateMoreState, deleteModule, insertModule } from '../platform/platform-edit.reducer'
import ImageCarousel from './modules/image_carousel';
import TitleText from './modules/title_text';
import ActiveGroup from './modules/active-group';
import ActiveSeckill from './modules/active-seckill';
import AuxiliaryBlank from './modules/auxiliary_blank';
import AuxiliaryLine from './modules/auxiliary_line';
import ClassificationList from './modules/classification_list';
import ColumnNav from './modules/column-nav';
import CouponScroll from './modules/coupon-scroll';
import CustomArea from './modules/custom_area';
import GoodsCube from './modules/goods-cube';
import GoodsScroll from './modules/goods-scroll';
import Goods from './modules/goods';
import ImageAd from './modules/image_ad';
import ListNav from './modules/list_nav';
import Notice from './modules/notice';
import PageTab from './modules/page_tab';
import RecommendationList from './modules/recommendation_list';
import Shop from './modules/shop';
import TabNav from './modules/tab_nav';
import Search from './modules/search';
import Popup from './modules/popup';
import MainMeeting from './modules/main_meeting';
import TingmimiHot from './modules/tingmimi_hot';
import ImageCarouselFill from './modules/image_carousel_fill';
import GoodsCubeFill from './modules/goods-cube-fill';
import ImageArea from './modules/image_area';
import FloatingFrame from './modules/floating-frame';
import CollageListNav from './modules/collage_list_nav';
interface PagePreviewProps extends StateProps, DispatchProps { }

interface ModulesContainProps {
  module?: any
  onDrop: (item: any) => void
  id: string
  insertPositionId: string
  updatePageConfigPrivate: () => void
  findModulePrivate: (id: string) => { module: any, index: number }
  moveModulePrivate: (dragId: string, index: number) => void
  moveAtIndex?: number
  updateInsertPositionId: (id: string) => void
  updateMoreState: (props: { [key: string]: any }) => void
  settingModuleId?: string
  deleteModule: (id: any) => void
  copyModule: (id: any) => void
  moduleData: any
  type: string //当前模块类型
}

const onDrop = (moduleData: any, dragInfo: any) => {

}

const ModuleContain: FC<ModulesContainProps> = memo(({ module, onDrop, type, id, insertPositionId, updatePageConfigPrivate, findModulePrivate, moveModulePrivate, moveAtIndex, updateInsertPositionId, updateMoreState, settingModuleId, deleteModule, copyModule }) => {
  // const [isHover,setHoverState] = useState(false)
  const operationTopType = [
    'goods',
    'list_nav',
    'main_meeting',
    'tingmimi_hot',
    'image_area'
  ]
  const [{ isOver }, drop] = useDrop({
    accept: ['all'],
    drop: onDrop,
    collect: (monitor) => ({
      isOver: monitor.isOver(),
      canDrop: monitor.canDrop(),
    }),
    canDrop: () => false,
    hover(props) {
      //组件位置调整
      if (props.id && props.id !== id) {
        const { index } = findModulePrivate(id);
        moveModulePrivate(props.id, index)
      }
      //新增组件
      if (props.title && props.defaultConfig && insertPositionId !== id) {
        updateInsertPositionId(id)
      }
    },
  }, [onDrop, id, findModulePrivate, moveModulePrivate])
  useEffect(() => {
    // console.log('isOver',isOver,id,insertPositionId)
    if (!isOver && id === insertPositionId) {
      updateInsertPositionId('-1')
    }
  }, [isOver, id, insertPositionId, updateInsertPositionId])
  // const [{ isDragging }, drag] = useDrag(() => ({
  //   type: 'all',
  //   item: { id },
  //   end: (item, monitor) => {
  //     const dropResult = monitor.getDropResult<DropResult>()
  //     if (item && dropResult && moveAtIndex !== -1) {
  //       updatePageConfigPrivate()
  //     }
  //   },
  //   collect: (monitor) => ({
  //     isDragging: monitor.isDragging(),
  //     handlerId: monitor.getHandlerId(),
  //   }),
  // }), [id, moveAtIndex, moveModulePrivate])


  const dataField = module?.props?.dataField;
  dataField && delete dataField.top;

  // ${type === 'floating_frame' ? 'floating_frame' : ''}
  return <div className={`module-item  ${settingModuleId === id ? 'selected' : ''} ${insertPositionId === id ? 'hover' : ''}`} onClick={
    () => {
      updateMoreState({
        settingModuleId: (settingModuleId === id ? 'none' : id)
      })
    }
  } ref={(node) => (drop(node))} 
  style={(type === 'floating_frame' && dataField && dataField.img) ? {
    ...dataField,
    position: 'absolute',
    'z-index': '1'
  } : {}}
  >
    <div className="hover-border"></div>

    {module}
    <div className={`operation-con ${operationTopType.indexOf(type) !== -1 ? 'top' : ''}`}>
      <Button className="copy icon-opera" onClick={(e) => {
        copyModule(id)
        e.stopPropagation();
      }} type="primary" shape="circle" icon={<CopyOutlined />} ></Button>

      <Button className="delete icon-opera" onClick={(e) => {
        deleteModule(id)
        e.stopPropagation();
      }} type="primary" shape="circle" icon={<DeleteOutlined />} ></Button>

    </div>
  </div>
})
const PagePreview: FC<PagePreviewProps> = memo(({ pageData, pageConfig, popupData, insertPositionId, updatePageConfig, updateInsertPositionId, updateMoreState, settingModuleId, deleteModule, insertModule }) => {
  const [pageConfigPrivate, setPageConfigPrivate] = useState(pageConfig)
  const [configDataField, setConfigDataField] = useState({} as any)
  const [moveAtIndex, setMoveMoveAtIndex] = useState(-1)
  const findModulePrivate = useCallback(
    (id: string) => {
      const module = pageConfigPrivate.filter((c: any) => `${c.id}` === id)[0]
      return {
        module,
        index: pageConfigPrivate.indexOf(module)
      }
    },
    [pageConfigPrivate],
  )
  const moveModulePrivate = useCallback(
    (dropId: string, index: number) => {
      const { module, index: originIndex } = findModulePrivate(dropId)
      setMoveMoveAtIndex(index)
      // console.log('index',index,'originIndex',originIndex)
      setPageConfigPrivate(
        update(pageConfigPrivate, {
          $splice: [
            [originIndex, 1,],
            [index, 0, module]
          ]
        })
      )
    },
    [pageConfigPrivate, findModulePrivate, setMoveMoveAtIndex, setPageConfigPrivate]
  )
  const copyModule = useCallback(
    (id:any) => {
      const { module } = findModulePrivate(id)

      updateInsertPositionId(id)
      insertModule({
        type: module.type,
        defaultConfig: module.dataField,
        isCopy:true
      })

    },
    [updateInsertPositionId, insertModule, findModulePrivate]
  );
  const getModules = (v: any, i: any) => {
    switch (v.type) {
      case 'image_carousel':
        return <ImageCarousel dataField={v.dataField} index={i} key={i} nextModule={pageConfigPrivate[i + 1]} />
      case 'image_carousel_fill':
        return <ImageCarouselFill dataField={v.dataField} index={i} key={i} nextModule={pageConfigPrivate[i + 1]} />
      case 'title_text':
        return <TitleText dataField={v.dataField} index={i} key={i} />
      case 'active-group':
        return <ActiveGroup dataField={v.dataField} index={i} key={i} />
      case 'active-seckill':
        return <ActiveSeckill dataField={v.dataField} index={i} key={i} />
      case 'auxiliary_blank':
        return <AuxiliaryBlank dataField={v.dataField} index={i} key={i} />
      case 'auxiliary_line':
        return <AuxiliaryLine dataField={v.dataField} index={i} key={i} />
      case 'classification_list':
        return <ClassificationList dataField={v.dataField} index={i} key={i} />
      case 'column-nav':
        return <ColumnNav dataField={v.dataField} index={i} key={i} />
      case 'coupon-scroll':
        return <CouponScroll dataField={v.dataField} index={i} key={i} />
      case 'custom_area':
        return <CustomArea dataField={v.dataField} index={i} key={i} />
      case 'goods-cube':
        return <GoodsCube dataField={v.dataField} index={i} key={i} />
      case 'goods-cube-fill':
        return <GoodsCubeFill dataField={v.dataField} index={i} key={i} />
      case 'goods-scroll':
        return <GoodsScroll dataField={v.dataField} index={i} key={i} />
      case 'goods':
        return <Goods dataField={v.dataField} index={i} key={i} preModule={pageConfigPrivate[i - 1]} />
      case 'image_ad':
        return <ImageAd dataField={v.dataField} index={i} key={i} />
      case 'list_nav':
        return <ListNav dataField={v.dataField} config={configDataField} index={i} key={i} />
      case 'collage_list_nav':
        return <CollageListNav dataField={v.dataField} config={configDataField} index={i} key={i} />
      case 'notice':
        return <Notice dataField={v.dataField} index={i} key={i} />
      case 'page_tab':
        return <PageTab dataField={v.dataField} index={i} key={i} />
      case 'recommendation_list':
        return <RecommendationList dataField={v.dataField} index={i} key={i} />
      case 'shop':
        return <Shop dataField={v.dataField} index={i} key={i} />
      case 'tab_nav':
        return <TabNav dataField={v.dataField} index={i} key={i} />
      case 'search':
        return <Search dataField={v.dataField} index={i} key={i} />
      case 'popup':
        return <Popup dataField={v.dataField} index={i} key={i} />
      case 'floating_frame':
        return <FloatingFrame dataField={v.dataField} index={i} key={i} />
      case 'main_meeting':
        return <MainMeeting dataField={v.dataField} index={i} key={i} />
      case 'tingmimi_hot':
        return <TingmimiHot dataField={v.dataField} index={i} key={i} />
      case 'image_area':
        return <ImageArea dataField={v.dataField} index={i} key={i} />
      case 'config':
        return ''
      default:
        return <div className="holder">Holder</div>
    }
  }
  const updatePageConfigPrivate = useCallback(() => {
    updatePageConfig(pageConfigPrivate)
  }, [pageConfigPrivate, updatePageConfig])
  useEffect(() => {
    let configModule = pageConfig.filter((v: any) => v.type === 'config')
    if (configModule.length > 0) {
      setConfigDataField(configModule[0].dataField)
    }
    setPageConfigPrivate(pageConfig)

  }, [pageConfig, configDataField, setConfigDataField])
  const [, drop] = useDrop(() => ({ accept: 'all' }))
  return <div className="page-preview">
    <div className={`page-view `} >
      <img src={mobileBg} alt="" className="mobile-bg" />
      <div className="header">
        <div className="con">
          <LeftOutlined className="back" style={{ 'fontSize': '20px', 'color': '#888' }} />
          <p className="txt text-1">{configDataField.title}</p>
        </div>
      </div>
      <div className={`page-container ${popupData && popupData.img ? 'dis-scroll' : ''}`} style={
        {
          'backgroundColor': configDataField?.bgColor || '#f5f5f5'
        }
      } ref={drop}>
        {/* 弹窗预览 */}
        {
          popupData && popupData.img && !popupData.type && <div className="popup-preview">
            <div className="popup-mask" onClick={
              () => {
                updateMoreState({
                  popupData: {}
                })
              }
            }>
              <div className="img-con" style={
                {
                  width: '100%',
                  display: 'flex',
                  justifyContent: 'center',
                  flexDirection: 'column',
                  top: 0,
                  height: '100%',
                }
              } onClick={
                (e) => {
                  e.stopPropagation()
                }
              }>
                <div className="img" >
                  <Image preview={false} src={popupData.img + '?imageView2/2/w/600'} />
                </div>
                <div className="close" onClick={
                  () => {
                    updateMoreState({
                      popupData: {}
                    })
                  }
                }>
                  <CloseCircleOutlined className="icon" />
                </div>

              </div>
            </div>
          </div>
        }
        {/* 浮标预览 */}
        {
          popupData && popupData.img && popupData.type && <div className="popup-preview">
            <div className="popup-mask" onClick={
              () => {
                updateMoreState({
                  popupData: {}
                })
              }
            }>
              <div className="img-con" style={
                {
                  top: 'auto',
                  left: 'auto',
                  transform:'none',
                  bottom:popupData.bottom + 'px',
                  width:popupData.width+'px',
                  right:popupData.right + 'px',
                }
              } onClick={
                (e) => {
                  e.stopPropagation()
                }
              }>
                <div className="img" >
                  <Image preview={false} src={popupData.img + '?imageView2/2/w/600'} />
                </div>
              </div>
            </div>
          </div>
        }
        {/* 主要模块渲染 */}
        {
          
          pageConfigPrivate?.map((v: any, i: number) => {
            
            v.dataField.pageStyle = configDataField.style || ''
            return <ModuleContain
              moveAtIndex={moveAtIndex}
              updateInsertPositionId={updateInsertPositionId}
              findModulePrivate={findModulePrivate}
              moveModulePrivate={moveModulePrivate}
              insertPositionId={insertPositionId}
              key={v.id}
              type={v.type}
              updatePageConfigPrivate={updatePageConfigPrivate}
              updateMoreState={updateMoreState}
              id={v.id} module={getModules(v, i)}
              onDrop={(item) => onDrop(v, item)}
              settingModuleId={settingModuleId}
              moduleData={v}
              deleteModule={deleteModule}
              copyModule={copyModule}
            />
          })
        }
        {insertPositionId === '-1' && <div className="insert-box"></div>}
{/* 
        {
        (`${pageData.pageType}` === '1' || `${pageData.pageType}` === '11') && <div className="footer" >
          <div className="con">
            <div className="item active">
              <img src={tabbar.homeSel} alt="" />
              <p className="title">商城</p>
            </div>
            <div className="item ">
              <img src={tabbar.live} alt="" />
              <p className="title">直播</p>
            </div>
            <div className="item ">
              <img src={tabbar.shortVideo} alt="" />
              <p className="title">短视频</p>
            </div>
            <div className="item ">
              <img src={tabbar.account} alt="" />
              <p className="title">个人中心</p>
            </div>
          </div>
        </div>
      } */}
      </div>
      

    </div>

  </div>
})
const mapStateToProps = ({ decorateEdit }: RootState) => ({
  pageConfig: decorateEdit.pageConfig,
  pageData: decorateEdit.pageData,
  insertPositionId: decorateEdit.insertPositionId,
  settingModuleId: decorateEdit.settingModuleId,
  popupData: decorateEdit.popupData,

});
const mapDispatchToProps = {
  updatePageConfig,
  updateMoreState,
  updateInsertPositionId,
  deleteModule,
  insertModule
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(PagePreview);
