import { FC, ReactNode, useState, } from "react";
import { Form, Input, Image, Button, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import TrainListDetails from "./trainList-details";
import { deleteEntity, getEntities, getEntity, showHideMeeting } from "./trainList.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import TrainListEdit from "./trainList-edit";
import { TrainList } from "model/train/trainList.model";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { ForceUpdate } from "model/system/version.model";

export interface TrainListListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const TrainListList: FC<TrainListListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const [refreshFlag,setRefreshFlag] = useState(0);

  const columns: ProColumns<TrainList, 'trainType'>[] = [{
    title: 'id',
    dataIndex: 'id',
    width: 60,
    hideInDescriptions: true,
  }, {
    title: 'banner',
    dataIndex: 'coverPicUrl',
    width: 80,
    hideInDescriptions: true,
    render: (dom, record) => {
      return <Image style={{ width: '80px' }} src={record.coverPicUrl} />
    }
  }, {
    title: '标题',
    dataIndex: 'name',
    width: 100,
  }, {
    title: '会议类型',
    dataIndex: 'meetType',
    valueType: 'trainType',
    width: 100,
  }, {
    title: '报名开始时间',
    dataIndex: 'startTime',
    width: 100,
  }, {
    title: '报名结束时间',
    dataIndex: 'endTime',
    width: 100,
  }, {
    title: '签到开始时间',
    dataIndex: 'signTime',
    width: 100,
  }, {
    title: '签到结束时间',
    dataIndex: 'signEndTime',
    width: 100,
  }, {
    title: '签到地点',
    dataIndex: 'address',
    hideInDescriptions: true,
    width: 120,
  }, {
    title: '报名人数',
    dataIndex: 'participantNumber',
    width: 100,
    render: (dom, record) => <p><span style={{ color: '#1890ff' }}>{record.applicationNumber}</span> / {record.participantNumber}</p>
  }, {
    title: '取消时效 (天)',
    dataIndex: 'cancelTimeDay',
    width: 100,
  }, {
    title: '创建时间',
    dataIndex: 'createdDate',
    width: 100,
  }, {
    title: '是否隐藏',
    dataIndex: 'isHidden',
    width: 100,
    valueEnum: ForceUpdate,
  }];
  const searchFields = [{
    label: '会议标题',
    name: 'name.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="meetType.equals"
          options={convertEnumToOptionType('trainType')}
          placeholder="会议类型"
        />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions: any = [(dom: any, record: any) => {
    const title = !record.isHidden ? '隐藏后用户端可报名培训会列表将不显示该场培训会，已报名列表仍可见。确认隐藏该场会议吗？' : '显示后用户端可报名培训列表将显示该场培训会，确认显示该场会议吗？'
    return <ModalForm
      title={record.isHidden ? '展示会议' : '隐藏会议'}
      width={400}
      trigger={
        <Button
          type="link"
          size="small"
        >{record.isHidden ? '展示' : '隐藏'}</Button>
      }
      preserve={false}
      onFinish={async () => {
        showHideMeeting(record.id, !record.isHidden).then((e: any) => {
          message.success('操作成功');
        }).catch((error: any) => {
          message.error(error?.response?.data?.title);
        });
        setRefreshFlag(new Date().getTime())
        return true;
      }}
    >
      {title}
    </ModalForm>
  }]

  return (
    <ListViewPage<TrainList, 'trainType'>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<TrainListDetails text={new Date().getTime()} navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      refreshFlag={refreshFlag}
      rowActions={rowActions}
      editPage={(params: any) => <TrainListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ trainList }: RootState) => ({
  entity: trainList.entity,
  entities: trainList.entities,
  loading: trainList.loading,
  updateSuccess: trainList.updateSuccess,
  createSuccess: trainList.createSuccess,
  total: trainList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TrainListList);