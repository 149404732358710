import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ExchangeGoodsDetails from './exchangeGoods-details';
import ExchangeGoodsEdit from './exchangeGoods-edit';
import ExchangeGoodsList from './exchangeGoods-list';

const ExchangeGoods: React.FC<RouteComponentProps> = () => (
  <Router>
    <ExchangeGoodsList path="/" />
    <ExchangeGoodsDetails path=":id" />
    <ExchangeGoodsEdit path=":id/edit" />
    <ExchangeGoodsEdit path="new" />
  </Router>
);
export default ExchangeGoods;
