import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './resources.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Resources } from "model/system/resources.model";
import { Tag, Typography } from "antd";
import ProTable from "@ant-design/pro-table";
const { Title } = Typography;

export interface ResourcesDetailsProps extends DetailsStateAndDispatchProps<Resources>,
  RouteComponentProps, StateProps, DispatchProps {
}

const columns: any = [{
  title: '角色id',
  dataIndex: ['role', 'id'],
}, {
  title: '角色code',
  dataIndex: ['role', 'code'],
}, {
  title: '角色名称',
  dataIndex: ['role', 'name'],
}, {
  title: '读取',
  dataIndex: ['read'],
  render: (e: boolean) => e ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
}, {
  title: '编辑',
  dataIndex: ['write'],
  render: (e: boolean) => e ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
}, {
  title: '删除',
  dataIndex: ['delete'],
  render: (e: boolean) => e ? <Tag color="green">允许</Tag> : <Tag color="red">禁止</Tag>
}];

const ResourcesDetails: FC<ResourcesDetailsProps> = (props) => {
  return (
    <DetailsPage<Resources>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Resources>
        columns={props.columns as any}
        dataSource={props.entity}
        column={1}
      >
      </ProDescriptions>
      <Title style={{ marginTop: '20px' }} level={5}>资源关联的角色</Title>
      <ProTable<any>
        search={false}
        toolBarRender={false}
        dataSource={props.entity.permissions}
        columns={columns}
      />
    </DetailsPage>);
};

const mapStateToProps = ({ resources }: RootState) => ({
  entity: resources.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ResourcesDetails);
