import { defaultPowerModule, PowerModule } from '../../../model/system/power-module.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';

const entity = 'module';
const apiUrl = '/api/yumei-mall-auth/module';
const standardReducerImpl = new StandardReducerImpl<PowerModule>(entity, defaultPowerModule, apiUrl);
export type powerModuleState = StandardState<PowerModule>;
export const powerModule = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchModuleList = async (params: any) => {
    const moduleData = await axios.get(apiUrl, { params });
    return moduleData.data || [];
};