import axios from 'axios';
import { ExcellentStore, defaultExcellentStore } from 'model/dealerStore/excellentStore.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'physicalStores';
const apiUrl = '/api/yumei-mall-store/excellent-physical-apply';
const standardReducerImpl = new StandardReducerImpl<ExcellentStore>(entity, defaultExcellentStore, apiUrl);
export type ExcellentStoreState = StandardState<ExcellentStore>;
export const excellentStore = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 审核
export const auditRefuse = async (entity:any) => {
  const requestUrl = `${apiUrl}/${entity.id}/audit`;
  const axiosResponse = await axios.post<any>(requestUrl, entity);
  return axiosResponse;
}

// 审核
export const downgrading = async (data:any,url:string) => {
  const requestUrl = `/api/yumei-mall-store/${url}/out`;
  const axiosResponse = await axios.post<any>(requestUrl, data);
  return axiosResponse;
}

// 店铺类型
export const storeTypeMap:any = {
  PhysicalStores: '实体店',
  ExcellentPhysicalStore: "优秀实体店",
  LifePavilion: "生活馆"
}

// 店铺状态
export const statusMap:any = {
  Created: '待审核',
  AuditSuccess: "审核通过",
  AuditFail:"审核失败",
  Checked:"提交验收",
  CheckSuccess:"验收通过",
  CheckFail:"验收失败",
  Complete:"验收完成"
}

// 店铺状态
export const statusList:any = [
  {label: '待审核', value: 'Created'},
  {label: '审核通过', value: 'AuditSuccess'},
  {label: '审核失败', value: 'AuditFail'},
  {label: '提交验收', value: 'Checked'},
  {label: '验收通过', value: 'CheckSuccess'},
  {label: '验收失败', value: 'CheckFail'},
  {label: '验收完成', value: 'Complete'}
]


