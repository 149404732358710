import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RateDetails from './rate-details';
import RateEdit from './rate-edit';
import RateList from './rate-list';

const Rate: React.FC<RouteComponentProps> = () => (
  <Router>
    <RateList path="/" />
    <RateDetails path=":id" />
    <RateEdit path=":id/edit" />
    <RateEdit path="new" />
  </Router>
);
export default Rate;
