import { FC, ReactNode } from "react";
import { Form, Input, } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SummaryListDetails from "./summary-details";
import { deleteEntity, getEntities, getEntity } from "./summary.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SummaryListEdit from "./summary-edit";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";
import ProFormDatePickerMonth from "@ant-design/pro-form/lib/components/DatePicker/MonthPicker";
import { SummaryList, SummaryStatus } from "model/bonus/summaryList.model";

export interface SummaryListListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const SummaryListList: FC<SummaryListListProps> = (props) => {

  const columns: ProColumns<SummaryList>[] = [{
    title: '店铺id',
    dataIndex: 'storeId',
    width: 100,
  }, {
    title: '店铺名称',
    dataIndex: 'storeName',
    width: 100,
  }, {
    title: '会员id',
    dataIndex: 'customerId',
    hideInTable:true,
    width: 100,
  }, {
    title: '会员code',
    dataIndex: 'customerCode',
    width: 100,
  }, {
    title: '会员名',
    dataIndex: 'customerName',
    width: 100,
  }, {
    title: '会员Level',
    dataIndex: 'customerLevel',
    hideInTable:true,
    width: 100,
  }, {
    title: '统计周期',
    dataIndex: 'period',
    width: 100,
  },  {
    title: '价差金额',
    dataIndex: 'gapAmount',
    width: 100,
  },  {
    title: '奖金金额',
    dataIndex: 'rewardAmount',
    width: 100,
  },  {
    title: '应付奖金金额',
    dataIndex: 'payoutRewardAmount',
    width: 100,
  },  {
    title: '冻结金额',
    dataIndex: 'totalFrozenAmount',
    width: 100,
  },  {
    title: '推荐奖金额',
    dataIndex: 'recommendAmount',
    width: 100,
  },  {
    title: '区域奖',
    dataIndex: 'regionAmount',
    width: 100,
  },  {
    title: '推荐6s分红',
    dataIndex: 'recommend6sAmount',
    width: 100,
  },  {
    title: '冻结推荐奖金额',
    dataIndex: 'frozenRecommendAmount',
    width: 100,
  },  {
    title: '冻结区域奖',
    dataIndex: 'frozenRegionAmount',
    width: 100,
  },  {
    title: '冻结推荐6s分红',
    dataIndex: 'frozenRecommend6sAmount',
    width: 100,
  },  {
    title: '历史货款-推荐奖金额',
    dataIndex: 'oldRecommendAmount',
    width: 100,
  },  {
    title: '历史货款-推荐奖金额-冻结',
    dataIndex: 'oldFrozenRecommendAmount',
    width: 100,
  },  {
    title: '历史货款-区域奖',
    dataIndex: 'oldRegionAmount',
    width: 100,
  },  {
    title: '历史货款-区域奖-冻结',
    dataIndex: 'oldFrozenRegionAmount',
    width: 100,
  },  {
    title: '历史货款-推荐6s分红',
    dataIndex: 'oldRecommend6sAmount',
    width: 100,
  },  {
    title: '历史货款-推荐6s分红-冻结',
    dataIndex: 'oldFrozenRecommend6sAmount',
    width: 100,
  }, {
    title: '状态',
    dataIndex: 'status',
    valueEnum: SummaryStatus,
    width: 100,
  }]

  const searchFields = [{
    label: '会员code',
    name: 'customerCode',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch required={false} name="storeId" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <ProFormDatePickerMonth
          name={'period.YM'}
          placeholder="统计月份"
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<SummaryList>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<SummaryListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideDeleteButton
      hideCreateButton
      hideEditButton
      hideActionColumn
      editPage={(params: any) => <SummaryListEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ summaryList }: RootState) => ({
  entity: summaryList.entity,
  entities: summaryList.entities,
  loading: summaryList.loading,
  updateSuccess: summaryList.updateSuccess,
  createSuccess: summaryList.createSuccess,
  total: summaryList.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SummaryListList);