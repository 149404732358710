import { ModalForm, ProFormDependency, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { Col, Form, Row, message, Button, Table } from "antd";
import { payerCodeLevel } from '../bankStatement/bankStatement.reducer'
import { internationalList } from "components/IntelFormText";
import { useEnumValue } from "common/enum/use-enum-value";
import { fetchLevelList } from "pages/dealer/unit/unit.reducer";
import { addNewAccount } from "./fund.reducer";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel, onRecharge } = props
    const [form] = Form.useForm();
    let [payerCodeLevelList, setPayerCodeLevelList] = useState<any>([]);
    let [disabled, setDisabled] = useState(false);
    const { convertEnumToOptionType } = useEnumValue();

    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 7 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 17 },
        },
    };


    useEffect(() => {
        setDisabled(false)
        setPayerCodeLevelList([]);
    }, [visible]);



    // 确认正确 confirm
    const confirmCorrectness = () => {
        let fromData = form.getFieldsValue()
        if (!fromData.payerCode) {
            message.error('请输入付款人授权码')
            return false
        }
        setDisabled(!disabled)
        if (!disabled) {
            fromData.id = record.id
            payerCodeLevel({
                payerCode: fromData.payerCode,
            }).then((e: any) => {
                let data = [...e.data]
                if (data.length > 0) {
                    data.forEach((item: any) => {
                        item.key = Math.random()
                    })
                }
                setPayerCodeLevelList(data || [])
            }).catch((e: any) => {

            })
        }

    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            let data = selectedRows[0]
            form.setFieldsValue({ payeeCode: data.payeeCode })
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User',
            name: record.name,
        }),
    };

    const columns: any = [
        {
            title: '店铺名称',
            dataIndex: 'storeName'
        },
        {
            title: '等级名称',
            dataIndex: 'levelName',
            render: (text: string) => { return internationalList(text) },
        },
        {
            title: '申请中ID',
            dataIndex: 'applicationId',
        },
        {
            title: '收款方类型',
            dataIndex: 'payeeType',
            render: (text: string) => {
                return text === 'Platform' ? '店铺' : text === 'Distributor' ? '经销商' : ''
            }
        },
        {
            title: '收款码',
            dataIndex: 'payeeCode',
        },
    ];

    return (
        <ModalForm
            title="新增账户"
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={820}
            form={form}
            {...formItemLayout}
            preserve={false}
            onFinish={async (data: any) => {
                let fromData = form.getFieldsValue()
                let payeeCode = form.getFieldValue('payeeCode')
                if (!fromData.payerCode) {
                    message.error('请输入付款人授权码')
                    return false
                }
                if (!disabled) {
                    message.error('请先确认正确')
                    return false
                }
                console.log(data)
                if (!payeeCode) {
                    message.warn('请选择店铺')
                    return false
                }
                if (!fromData.accountType) {
                    message.warn('请选择账户类型')
                    return false
                }
                if (fromData.accountType === 'CashDeposit' && !fromData.cashDepositRuleId) {
                    message.warn('请选择保证金类型')
                    return false
                }
                if (!fromData.paymentType) {
                    message.warn('请选择账户等级')
                    return false
                }
                addNewAccount(data).then(e => {
                    onCancel()
                    onRecharge()
                    return true;
                }).catch((e) => {
                    message.error(e.response.data.message);
                })
            }}

        >
            <ProFormText width={'sm'} hidden name='payeeCode' label="收款人授权码"></ProFormText>
            <Row>
                <ProFormText width={'sm'} required={true} name='payerCode' label="付款人授权码"
                    fieldProps={{
                        onChange: (e) => {
                            // form.setFieldsValue({ payerCode: '' })
                            // form.setFieldsValue({ paymentType: '' })
                            setPayerCodeLevelList([])
                        }
                    }} disabled={disabled} placeholder='付款人授权码' />
                <Button type="link" size="small" style={{ marginLeft: '-70px' }} onClick={confirmCorrectness}>{disabled ? '取消确认' : '确认正确'}</Button>
            </Row>
            <Row style={{ marginBottom: '20px' }}>
                <Col span={24}>
                    <Table
                        pagination={false}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={payerCodeLevelList}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <ProFormSelect
                        required
                        name="accountType"
                        options={convertEnumToOptionType('bankRollAccountType')}
                        placeholder="账户类型"
                        label='账户类型'
                    />
                </Col>

                <ProFormDependency name={['accountType']}>
                    {({ accountType }) => {
                        console.log(accountType)
                        return accountType === 'CashDeposit' && <>
                            <Col span={1}></Col>
                            <Col span={11}>
                                <ProFormSelect
                                    required={true}
                                    options={[{
                                        label: '保证金',
                                        value: 4
                                    }]}
                                    name="cashDepositRuleId"
                                    label="保证金"
                                    placeholder="保证金"
                                />
                            </Col>
                        </>
                    }}
                </ProFormDependency>
                <Col span={1}></Col>
                <Col span={11}>
                    <ProFormSelect
                        required={true}
                        request={async () => {
                            const unitRequest: any = await fetchLevelList();
                            return unitRequest.map((item: any) => ({
                                value: item.levelNo,
                                label: internationalList(item.levelName)
                            }))
                        }}
                        name="paymentType"
                        label="账户等级"
                        placeholder="账户等级"
                    />
                </Col>
            </Row>

        </ModalForm >
    )
}

export default RechargeModel