import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Popconfirm, Tooltip, message } from 'antd';
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import { deleteEntity, getEntities, getEntity, unbindWechat } from "./wechatbind.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";


export interface WechatbindListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<any>[] = [{
  title: '客户名称',
  dataIndex: 'customerName',
  width: 200
},{
  title: '授权码',
  dataIndex: 'account',
  width: 200
},{
  title: '昵称',
  dataIndex: 'nickname'
}];


const WechatbindList: FC<WechatbindListProps> = (props) => {
  let [key, setKey] = useState<any>(0);
  const searchFields = [{
    label: '授权码',
    name: 'account',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );


  const unbindButtonRender: any = (dom: ReactNode, record: any) => {
    return <Tooltip title="解绑">
       <Popconfirm title={`你确定要解绑“${ record.customerName }”吗？`}  onConfirm={() => {
        console.log('确定')
        unbindWechat(record.id).then((e)=> {
            message.success('解绑成功')
            setKey(++key)
        }).catch((e)=> {
          message.error(e.response.data.message)
        })
       }}>
      <Button type="link" size="small" >解绑</Button>
      </Popconfirm>
    </Tooltip>
  };


  return (
    <ListViewPage<any>
      key={key}
      columns={columns}
      actionColumnWidth={80}
      hideCreateButton
      hideDeleteButton
      hideEditButton
      quickFilterPanel={quickFilterPanel}
      detailsIndex={false}
      sort='lastModifiedDate,desc'
      rowActions={[unbindButtonRender]}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ wechatbind }: RootState) => ({
  entity: wechatbind.entity,
  entities: wechatbind.entities,
  loading: wechatbind.loading,
  updateSuccess: wechatbind.updateSuccess,
  createSuccess: wechatbind.createSuccess,
  total: wechatbind.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WechatbindList);