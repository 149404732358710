import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RetailDetails from './retail-details';
import RetailEdit from './retail-edit';
import RetailList from './retail-list';

const Retail: React.FC<RouteComponentProps> = () => (
  <Router>
    <RetailList path="/" />
    <RetailDetails path=":id" />
    <RetailEdit path=":id/edit" />
    <RetailEdit path="new" />
  </Router>
);
export default Retail;
