import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultProduct, Product, UpUnderPim } from 'model/product/product.model';
import {
  CrudDeleteBatchAction,
  CrudPutAction,
  CrudUploadAction,
  QueryParameter
} from "reducers/redux-action.type";
import axios from "axios";
import { Page } from "model/page.model";

const entity = 'product';
const apiUrl = '/api/yumei-mall-pim/product';
const standardReducerImpl = new StandardReducerImpl<Product>(entity, defaultProduct, apiUrl);
export type ProductState = StandardState<Product>;
export const product = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const copyEntity = standardReducerImpl.copyEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const getEntityData = async (id?: string | number) => axios.get<any>(`${apiUrl}/${id}`);



//库存单位
export const fetchUnitList = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-pim/brand/authorization/application`;
  const axiosResponse = await axios.get<any>(requestUrl, { params: { ...params, size: 100 } });
  return axiosResponse.data;
}


// 查询价格
export const fetchPriceQuery = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-pim/product/${params.productId}/calculation/price/sku`;
  const axiosResponse = await axios.get<any>(requestUrl);
  return axiosResponse;
}


// 商品搜索接口
export const searchProduct = async (params?: QueryParameter): Promise<Page<Product>> => {
  const {
    page = 0,
    keywords,
    ...rest
  } = params || {};
  const response = await axios.get<any>(apiUrl, {
    params: {
      page: page - 1,
      'status.in': [10, 20, 25, 30],
      ...rest
    }
  });
  return {
    total: parseInt(response.headers['x-total-count'], 10),
    data: response.data,
  };
};

// 商品数据
export const fetchProduct = async (params?: QueryParameter): Promise<Page<Product>> => {
  const response = await axios.get<any>(apiUrl, {
    params: { ...params }
  });
  return response.data
};

export const fetchRedeemList = async (params: QueryParameter): Promise<any> => {
  const response = await axios.get<any>(`${apiUrl}/redeem/${params.id}`, {
    params: { ...params }
  });
  return response
};

export const AddRedeem = async (params: QueryParameter): Promise<any> => {
  const response = await axios.post<any>(`${apiUrl}/redeem/${params.id}/add`, { ...params });
  return response
};

export const DeleteRedeem = async (params: QueryParameter): Promise<any> => {
  const response = await axios.delete<any>(`${apiUrl}/redeem/${params.id}`);
  return response
};

export const fetchStoreBrandList = async (params?: any) => {
  const requestUrl = params?.storeId ? `/api/yumei-mall-pim/brand?page=0&size=100&storeId.equals=${params?.storeId}`:  `/api/yumei-mall-pim/brand?page=0&size=100`
  const axiosResponse = await axios.get<any>(requestUrl, params);
  return axiosResponse.data;
}

export const fetchStoreBrandById = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-store/seller/brand-list/${params?.storeId}`
  const axiosResponse = await axios.get<any>(requestUrl, params);
  return axiosResponse.data;
}




export const searchProductSku = async (params?: QueryParameter): Promise<Page<Product>> => {
  const {
    page = 0,
    keywords,
    ...rest
  } = params || {};
  const response = await axios.get<any>(`${apiUrl}/sku`, {
    params: {
      page: page - 1,
      'status.in': [10,30,50],
      ...rest
    }
  });
  return {
    total: parseInt(response.headers['x-total-count'], 10),
    data: response.data,
  };
};

export const searchManualProductSku = async (params?: QueryParameter): Promise<Page<Product>> => {
  const response = await axios.post<any>(`/api/yumei-mall-pim/oms/product`, {
    ...params
  });
  return {
    total: response.data?.length || 0,
    data: response.data || [],
  };
};

export const fetchProductByIds = async (ids: String[]): Promise<Product[]> => {
  if (ids && ids.length > 0) {
    const requestUrl = `${apiUrl}/ids`;
    const response = await axios.post<any>(requestUrl, ids);
    return response.data;
  } else {
    return [];
  }
};

export const fetchTagGroupIds = async (params: any): Promise<Product[]> => {
  const requestUrl = `/api/yumei-mall-customer/tag/group?size=20&page=0&sort=lastModifiedDate,desc`;
  const response = await axios.get<any>(requestUrl, { params: { ...params, 'enabled.equals': true } });
  return response.data;
};

//导入文件
export const importTstEntities: CrudUploadAction<Product> = (data: any, params) => async dispatch => {
  const requestUrl = `/api/yumei-mall-pim/product/imports`; //tst：self  它牌：other
  const newData = data.map((obj: any) => {
    const newObj = Object.keys(obj);
    obj.freeShipping = !obj.freeShipping ? true : (obj.freeShipping === '是' ? true : false);
    obj.virtualProduct = !obj.virtualProduct ? false : (obj.virtualProduct === '是' ? true : false);
    obj.tags = ["MemberPriceAvailable", "StaffInternalPurchase"];
    params.forEach((e: any) => {
      newObj.forEach(i => {
        if (e.value === i) {
          obj[i] === '是' && (obj.tags.push(i));
          obj.MemberPriceAvailable === '否' && (obj.tags = obj.tags.filter((e: string) => e !== 'MemberPriceAvailable'));
          obj.StaffInternalPurchase === '否' && (obj.tags = obj.tags.filter((e: string) => e !== 'StaffInternalPurchase'));
          delete obj[i];
        }
      })
    });

    return obj;
  });
  console.log(newData);
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, newData),
  });
};

//批量修改价格
export const importPrice: CrudUploadAction<Product> = (data: any) => async dispatch => {
  const requestUrl = `${apiUrl}/sku/UpdatePrice`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, data),
  });
};

//批量删除
export const deleteEntitys: CrudDeleteBatchAction<any> = (ids?: string[] | number[]) => async dispatch => {
  const requestUrl = `${apiUrl}/ids`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.delete(requestUrl, { data: ids }),
  });
};

//上/下架 商品
export const upUnderPim: CrudPutAction<UpUnderPim> = entity => async dispatch => {
  const status = entity.status === 1 ? 'on' : 'off'
  const requestUrl = `${apiUrl}/sell/${status}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.put(requestUrl, entity.spuIds, { params: {} }),
  });
};

//定时上架 取消定时上架(单个)
export const scheduledPim: CrudPutAction<any> = entity => async dispatch => {
  const status = entity.type === 1 ? 'on' : 'off'; // on 定时上下架; off 取消定时上下架
  const requestUrl = `${apiUrl}/${status}/scheduled`;
  const data = {
    productIds: entity.productIds,
    onDateTime: entity.onDateTime,
    offDateTime: entity.offDateTime,
    status: entity.status, // 1 取消定时上架  0取消定时下架
  };
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, data),
  });
};

//批量定时上架 取消定时上架（多个商品）
export const upperLowerShelvesPim = async (entity?: any) => {
  const status = entity.type === 1 ? 'on' : 'off'; // on 定时上下架; off 取消定时上下架
  const requestUrl = `${apiUrl}/${status}/scheduled`;
  const data = {
    productIds: entity.productIds,
    onDateTime: entity.onDateTime,
    offDateTime: entity.offDateTime,
    status: entity.status, // 1 取消定时上架  0取消定时下架
  };
  const axiosResponse = await axios.post<any>(requestUrl, data);
  return axiosResponse.data;
};

//直播商品删除
export const delLiveGoods = async (id: number | string) => {
  const requestUrl = `/api/yumei-mall-pim/inject-goods/${id}`;
  const axiosResponse = await axios.delete<any>(requestUrl);
  return axiosResponse.data;
}

//直播商品上下架
export const updateLiveGoods = async (id: number | string, goodsStatus: string) => {
  const requestUrl = `/api/yumei-mall-pim/inject-goods/${id}?goodsStatus=${goodsStatus}`;
  const axiosResponse = await axios.post<any>(requestUrl, { goodsStatus });
  return axiosResponse.data;
}
export const refreshSerch = async () => {
  const requestUrl = `${apiUrl}/refresh/index/data`
  const axiosResponse = await axios.post<any>(requestUrl)
  return axiosResponse.data
}

export const refreshStructure = async () => {
  const requestUrl = `${apiUrl}/refresh/index/data/structure`
  const axiosResponse = await axios.post<any>(requestUrl)
  return axiosResponse.data
}

// 释放库存
export const releaseInventory = async (entity: any) => {
  const requestUrl = `/api/yumei-mall-order/order/release/inventory`;
  const data = {
    productIds: entity.productIds,
    bookTime: entity.releaseInventoryTime,
  };
  return await axios.post(requestUrl, data);
};

export const searchProductPromotion = async (params?: QueryParameter): Promise<Page<Product>> => {
  const {
    page = 0,
    keywords,
    ...rest
  } = params || {};
  const response = await axios.get<any>(`/api/yumei-mall-pim/product/promotion`, {
    params: {
      page: page - 1,
      'status.in': [10, 20, 25, 30],
      ...rest
    }
  });
  return {
    total: parseInt(response.headers['x-total-count'], 10),
    data: response.data,
  };
};

export const searchProductList = async (params?: QueryParameter): Promise<Page<Product>> => {
  const {
    page = 0,
    keywords,
    ...rest
  } = params || {};
  const response = await axios.get<any>(`/api/yumei-mall-pim/product`, {
    params: {
      page: page - 1,
      'status.in': [10,30,50],
      'productType.equals': 'Normal',
      ...rest
    }
  });
  return {
    total: parseInt(response.headers['x-total-count'], 10),
    data: response.data,
  };
};


// 查询定价规则
export const fetchPriceRulesList = async (params?: any) => {
  const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-pim/pricing_rules?page=0&size=100&storeId.equals=${params?.storeId}`);
  return axiosResponse.data || [];
}

export const fetchSelectCouponCashList = async (params: any): Promise<any[]> => {
  const paramsData = {
    'status.equals': 'Active',
    current: 0,
    size: 100,
    ...params
  };
  const response = await axios.get<any[]>(apiUrl, { params: paramsData });
  return response.data || [];
}

export {
  apiUrl,
};