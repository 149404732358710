import axios from 'axios';
import {Storage} from 'utils/storage-util';

//获取可用的页面列表
export const getPageList = (params:{page:any,size:any,sort?:string,[key:string]:any}) => {
    return axios.get('/api/yumei-mall-content/miro-page',{
        params
    })
}

/**
 * 获取可用的商品列表
 * current=1&size=10&keywords=&status=30
*/
export const getProductList = (params:{current?:number,pageSize?:number,name?:string,sellerId?:any,[key:string]:any}) => {
    return axios.get(`/api/yumei-mall-pim/product?page=${params.current ? params.current - 1 : 0}&size=${params.pageSize}&name.contains=${params.keyword || ''}&sellerId.contains=${params['sellerId.contains']}`,{
        params:params
    })
}
//列表导航商品列表
export const getNavProductList = (params:{current?:number,pageSize?:number,name?:string,sellerId?:any,[key:string]:any}) => {
  return axios.get(`/api/yumei-mall-search/product/search?page=${params.current ? params.current - 1 : 0}&size=${params.pageSize}`,{
      params:params,
      headers: {
        'Accept-Language': 'zh-CN'
      }
  })
}

//拼团商品列表
export const getCollageNavProductList = (params:{current?:number,pageSize?:number,name?:string,sellerId?:any,[key:string]:any}) => {
  return axios.get(`/api/yumei-mall-market/groupon-product/product?page=${params.current ? params.current - 1 : 0}&size=${params.pageSize}`,{
      params:params,
      headers: {
        'Accept-Language': 'zh-CN'
      }
  })
}


export const getTellingProductList = (params:{current?:number,pageSize?:number,name?:string,sellerId?:any,[key:string]:any}) => {
  return axios.get(`/api/yumei-mall-telling/product?page=${params.current ? params.current - 1 : 0}&size=${params.pageSize}&name.contains=${params.keyword || ''}`,{
      params:params
  })
}


// https://test-appstore.taobuting.online/h5/activities-list.json
/**
 * 获取活动页面数据
*/
export const getActivitiesList = async () => {
    let env:string = 'prod', href:string = window.location.href,enums:any = {
        'dev':'https://dev-appstore.taobuting.online/h5/activities-list.json',
        'test':'https://test-appstore.taobuting.online/h5/activities-list.json',
        'pre':'https://test-appstore.taobuting.online/h5/activities-list.json',
        'prod':'https://appstore.taobuting.online/h5/activities-list.json',
    },emunsTmm:any = {
        'dev':'https://dev-appstore.tinghao123.com/h5/activities-list-tst.json',
        'test':'https://test-appstore.tinghao123.com/h5/activities-list-tst.json',
        'pre':'https://pre-appstore.tinghao123.com/h5/activities-list-tst.json',
        'prod':'https://appstore.tinghao123.com/h5/activities-list-tst.json',
    }
    const xTenant = await Storage.session.get("x-tenant");
    // console.log('xTenant',xTenant)
    if((href.indexOf('console-dev') !== -1)){
        env = 'dev'
    }else if((href.indexOf('console-test') !== -1)){
        env = 'test'
    }else if((href.indexOf('console-pre') !== -1)){
        env = 'pre'
    }
    return axios.get(`${xTenant !== 'tbt'?emunsTmm[env]:enums[env]}?time=${+ new Date()}`)
}

//保存状态数据
export const updateUseState = (params:{used?:boolean,id:string | number}) =>  {
    return axios.post(`/api/yumei-mall-content/miro-page/${params.id}/${params.used === true?'use':'cancel'}`)
}
//获取促销活动列表
export const getActivityGoodsList = (params:{identifier:number | string,startDate:string,days:number,sellerId:string}) =>  {
    return axios.get(`/api/yumei-mall-front-api/marketing-activity/web/page/identifier`,{params})
}
//获取直播间列表
export const getLiveList = (params:{current?:number,pageSize?:number,name?:string,[key:string]:any}) =>  {
    return axios.get(`/api/yumei-live-web/api/v1/liveInfo/list`,{params})
}
//获取视频/图文列表
export const getVideoList = (params:{current?:number,pageSize?:number,name?:string,[key:string]:any}) =>  {
    return axios.get(`/api/yumei-post/post/search`,{params})
}
//获取商品列表
export const getGoodsDetailByIds = async (ids:string[], SkytoneCard?: boolean) =>  {
  const newArr:any = await new Set(ids);
  const arrMap = await Array.from(newArr).map((v:any) => {
        return v + ''
    });
  const request = await SkytoneCard ? axios.post(`/api/yumei-mall-telling/product/ids`, arrMap) : axios.get(`/api/yumei-mall-pim/product?id.in=${arrMap}`);
  return request;
}

//获取指定类型优惠券
export const getCouponList = (params:any) => {
    let type = params.type
    params = Object.assign({
        'status.equals':'Active',
        'current':0,
        'size':100
    },params)
    delete params.type
    return axios.get(`/api/yumei-mall-promotion/coupon/${type}`,{params:params})
}

//获取可用的营销活动
export const getMarketActivityList = (params:any) => {
    let newParams = Object.assign({
        'status.equals':'Active',
        'page':0,
        'size':100,
        'sort': 'lastModifiedDate,desc',
    },params)
    delete params.type
    return axios.get(`/api/yumei-mall-market/market/activity`,{params:newParams})
}


// 修改页面类型
export const updateExamPageType = async (data: any) => {
    const requestUrl = `/api/yumei-mall-customer/customer/update-tags/${data.id}`;
    return await axios.post<any>(requestUrl, data.data);
}




