
import { Button, Col, Drawer, Pagination, Row, Spin, Tabs, Tag, message } from "antd";
import { useEffect, useState } from "react";
import './comTable/index.less'
import { purchaseOrder } from './order.reducer'
import SearchFor from './comTable/searchFor'
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import { useParamGeneral } from "components/useParamGenera";
import { useEnumValue } from "common/enum/use-enum-value";
import { internationalList } from "components/IntelFormText";
import OrderDetails from "pages/procure/purchase-order/order-details";
import { Image } from 'antd';
import './index.less'
import tstLogo from '../../../assets/logo-tst.png';
import imgNoData from '../../../assets/nodata.svg'
import { getDate } from "components/tool";
import { Storage } from 'utils/storage-util';
import { goTop } from "layouts/page-wraper/list-view-page";
import menu from "layouts/menu";

// tab数据
export const orderTypeData = [
  {
    label: '全部',
    value: 'All',
  }, {
    label: '待审核',
    value: 'PendingReview',
  }, {
    label: '待发货',
    value: 'Paid',
  }, {
    label: '出库中',
    value: 'Shipping',
  }, {
    label: '已发货',
    value: 'Shipped',
  }, {
    label: '已收货',
    value: 'Received',
  }, {
    label: '已取消',
    value: 'Cancelled',
  }
]

const Table = (props: any) => {
  const { navigate, location } = props;
  const { getEnumValueDesc } = useEnumValue();
  const [tableData, setTableData]: any = useState([]);
  const [paginationTotal, setPaginationTotal]: any = useState(0);
  const [loading, setLoading] = useState(false);
  const [orderType, setOrdertype] = useState('All');
  const { currency } = useParamGeneral();
  const [activeKey, setActiveKey] = useState('0');
  const [items, setItems] = useState<any>([]);
  const [tabIdentifying, setTabIdentifying] = useState(0);

  // 详情弹框
  const [detailShow, SetDetailShow] = useState(false);
  // 行id
  const [detailId, SetDetailId] = useState<string>('');

  // 行id
  const [detailRow, SetDetailRow] = useState<string>('');
  // 请求参数
  const [tableParm, setTableParm] = useState<any>({
    size: 10,
    page: 0,
    sort: 'bookTime,desc',
    'bookTime.greaterThanOrEqual': getDate(-30)
  });

  useEffect(() => {
    if (tabIdentifying !== 0) {
      const tabsData = JSON.parse(Storage.session.get("tabsData") || '[]');
      const newTbsData = tabsData.concat().map((item: any) => {
        return (item.params.url === location.pathname) ? {
          ...item,
          params: {
            ...item.params,
            searchParams: tableParm,
          }
        } : item;
      });
      Storage.session.set("tabsData", JSON.stringify(newTbsData));
      getTableData(tableParm);
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableParm])


  // 获取table数据
  const getTableData = (data: any) => {
    setLoading(true)
    purchaseOrder(data).then(e => {
      setPaginationTotal(e['headers']['x-total-count'] || 0)
      setTableData(e.data)
      setLoading(false)
    }).catch(() => {
      setLoading(false)
    })
  }

  // tab切换搜索
  const tabSearch = (res: string) => { 
    setOrdertype(res)
    let newParam = {
      queryType: res,
      ...tableParm
    }
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      ...newParam,
      page: 0,
      size: 10,
    });
  }

  // 搜索条件
  const onSearch = (data: any) => {
    let newParam = {
      queryType: orderType,
      ...tableParm,
      ...data

    }
    Object.keys(newParam).map((item) => {
      if (!newParam[item] && item !== 'page') {
        delete newParam[item];
      }
      return true;
    });
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...newParam,
      page: 0,
      size: 10,
    });
  }

  // 展开收缩
  const setDataIsOpen = (index: number, isOpen: boolean) => {
    tableData[index].isOpen = !isOpen
    setTableData([...tableData])
  }

  // 页码改变
  const pageOnChange = (page: number, pageSize: number) => {
    let pag = page - 1;
    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      page: pag,
      size: pageSize
    })
    getTableData({
      ...tableParm,
      page: pag,
      size: pageSize,
      queryType: orderType,
    })
  }

  const copyToClipboard = (str: string) => {
    message.success('复制成功')
    return navigator?.clipboard?.writeText(str).catch((err) => {
      const el = document.createElement('textarea');
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand('copy');
      document.body.removeChild(el);
    });
  }


  const orderRead = (sonItem: any) => (
    <Row className="order-row">
      <Col className="col" span={5}>
        <div className="flexCss">
          <div className="flex-left">
            <Image
              width={60}
              src={!!sonItem.picture ? internationalList(sonItem.picture || '') : tstLogo}
            />
          </div>
          <div className="flex-right">
            <p className="textline2">{internationalList(sonItem.productName || '')}</p>
            <p>SKU编码：{sonItem.skuCode}</p>

          </div>
        </div>
      </Col>
      <Col className="col" span={2}>
        <p>  {sonItem?.specName?.map((v: any) => {
          return <><p>{v.k}: {v.v}</p></>
        })}
          {sonItem.blueStarProduct && <Tag color="green">蓝星商品</Tag>}</p>
      </Col>
      <Col className="col" span={3}>{currency?.symbol + ' ' + sonItem.memberPrice + '/' + sonItem.quantity}</Col>
      <Col className="col" span={3}>{sonItem.actualPrice + '/' + sonItem.quantity}</Col>
      <Col className="col" span={3}>{currency?.symbol + ' ' + sonItem.subtotal}</Col>
      <Col className="col" span={2}>{getEnumValueDesc('purchaseOrderLineStatus', sonItem.status || '')}</Col>
      <Col className="col" span={3}>{sonItem.serviceState || '-'} / {sonItem.quantity} </Col>

      <Col className="col" span={2}>
        {/* <p><Button ghost type="primary" size="small">确认发货</Button></p> */}
      </Col>
    </Row>

  )

  const TabsOnChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey + '');
    const url = items.concat().filter((e: any) => e.key === newActiveKey + '')[0].params.url
    navigate && navigate(url);
  };

  const searchActiveKey = (newPanes: any) => {
    const pathname = location.pathname;
    const key = newPanes.concat().filter((e: any) => e.params.url === pathname)?.[0]?.key || '0';
    setActiveKey(key);
  }

  const add = (params: any) => {
    const tabsData = JSON.parse(Storage.session.get("tabsData") || '[]');
    const newPanes = (tabsData && tabsData.length > 0) ? tabsData : [];
    const newActiveKey = newPanes.length;
    setItems(newPanes);
    searchActiveKey(newPanes);
    if (newPanes.concat().filter((item: any) => item.params.url === params.url).length === 0) {
      newPanes.push({ label: params.title, children: '', key: newActiveKey + '', params: params });
      setItems(newPanes);
      searchActiveKey(newPanes);
      Storage.session.set("tabsData", JSON.stringify(newPanes));
    } else {
      const filterParams = newPanes.concat().filter((item: any) => item.params.url === params.url)?.[0]?.params?.searchParams || {};
      setTabIdentifying(new Date().getTime());
      filterParams && setTableParm({
        ...tableParm,
        ...filterParams,
      });
    }
  };

  const remove = (targetKey: string) => {
    const newItems = items.concat().filter((e: any) => e.key !== targetKey);
    setItems(newItems);
    Storage.session.set("tabsData", JSON.stringify(newItems));
  };

  const onEdit = (
    targetKey: any,
    action: any,
  ) => {
    if (action === 'add') {
      // add();
    } else {
      remove(targetKey);
    }
  };

  useEffect(() => {

    //页面刷新回到顶部
    goTop();

    setTabIdentifying(new Date().getTime());
    setTableParm({
      ...tableParm,
      key: new Date().getTime()
    });
    const menuItem: any = menu.concat().filter((item: any) => {
      const pathOne = location.pathname.split('/')?.[1] || '';
      return item?.path === '/' + pathOne
    });
    // const titleOne = menuItem.length > 0 ? menuItem[0].name : '';
    const titleTwo = menuItem.length > 0 ? menuItem[0].children.concat().filter((item: any) => {
      const pathTwo = location.pathname.split('/')?.[2] || '';
      return item?.path === pathTwo
    })?.[0]?.name : '';
    // add({ title: titleOne + '-' + titleTwo, url: location.pathname });
    add({ title: titleTwo, url: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="content">
      <div className="content_top">
        <div className="box">
          <Tabs
            size="small"
            type="editable-card"
            onChange={TabsOnChange}
            activeKey={activeKey}
            onEdit={onEdit}
            items={items}
            tabBarGutter={4}
            hideAdd
          />
        </div>
      </div>
      <div className="content_center"></div>
      <div className="content_search">
        <SearchFor onSearch={(param: any) => onSearch(param)} />
      </div>
      <div className="tabs">
        <Tabs defaultActiveKey={orderType} activeKey={orderType}
          onChange={
            res => { tabSearch(res); }}>
          {orderTypeData.map(res => <TabPane tab={res.label} key={res.value}></TabPane>)}
        </Tabs>
      </div>
      <div className="table-wrapper">
        <Row className="header">
          <Col className="col" span={5}>商品信息</Col>
          <Col className="col" span={2}>颜色/尺码</Col>
          <Col className="col" span={3}>单价/数量</Col>
          <Col className="col" span={3}>实际单价/数量</Col>
          <Col className="col" span={3}>行小计</Col>
          <Col className="col" span={2}>发货状态</Col>
          <Col className="col" span={3}>售后(状态/数量)</Col>
          <Col className="col" span={2}>操作</Col>
        </Row>
        <Spin spinning={loading} wrapperClassName='spin'>
          {tableData.length === 0 &&
            <div className="no-data-wrapper">
              <img src={imgNoData} alt=""></img>
            </div>
          }
          {/* 订单 */}
          {tableData.map((item: any, index: number) => {
            return (<>
              <Row className="t-row" key={index}>
                <div className="order-title">
                  <div className="order-row-left">
                    <div className="order-row-left-sonrow"></div>
                    <div className="order-row-left-sonrow">
                      <span>订单ID: <Button size="small" type='link' onClick={() => { SetDetailShow(true); SetDetailRow(item); item.id && SetDetailId(String(item.id)) }}>{item.id}</Button> </span>
                      <span>订单编号: {item.orderNo} <Button size="small" type='link' onClick={() => copyToClipboard(item.orderNo)}>复制</Button> </span>
                      <span>下单时间：{item.bookTime}</span>
                      <span>订单类型: {getEnumValueDesc('purchaseOrderType', item.purchaseOrderType || '') || '-'}</span>
                      <span>订单状态: {getEnumValueDesc('purchaseOrderStatusLabel', item.statusLabel || '')}{getEnumValueDesc('purchaseOrderStatus', item.orderState || '') || '-'}</span>
                      <span>{item.selfSeller ? '自营商家' : ''}</span>
                    </div>
                    <div className="order-row-left-sonrow">
                      <span>下单时间：{item.bookTime}</span>
                      <span>付款时间：{item.payTime}</span>
                      <span>店铺名称: {item.seller.sellerName}</span>
                      <span>买家授权码: {item.buyer.buyerCode}</span>
                      <span>卖家授权码: {item.seller.sellerCode || '-'}</span>
                    </div>
                    <div className="order-row-left-sonrow">
                      <span>收货人姓名/电话：{item.address?.consigneeName} / {item.address?.mobile}</span>
                      <span>收货人信息：{item.address?.provinceName + ' '}{item.address?.cityName + ' '}{item.address?.districtName + ' '} {item.address?.address}</span>
                      <span>商家备注：{item.sellerMsg || '-'}</span>
                    </div>
                  </div>
                  <div className="order-row-center" style={{ fontSize: '14px' }}>实收金额: {currency?.symbol} {item.payAmount || 0}</div>
                </div>
                {/* 商品列表 */}
                {item.isOpen && item.lines.length > 1 ?
                  item.lines.map((sonItem: any) => {
                    return (
                      orderRead(sonItem)
                    )
                  }) :
                  item.lines.map((sonItem: any, sonindex: number) => {
                    return (
                      sonindex === 0 ? orderRead(sonItem) : ''
                    )
                  })
                }
                {/* 收缩展开 */}
                {item.lines.length > 1 ?
                  <Row className="order-row fold" onClick={() => setDataIsOpen(index, item.isOpen)} >
                    <span>{item?.isOpen ? '收起' : '查看更多'} {item?.isOpen ? true : false}</span>
                  </Row> : ''
                }
              </Row>
            </>)
          })}
        </Spin>
        {/* 分页器 */}
        <div className="btm-zw"></div>
        <div className="pagination">
          <Pagination
            onChange={pageOnChange}
            pageSizeOptions={['10', '20', '30', '50']}
            total={paginationTotal}
            size="small"
            showSizeChanger
            showTotal={(total, range) => `第${range[0]}-${range[1]}条/ 总共${total} 条`}
            defaultPageSize={10}
            current={tableParm.page + 1}
          />
        </div>
      </div>
      <Drawer
        visible={detailShow}
        onClose={() => SetDetailShow(false)}
        width={1000}
      >
        <OrderDetails detailRow={detailRow} id={detailId} listType={'order'} />
      </Drawer>
    </div>

  )
}

export default Table
