import { ReactNode, useState } from "react";
import { Drawer, Form, Input } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { RootState } from "reducers";
import { deleteEntity, getEntities, getEntity } from "./flowDetails.reducer";
import { RouteComponentProps } from "@reach/router";
import { Fund } from "model/financial/fund.model";
import ProForm, { ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { connect } from "react-redux";
import { internationalList } from "components/IntelFormText";
import { rechargeTypeList } from "pages/dealer/unit/unit.reducer";
import { fetchFundDetailsList } from "./fund.reducer";
import { debounce } from "lodash";

export interface FundListProps
    extends StateProps, DispatchProps, RouteComponentProps {
    children?: ReactNode;
}

const columns1 = [{
    title: '订单ID',
    dataIndex: 'referenceId',
    width: 100,
    ellipsis: true,
}];

const columns: ProColumns<Fund>[] = [{
    title: '流水编号',
    dataIndex: 'operationSequenceNumber',
    width: 100
}, {
    title: '账户类型',
    dataIndex: 'accountTypeName',
    width: 100
},
{
    title: '原金额',
    dataIndex: 'originAmount',
    width: 100
},
{
    title: '变更金额',
    dataIndex: 'availableAmount',
    render: (text, row: any) => {
        return row.operationAmount * row.operation || '-'
    }
}, {
    title: '变更后金额',
    dataIndex: 'amount'
}, {
    title: '原赠送金额',
    dataIndex: 'originGiftAmount',
}, {
    title: '变更赠送金额',
    dataIndex: 'fromName',
    render: (text, row: any) => {
        return row.operationGiftAmount * row.operation || '-'
    }
}, {
    title: '变更后赠送金额',
    dataIndex: 'giftAmount',
}, {
    title: '操作时间',
    dataIndex: 'transactionTime',
}, {
    title: '操作类型',
    dataIndex: 'transactionTypeName',
}, {
    title: '操作备注',
    dataIndex: 'transactionRemark',
    width: 200,
    render: (text: any, record: any) => {
        return internationalList(record.transactionRemark)
    }
}];


const FundList = (props: any) => {
    let { visible, record, onCancel } = props;

    const [allValues, setAllValues]: any = useState({});
    const [columnsType, setColumnsType] = useState(false);

    const handleValuesChange = (changedValues: any, allValues: any) => {
        setAllValues(allValues);
    }

    return (<>
        <Drawer title="资金明细" width={1300} placement="right" onClose={onCancel} visible={visible} >
            <ProForm
                labelAlign="left"
                style={{ padding: '20px 20px 0' }}
                initialValues={{}}
                onValuesChange={debounce(handleValuesChange, 500)}
            >
                <Input.Group compact>
                    <ProFormText
                        name="operationSequenceNumber.equals"
                        placeholder="流水编号"
                        label='流水编号'
                    />
                    <div className="wd10"></div>
                    <ProFormSelect
                        width="sm"
                        initialValue={record.accountType}
                        placeholder='账户类型'
                        label='账户类型'
                        name='bankRollAccountType.equals'
                        request={async () => {
                            const unitRequest: any = await rechargeTypeList();
                            return unitRequest.rechargeType.map((item: any) => ({
                                value: item?.name,
                                label: item?.desc,
                            }))
                        }}
                    />
                </Input.Group>
            </ProForm>
            <ProTable<any>
                columns={!!columnsType ? columns1.concat(columns as any) : columns}
                rowKey="id"
                search={false}
                params={{
                    'userId.equals': record.userId,
                    'storeId.equals': record.storeId,
                    'toStoreId.equals': record.toStoreId,
                    'bankRollAccountType.equals': record.accountType,
                    'paymentType.equals': record.paymentType,
                    'storeBankRollId.equals':record.id,
                    ...allValues,
                }}
                request={async (params) => {
                    const type = params['bankRollAccountType.equals'] === 'Goods';
                    setColumnsType(type);



                    if (allValues['operationSequenceNumber.equals']?.length > 11 || !allValues['operationSequenceNumber.equals']) {
                        let res = await fetchFundDetailsList({
                            ...params,
                            sort: 'lastModifiedDate,desc',
                            page: (params.current && params.current - 1) || 0,
                            size: params.pageSize || 20,
                        });
                        return Promise.resolve({
                            data: res?.data || [],
                            success: true,
                            total: res.headers['x-total-count'] || 0
                        });
                    } else {
                        return Promise.resolve({
                            data: [],
                            success: true,
                            total: 0
                        });
                    }


                }}
                pagination={{
                    showQuickJumper: true,
                }}
            />
        </Drawer>
    </>
    );
};

const mapStateToProps = ({ fund }: RootState) => ({
    entity: fund.entity,
    entities: fund.entities,
    loading: fund.loading,
    updateSuccess: fund.updateSuccess,
    createSuccess: fund.createSuccess,
    total: fund.total
});

const mapDispatchToProps = {
    getEntity,
    getEntities,
    deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(FundList);
// export default FundList