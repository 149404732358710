import { defaultMenuList, MenuList } from 'model/system/menuList.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'MenuList';
const apiUrl = '/api/yumei-mall-auth/menu';
const standardReducerImpl = new StandardReducerImpl<MenuList>(entity, defaultMenuList, apiUrl);
export type MenuListState = StandardState<MenuList>;
export const menuList = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

