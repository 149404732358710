import axios from 'axios';
import { defaultRole, Role } from 'model/system/role.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'role';
const apiUrl = '/api/yumei-mall-auth/role';
const standardReducerImpl = new StandardReducerImpl<Role>(entity, defaultRole, apiUrl);
export type RoleState = StandardState<Role>;
export const role = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const fetchActions = (params: any) => axios.get(`/api/yumei-mall-auth/role/menu/${params.menuId}/actions`);
export const putActions = (menuId: any,params:any) => axios.put(`/api/yumei-mall-auth/role/menu/${menuId}/actions`,params);
export const fetchRoleList = (params: any) => axios.get(apiUrl, { params });

export const fetchTenantList = () => axios.get('/api/yumei-mall-tenant/tenant');

export const fetchMenuList = (id?:any) => axios.get(`/api/yumei-mall-auth/role/menu/actions?roleId=${id ? id : ''}`);