import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ModuleDetails from './power-module-details';
import ModuleEdit from './power-module-edit';
import ModuleList from './power-module-list';

const PowerModule: React.FC<RouteComponentProps> = () => (
  <Router>
    <ModuleList path="/" />
    <ModuleDetails path=":id" />
    <ModuleEdit path=":id/edit" />
    <ModuleEdit path="new" />
  </Router>
);
export default PowerModule;
