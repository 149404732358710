import axios from 'axios';
import { FAILURE, REQUEST, SUCCESS } from 'reducers/action-type.util';
import { Storage } from 'utils/storage-util';
import { Dispatch } from 'redux';
import { Base64 } from 'js-base64';
import md5 from 'js-md5';
import sha1 from 'sha1';
import { navigate } from '@reach/router';


export const queryParamGeneral = async () => {
  const res = await axios.get(`/api/yumei-mall-common/param/group/general`);
  return res.data;
}

//action
export const ACTION_TYPES = {
  LOGIN: 'authentication/LOGIN',
  GET_SESSION: 'authentication/GET_SESSION',
  LOGOUT: 'authentication/LOGOUT',
  CLEAR_AUTH: 'authentication/CLEAR_AUTH',
  ERROR_MESSAGE: 'authentication/ERROR_MESSAGE',
  GETQueryParamGeneral: 'GETQueryParamGeneral',
};
//账户
export interface AccountInfo {
  id?: string | number
  account?: string;
  name?: string;
  email?: string;
  login?: string;
  avatar?: string;
  roleId?: string[];
  roleName?: string;
  userId?: string;
  role?: {
    groups?: string[];
    id?: string;
    name?: string;
  };
  isTenantRole?: boolean;
  menus?: MenusItem[];
}

export interface MenusItem {
  parentMenuCode?: string;
  children?: MenusItemChildren[];
  path?: string;
  name?: string;
  hideInMenu?: boolean,
  icon?: any;
}

interface MenusItemChildren {
  menuCode: string;
  parentMenuCode: string;
  actions: string[];
}

// 客户信息
export interface ClientInfo {
  clientId?: string;
  wholesaleShopId?: string | number;
  wholesale?: boolean;
  tstUid?: string;
  shopOwner?: boolean;
  shopId?: string | number;
  shopName?: string;
  passwordStrength?: boolean;
  seller?: boolean;
  sellerId?: string;
}

// 登录参数值
export interface LoginParams {
  username?: string;
  password?: string;
}

export interface TwoFactorLoginParams {
  username?: string;
  password?: string;
  captcha?: string;
  captchaKey?: string;
  captchaCode?: string;
  phoneNumber?: string;
}

const AUTH_TOKEN_KEY = 'authentication-token';

const apiUrl = '/api/yumei-mall-auth';

const initialState = {
  loading: false,
  isAuthenticated: false,
  loginSuccess: false,
  loginError: false, // Errors returned from server side
  showModalLogin: false,
  account: {} as AccountInfo,
  clientInfo: {} as ClientInfo,

  errorMessage: (null as unknown) as string, // Errors returned from server side
  redirectMessage: (null as unknown) as string,
  sessionHasBeenFetched: false,
  sessionIsFetching: false,
  idToken: (null as unknown) as string,
  logoutUrl: (null as unknown) as string,
  mfaFactor: false,
  phone: '',
  mfaTemplateToken: ''
};

export type AuthenticationState = Readonly<typeof initialState>;

// Reducer
export const authentication = (state: AuthenticationState = initialState, action: any): AuthenticationState => {
  // console.log(action);
  switch (action.type) {
    //请求
    case REQUEST(ACTION_TYPES.LOGIN):
      return {
        ...state,
        loading: true,
      };
    case REQUEST(ACTION_TYPES.GET_SESSION):
    case REQUEST(ACTION_TYPES.GETQueryParamGeneral):
      return {
        ...state,
        sessionIsFetching: true,
      };
    //失败
    case FAILURE(ACTION_TYPES.LOGIN):
      const newDate = new Date().getTime();
      action?.payload?.response?.data?.message && (action.payload.response.data.message = action?.payload?.response?.data?.message + '?' + newDate);
      navigate('/login');
      return {
        ...initialState,
        errorMessage: action.payload,
        showModalLogin: true,
        loginError: true,
        // mfaFactor: data?.error === "mfa_factor",
        // phone: data?.phoneNumber
      };
    case FAILURE(ACTION_TYPES.GET_SESSION):
    case FAILURE(ACTION_TYPES.GETQueryParamGeneral):
      return {
        ...state,
        sessionIsFetching: false,
        isAuthenticated: false,
        sessionHasBeenFetched: true,
        showModalLogin: true,
        errorMessage: action.payload,
      };
    //成功
    case ACTION_TYPES.LOGIN + 'isCode':
      Storage.session.set("loginData", action.payload);
      return {
        ...state,
        loading: false,
        loginSuccess: true,
        isAuthenticated: true,
      }
    case SUCCESS(ACTION_TYPES.LOGIN):
      console.log(action.payload)
      Storage.session.set("loginData", action.payload.data);
      Storage.session.set("store-id", '0');
      return {
        ...state,
        isAuthenticated: true,
        loading: false,
        loginSuccess: true,
      };
    case ACTION_TYPES.LOGOUT:
      return {
        ...initialState,
        showModalLogin: true,
      };
    case SUCCESS(ACTION_TYPES.GET_SESSION): {
      const userData = action?.payload?.data?.data || {};
      Storage.session.set("info", userData);
      return {
        ...state,
        isAuthenticated: true,
        sessionIsFetching: false,
        sessionHasBeenFetched: true,
        account: {
          name: userData?.nickname,
          login: userData?.account,
          avatar: userData?.avatar,
          roleId: userData?.deptId,
          roleName: userData?.deptName,
          userId: userData?.id,
          isTenantRole: true,
        },
      };
    }
    case SUCCESS(ACTION_TYPES.GETQueryParamGeneral): {
      const generalRes = action.payload.data || [];
      generalRes && Storage.session.set("param-general", generalRes)
      return {
        ...state,
      }
    }
    case ACTION_TYPES.ERROR_MESSAGE:
      return {
        ...initialState,
        showModalLogin: true,
        redirectMessage: action.message,
      };
    case ACTION_TYPES.CLEAR_AUTH:
      return {
        ...state,
        loading: false,
        showModalLogin: true,
        isAuthenticated: false,
      };
    default:
      return state;
  }
};
//同步操作
export const displayAuthError = (message: String) => ({ type: ACTION_TYPES.ERROR_MESSAGE, message });

export const getSession: (access_token:string) => void = (access_token) => {
  console.log(access_token)
  return {
    type: ACTION_TYPES.GET_SESSION,
    payload: axios.get(`/auth/user`, {
      headers: {
        'Authorization': `Bearer ${access_token}`,
      },
    }),
  };
};

export const getMenuList = async () => {
  const axiosResponse = await axios.get(`/auth/menu`, {
    headers: {
      'Authorization': `Bearer ${Storage.session.get("loginData").access_token}`,
    },
  });
  console.log(axiosResponse.data.data)
  Storage.session.set("menus", axiosResponse.data.data);
  return axiosResponse.data.data;
}

export const refreshAccessToken = async (refreshToken: string): Promise<String> => {
  return axios.post(`${apiUrl}/oauth2/token`, {}, {
    headers: {
      'Tenant-Id': '000000',
    },
    params: {
      refresh_token: refreshToken,
      grant_type: 'refresh_token',
      scope: 'backend',
      tenantId: '000000',
      type: 'account'
    }
  }).then(result => {
    const token = result?.data?.access_token;
    Storage.session.set(AUTH_TOKEN_KEY, token);
    Storage.session.set("refresh-token", result?.data?.refresh_token);

    return token;
  }).catch(err => {
    console.error("fail to refresh token:", err);
    Storage.session.remove(AUTH_TOKEN_KEY);
    Storage.session.remove("refresh-token");
    return '';
  });
};

//登录
// export const login: (user: user) => void = (user) => async (dispatch: any,) => {
//     const result = await dispatch({
//         type: ACTION_TYPES.LOGIN,
//         payload: axios.post(`${apiUrl}/oauth2/token`, {}, {
//             headers: {
//               // 'Tenant-Id': '000000',
//               // 'Captcha-Key': key,
//               // 'Captcha-Code': user.captcha,
//               'Authorization': `Basic ${Base64.encode(`saber:saber_secret`)}`,
//             },
//             params: {
//                 ...user,
//                 username: user.username,
//                 password: user?.password,
//                 grant_type: 'password',
//                 scope: 'backend',
//                 tenant_id: '000000',
//                 // type: 'account',
//                 // storeId: user.grant_type === 'captcha'? 0 : ''
//             }
//         }),
//     });
//
//     const token = result.value.data.access_token;
//     if (!result.value.data?.mfa_factor_enabled) {
//         Storage.session.set(AUTH_TOKEN_KEY, token);
//         Storage.session.set("refresh-token", result.value.data.refresh_token);
//         Storage.session.set('wholesale_shop_id', result.value.data.wholesale_shop_id);
//
//         // 保存用户信息，用户处理登录后，刷新页面操作 页面刷新后再进行身份验证
//         // Storage.session.set("user-info", result.value.data);
//         //
//         await dispatch(getSession());
//
//         if (result.value.data.tenant_id === '000000') {
//             Storage.session.set("store-id", '0');
//             // 登录成功后，刷新页面时，自动登录（过期时间）
//             // await dispatch(getSession());
//         } else {
//             // const res = user.username && await queryStoreByAccount(user.username);
//             // res && res.data && res.data.storeId && Storage.session.set("store-id", res.data.storeId);
//         }
//     }
// };

//登录
export const login: (loginParams: any, isCode?: boolean) => void = (loginParams, isCode = false) => async (dispatch: any,) => {
  let newLoginParams = {};
  const generalRes = await queryParamGeneral();
  generalRes && Storage.session.set("param-general", generalRes)
  if (!isCode) {
    let MD5str = md5(loginParams.password || '').toString();
    let sha1str = sha1(MD5str).toString();
    newLoginParams = {
      ...loginParams,
      password: sha1str
    };
  } else {
    delete loginParams.password;
    newLoginParams = {
      ...loginParams,
    }
    await dispatch({
      type: ACTION_TYPES.LOGIN + 'isCode',
      payload: newLoginParams
    });
    !!Storage.session.get("loginData").access_token && await dispatch(getSession(Storage.session.get("loginData").access_token));
  };
  const clientId = Storage.session.get("fetchWecom").clientId;
  !isCode && await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(`/oauth2/token`, { ...newLoginParams }, {
      headers: {
        'Authorization': `Basic ${Base64.encode(`${clientId}:${clientId}_backend`)}`,
      },
      params: {
        grant_type: 'password',
        scope: 'backend',
        tenant_id: '000000',
        ...newLoginParams,
      }
    }),
  });
  // if (!result.value.data?.mfa_factor_enabled) {
  //   await loginPostProcess(result, dispatch);
  // }
};

//登录
export const loginWith2Factor: (loginParams: TwoFactorLoginParams) => void = (loginParams) => async (dispatch: any,) => {
  const result = await dispatch({
    type: ACTION_TYPES.LOGIN,
    payload: axios.post(`${apiUrl}/oauth2/token`, {}, {
      headers: {
        'Authorization': `Basic ${Base64.encode(`saber:${Storage.session.get("fetchWecom").clientId}`)}`,
      },
      params: {
        ...loginParams,
        captcha_key: loginParams.captchaKey,
        captcha_code: loginParams.captchaCode,
        grant_type: 'two_factor',
        scope: 'backend',
        tenant_id: '000000'
      }
    }),
  });

  await loginPostProcess(result, dispatch);
};


const loginPostProcess = async (result: any, dispatch: any) => {
  const token = result.value.data.access_token;
  Storage.session.set(AUTH_TOKEN_KEY, token);
  Storage.session.set("refresh-token", result.value.data.refresh_token);
  Storage.session.set('wholesale_shop_id', result.value.data.wholesale_shop_id);
  // 保存用户信息，用户处理登录后，刷新页面操作 页面刷新后再进行身份验证
  // Storage.session.set("user-info", result.value.data);
  !!Storage.session.get("loginData").access_token && await dispatch(getSession(Storage.session.get("loginData").access_token));
  const generalRes = await queryParamGeneral();
  generalRes && Storage.session.set("param-general", generalRes)



  if (result.value.data.tenant_id === '000000') {
    Storage.session.set("store-id", '0');
    // 登录成功后，刷新页面时，自动登录（过期时间）
    // await dispatch(getSession());
  } else {
    // const res = user.username && await queryStoreByAccount(user.username);
    // res && res.data && res.data.storeId && Storage.session.set("store-id", res.data.storeId);
  }
}

export const getQueryParamGeneral: () => void = () => {
  return {
    type: ACTION_TYPES.GETQueryParamGeneral,
    payload: axios.get(`/api/yumei-mall-common/param/group/general`),
  };
};

//清除token动作
export const clearAuthToken = () => {
  if (Storage.local.get(AUTH_TOKEN_KEY)) {
    Storage.local.remove(AUTH_TOKEN_KEY);
  }
  if (Storage.session.get(AUTH_TOKEN_KEY)) {
    Storage.session.remove(AUTH_TOKEN_KEY);
  }
};
//退出操作
export const logout: () => void = () => (dispatch: any) => {
  Storage.session.clear();
  dispatch({
    type: ACTION_TYPES.LOGOUT,
  });
};

export const clearAuthentication = (messageKey: String) => (dispatch: Dispatch, getState: any) => {
  clearAuthToken();
  dispatch(displayAuthError(messageKey));
  dispatch({
    type: ACTION_TYPES.CLEAR_AUTH,
  });
};

// 获取短信验证码
export const querySmsCaptcha = async (phone: string) => {
  const requestUrl = `/api/yumei-mall-message/sms/captcha?operation=LOGIN`;
  const res = await axios.post(requestUrl,
    { phone },
    {
      headers: {
        'Authorization': `Basic ${Base64.encode(`saber:${Storage.session.get("fetchWecom").clientId}`)}`,
      },
    });
  return res.data;
}
