import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './physicalStores.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { PhysicalStores } from "model/dealerStore/physicalStores.model";

export interface LevelDetailsProps extends DetailsStateAndDispatchProps<PhysicalStores>,
  RouteComponentProps, StateProps, DispatchProps {
}
const PhysicalStoresDetails: FC<LevelDetailsProps> = (props) => {
  

  return (
    <DetailsPage<PhysicalStores>
      title={'实体店详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<PhysicalStores>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ physicalStores }: RootState) => ({
  entity: physicalStores.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PhysicalStoresDetails);
