import {BaseModel, DisableModel} from 'model/base.model';

export interface Equity extends BaseModel, DisableModel {
  id?: number | string;
  rightLogo?: any;
  rightGrayLogo?: any;
  customerRightDetails?: CustomerRightDetails;
}

export interface CustomerRightDetails {
  id?: number;
  levelNo?: number;
  levelName?: string;
  requiredPoint?: number;
  keepLevelPoint?: number;
}

export const defaultEquity: Readonly<Equity> = {
};
