import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ResourcesDetails from './resources-details';
import ResourcesEdit from './resources-edit';
import ResourcesList from './resources-list';

const Resources: React.FC<RouteComponentProps> = () => (
  <Router>
    <ResourcesList path="/" />
    <ResourcesDetails path=":id" />
    <ResourcesEdit path=":id/edit" />
    <ResourcesEdit path="new" />
  </Router>
);
export default Resources;
