import React, { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity, tenantInitialize } from "./tenant.reducer";
import { RouteComponentProps } from "@reach/router";
import { Tenant } from "model/system/tenant";
import { Popconfirm } from "antd";
import { Button } from "antd";
import TenantEdit from "./tenant-edit";
import TenantDetails from "./tenant-details";
import { platform } from "model/store/seller.model";

export interface TenantListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns<Tenant, 'tenantStatus'>[] = [{
  title: '租户ID',
  dataIndex: 'id',
}, {
  title: '租户名称',
  dataIndex: 'name',
}, {
  title: '商城平台',
  dataIndex: 'platformIds',
  valueEnum: platform
}, {
  title: '启用状态',
  dataIndex: 'desabled',
  renderText: (text: boolean) => {
    return text? '启用' : '禁用'
  }
}, {
  title: '默认标识',
  dataIndex: 'defaultSchema',
  renderText: (text: boolean) => {
    return text? '是' : '否'
  }
}, {
  title: '状态',
  dataIndex: 'status',
  valueType: 'tenantStatus',
}];

const TenantList: FC<TenantListProps> = (props) => {
  const { tenantInitialize } = props;
  const rowActions = [(dom: ReactNode, record: Tenant): ReactNode => (
    (record.id &&
        <Popconfirm title="你确定要初始化该商城吗？"  key="initialize" onConfirm={() => tenantInitialize(record.id as string)}>
            <Button type="link" disabled={record?.status === 'Normal'} >初始化</Button>
        </Popconfirm>
    )
  )];

  return (
    <ListViewPage<Tenant, 'tenantStatus'>
      columns={columns}
      rowActions={rowActions}   
      editPage={(params: any) => <TenantEdit id={params.id} text={params.text} />}
      detailPage={<TenantDetails navigate={props.navigate} columns={columns} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ tenant }: RootState) => ({
  entity: tenant.entity,
  entities: tenant.entities,
  loading: tenant.loading,
  updateSuccess: tenant.updateSuccess,
  total: tenant.total,
  createSuccess: tenant.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  tenantInitialize
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TenantList);