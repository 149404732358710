import { ModalForm, ProFormText, ProFormRadio } from "@ant-design/pro-form";
import { useEffect, useState } from "react";
import { Col, Form, Row, message, Divider, Button, Table } from "antd";
import { useEnumValue } from "common/enum/use-enum-value";
import { flowDistribution, platformList, bankFlowDetail, payerCodeLevel } from './bankStatement.reducer'
import { internationalList } from "components/IntelFormText";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel, onRecharge } = props
    const [form] = Form.useForm();
    let [platformData, setPlatformData] = useState<any>([]);
    let [payerCodeLevelList, setPayerCodeLevelList] = useState<any>([]);
    let [disabled, setDisabled] = useState(false);

    const { convertEnumToOptionType } = useEnumValue();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 7 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 17 },
        },
    };


    useEffect(() => {
        getPlatformData()
        getDetail(record.id)
        setDisabled(false)
        setPayerCodeLevelList([]);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible]);


    // 获取详情
    const getDetail = (id: number) => {
        if (visible) {
            bankFlowDetail(id).then((e: any) => {
                form.setFieldsValue({
                    payerCode: e.data.payerCode ? e.data.payerCode : e.data.matchedPayerCode
                })
            })
        }
    }


    // 获取店铺列表
    const getPlatformData = async () => {
        const data: any = await platformList();
        let list = data.map((item: any) => ({
            ...item,
            value: item.id,
            label: item.name,
        }))
        setPlatformData(list)
    }


    // 收款方类型选择
    const payeeTypeChange = (e: any) => {
        let storeId = form.getFieldValue('storeId')
        let payerCode = form.getFieldValue('payerCode')
        if (e.target.value === 'Platform' && !storeId) {
            message.warn('请选择店铺')
            form.setFieldsValue({ payeeType: '' })
            return false
        } else if (e.target.value === 'Platform' && storeId) {
            let data: any = platformData.find((item: any) => item.value === storeId)
            form.setFieldsValue({ payeeCode: data.storeCode })
            return false
        } else if (e.target.value === 'Distributor' && (!storeId || !payerCode)) {
            message.warn('店铺、付款码不能为空')
            form.setFieldsValue({ payeeType: '' })
            return false
        } else if (e.target.value === 'Distributor' && storeId && payerCode) {


        }
    }
    // 确认正确 confirm
    const confirmCorrectness = () => {
        let fromData = form.getFieldsValue()
        if (!fromData.payerCode) {
            message.error('请输入付款人授权码')
            return false
        }
        setDisabled(!disabled)
        if (!disabled) {
            fromData.id = record.id
            payerCodeLevel({
                payerCode: fromData.payerCode,
            }).then((e: any) => {
                let data = [...e.data]
                if (data.length > 0) {
                    data.forEach((item: any) => {
                        item.key = Math.random()
                    })
                }
                setPayerCodeLevelList(data || [])
            }).catch(e => {

            })
        }

    }

    const rowSelection = {
        onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
            let data = selectedRows[0]
            form.setFieldsValue({ payeeType: data.payeeType })
            form.setFieldsValue({ payeeCode: data.payeeCode })
            form.setFieldsValue({ applicationId: data.applicationId })
            form.setFieldsValue({ storeId: data.storeId })
        },
        getCheckboxProps: (record: any) => ({
            disabled: record.name === 'Disabled User', // Column configuration not to be checked
            name: record.name,
        }),
    };

    const columns: any = [
        {
            title: '店铺名称',
            dataIndex: 'storeName'
        },
        {
            title: '等级名称',
            dataIndex: 'levelName',
            render: (text: string) => { return internationalList(text) },
        },
        {
            title: '申请中ID',
            dataIndex: 'applicationId',
        },
        {
            title: '收款方类型',
            dataIndex: 'payeeType',
            render: (text: string) => {
                return text === 'Platform' ? '店铺' : text === 'Distributor' ? '经销商' : ''
            }
        },
        {
            title: '收款码',
            dataIndex: 'payeeCode',
        },
    ];

    return (
        <ModalForm
            title="流水分配"
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={820}
            form={form}
            {...formItemLayout}
            layout='horizontal'
            preserve={false}
            onFinish={async (data: any) => {
                if (!disabled) {
                    message.error('请先确认正确')
                    return false
                }
                let storeId = form.getFieldValue('storeId')
                if (!storeId) {
                    message.warn('请选择店铺')
                    return false
                }
                data.id = record.id
                flowDistribution(data).then(e => {
                    onCancel()
                    onRecharge()
                    return true;
                }).catch((e) => {
                    message.error(e.response.data.message);
                })

            }}
        >
            <Row>
                <div className="pay-info">
                    <p>收款总金额 : {record.receiptAmount || 0} ,  <span className="ml10">交易对方名称 : {record.counterpart || '-'} </span> </p>
                    <p>用途说明 : {record.useDescription || '-'} </p>
                </div>
            </Row>
            <Row>
                <Col span={11}>
                    <ProFormText required={true} name='payerCode' label="付款人授权码"
                        fieldProps={{
                            onChange: (e) => {
                                form.setFieldsValue({ payeeType: '' })
                                form.setFieldsValue({ payeeCode: '' })
                                setPayerCodeLevelList([])
                            }
                        }} disabled={disabled} placeholder='付款人授权码' />

                </Col>
                <Col span={11}><Button type="link" size="small" onClick={confirmCorrectness}>{disabled ? '取消确认' : '确认正确'}</Button></Col>
            </Row>
            <Row style={{ marginBottom: '20px', padding: '0px 26px' }}>
                <Col span={24}>
                    <Table
                        pagination={false}
                        rowSelection={{
                            type: 'radio',
                            ...rowSelection,
                        }}
                        columns={columns}
                        dataSource={payerCodeLevelList}
                    />
                </Col>
            </Row>
            <Row>
                <Col span={11}>
                    <ProFormRadio.Group
                        required
                        disabled
                        hidden
                        name="payeeType"
                        label='收款方类型'
                        fieldProps={{
                            onChange: (e) => payeeTypeChange(e)
                        }}
                        options={[
                            {
                                label: '店铺',
                                value: 'Platform',
                            }, {
                                label: '经销商',
                                value: 'Distributor'
                            }
                        ]}
                    />
                    <ProFormText disabled name='storeId' hidden label="店铺id" placeholder='店铺id' />
                    <ProFormText disabled name='applicationId' hidden label="申请人id" placeholder='申请人id' />
                    <ProFormText disabled name='payeeCode' hidden label="收款码" placeholder='收款对象授权码' />
                </Col>
            </Row>
            {
                convertEnumToOptionType('rechargeType').length > 0 && convertEnumToOptionType('rechargeType').map((item: any) => {

                    return (<>
                        <Row>
                            <Col span={24}>
                                <Divider className="divider-title">{item.label}</Divider>
                                {item.label === '培训费' && <div style={{ textAlign: 'center', marginBottom: '20px', color: '#ff4d4f', fontWeight: 'bold', fontSize: '14px' }}>注:培训费默认充向平台店铺，不受上方选择收款授权码影响</div>}
                            </Col>
                        </Row>
                        <Row>
                            <Col span={12}>
                                <ProFormText name={['allotDetailMap', item.value, 'amount']} label="金额" placeholder='金额' />
                            </Col>
                            <Col span={1}></Col>
                            {item.value === 'Goods' ?
                                <Col span={11}>
                                    <ProFormText name={['allotDetailMap', item.value, 'giftAmount']} label="赠送金额" placeholder='赠送金额' />
                                </Col>
                                : ''}

                            <Col span={item.value === 'Goods' ? 12 : 11}>
                                <ProFormText name={['allotDetailMap', item.value, 'remark']} label="备注" placeholder='备注' />
                            </Col>
                        </Row>

                    </>)

                })
            }

        </ModalForm >
    )
}

export default RechargeModel