import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import {defaultAdvertisement, advertisementModel } from "model/market/advertisement.model";

const entity = 'level';
const apiUrl = '/api/yumei-mall-post/banner';
const standardReducerImpl = new StandardReducerImpl<advertisementModel>(entity, defaultAdvertisement, apiUrl);
export type AdvertisementState = StandardState<advertisementModel>;
export const advertisement = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;






