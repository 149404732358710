import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ExcellentStoreDetails from './excellentStore-details';
import ExcellentStoreList from './excellentStore-list';

const ExcellentStore: React.FC<RouteComponentProps> = () => (
  <Router>
    <ExcellentStoreList path="/" />
    <ExcellentStoreDetails path=":id" />
  </Router>
);
export default ExcellentStore;
