import axios from 'axios';
import { Base64 } from 'js-base64';

export const fetchCaptcha = (): Promise<any> => {
  return axios.get('/api/tbt-mall-auth/auth/captcha').then(response => response.data);
};

export const fetchWecom = () => {
  return axios.get('/auth/app');
};

export const fetchWecomInfo = (data: any) => {
  return axios.post('/api/yumei-mall-auth/oauth2/token', data, {
    headers: {
      'Authorization': `Basic ${Base64.encode(`saber:saber_secret`)}`,
    },
    params: {
      ...data,
    }
  });
};