import axios from "axios";

export const fetchBalance = async (customerCode: any) => {
  const result = await axios.get<any>(`/api/yumei-mall-finance/customer/finance/balance/get?customerCode=${customerCode}`);
  return result.data;
}
export const fetchWithdrawalList = async (customerCode: any) => {
  const result = await axios.get<any>(`/api/yumei-mall-finance/customer/finance/withdrawllist/get?customerCode=${customerCode}`);
  return result.data;
}
export const fetchBankCard = async (customerCode: any) => {
  const result = await axios.get<any>(`/api/yumei-mall-finance/customer/finance/withdrawlinfo/get?customerCode=${customerCode}`);
  return result.data;
}

//token
export const passwordControl= (params:any, config = {}) => axios.post('/api/yumei-mall-pay/common/getPasswordToken', params, config = {})