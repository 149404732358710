import { FC, ReactNode } from "react";
import { Button, Form, Input, message } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import { beSign, deleteEntity, getEntities, getEntity } from "../../../contract/sign/sign.reducer"
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { ContractSign, signListStatus, signStatus } from "model/contract/sign.model";
import { ProFormSelect } from "@ant-design/pro-form";
import { internationalList } from "components/IntelFormText";

export interface ContractSignListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  data?: any;
  levelList?: any
}

const ContractSignList: FC<ContractSignListProps> = (props) => {


  const columns: ProColumns<ContractSign>[] = [{
    title: '合同ID',
    dataIndex: 'id',
    width: 100,
  }, {
    title: '店铺名称',
    dataIndex: 'storeName',
    width: 80,
  }, {
    title: '等级',
    dataIndex: 'levelNo',
    width: 100,
    render: ((res: any, record: any) => {
      let data = props.levelList.find((item: any) => item.levelNo === record.levelNo)
      return internationalList(data.levelName) || ''
    })
  }, {
    title: '合同名称',
    dataIndex: 'contractName',
    width: 100,
  }, {
    title: '合同状态',
    dataIndex: 'signStatus',
    valueEnum: signStatus,
    width: 60,
  }, {
    title: '合同有效期',
    dataIndex: 'endTime',
    width: 100,
    render: ((res: any, record: any) => {
      return <div>
        <p>起: {record.startTime}</p>
        <p>止: {record.endTime}</p>
      </div>
    })
  },
  {
    title: '状态',
    dataIndex: 'status',
    valueEnum: signListStatus,
    width: 100,
  }]

  const searchFields = [{
    label: '合同名称',
    name: 'contractName.equals',
    width: 100,
  }, {
    label: '店铺名称',
    name: 'storeName.equals',
    width: 100,
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          options={Object.entries<string>(signStatus).map(([value, label]) => ({ value: value, label }))}
          name={'signStatus.equals'}
          placeholder="合同状态"
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <Button onClick={() => {
      beSign({
        signFlowId: record.signFlowId,
      }).then((e: any) => {
        // message.success('即将跳转');
        window.open(e, '_blank');
      }).catch((error: any) => {
        message.error(error?.response?.data?.detail);
      })
    }} type="link" size="small" >下载签署文件</Button>
  }]


  return (
    <ListViewPage<ContractSign>
      actionColumnWidth={90}
      columns={columns}
      hideNavBar={true}
      quickFilterPanel={quickFilterPanel}
      detailsIndex={false}
      hideDeleteButton
      hideEditButton
      hideCreateButton
      sort={''}
      togetherParams={{
        'account.equals': props.data.account,
        //'account.equals': 'L02394',
        sort:''
      }}
      rowActions={rowActions}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ contractSign }: RootState) => ({
  entity: contractSign.entity,
  entities: contractSign.entities,
  loading: contractSign.loading,
  updateSuccess: contractSign.updateSuccess,
  createSuccess: contractSign.createSuccess,
  total: contractSign.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractSignList);