import React, { useState, useEffect, FC } from 'react'
import { Form, Input, Space, message, Select, InputNumber, Drawer, Button } from 'antd';
import { queryExpressList, updateShip } from '../purchase-order/order.reducer';
import { Order, LinesItem } from 'model/procure/order.model';
import ProTable, { ProColumns } from '@ant-design/pro-table';
import { useParamGeneral } from 'components/useParamGenera';
import { internationalList } from 'components/IntelFormText';
const { Option } = Select;

export interface OrderModalShipProps {
  orderData: Order,
  orderDetailsData?: LinesItem | any,
  isShip: boolean,
  onCancel: Function,
  updateShip?: Function
}

// 发货
const OrderShipModal: FC<OrderModalShipProps> = (props) => {
  const { orderData, orderDetailsData, isShip, onCancel } = props;
  const [form] = Form.useForm();
  const [expressListData, setExpressListData] = useState<[]>([]); //快递列表
  const [detailIds, setDetailIds] = useState<React.Key[]>([]);
  const [checkedRows, setCheckedRows] = useState<React.Key[]>([]);
  const { currency } = useParamGeneral();
  const [sonData, setSonData] = useState<any[]>([]);

  useEffect(() => {
    setSonData(orderData.lines || []);
  }, [isShip, orderData.lines])


  const columns: ProColumns<LinesItem, 'lineStatus'>[] = [{
    title: '商品名称',
    dataIndex: '',
    // width: 500,
    render: (text: any, row: any) => {
      return <div className='query-order-name'>
        <img alt='商品图片' style={{ width: '60px', height: 'auto', marginRight: '10px' }} src={internationalList(row?.picture || '')} />
        <div>
          <p>{internationalList(row.productName || '')}</p>
          <p>规格编码：{row.skuCode}</p>
        </div>
      </div>
    }
  }, {
    title: '颜色/尺码',
    dataIndex: 'sonItem',
    width: 200,
    render: (text, row) => {
      return row?.specName?.map((v: any) => {
        return <><p>{v.k}: {v.v}</p></>
      })
    }
  }, {
    title: '商品单价(元)',
    dataIndex: 'memberPrice',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  },
  {
    title: '数量',
    dataIndex: 'quantity',
  },
  {
    title: '发货数量',
    dataIndex: 'shippedQuantity',
  },
  {
    title: '数量',
    dataIndex: 'quantity',
    render: (text, row: any, index) => {
      let maxNunber = row.maxNunber ? row.maxNunber : Number(text || 0) - Number(row.shippedQuantity || 0) - Number(row.cancelledQuantity || 0)
      let data: any = [...sonData]
      // 发货数量
      data[index].maxNunber = maxNunber

      return <><p><InputNumber min={0} max={maxNunber} disabled={maxNunber === 0} defaultValue={maxNunber} onChange={(e) => {

        let data: any = [...sonData]
        data[index].quantity = e
        setSonData(data)

        // 更新已经选中行数据
        if (checkedRows.length > 0) {
          let checkRows = [...checkedRows]
          checkRows.map((item: any) => {
            if (item.id === row.id) {
              item.shipNum = e
            }
            return item
          })
          setCheckedRows(checkRows)
        }

      }} /></p>
        <p style={{ fontSize: '12px', color: '#ff4d4f' }}>最大数量 {maxNunber}</p></>
    }
  },
  {
    title: '取消数量',
    dataIndex: 'cancelledQuantity',
  },
  {
    title: '行小计',
    dataIndex: 'subtotal',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  }, {
    title: '发货状态',
    dataIndex: 'status',
    valueType: 'lineStatus',
  }];

  //快递下拉数据
  useEffect(() => {
    const expressFun = async () => {
      const res = await queryExpressList();
      res && setExpressListData(res);
    }
    isShip && expressFun();
  }, [isShip])

  useEffect(() => {
    orderDetailsData && (setDetailIds([]));
  }, [orderDetailsData])

  // 数组
  const setDetailRows = (keys: any[], rows: any[]) => {
    setDetailIds(keys)
    let data: any[] = []
    rows.forEach((item) => {
      data.push({
        id: item.id,
        shipNum: item.quantity
      })
    })
    setCheckedRows(data)

  }

  return (<>
    <Drawer
      width={1000}
      title='订单发货'
      visible={isShip}
      onClose={() => onCancel()}
      extra={
        <Space>
          <Button type="primary" onClick={
            () => {
              form.validateFields().then(values => { 
                if (detailIds.length === 0) message.error('请选择商品!')
                else {
                  let data = {
                    ...values,
                    orderId: orderData.id,
                    lines: checkedRows || [],
                  }
                  updateShip(data).then(e => {
                    message.success('操作成功')
                    form.resetFields();
                    onCancel()
                  }).catch(e => {
                    message.error(e.response.data.message || '系统异常')
                  })
                }
              })
            }
          }>提交发货</Button>
        </Space>
      }
    >
      <ProTable<LinesItem, Record<string, any>, 'lineStatus'>
        rowKey="id"
        columns={columns}
        options={false}
        search={false}
        pagination={false}
        dataSource={sonData}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: detailIds,
          getCheckboxProps: (record: LinesItem) => ({
            // disabled: record.serviceState === 1 || record.shipped || record.status === 'SHIPPED'
          }),
          onChange: (keys: React.Key[], Rows: any[]) => { setDetailRows(keys, Rows) }
        }}
      />
      <Space direction='vertical' className='ship-modal-label' style={{ marginLeft: '30px' }}>
        {/* <Space><label>配送方式：</label><span>{orderData.expressTypeName}</span></Space> */}
        <Space><label>收货人：</label><span>{orderData.address?.consigneeName}</span></Space>
        <Space><label>联系电话：</label><span>{orderData.address?.mobile}</span></Space>
        <Space>
          <label>收货地址：</label>
          <span>
            {orderData.address?.provinceName}
            {orderData.address?.cityName}
            {orderData.address?.districtName}
            {orderData.address?.address}
          </span>
        </Space>
      </Space>
      <Form
        style={{ marginLeft: '30px' }}
        labelAlign='right'
        form={form}
        name='form_stock_modal'
        labelCol={{ span: 2 }} wrapperCol={{ span: 12 }}
        initialValues={{
          expressId: '',
          expressNo: ''
        }}
      >
        <Form.Item
          label='物流公司'
          name='expressCode'
          rules={[{ required: true }]}>
          <Select style={{ width: 200 }} placeholder='请选择' onChange={(e) => {
            let row: any = expressListData.find((item: any) => item.expressCode === e)
            form.setFieldsValue({ expressName: row.expressName || '' }); 
          }}>
            {expressListData && expressListData.map((item: any) => (
              <Option key={item.id} value={item.expressCode}>{item.expressName}</Option>
            ))}
          </Select>
        </Form.Item>
        <Form.Item
          label='快递名称'
          hidden
          name='expressName'
        >
          <Input placeholder='请输入快递名称' />
        </Form.Item>
        <Form.Item
          label='快递单号'
          name='expressNo'
          rules={[{ required: true }]}>
          <Input placeholder='请输入单号' />
        </Form.Item>
      </Form>
      <p style={{ color: 'red' }}>* 请仔细填写物流公司及快递单号，发货后24小时内仅支持做一次更正，逾期不可修改</p>
    </Drawer>
  </>)
}


export default OrderShipModal;