import { FC, ReactNode } from "react";
import { Form, Input, Image} from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./material-details";
import { deleteEntity, getEntities, getEntity } from "./material.reducer";
import { RouteComponentProps } from "@reach/router";
import { ProFormDatePicker, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import MaterialEdit from "./material-edit";

export interface PostListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns<any>[] = [
  {
    title: 'ID',
    dataIndex: 'id',
  },{
    title: '名称',
    dataIndex: 'name'
  }, {
    title: '素材类型',
    dataIndex: 'type',
    render: (dom) => {
      return dom === 1 ? '文本' : '图片'
    }
  },
  {
    title: '内容',
    dataIndex: 'text',
    hideInDescriptions: true,
    render: ((res, record) => {
      const picture = record.text && <Image alt='' style={{ width: '60px', height: 'auto' }} src={record.text} />;
      return record.type === 1 ? record.text : picture
    })
  },
 {
    title: '创建时间',
    dataIndex: 'createTime',
    valueType: 'dateTime'
  }];


const PostList: FC<PostListProps> = (props) => {
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormText
          name='name.equals'
          placeholder="名称"
        />
        <div className="wd10"></div>
        <ProFormSelect
         width={180}
          name="type.equals"
          options={[{
            label: '文本',
            value: 2
          }, {
            label: '图片',
            value: 3
          }]}
          placeholder="素材类型"
        />
        <div className="wd10"></div>
        

        <ProFormDatePicker
        width={180}
          name="crateTime.equals"
          placeholder="创建时间"
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage
      key={'id'}
      hideDeleteButton
      scrollConfig={{ x: 1200 }}
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      sort='lastModifiedDate,desc'
      actionColumnWidth={50}
      createButtonText='添加素材'
      editPage={(params) => <MaterialEdit id={params.id} entityData={props.entities} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ material }: RootState) => ({
  entity: material.entity,
  entities: material.entities,
  loading: material.loading,
  updateSuccess: material.updateSuccess,
  createSuccess: material.createSuccess,
  total: material.total
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostList);