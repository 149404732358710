import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPost } from "model/video/post.model";
import { CrudDeleteAction, CrudGetAction, CrudPostAction, CrudPutAction } from 'reducers/redux-action.type';
import axios from 'axios';
import { cleanEntity } from 'utils/entity-utils';

const entity = 'topic';
const apiUrl = '/api/yumei-mall-content/lesson/findLessonByPage';

const standardReducerImpl = new StandardReducerImpl<any>(entity, defaultPost, apiUrl);
export type ExamTopicState = StandardState<any>;
export const examtopic = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const reset = standardReducerImpl.reset;



// 查询子课程
export const getLessonSonMiddle = async(data:any) => {
    const axiosResponse = await axios.get(`/api/yumei-mall-content/lesson/getLessonSonMiddle?id=${data.id}`);
    return axiosResponse.data || [];
}

// 绑定子课程
export const addLessonSonMiddle = async(data:any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-content/lesson/addLessonSonMiddle`, data);
    return axiosResponse.data || [];
}

// 删除子课程
export const delLessonSonMiddle = async(data:any) => {
    const axiosResponse = await axios.post(`/api/yumei-mall-content/lesson/delLessonSonMiddle`, data);
    return axiosResponse.data || [];
}


// 查询详情
export const getEntity: CrudGetAction<any> = (id: any, togetherParams: any) => {
    let apiUrl = `/api/yumei-mall-content/lesson/findLessonById/${id}`
    const requestUrl = (apiUrl as any)?.detailUrl ? `${(apiUrl as any).detailUrl + togetherParams}` : `${apiUrl}`;
    return {
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
        payload: axios.get<any>(requestUrl),
    };
};


// 添加
export const createEntity: CrudPostAction<any> = entity => async dispatch => {
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
        payload: axios.post('/api/yumei-mall-content/lesson/addLesson', cleanEntity(entity)),
    });
};

// 修改
export const updateEntity: CrudPutAction<any> = (entity) => async dispatch => {
    let apiUrl = '/api/yumei-mall-content/lesson/updateLesson'
    const requestUrl = (apiUrl as any)?.editUrl ? `${(apiUrl as any).editUrl + (entity as any).updateEntityParams}` : `${apiUrl}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl, cleanEntity(entity)),
    })
};

// 删除
export const deleteEntity: CrudDeleteAction<any> = (id, remove, deleteEntityParams) => async dispatch => {
     let apiUrl = '/api/yumei-mall-content/lesson/delLesson'
    const requestUrl = (apiUrl as any)?.deleteUrl ? `${(apiUrl as any).deleteUrl + deleteEntityParams}` : `${apiUrl}/${id}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE),
        payload: axios.delete(requestUrl, {
            params: { remove, id }
        }),
    });
};

