import { FC } from "react";
import { RouteComponentProps } from "@reach/router";
import { RootState } from "reducers";
import { connect } from "react-redux";
import {
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from "./evaluation.reducer";
import { Form } from "antd";
import EditPage, {
  EditStateAndDispatchProps,
} from "layouts/page-wraper/edit-page-modal";
import { Evaluation } from "model/dealer/evaluation.model";
export interface EvaluationEditProps
  extends EditStateAndDispatchProps<Evaluation>,
    StateProps,
    DispatchProps,
    RouteComponentProps {}

const EvaluationEdit: FC<EvaluationEditProps> = (props) => {
  const { ...editProps } = props;

  const [form] = Form.useForm();

  return (
    <EditPage<Evaluation> title="品牌" form={form} {...editProps}></EditPage>
  );
};

const mapStateToProps = ({ evaluation }: RootState) => ({
  entity: evaluation.entity,
  updateSuccess: evaluation.updateSuccess,
  updating: evaluation.updating,
  createSuccess: evaluation.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(EvaluationEdit);
