import { EditableProTable, ProColumns } from "@ant-design/pro-table";
import { Button } from "antd";
import { reasonOption, validityType } from "model/procure/return-package.model";
import moment from "moment";
import ManualSkuSelectionForm from "pages/pim/product/manual-sku-selection-form";
import React, { useEffect, useRef, useState } from "react";

interface ReturnPackagePimDetailsProps {
  type?: string;
  form?: any;
  onChange?: (data: any) => void;
  value?: any;
  text?: string
}

export const ReturnPackagePimColumns: ProColumns<any>[] = [{
  title: '商品编号',
  dataIndex: 'skuCode',
}, {
  title: '商品名称',
  dataIndex: 'productName',
}, {
  title: '数量',
  dataIndex: 'quantity',
  valueType: 'digit',
  fieldProps: {
    min: 1,
    max: 10000,
    controls: false,
    precision: 0,
  },
}, {
  title: '不良品数量',
  dataIndex: 'rejectsQuantity',
  valueType: 'digit',
  fieldProps: {
    min: 1,
    max: 10000,
    controls: false,
    precision: 0,
  },
}, {
  title: '退回原因',
  dataIndex: 'reason',
  valueType: 'cascader',
  fieldProps: {
    options: reasonOption.map(e => ({
      label: e.top,
      value: e.top,
      children: e.bottom.map(v => ({
        label: v,
        value: v,
      }))
    }))
  }
}, {
  title: '有效期类型',
  dataIndex: 'validityType',
  valueType: 'select',
  valueEnum: validityType,
}, {
  title: '生产日期',
  dataIndex: 'produceDate',
}, {
  title: '到期日期',
  dataIndex: 'dueDate',
}, {
  title: '一物一码',
  dataIndex: 'sourceCode',
}, {
  title: '备注',
  dataIndex: 'productRemark',
}]

const ReturnPackagePimDetails = (props: ReturnPackagePimDetailsProps) => {
  const { onChange, value, text } = props;
  const [PimValues, SetPimValues] = useState<any>([]);
  const [editableKeys, setEditableRowKeys] = useState<React.Key[]>([]);
  const actionRef = useRef<any>();

  const ReturnPackagePimColumns: ProColumns<any>[] = [{
    title: 'id',
    dataIndex: 'id',
    hideInTable: true
  }, {
    title: '商品编号',
    dataIndex: 'skuCode',
  }, {
    title: '商品名称',
    dataIndex: 'productName',
  }, {
    title: '数量',
    dataIndex: 'quantity',
    valueType: 'digit',
    fieldProps: {
      min: 1,
      max: 10000,
      controls: false,
      precision: 0,
    },
  }, {
    title: '不良品数量',
    dataIndex: 'rejectsQuantity',
    valueType: 'digit',
    fieldProps: {
      min: 1,
      max: 10000,
      controls: false,
      precision: 0,
    },
  }, {
    title: '退回原因',
    dataIndex: 'reason',
    valueType: 'cascader',
    fieldProps: {
      options: reasonOption.map(e => ({
        label: e.top,
        value: e.top,
        children: e.bottom.map(v => ({
          label: v,
          value: v,
        }))
      }))
    }
  }, {
    title: '有效期类型',
    dataIndex: 'validityType',
    valueType: 'select',
    valueEnum: validityType,
  }, {
    title: '生产日期',
    dataIndex: 'produceDate',
    valueType: 'date',
    fieldProps: (_, { rowIndex }) => {
      return {
        onChange: (text: any, value: any) => {
          const newDueDate = text && moment(text).add(PimValues[rowIndex]['expireDays'] || 0, 'days').format('YYYY-MM-DD');
          SetPimValues(PimValues.concat().map((item: any, index: number) => {
            return index === rowIndex ? {
              ...item,
              dueDate: newDueDate,
              produceDate: value
            } : item;
          }));
        },
      };
    },
  }, {
    title: '到期日期',
    dataIndex: 'dueDate',
    valueType: 'date',
  }, {
    title: '一物一码',
    dataIndex: 'sourceCode',
  }, {
    title: '备注',
    dataIndex: 'productRemark',
  }]

  useEffect(() => {
    value && value.length > 0 && SetPimValues(value.concat().map((e: any) => {
      return {
        ...e,
        reason: e.reason ? (typeof (e.reason) === 'object' ? e.reason : e.reason.split(',')) : []
      }
    }));
    value && value.length > 0 && setEditableRowKeys(value.concat().map((e: any) => e.id));
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text]);

  useEffect(() => {
    onChange?.(PimValues);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [PimValues]);

  return <EditableProTable<any>
    value={PimValues}
    onChange={(e) => { }}
    headerTitle="添加商品"
    recordCreatorProps={false}
    actionRef={actionRef}
    rowKey="id"
    columns={ReturnPackagePimColumns}
    search={false}
    cardBordered={true}
    scroll={{ x: 1400 }}
    rowSelection={{}}
    controlled
    tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) =>
      <Button type="link" size="small"
        onClick={() => {
          const newData = (PimValues || []).filter((item: any) => selectedRowKeys?.indexOf(item.id || '') < 0)
          SetPimValues(newData);
          onCleanSelected();
        }}>
        删除
      </Button>}
    toolBarRender={() => [
      <ManualSkuSelectionForm
        trigger="添加商品"
        onFinish={(skuS) => {
          const length = [];
          let newArr: any = [];
          const funC = () => {
            const e: any = skuS[length.length]
            newArr.push({
              ...e,
              quantity: e.quantity || 1,
              rejectsQuantity: e.rejectsQuantity || 1,
              skuCode: e.itemCode,
              productName: e.itemName,
              id: String(new Date().getTime()) + '_date',
            });
            setTimeout(() => {
              length.push('0');
              length.length < skuS.length && funC();
              length.length === skuS.length && PimValues.concat(newArr).forEach((e: any) => {
                actionRef?.current?.addEditRecord(e, {
                  newRecordType: 'dataSource',
                })
              });
            }, 5)
          }; funC();
        }}
      />
    ]
    }
    editable={{
      type: 'multiple',
      editableKeys,
      onChange: setEditableRowKeys,
      onValuesChange: (record, recordList) => {
        const newList = recordList.filter(e => e.skuCode).concat().map(e => {
          return {
            ...e,
          }
        });
        SetPimValues(newList);
      },
    }}
  />
};

export default ReturnPackagePimDetails;