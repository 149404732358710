import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AuditList from './audit-list';

const AppAudit: React.FC<RouteComponentProps> = () => (
  <Router>
    <AuditList path="/" />
  </Router>
);
export default AppAudit;
