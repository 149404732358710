import React, { FC, useRef, useImperativeHandle, useState, memo, useEffect } from 'react';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Modal, Tabs, Image, Tree, Button, Collapse, Input, Form, Select, message } from 'antd';
import { updatePageConfig, updateInsertPositionId, updateMoreState, deleteModule, insertModule } from '../platform/platform-edit.reducer'
import type { ProColumns } from '@ant-design/pro-table';
import ProTable from '@ant-design/pro-table';
import { tureOrFalse, pageTypeMapEnum } from "model/decorate/decorate.model";
import { getPageList, getProductList, getActivitiesList, getCouponList, getLiveList, getVideoList, getMarketActivityList } from '../api';
import './common.less';
import { fetchClassificationList } from 'pages/pim/classification/classification.reducer';
import { getGlobalKeyValue } from '../../../utils/utils';
import { ProFormSelect } from '@ant-design/pro-form';
import { internationalList } from 'components/IntelFormText';

const { TabPane } = Tabs;
const { Panel } = Collapse;
const { Option } = Select;

interface LinkSelectionProps extends StateProps, DispatchProps {
    show: boolean
    setShowLinkSelectionPrivate: (show: any, returnData?: any) => void
    defaultTab?: string
    language?: string
    isMutipleProduct?: boolean
}


const SystemPageList: FC<{ onRef?: any, setLinkInfo: (data: any) => void }> = (({ setLinkInfo, onRef },) => {
    const [liveVisible, setLiveVisible] = useState<any>({});
    const [videoVisible, setVideoVisible] = useState<any>({});
    let listDefault = [
        {
            type: 'tabbar',
            name: ' TAB相关页',
            list: [
                {
                    name: "玉美首页",
                    path: "pages-main/index/index",
                    openType: "switchTab"
                },
                {
                    name: "美人计首页",
                    path: "pages-main/yumei-index/yumei-index",
                    openType: "switchTab"
                },
                {
                    name: "直播",
                    path: "pages-main/live/index",
                    openType: "switchTab"
                },
                {
                    name: "短视频",
                    path: "pages-main/short-video/index",
                    openType: "switchTab"
                },
                {
                    name: "购物车",
                    path: "pages-main/cart/cart",
                    openType: "switchTab"
                },
                {
                    name: "个人中心",
                    path: "pages-main/account/account",
                    openType: "switchTab"
                },
            ]
        },
        {
            type: 'live',
            name: '直播相关',
            list: [
                {
                    name: "直播列表",
                    path: "pages-live/live/live",
                    openType: "",
                    type: 'live',
                    selectedFn: (liveData: any) => {
                        setLiveVisible(liveData);
                    }
                },
            ]
        },
        {
            type: 'live',
            name: '短视频图文',
            list: [
                {
                    name: "短视频列表",
                    path: "pages-video/detail/detail",
                    openType: "",
                    type: 'video',
                    selectedFn: (actionData: any) => {
                        setVideoVisible(actionData);
                    }
                }, {
                    name: "图文列表",
                    path: "pages-video/detail-picture/detail-picture",
                    openType: "",
                    type: 'text',
                    selectedFn: (actionData: any) => {
                        setVideoVisible(actionData);
                    }
                }
            ]
        },
        {
            type: 'goods',
            name: ' 商品相关',
            list: [
                {
                    name: "商品分类",
                    path: "pages-goods/classify/classify",
                    openType: "navigateTo"
                },
                {
                    name: "搜索列表",
                    path: "pages-goods/product-list/product-list",
                    openType: "navigateTo"
                },
            ]
        },
        {
            type: 'custom-page',
            name: '固定配置页',
            list: [
                {
                    name: "主会场",
                    path: "/custom.html?pageType=12",
                    openType: "navigateTo"
                }
            ]
        },

    ];
    let [list, setList] = useState(listDefault)
    useEffect(() => {
        getActivitiesList()
            .then((res: any) => {
                list.unshift({
                    type: 'activities',
                    name: '活动页',
                    list: [
                        ...res.data.list
                    ]
                })
                setList([...list])
            })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [getActivitiesList])
    const clear = () => {

    }
    useImperativeHandle(onRef, () => {
        return {
            clear
        }
    })
    return <div className="page-list">
        <Collapse
            defaultActiveKey={['0', '1', '2']}
            bordered={false}
            className="site-collapse-custom-collapse"
        >
            {
                list.map((v: any, i: any) => {
                    return <Panel header={v.name} key={i} className="site-collapse-custom-panel">
                        {
                            v.list.map((v: any, i: any) => {
                                return <Button onClick={
                                    () => {
                                        setLinkInfo(v);
                                        v.selectedFn && v.selectedFn(v);
                                    }
                                } className="page-item" type="dashed" key={i}>{v.name}</Button>
                            })
                        }
                    </Panel>
                })
            }
        </Collapse>
        {<Modal
            title="直播列表"
            width={1100}
            visible={liveVisible && liveVisible.type === 'live'}
            onOk={(e) => {
                setLiveVisible({})
            }}
            // confirmLoading={confirmLoading}
            onCancel={(e) => setLiveVisible({})}
        >
            <ProTable
                columns={[]}
                rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                        selectedRows.length > 0 && setLinkInfo({
                            path: liveVisible.path + '?number=' + selectedRows[0].number,
                            openType: 'navigateTo',
                        });
                    },

                }}
                tableAlertOptionRender={(res: any) => <Button type='link' onClick={() => res.onCleanSelected()}>取消选择</Button>}
                request={async (params) => {
                    let res = await getLiveList({
                        pageNum: (params as any).current || 0,
                        pageSize: params.pageSize,
                        sort: 'lastModifiedDate,desc',
                        ...params
                    });
                    return Promise.resolve({
                        data: res?.data?.data?.rows || [],
                        success: true,
                        total: res?.data?.data?.total || 0
                    });
                }}
                rowKey="id"
                search={{
                    filterType: 'light',
                }}
                dateFormatter="string"
            />
        </Modal>}
        {<Modal
            title={videoVisible.name || '列表'}
            width={1100}
            visible={videoVisible.type || false}
            onOk={(e) => {
                setVideoVisible({})
            }}
            onCancel={(e) => setVideoVisible({})}
        >
            <ProTable
                columns={[]}
                rowSelection={{
                    type: 'radio',
                    onChange: (selectedRowKeys: any, selectedRows: any) => {
                        selectedRows.length > 0 && setLinkInfo({
                            path: videoVisible.path + '?id=' + selectedRows[0].id,
                            openType: 'navigateTo',
                        });
                    },

                }}
                tableAlertOptionRender={(res: any) => <Button type='link' onClick={() => res.onCleanSelected()}>取消选择</Button>}
                params={{ topicType: videoVisible.type === 'video' ? 0 : 1, }}
                request={async (params) => {
                    let res = await getVideoList({
                        currentPage: ((params as any).current) || 0,
                        pageSize: params.pageSize,
                        sort: 'lastModifiedDate,desc',
                        ...params
                    });
                    return Promise.resolve({
                        data: res?.data?.res?.datas || [],
                        success: true,
                        total: res?.data?.res?.count || 0
                    });
                }}
                rowKey="id"
                search={{
                    filterType: 'light',
                }}
                dateFormatter="string"
            />
        </Modal>}
    </div>
})

const ConfigPageList: FC<{ onRef?: any, setLinkInfo: (data: any) => void }> = (({ setLinkInfo, onRef }) => {
    // const [pageList, setPageList] = useState([] as any)
    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    const clear = () => {
        setSelectedRowKeys([])
    }
    useImperativeHandle(onRef, () => {
        return {
            clear
        }
    })
    const columns: ProColumns<{ [key: string]: any }>[] = [{
        title: '页面名称',
        dataIndex: 'title',
        valueType: 'text',
        align: 'center',
        // // width: 100,
        // render:(text, record: any) => {
        //   return  record.title
        // }
    }, {
        title: '页面类型',
        dataIndex: 'pageType',
        valueType: 'text',
        valueEnum: pageTypeMapEnum(),
        align: 'center'
    }, {
        title: '是否默认',
        dataIndex: 'isDefault',
        valueType: 'text',
        valueEnum: tureOrFalse,
        align: 'center'
    }, {
        title: '是否已使用',
        dataIndex: 'used',
        valueType: 'text',
        align: 'center',
        render: (e) => {
            if (e === true) {
                return '是'
            }
            return '否'
        }
    }, {
        title: '创建时间',
        dataIndex: 'createdDate',
        valueType: 'text',
        align: 'center'
    }, {
        title: '最近更新时间',
        dataIndex: 'createdDate',
        valueType: 'text',
        align: 'center'
    }]
    return (
        <ProTable< { keyWord?: string }>
            columns={columns}
            rowSelection={{
                // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
                // 注释该行则默认不显示下拉选项
                // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                type: 'radio',
                alwaysShowAlert: false,
                selectedRowKeys,
                onChange: (selectedRowKeys: any, selectedRows: any) => {
                    if (selectedRows.length > 0) {
                        setLinkInfo({
                            path: '/custom.html?pageType=' + selectedRows[0].pageType + '&id=' + ((selectedRows[0].pageType + '') !== '12' ? selectedRows[0].id : ''),
                            openType: 'navigateTo',
                        })
                        setSelectedRowKeys(selectedRowKeys)
                    }
                },

            }}
            request={async (params) => {
                // if (params.keyword && pageList && pageList.length > 0) {
                //     let filterList = pageList.filter((v: any) => (v.title.indexOf(params.keyword) !== -1))
                //     return Promise.resolve({
                //         data: filterList,
                //         success: true,
                //         total: filterList.length
                //     })
                // }
                let res = await getPageList({
                    page: ((params as any).current - 1) || 0,
                    size: params.pageSize,
                    'title.contains': params.keyword || '',
                    sort: 'lastModifiedDate,desc',
                })
                if (!res.data) {
                    return Promise.resolve({
                        data: [],
                        success: false,
                    });
                } else {
                    let newList = res.data.filter((v: any) => v)
                    // setPageList(newList)
                    return Promise.resolve({
                        data: newList,
                        success: true,
                        total: parseInt(res.headers['x-total-count'], 10)
                    });
                }

            }}
            options={{
                search: true,
            }}
            rowKey="id"
            search={false}
            dateFormatter="string"
        //   headerTitle=" "
        />
    );
})
const ProductList: FC<{ onRef?: any, selectionType?: ('radio' | 'checkbox'), setLinkInfo: (data: any) => void, language?: string }> = (({ selectionType, setLinkInfo, onRef, language }) => {
    selectionType = selectionType || 'radio'

    const [selectedRowKeys, setSelectedRowKeys] = useState([])
    type TableListItem = {
        key: number;
        name: string;
        status: string;
        updatedAt: number;
        createdAt: number;
        money: number;
    };

    const columns: ProColumns<TableListItem>[] = [
        {
            title: '商品主图',
            dataIndex: 'mainPicture',
            key: 'name',
            align: 'center',
            width: '120px',
            render: (e: any) => {
                return <div className="img" style={
                    {
                        width: '80px',
                        height: '80px'
                    }
                }>
                    <Image src={getGlobalKeyValue(e, language) + '?imageView2/2/w/400'} preview={false}></Image>
                </div>
            },
        },
        {
            title: '商品名称',
            dataIndex: 'name',
            align: 'left',
            render: (e: any) => {
                return <p>{getGlobalKeyValue(e, language)}</p>
            },
        },
        {
            title: 'id',
            dataIndex: 'id',
            align: 'left',
        },
        {
            title: '售价',
            dataIndex: 'retailPrice',
            align: 'left',
        },


    ];

    const clear = () => {
        setSelectedRowKeys([])
    }
    useImperativeHandle(onRef, () => {
        return {
            clear
        }
    })
    return (
        <ProTable<TableListItem, { keyWord?: string }>
            columns={columns}
            rowSelection={{
                // 自定义选择项参考: https://ant.design/components/table-cn/#components-table-demo-row-selection-custom
                // 注释该行则默认不显示下拉选项
                // selections: [Table.SELECTION_ALL, Table.SELECTION_INVERT],
                selectedRowKeys,
                type: selectionType || 'radio',
                onChange: (selectedRowKeys: any, selectedRows: any) => {
                    if (selectedRows.length > 0 && selectionType === 'radio') {
                        setLinkInfo({
                            path: '/product-detail.html?id=' + selectedRows[0].id,
                            openType: 'navigateTo',
                        })
                        setSelectedRowKeys(selectedRowKeys)
                    }
                },

            }}
            request={async (params) => {
                let res = await getProductList(params);
                if (res.status !== 200) {
                    return Promise.resolve({
                        data: [],
                        success: false,
                    });
                } else {
                    return Promise.resolve({
                        data: res.data,
                        success: true,
                        total: parseInt(res.headers['x-total-count'], 10),
                    });
                }

            }}
            options={{
                search: true,
            }}
            rowKey="id"
            search={false}
            dateFormatter="string"
        //   headerTitle=" "
        />
    );
})
const ClassifyList: FC<{ onRef?: any, setLinkInfo: (data: any) => void }> = (({ setLinkInfo, onRef }) => {
    const [classifyList, setClassifyList] = useState([] as any)
    const [selectedKeys, setSelectedKeys] = useState([])
    const [checkedKeys, setCheckedKeys] = useState([] as any)
    useEffect(
        () => {
            if (classifyList.length > 0) return;
            fetchClassificationList().then((res: any) => {
                if (res) {
                    res = res.filter((v: any) => v.name !== '其他')
                    res = res.map((v: any) => {
                        if (v.children && v.children.length > 0) {
                            v.children = v.children.map((v_1: any) => {
                                if (v_1.children && v_1.children.length > 0) {
                                    v_1.children = v_1.children.map((v_2: any) => {
                                        v_2.key = v_2.id
                                        v_2.title = internationalList(v_2.name || '')
                                        return v_2
                                    })
                                }
                                v_1.key = v_1.id
                                v_1.title = internationalList(v_1.name || '')
                                return v_1
                            })
                        }
                        v.key = v.id
                        v.title = internationalList(v.name || '')
                        return v
                    })
                    setClassifyList(res)
                }
            })
        },
        [classifyList, setClassifyList]
    )
    const onSelect = (e: any) => {
    }
    const onCheck = (e: any) => {
        let isFirstClassify = classifyList.filter((v: any) => e.indexOf(v.id) !== -1)[0];
        let selectedId = [(isFirstClassify && isFirstClassify.id) || e.pop()];
        setCheckedKeys(selectedId)
        setLinkInfo({
            path: '/pages-main/product-list/product-list?classifyId=' + (selectedId.join(','))
        })
    }

    const clear = () => {
        setSelectedKeys([])
        setCheckedKeys([])
    }
    useImperativeHandle(onRef, () => {
        return {
            clear
        }
    })
    return <div className="page-list">
        <Tree
            checkable
            selectedKeys={selectedKeys}
            checkedKeys={checkedKeys}
            onSelect={onSelect}
            onCheck={onCheck}
            treeData={classifyList}
        />
    </div>
})

const OtherPageList: FC<{ onRef?: any, setLinkInfo: (data: any) => void }> = (({ setLinkInfo, onRef },) => {
    // let [showInput, setShowInput] = useState(false)
    let [inputValueObj, setInputValueObj] = useState({} as any)
    let [actionData, setActionData] = useState({} as any);
    let listDefault = [
        {
            type: 'clickActiion',
            name: ' 点击行为',
            list: [

                {
                    name: "领取优惠券",
                    path: "RECEIVECOUPON?",
                    openType: "",
                    type: 'receive_coupon',
                    selectedFn: (actionData: any) => {
                        setActionData(actionData)
                    }
                },
                {
                    name: "触发营销活动",
                    path: "TRIGGER_ACTIVITY?",
                    openType: "",
                    type: 'trigger_activity',
                    selectedFn: (actionData: any) => {
                      setActionData(actionData)
                    }
                },
                {
                    name: "播放视频",
                    path: "PLAYVIDEO?",
                    openType: "",
                    type: 'play_video',
                    selectedFn: (actionData: any) => {
                        setActionData(actionData)
                        // console.log('selected 0011')
                    }
                },
            ]
        },


    ];
    // let [list, setList] = useState(listDefault)
    let list = listDefault;

    const clear = () => {
    }
    useImperativeHandle(onRef, () => {
        return {
            clear
        }
    })
    return <div className="page-list">
        <Collapse
            defaultActiveKey={['0', '1', '2']}
            bordered={false}
            className="site-collapse-custom-collapse"
        >
            {
                list.map((v: any, i: any) => {
                    return <Panel header={v.name} key={i} className="site-collapse-custom-panel">
                        {
                            v.list.map((v: any, i: any) => {
                                return <Button onClick={
                                    () => {

                                        v.selectedFn(v)
                                    }
                                } className="page-item" type="dashed" key={i}>{v.name}</Button>
                            })
                        }
                    </Panel>
                })
            }
        </Collapse>
        {/* 播放视频输入 */}
        {

            <Modal
                title="提示"
                visible={actionData && actionData.type === 'play_video'}
                onOk={(e) => {
                    if (actionData.type === 'play_video') {
                        let str = ''
                        for (let k in inputValueObj) {
                            str += k + '=' + inputValueObj[k] + '&'
                        }
                        setLinkInfo({
                            path: actionData.path + encodeURIComponent(str),
                            openType: 'navigateTo'
                        })
                    }
                    setActionData({})
                }}
                // confirmLoading={confirmLoading}
                onCancel={(e) => {
                    setActionData({})
                }}
            >
                {
                    actionData && actionData.type === 'play_video' &&
                    <div>
                        <Form.Item label="视频标题" >

                            <Input onChange={
                                (e) => {
                                    setInputValueObj({
                                        ...inputValueObj,
                                        title: e.target.value
                                    })
                                }
                            } placeholder="请输入视频标题"></Input>
                        </Form.Item>
                        <Form.Item label="视频地址" >

                            <Input onChange={
                                (e) => {
                                    setInputValueObj({
                                        ...inputValueObj,
                                        path: e.target.value
                                    })
                                }
                            } placeholder="请输入视频地址"></Input>
                        </Form.Item>
                        <Form.Item label="视频封面" >

                            <Input onChange={
                                (e) => {
                                    setInputValueObj({
                                        ...inputValueObj,
                                        poster: e.target.value
                                    })
                                }
                            } placeholder="请输入视频封面"></Input>
                        </Form.Item>
                    </div>
                }

            </Modal>
        }
        {/* 优惠券领取 */}
        {

            <Modal
                title="请选择券或礼包"
                visible={actionData && actionData.type === 'receive_coupon'}
                onOk={(e) => {
                    if (!actionData.selectedCouponId) {
                        message.warning('请选择券/礼包');
                        return
                    }
                    setLinkInfo({
                        path: actionData.path + 'couponId=' + actionData.selectedCouponId.join(','),
                        openType: 'navigateTo'
                    })
                    setActionData({})
                }}
                // confirmLoading={confirmLoading}
                onCancel={(e) => {
                    setActionData({})
                }}
            >

                <Form.Item label="券类型">
                    <Select placeholder="请选择券类型" onChange={async (e) => {
                        if (e) {
                          setActionData({
                            ...actionData,
                            couponType: e
                          })
                        }
                    }}>
                        <Option value="promotion">优惠券</Option>
                        <Option value="cash">代金券</Option>
                        <Option value="gift">礼包</Option>
                    </Select>
                </Form.Item>
                <ProFormSelect 
                  label="可用券"
                  fieldProps={{
                    onChange:(e) => {
                      setActionData({
                        ...actionData,
                        selectedCouponId: e
                      })
                    }
                  }}
                  mode="multiple"
                  placeholder="请选择券/礼包" 
                  params={{couponType: actionData.couponType}}
                  request={async (e) => {
                    const res = await getCouponList({
                      type: actionData.couponType,
                      'name.contains': e.keyWords || ''
                    });
                    return res.data?.map((item: any) => ({label: internationalList(item.name), value: item.id})) || []
                  }}
                  showSearch
                />
            </Modal>
        }

        {/* 触发营销活动 */}
        {

            <Modal
                title="请选择营销活动"
                visible={actionData && actionData.type === 'trigger_activity'}
                onOk={(e) => {
                    if (!actionData.selectedActivityNo) {
                        message.warning('请选择营销活动');
                        return
                    }
                    setLinkInfo({
                        path: actionData.path + 'activityNo=' + actionData.selectedActivityNo,
                        openType: 'navigateTo'
                    })
                    setActionData({})
                }}
                // confirmLoading={confirmLoading}
                onCancel={(e) => {
                    setActionData({})
                }}
            >

                {/* <Form.Item label="券类型">
                    <Select placeholder="请选择券类型" onChange={async (e) => {
                        if (e) {
                            let res = await getCouponList({
                                type: e,
                            }), list: any = []
                            console.log(res)
                            res.data.forEach((v: any) => {
                                console.log(v)
                                if (v.type !== 'Cash' || (v.type === 'Cash' && v.unfixed === false)) {
                                    list.push(v)
                                }
                            })
                            console.log('list', list)
                            if (res.status === 200 && list.length > 0) {
                                setActionData({
                                    ...actionData,
                                    couponList: list,
                                    couponType: e
                                })
                            } else {
                                setActionData({
                                    ...actionData,
                                    couponList: [],
                                    couponType: e
                                })
                            }
                        }
                    }}>
                        <Option value="promotion">优惠券</Option>
                        <Option value="cash">代金券</Option>
                        <Option value="gift">礼包</Option>
                    </Select>
                </Form.Item> */}
                <ProFormSelect 
                  label="可选营销活动"
                  fieldProps={{
                    onChange:(e) => {
                      setActionData({
                        ...actionData,
                        selectedActivityNo: e
                      })
                    }
                  }}
                  placeholder="请选择营销活动" 
                  request={async (e) => {
                    const res = await getMarketActivityList({
                      'name.contains': e.keyWords || ''
                    });
                    return res.data?.map((item: any) => ({label: item.name, value: item.activityNo})) || []
                  }}
                  showSearch
                />
            </Modal>
        }
    </div>
})
const LinkSelection: FC<LinkSelectionProps> = memo(({ show, defaultTab, setShowLinkSelectionPrivate, language }) => {
    const [showContainer, setShowContainer] = useState(show);
    const [linkInfo, setLinkInfo] = useState();
    const [defaultActiveKey, setDefaultActiveKey] = useState(defaultTab || '0')
    // const [ConfigPageListData, setConfigPageListData] = useState([]);
    let refs: any = {
        system: useRef(),
        page: useRef(),
        product: useRef(),
        classify: useRef(),
    };
    //更新显示状态
    useEffect(() => {
        setShowContainer(show)
    }, [show, setShowContainer])


    useEffect(
        () => {
        },
        [linkInfo]
    )
    let tabsList: any = [
        {
            title: '系统页',
            module: <SystemPageList onRef={refs.system} setLinkInfo={setLinkInfo} />

        },
        {
            title: '配置的活动页',
            module: <ConfigPageList onRef={refs.page} setLinkInfo={setLinkInfo} />
        },
        {
            title: '商品',
            module: <ProductList language={language} onRef={refs.product} setLinkInfo={setLinkInfo} />
        },
        {
            title: '商品分类',
            module: <ClassifyList onRef={refs.classify} setLinkInfo={setLinkInfo} />
        },
        {
            title: '其它',
            module: <OtherPageList onRef={refs.classify} setLinkInfo={setLinkInfo} />
        },


    ]

    // 清除选中
    const clearSelect = () => {
        setLinkInfo(undefined)
        for (let k in refs) {
            let ref = refs[k];
            if (ref && ref.current && ref.current.clear) {
                ref.current.clear()
            }
        }
        // systemRef.clear()
    }

    return <div className="">
        <Modal
            title="选择链接"
            centered
            visible={showContainer}
            onOk={() => {
                setShowLinkSelectionPrivate(false, linkInfo)
                clearSelect()
            }}
            onCancel={() => {
                setShowLinkSelectionPrivate(false)
                clearSelect()
            }}
            width={1200}
            bodyStyle={{
                height: '60vh',
                overflowY: 'scroll',
                padding: '0px 20px 20px 20px'
            }}
        >
            <div className="section-container">
                <Tabs defaultActiveKey={defaultActiveKey} size="small" onChange={
                    (e) => {
                        setDefaultActiveKey(e)
                    }
                }>
                    {
                        tabsList?.map((v: any, i: any) => {
                            return <TabPane tab={v.title} key={i}>
                                {v.module}
                            </TabPane>
                        })
                    }

                </Tabs>
            </div>
        </Modal>
    </div>
})
const mapStateToProps = ({ decorateEdit }: RootState) => ({
    pageConfig: decorateEdit.pageConfig,
    pageData: decorateEdit.pageData,
    insertPositionId: decorateEdit.insertPositionId,
    settingModuleId: decorateEdit.settingModuleId,
});
const mapDispatchToProps = {
    updatePageConfig,
    updateMoreState,
    updateInsertPositionId,
    deleteModule,
    insertModule
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(LinkSelection);
