import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import './index.less'
import TrainList from './trainList';
import SalonApplication from './salonApplication';
import SalonReimbursmt from './salonReimbursmt';
import VideoCheckIn from './videoCheckIn';

const Train: React.FC<RouteComponentProps> = () => (
    <Router>
        <TrainList path="/train-list" />
        <SalonApplication path="/salonApplication" />
        <SalonReimbursmt path="/salonReimbursmt" />
        <VideoCheckIn path="/videoCheckIn" />
    </Router>
);
export default Train;
