import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row, TreeSelect } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./coupon.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Coupon } from "model/market/coupon.model";
import { useEnumValue } from "common/enum/use-enum-value";
import { ProFormDateTimeRangePicker, ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { fetchLevelList, fetchStoreList } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";
import { fetchTrainList } from "pages/train/trainList/trainList.reducer";
export interface CouponEditProps extends EditStateAndDispatchProps<Coupon>, StateProps, DispatchProps, RouteComponentProps {
}

const CouponEdit: FC<CouponEditProps> = props => {
  const { text, entity } = props;
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  const [treeData, setTreeData] = useState<[]>();
  const [value, setValue] = useState<string>();
  const [valueNodes, setValueNodes] = useState<any[]>([]);

  useEffect(() => {
    getLevelTree();
  }, [text]);

  useEffect(() => {
    if (entity.id) {
      if (entity?.useTime?.type === 'RANGE') {
        form.setFieldsValue({
          useTimeSE: [entity.useTime.startTime, entity.useTime.endTime]
        });
      };
      form.setFieldsValue({
        receiveTime: [entity.receiveStartTime, entity.receiveEndTime]
      });
      const newInvolvedPlatforms: any = [];
      entity.receiveRange && entity.receiveRange.concat().forEach((e: any) => {
        e.levels && e.levels.forEach((element: any) => {
          newInvolvedPlatforms.push(e.storeId + '-' + element.levelId)
        });
      })
      form.setFieldsValue({
        involvedPlatforms: newInvolvedPlatforms
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  const getLevelTree = () => {
    fetchStoreList().then((res: any) => {
      fetchLevelList().then((data: any) => {
        const newTreeData = res.concat().map((e: any) => ({
          value: e.id,
          title: e.name,
          key: e.signAppKey,
          children: data.concat().map((item: any) => ({
            value: e.id + '-' + item.id,
            title: e.name + '-' + item.levelName,
            originalTitle: item.levelName,
            originalValue: item.id,
            levelNo: item.levelNo,
            parentValue: e.id,
            parentTitle: e.name,
            key: item.id,
          }))
        }));
        setTreeData(newTreeData);
      });
    });

  }

  const onChange = (value: any, label: any, extra: any) => {
    setValue(value);
    const newValueNodes = extra.allCheckedNodes.concat().map((e: any) => {
      const obj = e.node?.props || e.props;
      return ({
        storeId: obj.children.length === 0 ? obj.parentValue : obj.value,
        storeName: obj.children.length === 0 ? obj.parentTitle : obj.title,
        levels: obj.children.length > 0 ? obj.children.concat().map((item: any) => {
          const itemObj = item.props;
          return {
            levelId: itemObj.originalValue,
            levelName: itemObj.originalTitle,
            levelNo: itemObj.levelNo,
          }
        }) : [{
          levelId: obj.originalValue,
          levelName: obj.originalTitle,
          levelNo: obj.levelNo,
        }]
      })
    });

    let newData: any = [];
    let num = [];
    const funC = () => {
      const e = newValueNodes[num.length];
      (newData.length === 0) || (newData.filter((item: any) => item.storeId === e.storeId).length === 0)
        ? newData.push(e)
        : ((newData = newData.map((s: any) => {
          return ({
            ...s,
            levels: s.storeId === e.storeId ? s.levels.concat(e.levels) : s.levels
          })
        })));
      num.push(0);
      num.length === newValueNodes.length ? setValueNodes(newData) : funC();
    }; funC();
  };

  const beforeUpdateEntity = (res: any) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        delete res.involvedPlatforms;
        res.receiveRange = valueNodes;
        if (res.useTime.type === 'RANGE') {
          res.useTime.startTime = res.useTimeSE[0] || '';
          res.useTime.endTime = res.useTimeSE[1] || '';
        }
        res.receiveStartTime = res.receiveTime[0] || '';
        res.receiveEndTime = res.receiveTime[1] || '';
        console.log(res);
        resolve(res);
        // reject();
      } else {
        reject();
      }

    })
  }

  return (
    <EditPage<Coupon>
      {...props}
      title="编辑"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={5}>
          <ProFormSelect
            options={convertEnumToOptionType('couponBreed')}
            name="couponBreed"
            label="券种类"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormSelect
            rules={[{ required: true }]}
            options={convertEnumToOptionType('couponType')}
            name="couponType"
            label="优惠券类型"
          />
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormSwitch
            name={'hasCondition'}
            label="使用门槛"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '有',
              unCheckedChildren: '无',
            }}
          />
        </Col><Col span={1} />
        <ProFormDependency name={['hasCondition']}>
          {({ hasCondition }) => {
            return hasCondition && <>
              <Col span={5}>
                <ProFormDigit
                  rules={[{ required: true }]}
                  name="minSpendAmount"
                  label="门槛金额（最低消费金额）"
                  placeholder={'填写该优惠券最低需消费金额'}
                />
              </Col><Col span={1} />
            </>
          }
          }
        </ProFormDependency>
        <Col span={24} />
        <Col span={11}>
          <ProFormText
            name="couponName"
            label="优惠券名称"
            rules={[{ required: true }]}
            placeholder={'优惠券名称不可超过10个字'}
            fieldProps={{
              maxLength: 10
            }}
          />
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormDigit
            name="couponNumber"
            label="优惠券总数"
            rules={[{ required: true }]}
            placeholder='最多支持100000张'
          />
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormDigit
            name="couponAmount"
            label="优惠券面值"
            placeholder='优惠券面值'
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={17}>
          <Form.Item rules={[{ required: true }]} name='involvedPlatforms' label='指定等级'>
            <TreeSelect
              showSearch
              style={{ width: '100%' }}
              value={value}
              placeholder="指定等级"
              allowClear
              multiple
              onChange={onChange}
              treeData={treeData}
              treeCheckable
              showCheckedStrategy={'SHOW_PARENT'}
            />
          </Form.Item>
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormDigit
            name={['restrictNumber']}
            label="每人限领"
            placeholder={'请输入限领数量'}
            extra='输入0表示不限制'
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={5}>
          <ProFormSelect
            options={convertEnumToOptionType('useTimeType')}
            name={['useTime', "type"]}
            initialValue={'RANGE'}
            rules={[{ required: true }]}
            label="时间类型"
          />
        </Col><Col span={1} />
        <ProFormDependency name={['useTime', "type"]}>
          {({ useTime }) => {
            console.log(useTime)
            return useTime && useTime.type === 'RANGE' ? <>
              <Col span={5} >
                <ProFormDateTimeRangePicker rules={[{ required: true }]} label="使用时间段 " name='useTimeSE' />
              </Col><Col span={1} />

            </> : <>
              <Col span={5}>
                <ProFormDigit
                  name={['useTime', "days"]}
                  label="领取后多少天内使用"
                  placeholder={'领取后多少天内使用'}
                  rules={[{ required: true }]}
                />
              </Col><Col span={1} />
            </>
          }
          }
        </ProFormDependency>
        <Col span={5}>
          <ProFormSelect
            options={convertEnumToOptionType('receiveType')}
            name={["receiveType"]}
            rules={[{ required: true }]}
            label="领取方式"
          />
        </Col><Col span={1} />
        <Col span={5} >
          <ProFormDateTimeRangePicker rules={[{ required: true }]} label="领取时间段 " name='receiveTime' />
        </Col><Col span={1} />
        <Col span={24} />
        <Col span={24}>
          <ProFormTextArea
            name="ruleDescribe"
            allowClear={true}
            rules={[{ required: true }]}
            label="规则说明"
            placeholder={'请输入优惠券规则说明，100字以内'}
          />
        </Col>
        <Col span={5}>
          <ProFormSelect
            options={convertEnumToOptionType('useRangeType')}
            name={["useRangeType"]}
            initialValue={'ALL'}
            rules={[{ required: true }]}
            label="使用范围"
          />
        </Col><Col span={1} />
        <ProFormDependency name={["useRangeType"]}>
          {({ useRangeType }) => {
            return useRangeType !== 'ALL' && <>
              <Col span={5}>
                <Form.Item name="useRange" label="指定培训会">
                  <SelectSearch width={700} mode="multiple" required={false} name="useRange" valueType="number" placeholder="指定培训会"  defaultParams='id.in' fetchFunc={fetchTrainList} searchParams='name.contains' />
                </Form.Item>
              </Col>
              <Col span={1} />
            </>
          }}
        </ProFormDependency>
        <Col span={24} />
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ coupon }: RootState) => ({
  entity: coupon.entity,
  updateSuccess: coupon.updateSuccess,
  updating: coupon.updating,
  createSuccess: coupon.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CouponEdit);
