import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import { deleteEntity, getEntities, getEntity } from "./split-order-type.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { SplitOrderType } from "model/product/split-order-type.model";
import SplitOrderTypeDetails from "./split-order-type-details";
import SplitOrderTypeEdit from "./split-order-type-edit";


export interface SplitOrderTypeListProps
    extends StateProps, DispatchProps, RouteComponentProps {
    children?: ReactNode;
}
const columns: ProColumns<SplitOrderType>[] = [{
    title: 'id',
    dataIndex: 'id',
}, {
    title: '编码',
    dataIndex: 'code',
}, {
    title: '名称',
    dataIndex: 'name',
}]
const SplitOrderTypeList: FC<SplitOrderTypeListProps> = (props) => {
    const searchFields = [{
        label: '名称',
        name: 'name.contains',
    }];

    const quickFilterPanel = (
        <Form.Item>
            <Input.Group compact>
                <SearchBox
                    fields={searchFields}
                />
            </Input.Group>
        </Form.Item>
    );
    return (
        <ListViewPage<SplitOrderType>
            columns={columns}
            quickFilterPanel={quickFilterPanel}
            detailPage={<SplitOrderTypeDetails navigate={props.navigate} columns={columns} />}
            detailsIndex={false}
            hideDeleteButton={true}
            editPage={(params: any) => <SplitOrderTypeEdit id={params.id} text={params.text} />}
            {...props}
        >
            {props.children}
        </ListViewPage>
    );
};

const mapStateToProps = ({ splitOrderType }: RootState) => ({
    entity: splitOrderType.entity,
    entities: splitOrderType.entities,
    loading: splitOrderType.loading,
    updateSuccess: splitOrderType.updateSuccess,
    createSuccess: splitOrderType.createSuccess,
    total: splitOrderType.total
});

const mapDispatchToProps = {
    getEntity,
    getEntities,
    deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SplitOrderTypeList);