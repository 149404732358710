import { useEffect, useState } from "react";
import './css.less'
import { Col, message, Row, Tag } from "antd";
import ProTable from "@ant-design/pro-table";
import DecryptText from "./decryptText";
import { ModalForm, ProFormGroup, ProFormSelect } from "@ant-design/pro-form";
import { getTagList, updateIdentityTags } from "../customer.reducer";

// tab数据
export const DataColumn: any[] = [
    {
        title: '品牌',
        dataIndex: 'storeName'
    }, {
        title: '授权时间',
        dataIndex: 'authorizedTime'
    }, {
        title: '邀请人授权号',
        dataIndex: 'parentCustomerCode'
    }, {
        title: '邀请人姓名',
        dataIndex: 'parentCustomerName'
    }, {
        title: '所属6S授权号',
        dataIndex: 'belongCustomerCode'
    }, {
        title: '所属6S姓名',
        dataIndex: 'belongCustomerName'
    }
]

const calculateAge = (birthDate1: any) => {
    const currentDate = new Date();
    const birthDate = new Date(birthDate1);
    let age = currentDate.getFullYear() - birthDate.getFullYear();
    if (currentDate.getMonth() < birthDate.getMonth() || (currentDate.getMonth() === birthDate.getMonth() && currentDate.getDate() < birthDate.getDate())) {
        age--;
    }
    return age;
}



const Table = (props: any) => {
    const [rowData, setRowData]: any = useState([]);
    const [tableData, setTableData]: any = useState([]);


    useEffect(() => {
        setRowData(props.data);
        setTableData(props.data.relationshipList);
    }, [props])
    return (<>
        <div>
            <div className="utitle">基本信息</div>
            <Row>
                <Col span={7}>
                    <div className="bstitle">年龄: {calculateAge(rowData?.birthday) || '-'}</div>
                </Col>
                <Col span={7}>
                    <div className="bstitle">生日: {rowData?.birthday || '-'}</div>
                </Col>
                <Col span={7}>
                    <div className="bstitle">手机号: ******
                        <DecryptText title='手机号' id={rowData.id} data={rowData?.phone} />
                    </div>
                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <div className="bstitle">身份证号: ******
                        <DecryptText title='身份证号' id={rowData.id} data={rowData?.credentialsNo} />
                    </div>
                </Col>
                <Col span={7}>
                    <div className="bstitle">注册时间: {rowData?.registerTime || '-'}</div>
                </Col>
                <Col span={7}>
                    <div className="bstitle">注册地址:  {rowData?.address?.provinceName || '-'}{rowData?.address?.cityName || ''}{rowData?.address?.districtName || ''}{rowData?.customer?.address?.address || ''}</div>
                </Col>
            </Row>
            <Row>
                <Col span={7}>
                    <div className="bstitle">身份标签: 
                        <ModalForm
                            width={400}
                            title="修改身份标签"
                            trigger={<div>
                                {rowData?.identityTags && rowData?.identityTags.map((e:any) => {
                                    return <Tag color="#1890FF">{e.identityTagDesc} </Tag>
                                })}
                            </div>}
                            modalProps={{ destroyOnClose: true, }}
                            onFinish={async (values) => {

                                let data = {
                                    idList: [rowData.id],
                                    tagIds: values.tagId,
                                    isAll: false
                                }
                                return new Promise<boolean>((resolve, reject) => {
                                    updateIdentityTags(data).then((e: any) => {
                                        props.setRefreshTag(new Date().getTime())
                                        resolve(true);
                                    }).catch((e: any) => {
                                        message.error(e.response.data.message);
                                        resolve(false);
                                    })
                                });
                            }}
                        >
                            <ProFormGroup size="middle">
                                {/* <SelectSearch optionKey="name" togetherParams={{ 'reverseTag.equals': false }} width={'md'} label="" required={false} name={'tagId'} placeholder="标签" labelValue="name" defaultParams={'id.equals'} fetchFunc={getTagList} searchParams='name.contains' /> */}
                                <ProFormSelect
                                    width={'md'}
                                    name='tagId'
                                    mode="multiple"
                                    request={async (params) => {
                                        const data: any = await getTagList();

                                        return data.map((item: any) => ({
                                            value: item.id,
                                            label: item.name
                                        }))
                                    }}
                                    placeholder="请选择身份标签"
                                />
                            </ProFormGroup>

                        </ModalForm>
                    </div>
                </Col>
            </Row>
        </div>
        <div className="utitle mtop">邀请人/上级信息</div>
        <ProTable
            search={false}
            toolBarRender={false}
            pagination={false}
            dataSource={tableData}
            columns={DataColumn}
        />
    </>
    )
}

export default Table