import { ModalForm } from "@ant-design/pro-form";
import { Form, Input } from "antd";

const RechargeModel = (props: any) => {
    let { visible, record, onCancel, onRecharge } = props
    const [form] = Form.useForm();
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 5 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 19 },
        }
    };


    return (
        <ModalForm
            title="修改账号密码"
            visible={visible}
            onVisibleChange={(e) => !e && onCancel()}
            width={600}
            form={form}
            layout='horizontal'
            {...formItemLayout}
            preserve={false}
            className="editePwd-Layout"
            onFinish={async (data: any) => {
                let newData = {
                    id: record.userId,
                    ...data
                }
                onRecharge(newData)
                return false
            }}
        >
            <Form.Item
                label="登录账号"
                name="account"
                initialValue={record.userAccount}
                rules={[{ required: true }]}
            >
                <Input placeholder="登录账号" />
            </Form.Item>
            <Form.Item
                label="新密码"
                name="password"
                rules={[{ required: true }]}
            >
                <Input.Password autoComplete="new-password" placeholder="新密码" />
            </Form.Item>
        </ModalForm>
    )
}

export default RechargeModel