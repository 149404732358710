import {BaseModel} from "../base.model";

export interface Customer extends BaseModel {
  customerRelationshipFullStoreDtoList?: any
}


export const RelationshipState:any  = {
    'Active': "激活",
    'NotActive': "未激活",
    'DropOut': "退出"
  }

export const defaultCustomer:  Readonly<Customer> = {};