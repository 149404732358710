import { initialState, STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultProduct } from 'model/product/product.model';
import { FAILURE, REQUEST, SUCCESS } from "reducers/action-type.util";
import { CrudDeleteAction, CrudGetAction, CrudGetAllAction, CrudPostAction, CrudPutAction, QueryParameter } from 'reducers/redux-action.type';
import axios from 'axios';
import { defaultUnusualMessage, UnusualMessage } from 'model/system/unusual-message.model';
import { isArray } from 'lodash';


const entity = 'UnusualMessage';
const apiUrl = '/api/mall-exception/message/list';
const standardReducerImpl = new StandardReducerImpl<UnusualMessage>(entity, defaultUnusualMessage, apiUrl);

export type UnusualMessageState = StandardState<UnusualMessage>;
export const unusualMessage = (state: UnusualMessageState = initialState(), action: any): UnusualMessageState => {
  switch (action.type) {
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        loading: true
      };
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)):
    case REQUEST(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)):
      return {
        ...state,
        errorMessage: null,
        updateSuccess: false,
        updating: true,
      };
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)):
    case FAILURE(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)):
      return {
        ...state,
        loading: false,
        updating: false,
        updateSuccess: false,
        errorMessage: action.payload?.response?.data?.detail || action.payload?.response?.data?.title,
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST)):
      return {
        ...state,
        loading: false,
        entities: action.payload?.data?.data || [],
        total: action.payload?.data?.data?.total || 0,
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH)):
      return {
        ...state,
        loading: false,
        entities: action.payload.data,
        entity: action.payload.data,
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE)):
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE)):
      const entity = action.payload.data.data || {};
      const putEntity: any = state.entities.filter((e: any) => e.id ? e?.id === entity?.id : e?.code === entity?.code)[0];
      const keys = entity ? Object.keys(entity) : [];
      (putEntity && keys.length > 0) && keys.forEach(e => {
        putEntity[e] = entity[e];
      });
      // 刷新修改的数据行。
      let entities = (state.entities && state.entities.concat()) || [];
      isArray(action.payload.data) && action.payload.data.concat().forEach((actionData: any) => {
        entities = entities.map((item: any) => {
          return ((item.id ? item?.id === actionData?.id : item.code === actionData.code) && ({
            ...item,
            ...actionData
          })) || item
        });
      });
      return {
        ...state,
        updating: false,
        updateSuccess: true,
        entity: action.payload.data,
        entities: entities,
      };
    case SUCCESS(standardReducerImpl.actionType(STANDARD_ACTION_TYPES.DELETE)): {
      return {
        ...state,
        entities: state.entities,
        total: state.total,
        updating: false,
        updateSuccess: true,
        entity: {} as UnusualMessage,
      };
    }
    case standardReducerImpl.actionType(STANDARD_ACTION_TYPES.RESET):
      return {
        ...state,
        ...action.payload || defaultProduct || {},
      };
    default:
      return state;
  }
};

export const getEntities: CrudGetAllAction<UnusualMessage> = (params?: QueryParameter) => {
  const requestUrl = `${apiUrl}`;
  return {
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.get<any>(requestUrl),
  };
};

export const getEntity: CrudGetAction<UnusualMessage> = id => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
    payload: axios.get<any>(requestUrl),
  };
};

export const createEntity: CrudPostAction<UnusualMessage> = entity => async dispatch => {
  const requestUrl = `${apiUrl}`;
  return {
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.post<any>(requestUrl),
  };
};

export const updateEntity: CrudPutAction<UnusualMessage> = entity => async dispatch => {
  const requestUrl = `/api/mall-exception/message/payload`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post<any>(requestUrl, entity),
  });
};

export const resetEntity: CrudPutAction<UnusualMessage> = id => async dispatch => {
  const requestUrl = `/api/mall-exception/message/reprocess/${id}`;
  return  await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.put<any>(requestUrl, entity),
  });
};

export const ignoreEntity: CrudPutAction<UnusualMessage> = id => async dispatch => {
  const requestUrl = `/api/mall-exception/message/ignored/${id}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
    payload: axios.put<any>(requestUrl, entity),
  });
};

export const deleteEntity: CrudDeleteAction<UnusualMessage> = (id, remove) => async dispatch => {
  const requestUrl = `${apiUrl}/${id}`;
  return {
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.delete(requestUrl, {
          params: { remove, id }
        }),
  };
};

export const reset = (entity?: UnusualMessage) => (dispatch: any) => {
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.RESET),
    payload: {
      updating: false,
      updateSuccess: false,
      createSuccess: false,
      entity: {
        ...defaultUnusualMessage,
        ...entity
      }
    }
  })
};



