import React, { FC, useState, memo, useEffect } from 'react';
import { Tabs, Radio, Empty, Spin, Form, DatePicker } from 'antd';
import '../style/main_meeting.less';
import { getActivityGoodsList, getGoodsDetailByIds } from '../../api';
import GoodsList from '../goods-list';
import moment from 'moment';
import { goodsStyleListSimple } from '../../../../model/decorate/decorate.model';
const { RangePicker } = DatePicker;
const { TabPane } = Tabs;
export interface Props {
    dataField: {
        nav: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const RenderGoodsList: FC<{ data: { [key: string]: any }, listStyle: string }> = memo(({ data, listStyle }) => {

    const [list, setList] = useState([])
    const [state, setState] = useState({
        noData: false,
        noMore: false,
        loading: true
    })


    useEffect(() => {
        if (!data.products) {
            setState({
                loading: false,
                noMore: true,
                noData: false
            })
            return
        }
        let ids = data.products.map((v: any) => {
            return v.productId
        })
        getGoodsDetailByIds(ids, data.SkytoneCard).then((res: any) => {
            if (res.data) {
                setList(res.data)
            }
            setState({
                noData: res.data.length > 0 ? false : true,
                noMore: res.data.length > 0 ? true : false,
                loading: false
            })
        })
    }, [data, setList,])
    return <div className="goods-list-container" onScroll={
        (e: any) => {

        }
    }>
        <GoodsList list={list} type={listStyle || 'list'} />
        {
            state.noData && <Empty />
        }
        {
            state.noMore && <div className="no-more">没有更多商品了</div>
        }
        {
            state.loading && <div className="loading-con" style={
                {
                    paddingTop: `${80}px`
                }
            }><Spin /></div>
        }
    </div>
})
const MainMeeting: FC<Props> = memo(({ dataField }) => {
    const [tabIndex, setTabIndex] = useState('0');
    const dateFormat = 'YYYY-MM-DD';
    const [dayList, setDayList] = useState({} as any)
    useEffect(() => {
        const defaultDateRange: any = [moment(dataField.start_time || new Date(), dateFormat), moment(dataField.end_time || new Date(), dateFormat)];
        if (!dataField.start_time) {
            defaultDateRange[0] = defaultDateRange[0].startOf('month')
            defaultDateRange[1] = defaultDateRange[1].endOf('month')
            dataField.start_time = defaultDateRange[0].format(dateFormat)
            dataField.end_time = defaultDateRange[1].format(dateFormat)
        }
        let days = defaultDateRange[1].diff(defaultDateRange[0], 'days');

        getActivityGoodsList({
            identifier: dataField.identifier,
            startDate: dataField.start_time,
            days,
            sellerId: '0'
        }).then((res: any) => {
            let dayList = [], dayObj: any = {};
            if (res.data) {

                res.data.forEach((v: any) => {
                    if (v?.activityAction && v.activityAction?.items) {
                        let products = v.activityAction.items.map((v: any) => {
                            return v
                        })
                        if (dayObj[v.startDate]) {
                            dayObj[v.startDate].products.push(products)
                        } else {
                            dayObj[v.startDate] = {
                                products: products,
                                id: v.id,
                                startDate: moment(v.startDate).format('MM-DD')
                            }
                        }
                    }
                })
            }
            for (let k in dayObj) {
                dayList.push(dayObj[k])
            }
            setDayList(dayList)
        })
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [dataField.start_time, dataField.end_time, dataField.identifier])
    return (
        <div className={`main-meeting-container ${dataField.pageStyle}`} style={
            {
            }
        }>
            <Tabs activeKey={tabIndex} className="list-nav-tabs" onChange={
                (e) => {
                    setTabIndex(e)
                }
            }>
                {
                    dayList && dayList.length > 0 && dayList?.map((v: any, i: number) => {
                        return <TabPane tab={`${v.startDate}`} key={i + ''} >
                            {
                                tabIndex === `${i}` && <RenderGoodsList listStyle={dataField.listStyle.selected} data={v} />
                            }
                        </TabPane>
                    })
                }

            </Tabs>

        </div>
    )
})

export const MainMeetingSet: FC<SetProps> = (({ dataField, id, methods, selectedInfo, }) => {

    const dateFormat = 'YYYY-MM-DD';
    const defaultDateRange: any = [moment(dataField.start_time || new Date(), dateFormat), moment(dataField.end_time || new Date(), dateFormat)];
    if (!dataField.start_time) {
        defaultDateRange[0] = defaultDateRange[0].startOf('month')
        defaultDateRange[1] = defaultDateRange[1].endOf('month')
        methods.updateModuleData({
            ...dataField,
            start_time: defaultDateRange[0].format(dateFormat),
            end_time: defaultDateRange[1].format(dateFormat),
        }, id)
    }

    return (
        <div className="main-meeting-set" >
            <Form.Item label="日期区间" >
                <RangePicker
                    onChange={
                        (e: any) => {
                            methods.updateModuleData({
                                ...dataField,
                                start_time: e[0].format(dateFormat),
                                end_time: e[1].format(dateFormat),
                            }, id)
                        }
                    }
                    defaultValue={defaultDateRange}
                />
            </Form.Item>
            <Form.Item label="列表风格" >
                <Radio.Group value={dataField.listStyle.selected} onChange={
                    (e) => {
                        methods.updateModuleData({
                            ...dataField,
                            listStyle: {
                                ...dataField.listStyle,
                                selected: e.target.value
                            },
                        }, id)
                    }
                }>
                    {
                        goodsStyleListSimple.map((v: any, i: number) => {
                            return <Radio.Button value={v.value} key={i}>{v.label}</Radio.Button>
                        })
                    }
                </Radio.Group>
            </Form.Item>
        </div>
    )
})

export default MainMeeting