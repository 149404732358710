import { BaseModel } from "../base.model";

export interface Region extends BaseModel {
    regionTemplateType?: string;
    regions?: string[];
    templateName?: string;
    overallSituation?: boolean;
    sellerId?: string | number;
    disabled?: boolean;
    sellerName?: string;
}

export const RegionDisabled = {
    '':'全部',
    'false': '启用',
    'true': '禁用'
}

export const RegionTemplateType = {
    INCLUDE: ("销售区域"),
    EXCLUDE: ("禁售区域")
};

export const defaultRegion: Readonly<Region> = {};