import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import StoreEdit from './store-edit';
import StoreList from './store-list';

const Store: React.FC<RouteComponentProps> = () => (
  <Router>
    <StoreList path="/" />
    <StoreEdit path="new" />
  </Router>
);
export default Store;
