import {BaseModel, DisableModel} from 'model/base.model';

export interface BankStatement extends BaseModel, DisableModel {
  id?: number | string;
  bankName?: string;
  bankNameIndex?: string;
  bankAccountIndex?: string;
  receiptAmountIndex?: string;
  arrivalDateFormat?: string;
  counterpartIndex?: string;
  flowNoIndex?: string;
  useDescriptionIndex?: string;
  hasDealWithIndex?: string;
  remarkIndex?: string;
  otherBankIndex?: string;
  allotStatus?: string;
  visible?: boolean
}

export const defaultBankStatement: Readonly<BankStatement> = {
};
