import { FC, ReactNode, useState } from "react";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { getEntities } from "./audit.reducer";
import { RouteComponentProps } from "@reach/router";
import { Audit, diffStates, operationMethod } from "model/system/audit.model";
import { Button, Form, Input } from "antd";
import { ModalForm, ProFormDateTimePicker } from "@ant-design/pro-form";
import { PageContainer } from "@ant-design/pro-layout";
import QuickFilter from "components/filter/quick-filter";
import { FilterCriteria } from "components/model/share.model";
import SearchBox from "components/search-box";
import moment from "moment";
import { getDate } from "components/tool";

export interface AuditListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const AuditColumns: ProColumns<Audit>[] = [{
  title: '操作者名称',
  dataIndex: 'operatorName',
  valueType: 'text',
  width: 100,
  fixed: true,
}, {
  title: '操作者ID',
  dataIndex: 'operatorId',
  valueType: 'text',
  width: 80,
  fixed: true,
}, {
  title: '操作方式',
  dataIndex: 'operationMethod',
  valueEnum: operationMethod,
  width: 80,
  fixed: true,
}, {
  title: '对象名称',
  dataIndex: 'className',
  valueType: 'text',
  ellipsis: true,
  width: 80,
}, {
  title: '对象ID',
  dataIndex: 'referenceId',
  valueType: 'text',
  width: 80,
}, {
  title: '更改前',
  dataIndex: 'oldStates',
  width: 300,
  render: (text, record) => <p className="dataDetails">{record.oldStates}</p>
}, {
  title: '更改后',
  dataIndex: 'states',
  width: 300,
  render: (text, record) => <p className="dataDetails">{record.states}</p>
}, {
  title: '更改前后对比',
  dataIndex: '',
  width: 100,
  render: (text, record) => {
    const oldStatesJson = (record.oldStates || '').replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
    const statesJson = (record.states || '').replace(/&/g, '&').replace(/</g, '<').replace(/>/g, '>');
    const html = (jsonData: any) => {
      return jsonData.replace(/("(\\u[a-zA-Z0-9]{4}|\\[^u]|[^\\"])*"(\s*:)?|\b(true|false|null)\b|-?\d+(?:\.\d*)?(?:[eE][+\\-]?\d+)?)/g, function (match: any) {
        var cls = 'number';
        if (/^"/.test(match)) {
          if (/:$/.test(match)) {
            cls = 'key';
          } else {
            cls = 'string';
          }
        } else if (/true|false/.test(match)) {
          cls = 'boolean';
        } else if (/null/.test(match)) {
          cls = 'null';
        }
        return '<span class="' + cls + '">' + match + '</span>';
      });
    };
    return <ModalForm<{ postage: number }>
      title='更改前后数据对比'
      trigger={<Button>查看</Button>}
      width={1200}
      onFinish={async (res) => {
        return true
      }}
    >
      <div className="dataComparison">
        <div className="box">
          <p className="title">更改前</p>
          <div className="data" dangerouslySetInnerHTML={{ __html: html(oldStatesJson) as any }}></div>
        </div>
        <div className="box">
          <p className="title">更改后</p>
          <div className="data" dangerouslySetInnerHTML={{ __html: html(statesJson) as any }}></div>
        </div>
      </div>
    </ModalForm>
  }
}, {
  title: '更改明细',
  dataIndex: 'states',
  width: 500,
  render: (dom, record) => {
    return record.diffStates && <ProTable<diffStates>
      rowKey="id"
      search={false}
      scroll={{ x: 400 }}
      columns={diffStatesColumns}
      dataSource={record.diffStates || []}
      pagination={false}
      options={false}
    />
  }
}, {
  title: '操作时间',
  dataIndex: 'operationDate',
  valueType: 'dateTime',
  width: 100,
}];

const diffStatesColumns: ProColumns<diffStates>[] = [{
  title: '属性',
  dataIndex: 'propertyName',
  width: 80
}, {
  title: '更改前',
  dataIndex: 'oldState',
  width: 160
}, {
  title: '更改后',
  dataIndex: 'state',
  width: 160
}];

const searchFields = [{
  label: '操作者名称',
  name: 'operatorName',
}, {
  label: '操作者Id',
  name: 'operatorId',
}, {
  label: '对象名称',
  name: 'className',
}, {
  label: '对象ID',
  name: 'referenceId',
}];


const AuditList: FC<AuditListProps> = (props) => {
  const { entities, loading, getEntities, more, context } = props;
  const [filterObj, setFilterObj] = useState({});
  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormDateTimePicker
          name="from"
          placeholder="操作开始时间"
          initialValue={getDate(-30)}
          rules={[{ required: true }]}
        />
        <ProFormDateTimePicker
          name="to"
          placeholder="操作结束时间"
          initialValue={getDate(0)}
          rules={[{ required: true }]}
        />
        <SearchBox
          fields={searchFields}
          width={300}
        />
      </Input.Group>
    </Form.Item>
  );

  // 过滤器面板
  const handleFilterChange = (value: FilterCriteria) => {
    const keys = value ? Object.keys(value) : [];
    const newAllValues = JSON.parse(JSON.stringify(value));
    keys.forEach(e => {
      typeof (value[e]) === 'object' && value[e] && !(value[e] instanceof Array) && (newAllValues[e] = moment(value[e]).format('YYYY-MM-DD HH:mm:ss'))
    });
    delete newAllValues.context;
    getEntities(newAllValues);
    setFilterObj(newAllValues);
  };

  return (
    <PageContainer
      title={'审计日志'}
      breadcrumb={{}}
      extra={quickFilterPanel &&
        <QuickFilter
          loading={loading}
          filters={{}}
          hasAdvance={false}
          onFilterChange={handleFilterChange}
          onCollapseAdvanceFilter={() => { }}
        >
          {quickFilterPanel}
        </QuickFilter>}
    >
      <ProTable<Audit>
        rowKey="id"
        search={false}
        dateFormatter="string"
        scroll={{ x: 1500 }}
        columns={AuditColumns}
        dataSource={entities}
        loading={loading}
        pagination={false}
        options={{
          reload: false
        }}
      />
      {more && <div className="more-box">
        <Button type="primary" onClick={() => {
          getEntities({
            ...filterObj,
            context,
          })
        }}>查看更多</Button>
      </div>}
      {
        !more && entities.length > 0 && <div className="more-no">没有更多了～</div>
      }
    </PageContainer>

  );
};

const mapStateToProps = ({ audit }: RootState) => ({
  entities: audit.entities,
  loading: audit.loading,
  context: audit.context,
  more: audit.more
});

const mapDispatchToProps = {
  getEntities
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AuditList);