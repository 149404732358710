import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, getStation, reset, updateEntity, getDept } from "./user.reducer";
import { ProFormCheckbox, ProFormDatePicker, ProFormGroup, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { Col, Form, Row, TreeSelect } from "antd";
import { User } from "model/system/user.model";
import ProCard from "@ant-design/pro-card";
import { fetchRoleList, fetchTenantList } from "../role/role.reducer";
import { Role } from "model/system/role.model";
import { toTreeData } from "utils/utils";
import { TreeData } from "components/model/form.model";
import { Dept } from "model/system/dept.model";
export const loginType = [{
  label:'企业微信',
  value:'wecom'
},{
  label:'账号密码',
  value:'password'
}]
export interface UserEditProps extends EditStateAndDispatchProps<User>, StateProps, DispatchProps, RouteComponentProps {
}

const UserEdit: FC<UserEditProps> = props => {
  const { ...editProps } = props;
  const { entity } = props;
  const [deptData, setDeptData] = useState<TreeData>();
  const [roleList, setRoleList] = useState<any>([]); //角色option
  const [form] = Form.useForm();
  const [tenantList, setTenantList] = useState<any>([]); //角色option

  useEffect(() => {
    const itemArray: any = [];
    entity.depts && entity.depts.map((item: Dept) => itemArray.push(item.id));
    form.setFieldsValue({
      ...entity,
      depts: itemArray
    })
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity])

  useEffect(() => {
    // 获取角色列表
    fetchRoleList({ page: 0, size: 500 }).then(e => {
      setRoleList(e.data.map((item: Role) => ({
        label: item.name,
        value: item.id?.toString() || ''
      })));
    });
    // 获取应用列表
    fetchTenantList().then((e) => {
      let data = e.data.map((item: any) => {
        return {
          label: item.name,
          value: item.id
        }
      })
      setTenantList(data)
    })
  }, [])

  useEffect(() => {
    getDept().then((data) => {
      setDeptData(toTreeData(data, item => item?.children || [], item => item?.deptName || ''))
    })
  }, []);

  const modify = (res: any) => {
    return new Promise<User>((resolve, reject) => {
      if (res) {
        res.depts = res.depts && res.depts.map((item: Dept) => { return { id: item } });
        res.roleIds = res.roleIds && res.roleIds.join(',').split(',');
        res.postIds = res.postIds && res.postIds.join(',').split(',');
        resolve(res);
      }
    })
  }

  return (
    <EditPage<User>
      title="用户"
      beforeUpdateEntity={modify}
      form={form}
      {...editProps}
    >
      <ProCard
        title="基础信息"
        bordered
        headerBordered
        collapsible
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <ProFormText
          name="operation"
          label=""
          hidden
          initialValue={1}
        />
        <Row justify='start'>
          <Col span="7">
            <ProFormText
              name="account"
              label="登录账号"
              rules={[{ required: true }]}
            />
          </Col>
          <Col span="1" />
          <Col span={7}>
            <ProFormCheckbox.Group
              name={'loginType'}
              layout="horizontal"
              label="登录方式"
              options={loginType}
            />
          </Col>
          <Col span="1" />
          <Col span="7">
            {!entity.id && <ProFormText.Password
              name="password"
              label="登录密码"
              rules={[{ required: true }]}
            />}
          </Col>
          <Col span="1" />
          <Col span="7">
            {!entity.id && <ProFormText.Password
              name="confirm"
              label="确认密码"
              rules={[{ required: true }]}
            />}
          </Col>
        </Row>
      </ProCard>
      <ProCard
        title="详细信息"
        bordered
        headerBordered
        collapsible
        defaultCollapsed
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <Row justify='start'>
          <Col span="7">
            <ProFormText
              name="name"
              label="用户昵称"
              rules={[{ required: true }]}
            />
          </Col>
          <Col span="1" />
          <Col span="7">
            <ProFormText
              name="realName"
              label="用户姓名"
            />
          </Col>
          <Col span="1" />
          <Col span="7">
            <ProFormText
              name="phone"
              label="手机号码"
            />
          </Col>
          <Col span="7">
            <ProFormText
              name="email"
              label="电子邮箱"
            />
          </Col>
          <Col span="1" />
          <Col span="7">
            <ProFormSelect
              request={async () => {
                return [{
                  label: "男",
                  value: 1,
                }, {
                  label: "女",
                  value: 2,
                }]
              }}
              width="sm"
              name="sex"
              label="用户性别"
            />
          </Col>
          <Col span="1" />
          <Col span="7">
            <ProFormDatePicker
              name="birthday"
              label="用户生日"
              width="md"
            />
          </Col>
        </Row>
      </ProCard>
      <ProCard
        title="职责信息"
        bordered
        headerBordered
        collapsible
        defaultCollapsed
        style={{
          marginBottom: 16,
        }}
      >
        <ProFormGroup>
          <ProFormText
            name="code"
            label="用户编码"
            width='md'
            rules={[{
              pattern: RegExp(/^[a-zA-Z0-9_]+$/i),
              message: '仅限输入英文数字下划线，不可有空格及符号'
            }]}
          />
          <ProFormSelect
            width='md'
            options={roleList}
            name="roleIds"
            label="所属角色"
            mode="multiple"
            rules={[{ required: true }]}
          />
        </ProFormGroup>
        <ProFormGroup>
          <Form.Item
            style={{ width: '330px' }}
            name="depts"
            label="所属部门"
          >
            <TreeSelect
              showSearch
              multiple
              treeData={deptData}
              treeCheckable
              placeholder="请选择"
              showCheckedStrategy="SHOW_PARENT"
            />
          </Form.Item >
          <ProFormSelect
            width='md'
            request={async () => {
              const Request = await getStation();
              return Request.map((item: any) => ({
                value: item?.id,
                label: item?.postName
              }))
            }}
            name="postIds"
            label="所属岗位"
            mode="multiple"
            rules={[{ required: true }]}
          />

          <ProFormSelect
            width='md'
            options={tenantList}
            name="tenantIds"
            label="所属应用"
            mode="multiple"
            rules={[{ required: true }]}
          />
        </ProFormGroup>
      </ProCard>
    </EditPage>);
};

const mapStateToProps = ({ user }: RootState) => ({
  entity: user.entity,
  updateSuccess: user.updateSuccess,
  updating: user.updating,
  createSuccess: user.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UserEdit);
