import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import TrainListDetails from './trainList-details';
import TrainListEdit from './trainList-edit';
import TrainListList from './trainList-list';

const TrainList: React.FC<RouteComponentProps> = () => (
  <Router>
    <TrainListList path="/" />
    <TrainListDetails path=":id" />
    <TrainListEdit path=":id/edit" />
    <TrainListEdit path="new" />
  </Router>
);
export default TrainList;
