import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import RoleDetails from "./role-details";
import { deleteEntity, getEntities, getEntity } from "./role.reducer";
import { RouteComponentProps } from "@reach/router";
import { Form, Input } from "antd";
import SearchBox from "components/search-box";
import { Role } from "model/system/role.model";
import RoleEdit from "./role-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";


export interface RoleListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<Role>[] = [{
  title: 'id',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '角色编码',
  dataIndex: 'code',
  valueType: 'text',
}, {
  title: '角色名称',
  dataIndex: 'name',
}]

const RoleList: FC<RoleListProps> = (props) => {
  const searchFields = [{
    label: '角色名称',
    name: 'name.contains',
  }, {
    label: 'code',
    name: 'code.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Role>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<RoleDetails navigate={props.navigate} columns={columns} />}
      sort="" 
      editPage={(params: any) => <RoleEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ role }: RootState) => ({
  entity: role.entity,
  entities: role.entities,
  loading: role.loading,
  updateSuccess: role.updateSuccess,
  createSuccess: role.createSuccess,
  total: role.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RoleList);