import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultSplitOrderType, SplitOrderType } from 'model/product/split-order-type.model';
import axios from 'axios';


const entity = 'SplitOrderType';
const apiUrl = '/api/yumei-mall-pim/split-order-type';
const standardReducerImpl = new StandardReducerImpl<SplitOrderType>(entity, defaultSplitOrderType, apiUrl);
export type SplitOrderTypeState = StandardState<SplitOrderType>;
export const splitOrderType = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


//拆单分类列表
export const fetchSplitOrderTypeList = async (params?: any) => {
  const requestUrl = `/api/yumei-mall-pim/split-order-type`;
  const axiosResponse = await axios.get<any>(requestUrl, { params: { ...params, size: 100 } });
  return axiosResponse.data;
}
