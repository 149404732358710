import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import SendOutGoodsDetails from './sendOutGoods-details';
import SendOutGoodsEdit from './sendOutGoods-edit';
import SendOutGoodsList from './sendOutGoods-list';

const SendOutGoods: React.FC<RouteComponentProps> = () => (
  <Router>
    <SendOutGoodsList path="/" />
    <SendOutGoodsDetails path=":id" />
    <SendOutGoodsEdit path=":id/edit" />
    <SendOutGoodsEdit path="new" />
  </Router>
);
export default SendOutGoods;
