import React, { FC, memo } from 'react';
import { Image, Button } from 'antd';
import './goods-list.less';
import holderImg from '../../../assets/images/public/holder.jpg';
import { getGlobalKeyValue } from 'utils/utils';
import { internationalList } from 'components/IntelFormText';
import { useParamGeneral } from 'components/useParamGenera';
interface GoodsListProps {
  list: { [key: string]: any }[]
  type: string
  borderRadius?: string
  dataField?: any
}
//商品列表组件
const GoodsItem: FC<{ list: any[], type?: ('scroll' | 'list' | 'card' | 'big-img' | 'collageList') }> = memo(({ list, type }) => {
  const { currency } = useParamGeneral();
  return <>
    {
      list.length > 0 && list?.map((v: any, i: number) => {
        const mainPicture = internationalList(v.mainPicture || '');
        return <div className={`${type === 'scroll' ? 'scroll-item' : ''} ${type === 'list' ? 'list-item' : ''} ${type === 'collageList' ? 'collageList-item' : ''} ${type === 'big-img' ? 'big-img-item' : ''} item`} key={i}>
          <div className="img">
            {
              type === 'big-img' && mainPicture && <Image className="i" fallback={holderImg} preview={false} src={(getGlobalKeyValue(v.banner, 'zh-CN') || getGlobalKeyValue(mainPicture, 'zh-CN')).indexOf('http') === 0 ? (v.banner || mainPicture) + '?imageView2/2/w/500' : (v.banner || mainPicture)}></Image>
            }
            {
              type !== 'big-img' && mainPicture && <Image className="i" fallback={holderImg} preview={false} src={(mainPicture).indexOf('http') === 0 ? (mainPicture) + '?imageView2/2/w/500' : (mainPicture)}></Image>
            }
          </div>
          <div className="info">
            <p className="name text-2">{internationalList(v.name || '')}</p>
            {type !== 'collageList' && <div className="price">
              <span className="l">{currency?.symbol}</span><span className="c">{v.retailPriceFloor}</span><span className="r">{v.retailPriceFloat}</span>
            </div>}
            {type === 'collageList' && <div className='dumpling-box'>{v.grouponProduct?.quantity || 0}人团</div>}
            {type === 'collageList' && <div className="price">
              <p className='retailPrice'><span>{currency?.symbol}</span> <span className='num'>{v.grouponProduct?.grouponPrice || 0}</span></p>
              <p className='original'>{currency?.symbol}{v.retailPriceFloor}</p>
              <span className='text'>去开团</span>
            </div>}
            {
              type === 'big-img' && mainPicture && <Button type="primary" className="buy-btn">{v.status === 'SELLING' ? '立即抢购' : '即将开始'}</Button>

            }
          </div>
        </div>
      })
    }
  </>
})
const GoodsList: FC<GoodsListProps> = (({ list, type, borderRadius, dataField }) => {
  type = type && type !== 'two' ? type : 'card';
  let left: any[] = [], right: any[] = [];
  const retailPriceKey: string = dataField?.SkytoneCard ? 'salePrice' : 'retailPrice';
  list = list?.map((v: any) => {
    let retailPrice =
      v.items && v.items[0] && v.items[0][retailPriceKey]
        ? v.items[0][retailPriceKey]
        : v[retailPriceKey];
    v.retailPriceFloat = ((retailPrice * 1) % 1)
      .toFixed(2)
      .replace("0.", ".");
    v.retailPriceFloor = Math.floor(retailPrice);
    return v
  })
  if (type === 'card') {
    list?.forEach((v: any) => {
      if (left.length <= right.length) {
        left.push(v)
      } else {
        right.push(v)
      }
    })
  }
  return (
    <div className="goods-container">
      {

        type === 'card' && (<div className="goods-list clearfix" >
          <div className="left">
            <GoodsItem list={left} />
          </div>
          <div className="right">
            <GoodsItem list={right} />
          </div>
        </div>)
      }
      {

        (type === 'big-img' || type === 'list') && (<div className="goods-list  clearfix" >
          <GoodsItem type={type} list={list} />
        </div>)
      }
      {

        (type === 'collageList') && (<div className="goods-list  clearfix" >
          <GoodsItem type={type} list={list} />
        </div>)
      }

      {
        type === 'slide-x' && (
          <div className={`scroll-con `} style={
            {
              borderRadius: `${borderRadius}`
            }
          }>
            <div className="scroll-list" style={
              {
                'width': (108 * list.length) + 'px'
              }
            }>
              <GoodsItem type="scroll" list={list} />
            </div>
          </div>
        )
      }
    </div>
  )
})
export default GoodsList
