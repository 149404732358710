import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { fetchLevelList, getEntity } from './pricingRules.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Level } from "model/customerCore/level.model";
import { Table } from "antd";
import { ColumnsType } from "antd/lib/table";
import { internationalList } from "components/IntelFormText";

export interface LevelDetailsProps extends DetailsStateAndDispatchProps<Level>,
  RouteComponentProps, StateProps, DispatchProps {
}
const PricingRulesDetails: FC<LevelDetailsProps> = (props) => {
  const [levelData, setlevelData]: any = useState([]);
  const [levelList, setLevelList]: any = useState([]); // 等级列表

  useEffect(() => {
    let list: any = []
    let datas: any = props.entity
    if (datas.ruleDetailMap) {
      for (let i in datas.ruleDetailMap) {
        list.push({
          discount: datas.ruleDetailMap[i].discount,
          remark: datas.ruleDetailMap[i].remark,
          levelNo: i
        })
      }
    }
    setlevelData([...list])
    getLevelList()
  }, [props])

  // 获取等级列表
  const getLevelList = async () => {
    const unitRequest = await fetchLevelList();
    let data = unitRequest.map((item: any) => ({
      value: item?.levelNo,
      label: internationalList(item?.levelName)
    }))
    setLevelList(data)
  }

  interface DataType {
    levelNo: string;
    discount: string;
    remark: string;
  }
  const columns: ColumnsType<DataType> = [
    {
      title: '级别类型',
      dataIndex: 'levelNo',
      key: 'levelNo',
      render: (text) => {
        let row: any = levelList.find((item: any) => item.value === text)
        return row ? row?.label : ''
      }
    },
    {
      title: '折扣率',
      dataIndex: 'discount',
      key: 'discount',
    },
    {
      title: '备注',
      dataIndex: 'remark',
      key: 'remark',
    }]
  return (
    <DetailsPage<Level>
      title={'定价规则详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Level>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>
      <Table columns={columns} dataSource={levelData} pagination={false} />

    </DetailsPage>);
};

const mapStateToProps = ({ level }: RootState) => ({
  entity: level.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PricingRulesDetails);
