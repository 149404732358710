import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PayeeEnterpriseDetails from './payee-enterprise-details';
import PayeeEnterpriseEdit from './payee-enterprise-edit';
import PayeeEnterpriseList from './payee-enterprise-list';

const PayeeEnterprise: React.FC<RouteComponentProps> = () => (
  <Router>
    <PayeeEnterpriseList path="/" />
    <PayeeEnterpriseDetails path=":id" />
    <PayeeEnterpriseEdit path=":id/edit" />
    <PayeeEnterpriseEdit path="new" />
  </Router>
);
export default PayeeEnterprise;
