import React, { FC, useCallback, useState, memo, useEffect } from 'react';
import { Image, Upload, Input, Button, Tooltip, Select, message } from 'antd';
import { PlusOutlined, FormOutlined, ArrowUpOutlined, ArrowDownOutlined, DeleteOutlined } from '@ant-design/icons';
import '../style/tab_nav.less';
import holderImg from '../../../../assets/images/public/holder.jpg';
import { uploadImage } from "utils/file-utils";
const { Option } = Select;
export interface Props {
    dataField: {
        nav: any[]
        [key: string]: any
    }
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const TabNav: FC<Props> = ({ dataField }) => {
    return (
        <div className="tab-nav-container" >
            <div className="image-ad-list">
                {
                    (dataField?.nav.length > 0 ? dataField?.nav : [{}, {}, {}, {}, {}, {}]).map((v: any, i: number) => {
                        return <div className="nav-item" key={i}>
                            <div className="img">
                                <Image className="i" preview={false} src={v.img || (holderImg)}></Image>

                            </div>
                            <p className="txt text-1">{v.title || '标题'}</p>
                        </div>
                    })
                }
            </div>
        </div>
    )
}

interface ImageItemProps {
    id: string
    index: number
    data?: any
    updateModuleDataPrivate: (dataField: any, index: number, type?: string) => void
    setShowLinkSelectionPrivate: (show: boolean) => void
    setCallerIndex: (index: number) => void
}

const ImageItem: FC<ImageItemProps> = (({ data, id, index, updateModuleDataPrivate, setShowLinkSelectionPrivate, setCallerIndex }) => {
    const OpenType: FC<{ defaultValue: string }> = ({ defaultValue }) => {
        return (
            <Select defaultValue={defaultValue} onChange={
                (e) => {
                    data.type = e
                    updateModuleDataPrivate(data, index, '')
                }
            } className="select-after">
                <Option value="navigateTo">新窗口</Option>
                <Option value="switchTab">TAB切换</Option>
                <Option value="redirectTo">重定向</Option>
                <Option value="back">返回</Option>
            </Select>
        );
    }
    const uploadProps = {
        multiple: false,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`
            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                updateModuleDataPrivate({
                    ...data,
                    'img': res
                }, index, '')
            }).catch(() => {message.error('图片上传失败')});
        },
    };
    return <div className={`image-item`} >
        <div className="top-con">
            <div className="img">
                <Upload {...uploadProps}>
                    <div className="change-img" >
                        <Tooltip title="更换">
                            <FormOutlined />
                        </Tooltip>
                    </div>
                </Upload>
                <Image preview={true} src={data.img + '?imageView2/2/w/120'}></Image>
            </div>
            <div className="info">
                <div className="input-group">
                    <Input placeholder="请输入导航标题" onChange={(e) => {
                        data.title = e.target.value;
                        updateModuleDataPrivate(data, index, '')
                    }} value={data.title} />
                    <Input.Group compact className="input-item">
                        <Button type="primary" onClick={
                            () => {
                                setCallerIndex(index)
                                setShowLinkSelectionPrivate(true,)
                            }
                        }>选择</Button>
                        <Input addonAfter={<OpenType defaultValue={data.type || 'navigateTo'} />} placeholder="请输入/选择链接地址" onChange={(e) => {
                            data.link = e.target.value;
                            updateModuleDataPrivate(data, index, '')
                        }} value={data.link} />

                    </Input.Group>
                </div>
            </div>
        </div>

        <div className="operation">
            {/* <div className="icon">{index + 1}</div> */}
            <div className="icon " onClick={
                () => {
                    updateModuleDataPrivate(data, index, 'moveUp')
                }
            }>
                <Tooltip title="上移">
                    <ArrowUpOutlined />
                </Tooltip>
            </div>

            <div className="icon" onClick={
                () => {
                    updateModuleDataPrivate(data, index, 'moveDown')
                }
            }>
                <Tooltip title="下移">
                    <ArrowDownOutlined />
                </Tooltip>
            </div>
            <div className="icon" onClick={
                () => {
                    updateModuleDataPrivate(data, index, 'delete')
                }
            }>
                <Tooltip title="移除" >
                    <DeleteOutlined />
                </Tooltip>
            </div>

        </div>
    </div>
})
export const TabNavSet: FC<SetProps> = memo(({ dataField, id, methods, selectedInfo, }) => {

    const { Dragger } = Upload;
    const [dataFieldPrivate, setDataFieldPrivate] = useState(dataField)
    const [callerIndex, setCallerIndex] = useState(-1)
    const updateModuleDataPrivate = useCallback(
        (item:any, index:any, type:any) => {//type delete up down
            let newDataFieldPrivate = dataFieldPrivate
            if (!type) {
                newDataFieldPrivate.nav[index] = item
            } else if (type === 'delete') {
                delete newDataFieldPrivate.nav[index]
                newDataFieldPrivate.nav = newDataFieldPrivate.nav.filter((v: any) => v)
            } else if (type === 'moveDown') {
                let nextIndex = index + 1, length = newDataFieldPrivate.nav.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.nav);
                if (nextIndex > length - 1) {
                    nextIndex = 0
                }
                nextItem = newDataFieldPrivate.nav[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.nav = newImageList
            } else if (type === 'moveUp') {
                let nextIndex = index - 1, length = newDataFieldPrivate.nav.length, nextItem, newImageList: any[] = [].concat(newDataFieldPrivate.nav);
                if (nextIndex < 0) {
                    nextIndex = length - 1
                }
                nextItem = newDataFieldPrivate.nav[nextIndex];
                newImageList[nextIndex] = item
                newImageList[index] = nextItem
                newDataFieldPrivate.nav = newImageList
            } else if (type === 'add') {
                if (item instanceof Array) {
                    newDataFieldPrivate.nav = [...item, ...newDataFieldPrivate.nav]
                } else {
                    newDataFieldPrivate.nav.unshift(item)
                    newDataFieldPrivate.nav = [].concat(newDataFieldPrivate.nav)
                }
            }
            // newDataFieldPrivate
            // console.log('newDataFieldPrivate',newDataFieldPrivate)
            methods.updateModuleData(newDataFieldPrivate, id)
        },
        [dataFieldPrivate, methods, id]
    )
    useEffect(
        () => {
            if (!selectedInfo || !selectedInfo.path) return
            let callItem = { ...dataFieldPrivate.nav[callerIndex] }
            callItem.link = selectedInfo.path
            callItem.type = selectedInfo.openType
            updateModuleDataPrivate(callItem, callerIndex, '')
        },
        // eslint-disable-next-line react-hooks/exhaustive-deps
        [selectedInfo]
    )
    useEffect(() => {
        setDataFieldPrivate(dataField)
    }, [dataField])
    const DraggerUploadProps = {
        name: 'file',
        multiple: true,
        showUploadList: false,
        customRequest(e: any) {
            let imageName = `img-${(+new Date())}-${(Math.random() * 10000).toFixed(0)}`

            uploadImage(e.file, 'minipage/' + imageName).then(async res => {
                let imageItem = { "img": res, "hint": "", "link": "", "title": "" }
                updateModuleDataPrivate(imageItem, 0, 'add')
            }).catch(() => {message.error('图片上传失败')});
        },
        onChange(info: any) {
            const { status } = info.file;
            if (status !== 'uploading') {
                // console.log(info.file, info.fileList);
            }
            if (status === 'done') {
                // message.success(`${info.file.name} file uploaded successfully.`);
            } else if (status === 'error') {
                // message.error(`${info.file.name} file upload failed.`);
            }
        },
        onDrop(e: any) {
          
        },
    };

    return (
        <div className="tab-nav-set" >

            <div className="dragger-con">
                <Dragger {...DraggerUploadProps} className="add-area">
                    <p className="desc">
                        <PlusOutlined className="icon" />
                    </p>
                    <p className="desc">点击/拖放以新增图片</p>
                </Dragger>
            </div>
            <div className="image-list">
                {dataFieldPrivate?.nav.map((v: any, i: number) => {
                    return v.type !== 'config' && <ImageItem
                        key={i}
                        id={v.id}
                        index={i}
                        data={v}
                        updateModuleDataPrivate={updateModuleDataPrivate}
                        setShowLinkSelectionPrivate={methods.setShowLinkSelectionPrivate}
                        // selectedInfoPrivate={selectedInfoPrivate}
                        // callerIndex={callerIndex}
                        setCallerIndex={setCallerIndex}
                    />
                })}
            </div>

        </div>
    )
})

export default TabNav