import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ArticleDetails from './article-details';
import ArticleEdit from './article-edit';
import ArticleList from './article-list';

const Article: React.FC<RouteComponentProps> = () => (
  <Router>
    <ArticleList path="/" />
    <ArticleDetails path=":id" />
    <ArticleEdit path=":id/edit" />
    <ArticleEdit path="new" />
  </Router>
);
export default Article;
