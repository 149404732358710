import React, { FC, useEffect, useRef, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { editLogistics, fetchLogList, fetchVerificationList, getEntity } from './exchange.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import { Exchange } from "model/procure/exchange.model";
import { useEnumValue } from "common/enum/use-enum-value";
import { useParamGeneral } from "components/useParamGenera";
import ProTable, { ActionType, ProColumns } from "@ant-design/pro-table";
import { LinesItem, PackageList } from "model/procure/order.model";
import { Button, Drawer, Space, Tag, Timeline, message } from "antd";
import { EnvironmentOutlined, EyeOutlined, ReloadOutlined } from "@ant-design/icons";
import { internationalList } from "components/IntelFormText";
import { fetchExchangePackage, pushCenter, queryLogistic } from "../../procure/purchase-order/order.reducer";
import { Content } from "antd/lib/layout/layout";
import AuditModalForm from "pages/system/audit/audit-modal-form";
import ProForm, { ModalForm, ProFormText } from "@ant-design/pro-form";
import Title from "antd/lib/typography/Title";
import Deadline from "components/deadLine";
import SelectSearch from "components/select-search";
import { fetchExpressNameList } from "pages/system/express-company/express.reducer";

const pimColumns: any = [{
  title: '商品名称',
  dataIndex: 'productName',
  width: 100,
  fixed: 'left',
  render: (text: any, row: any) => {
    return <p>{internationalList(row.productName || '')}</p>
  }
}, {
  title: 'SPU编码',
  dataIndex: 'productCode',
  width: 70,
}, {
  title: 'SKU编码',
  dataIndex: 'skuCode',
  width: 70,
}, {
  title: '规格',
  dataIndex: '',
  width: 100,
  render: (text: any, record: any) => {
    console.log(record)
    return record.specName.length > 0 && record.specName.map((tag: any) => <span>{tag.k}:{tag.v}，</span>)
  },
}, {
  title: '单价',
  dataIndex: 'purchasePrice',
  width: 80,
}, {
  title: '数量',
  dataIndex: 'quantity',
  width: 80,
}, {
  title: '行小计',
  dataIndex: 'subtotal',
  width: 200,
}];

export const InspectionColumnsRead = pimColumns.concat([{
  title: '换货类型',
  dataIndex: 'exchangeType',
  width: 100,
  valueType: 'exchangeType',
}, {
  title: '质量问题',
  dataIndex: 'qualityProblem',
  width: 100,
  valueType: 'qualityProblem',
}, {
  title: '工厂',
  dataIndex: 'exchangeFactory',
  width: 100,
  valueType: 'exchangeFactory',
}, {
  title: '标签',
  dataIndex: 'exchangeLabel',
  width: 100,
  valueType: 'exchangeLabel',
}, {
  title: '备注',
  dataIndex: 'remark',
  width: 200,
}]);

const payListColumns = [{
  title: '支付类型',
  dataIndex: 'type',
  width: 100,
}, {
  title: '支付方式',
  dataIndex: 'payTypeName',
  width: 100,
}, {
  title: '支付金额',
  dataIndex: 'payAmount',
  width: 100,
}, {
  title: '支付时间',
  dataIndex: 'payTime',
  width: 100,
}, {
  title: '商户号',
  dataIndex: 'orderNo',
  width: 100,
}, {
  title: '流水单号',
  dataIndex: 'accpTxno',
  width: 100,
},]

export interface ExchangeDetailsProps extends DetailsStateAndDispatchProps<Exchange, 'exchangeOrderStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
  id?: string;
  type?: string;
  listType?: 'order' | 'wholesale';
  detailText?: string;
  detailRow?: any;
}
const ExchangeDetails: FC<ExchangeDetailsProps> = (props) => {
  const { entity, listType, getEntity } = props;
  const { getEnumValueDesc } = useEnumValue();
  const [trajectoryList, setTrajectoryList] = useState<any>([]);
  const [verificationList, setVerificationList] = useState<any>([]);
  const [payList, setPayList] = useState<any>([]);
  const [logList, setLogList] = useState<any>([]);
  const [expressName, setExpressName] = useState<any>('');
  const [logisticsShow, SetLogisticsShow] = useState<any>(false);
  const [expressVisible, SetExpressVisible] = useState<any>(false);
  const [expressObj, SetExpressObj] = useState<any>(false);
  const { currency } = useParamGeneral();

  const ref = useRef<ActionType>();

  useEffect(() => {
    if (entity.id) {
      fetchVerificationList(entity.id).then(e => {
        setVerificationList(e)
      });
      fetchLogList({'id.equals':entity.id,sort:'lastModifiedDate,desc',size:200,page:0}).then(e => {
        setLogList(e)
      });
      setPayList([{
        type: '订单支付',
        payAmount: entity.payAmount,
        payTime: entity.payTime,
        payTypeName: entity.payTypeName,
        orderNo: entity.orderNo,
        accpTxno: entity.accpTxno,
      }, {
        type: '补差价支付',
        payAmount: entity.lossAmount,
        payTime: entity.lossPayTime,
        payTypeName: entity.lossPayTypeName,
        orderNo: entity.lossOrderNo,
        accpTxno: entity.lossAccpTxno,
      }])
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity.id]);

  const logColumns:any = [{
    title: '账号',
    width: 100,
    dataIndex: 'account',
  }, {
    title: '用户名',
    width: 100,
    dataIndex: 'userName',
  }, {
    title: '操作时间',
    width: 100,
    dataIndex: 'createdDate',
  }, {
    title: '操作内容',
    width: 100,
    dataIndex: 'content',
  }, ];


  // 物流Columns
  const expressColumns: ProColumns<PackageList>[] = [{
    title: '运单号',
    dataIndex: 'actualExpressNo',
    width: 150,
  }, {
    title: '快递公司',
    dataIndex: 'actualExpressName',
    width: 150,
  },
  {
    title: '发货时间',
    width: 100,
    dataIndex: 'shipTime',
  },
  {
    title: '物流轨迹',
    width: 100,
    render: (text, record: any) => {
      return <Button size="small" type='link' style={{ padding: '0' }} onClick={() => loogwl(record)} icon={<EyeOutlined />}>查看</Button>
    }
  }];
  // 退回物流信息Columns
  const expressC: ProColumns<PackageList>[] = [{
    title: '运单号',
    width: 100,
    dataIndex: 'expressNo',
  }, {
    title: '快递公司',
    width: 100,
    dataIndex: 'expressName',
  }, {
    title: '发货时间',
    width: 100,
    dataIndex: 'shipTime',
  }, {
    title: '物流轨迹',
    width: 100,
    render: (text: any, record: any) => {
      return <Button size="small" type='link' style={{ padding: '0' }} onClick={() => loogwl(record)} icon={<EyeOutlined />}>查看</Button>
    }
  }, {
    title: '操作',
    width: 100,
    render: (text: any, record: any) => {
      return <Button onClick={() => {
        SetExpressVisible(true);
        SetExpressObj(record);
      }} type="link" size="small" >修改物流</Button>
    }
  }];

  // 商品信息columns
  const columns: ProColumns<LinesItem, 'purchaseOrderLineStatus'>[] = [{
    title: 'SPU编码',
    dataIndex: 'productCode',
    width: 70,
  }, {
    title: 'SKU编码',
    dataIndex: 'skuCode',
    width: 70,
  }, {
    title: '商品名称',
    dataIndex: '',
    width: 100,
    fixed: 'left',
    render: (text: any, row: any) => {
      return <p>{internationalList(row.productName || '')}</p>
    }
  }, {
    title: '规格',
    dataIndex: 'sonItem',
    width: 100,
    render: (text, row) => {
      return row?.specName?.map((v: any) => {
        return <><span>{v.v}，</span></>
      })
    }
  }, {
    title: '商品单价',
    width: 100,
    dataIndex: 'purchasePrice',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  }, {
    title: '包装费',
    width: 100,
    dataIndex: 'packageAmount',
  },
  {
    title: '数量',
    width: 100,
    dataIndex: 'quantity',
  }, {
    title: '行小计',
    width: 100,
    dataIndex: 'subtotal',
    render: (text) => {
      return currency?.symbol + ' ' + text
    }
  }, {
    title: '发货状态',
    width: 100,
    dataIndex: 'status',
    render: (text, record: any) => {
      return getEnumValueDesc('exchangeOrderLineStatus', record.status || '')
    }
  }];

  // 查看物流
  const loogwl = (record: any) => {
    queryLogistic({
      expressNo: record?.expressNo,
      expressCode: record?.expressCode,
      mobile: entity?.address?.mobile,

    }).then((trajectory) => {
      if (trajectory?.traces?.length > 0) {
        setTrajectoryList(trajectory.traces || []);
        SetLogisticsShow(true)
      } else {
        message.error('暂无物流轨迹信息')
      }
    })
  }


  // 详情内容
  const OrderContent = () => {
    return (
      <div className='order-details'>
        <div className='exchangeGoodsOrder'>
          <h3>订单信息</h3>
          <p>订单编号：<span>{entity.orderNo}</span></p>
          <p>订单类型：<span>换货订单</span></p>
          <p>下单时间：<span>{entity.bookTime}</span></p>
          <p>付款时间：<span>{entity.payTime}</span></p>
          <p>发货时间：<span>{entity.shipTime}</span></p>
          <p>完成时间：<span>{entity.receiveTime}</span></p>
          <p>差价金额：<span>{entity.lossAmount}</span></p>

          {entity?.type === 'Manual' && <><span>订单备注：{entity.sellerMsg}</span>
            <span>商家备注：{entity.adminMsg}</span></>}
        </div>
        <Space align='start' direction='horizontal' className='order-div-content'>
          <Content className='buyer-seller-wrapper'>
            <h3>买家信息</h3>
            <span>买家姓名: {entity.buyer?.buyerName}</span>
            <span>授权码: {entity.buyer?.buyerCode}</span>
            <span>手机号: {entity.buyer?.buyerPhone}</span>
          </Content>
          {[1, 4].indexOf(entity.expressType || 1) > -1 && <Content className='buyer-seller-wrapper'>
            <h3>收货人信息
            </h3>
            <p>收货人：{entity.address?.consigneeName}</p>
            <p>联系电话：{entity.address?.mobile}</p>
            <p>省市区：{entity.address?.provinceName + ' '}
              {entity.address?.cityName + ' '}
              {entity.address?.districtName + ' '}</p>
            <p>收货地址：{entity.address?.address}</p>
          </Content>}
        </Space>
      </div>
    )
  };


  // 发货/取消订单/退款
  const operation = [
    <>
      <AuditModalForm rowId={entity.id + ''} className='Order' />
    </>,
    <ModalForm
      title="提示"
      width={300}
      trigger={<Button>订单推中台</Button>}
      onFinish={async () => {
        return new Promise<boolean>((resolve, reject) => {
          pushCenter({ id: entity.id }).then(e => {
            message.success('推送成功')
            resolve(true);
          }).catch(e => {
            message.error('推送错误');
            resolve(false);
          })
        });
      }}
    >确认要将订单：{entity.id} 推中台？</ModalForm>
  ];

  const OrderContentTemplate = () => {
    return (<>
      <OrderContent />
      <Title level={5}>退回商品信息</Title>
      <ProTable<LinesItem, Record<string, any>, 'purchaseOrderLineStatus'>
        className='order-details-table'
        columns={columns.concat([{
          title: '物流码',
          dataIndex: 'barCode',
          width: 100,
        },])}
        rowKey="id"
        dataSource={entity?.lines ? entity?.lines.filter((e: any) => !e.isExchange) : []}
        scroll={{ x: 800 }}
        pagination={false}
        options={false}
        search={false}
      />
      <p className="allPrice">商品总价：¥{entity?.refundTotalAmount}</p>
      <Title level={5} style={{ marginTop: '40px' }}>换货商品信息</Title>
      <ProTable<LinesItem, Record<string, any>, 'purchaseOrderLineStatus'>
        className='order-details-table'
        columns={columns.filter(e => e.dataIndex !== 'packageAmount')}
        rowKey="id"
        dataSource={entity?.lines ? entity?.lines.filter((e: any) => e.isExchange) : []}
        scroll={{ x: 800 }}
        pagination={false}
        options={false}
        search={false}
      />
      <p className="allPrice">
        <span>
          商品总价：¥{entity?.exchangeTotalAmount}
        </span>
        <span>
          包装费：¥{entity.packageAmount}
        </span>
        <span>差价金额：¥{entity.differenceAmount}</span>
        <span className="FK">应付金额：</span><span className="FKtext">¥{entity.payAmount}</span>
      </p>

      <Title level={5} style={{ marginTop: '40px' }}>核验复审的商品信息</Title>
      {entity.id && <ProTable<any>
        columns={InspectionColumnsRead}
        rowKey="skuId"
        options={false}
        search={false}
        pagination={false}
        dataSource={verificationList}
      // request={async (params) => {
      //   const result = await fetchVerificationList(entity.id);
      //   return {
      //     success: true,
      //     total: result.length || 0,
      //     data: result,
      //   };
      // }}
      />}
      <p className="allPrice">
        <span>
          商品总价：¥{verificationList.map((e: any) => e.subtotal).reduce((a: number, b: number) => a + b, 0)}
        </span>
        <span>补付金额：¥{entity.lossAmount}</span>
        <span className="FK">应付金额：</span><span className="FKtext">¥{entity.lossAmount}</span>
      </p>
      <Title style={{ marginTop: '30px' }} level={5}>退回物流信息</Title>
      <ProTable<any>
        actionRef={ref}
        className="express-table"
        columns={expressC}
        rowKey="express"
        params={{ id: entity.id }}
        pagination={false}
        dataSource={entity.refundExpress}
        options={false}
        search={false}
      />
      <Title style={{ marginTop: '30px' }} level={5}>发货物流信息<Button type="link" size="small" onClick={() => ref.current?.reload()}><ReloadOutlined />刷新</Button></Title>
      {
        entity?.id && <ProTable<any>
          actionRef={ref}
          className="express-table"
          columns={expressColumns}
          rowKey="express"
          params={{ id: entity.id }}
          pagination={false}
          request={async (params) => {
            let res = await fetchExchangePackage({
              sort: 'lastModifiedDate,desc',
              id: entity.id,
            });
            return Promise.resolve({
              data: res?.data || [],
              success: true,
            });
          }}
          options={false}
          search={false}
        />
      }

      {/* 物流轨迹 */}
      <Drawer
        visible={logisticsShow}
        title='物流轨迹'
        onClose={() => SetLogisticsShow(false)}
        width={1000}
      >
        <div className=''>
          {trajectoryList.length > 0 ?
            <Timeline reverse>
              {trajectoryList.map((e: any, index: Number) => {
                return <Timeline.Item key={e.id} dot={index === trajectoryList.length - 1 && <EnvironmentOutlined />}>
                  <div className='trajectory-box-li'>
                    <p>{index === trajectoryList.length - 1 && <Tag color="#f50">最新</Tag>}{e.status}</p>
                    <p>{e.time}</p>
                  </div>
                </Timeline.Item>
              })}
            </Timeline>
            : <>暂无物流轨迹信息</>}
        </div>
      </Drawer>

      {/* 支付信息 */}
      <div className='order-details'>
        <Title level={5}>支付信息</Title>
        {entity.id && <ProTable<any>
          columns={payListColumns}
          rowKey="skuId"
          options={false}
          search={false}
          pagination={false}
          dataSource={payList}
        />}
      </div>

      {/* 支付信息 */}
      <div className='order-details'>
        <Title level={5}>操作日志</Title>
        {entity.id && <ProTable<any>
          columns={logColumns}
          rowKey="id"
          options={false}
          search={false}
          pagination={false}
          dataSource={logList}
        />}
      </div>
    </>)
  }

  return (
    <DetailsPage<Exchange, 'exchangeOrderStatus'>
      title={<Space size='middle'>订单状态：<span>{getEnumValueDesc('exchangeOrderStatus', entity.orderState || '')}</span></Space>}
      className='order-detail'
      operation={listType === 'order' ? [operation] : []}
      hideEdit={true}
      {...props}
    >
      {entity.orderState === 'TO_BE_PAID' && entity.autoHandleTime && <div style={{ display: 'flex', lineHeight: '40px', marginBottom: '20px' }}>
        <p style={{ marginRight: '20px' }}>请在规定时间内支付，超时订单将自动取消，还剩</p>
        <Deadline deadline={new Date(entity.autoHandleTime).getTime()} />
      </div>}
      {entity.orderState === 'TO_BE_SEND_BACK' && entity.refundHandTime && <div style={{ display: 'flex', lineHeight: '40px', marginBottom: '20px' }}>
        <p style={{ marginRight: '20px' }}>请在规定时间内寄回商品并提交物流信息，超时订单将自动取消，还剩</p>
        <Deadline deadline={new Date(entity.refundHandTime).getTime()} />
      </div>}
      {entity.orderState === 'TO_BE_USER_DIFFERENTIAL' && entity.lossHandleTime && <div style={{ display: 'flex', lineHeight: '40px', marginBottom: '20px' }}>
        <p style={{ marginRight: '20px' }}>请在规定时间内支付，超时订单将自动取消，还剩</p>
        <Deadline deadline={new Date(entity.lossHandleTime).getTime()} />
      </div>}
      {entity.orderState === 'TO_BE_USER_RECEIVED' && entity.receiveTime && <div style={{ display: 'flex', lineHeight: '40px', marginBottom: '20px' }}>
        <p style={{ marginRight: '20px' }}>平台发货后14天，交易将自动完成，还剩</p>
        <Deadline deadline={new Date(entity.receiveTime).getTime()} />
      </div>}
      <OrderContentTemplate />
      <ModalForm
        title={'修改物流信息'}
        modalProps={{
          destroyOnClose: true,
        }}
        visible={expressVisible}
        onVisibleChange={(e) => {
          SetExpressVisible(e);
          e && setExpressName(expressObj.expressName);
        }}
        preserve={false}
        onFinish={async (data: any) => {
          return new Promise<boolean>((resolve, reject) => {
            editLogistics(entity.id, expressObj.expressNo, {
              ...data,
              expressName,
            }).then((e: any) => {
              message.success('操作成功');
              resolve(true);
              getEntity(entity?.id);
            }).catch((error: any) => {
              message.error(error?.response?.data?.detail);
              resolve(false);
            })
          });
        }}
      >
        <ProForm.Item label="快递公司" name={'expressCode'}>
          <SelectSearch optionKey="expressName"
            fetchOptionKey={(e: string) => {
              setExpressName(e)
            }}
            initialValue={expressObj.expressCode}
            placeholder='快递公司'
            required={true}
            optionValue='expressCode'
            defaultParams={''}
            name={'expressCode'}
            width={200}
            labelValue="expressName"
            fetchFunc={fetchExpressNameList}
            searchParams='expressName.contains'
          />
        </ProForm.Item>
        <ProFormText
          width="md"
          name="expressNo"
          label="物流单号"
          initialValue={expressObj.expressNo}
        />
      </ModalForm>
    </DetailsPage>
  );
};

const mapStateToProps = ({ exchange }: RootState) => ({
  entity: exchange.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ExchangeDetails);
