import { FC, ReactElement, useEffect, useState } from 'react'
import { Product } from 'model/product/product.model';
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { ModalForm, ProFormText } from "@ant-design/pro-form";
import '../index'

export interface ProductSelectionFormProps {
    trigger?: string;
    triggerRender?: ReactElement;
    name?: string;
    multiple?: boolean;
    hideSearch?: boolean;
    editSku?: boolean;
    productIds?: string[];
    requestParams?: any;
    fetchFunc?: (params: any) => any;
    onFinish: (result: any[]) => void;
    scrollConfig?: any;
    checkRooms?: any[]
}

const ProductSelectionForm: FC<ProductSelectionFormProps> = (props) => {
    const {
        trigger,
        onFinish,
        triggerRender,
        scrollConfig = {},
        checkRooms
    } = props;

    const columns: ProColumns[] = [
        {
            title: 'ID',
            dataIndex: 'id',
            width: 140,
            hideInSearch: true
        },
        {
            title: '姓名',
            dataIndex: 'name',
            valueType: 'text',
            width: 150,
            ellipsis: true,
            hideInSearch: true
        },

        {
            title: '手机号',
            dataIndex: 'phone',
            valueType: 'text',
            width: 150,
            ellipsis: true,
            hideInSearch: true
        },
        {
            title: '是否携带人员',
            dataIndex: 'isCarry',
            valueType: 'text',
            width: 150,
            ellipsis: true,
            hideInSearch: true,
            render: (dom, record) => {
                return record.isCarry ? '否' : '是'
            }
        },
        {
            title: '房间号',
            dataIndex: 'roomNo',
            width: 120,
            hideInSearch: true,
            render: (dom, record) => {
                console.log(record.roomNo)
                console.log('神奇')

                return <ProFormText
                    name='roomNo'
                    initialValue={record.roomNo}
                    fieldProps={{
                        onChange: (e) => {
                            record.roomNo = e.target.value
                            let chageIndex = checkRoomsData.findIndex((item: any) => item.id === record.id)
                            if (chageIndex > -1) {
                                checkRoomsData[chageIndex].roomNo = e.target.value
                                setCheckRoomsData(checkRoomsData)
                            }
                        }
                    }}
                ></ProFormText>

                // return <Input
                //    key={key}
                //     className='hedite'
                //     value={record.roomNo}
                //     onChange={(e)=> {
                //          record.roomNo = e.target.value || ''
                //          let chageIndex = checkRoomsData.findIndex((item: any) => item.id == record.id)
                //          if (chageIndex > -1) {
                //              checkRoomsData[chageIndex].roomNo = e.target.value
                //              setCheckRoomsData(checkRoomsData)
                //              setKey(++key)

                //          }
                //     }}
                // />




            }
        }];


    const [checkRoomsData, setCheckRoomsData]: any = useState<any[]>([]);
    useEffect(() => {
        setCheckRoomsData(checkRooms)

    }, [checkRooms])

    return (
        <ModalForm<Product[]>
            title="编辑房间号"
            width={800}
            trigger={triggerRender ||
                <div style={{ color: '#1890ff', cursor: 'pointer' }}>
                    {trigger}
                </div>
            }
            onVisibleChange={(e) => {

            }}
            modalProps={{
                destroyOnClose: true,
            }}
            onFinish={async () => {
                let data = checkRoomsData
                //let haslist = data.filter((item: any) => item.roomNo)

                let result = data.map((item: any) => {
                    return {
                        id: item.id,
                        roomNo: item.roomNo || ''
                    }
                })

                return onFinish(result || [])
            }}
        >
            <ProTable<any>

                className="selectPimTable"
                rowKey="id"
                scroll={scrollConfig}
                dateFormatter="string"
                columns={columns}
                pagination={false}
                search={false}
                dataSource={checkRoomsData}
            />

        </ModalForm>
    );
};

export default ProductSelectionForm;