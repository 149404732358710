import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import { User, defaultUser } from "model/system/user.model";
import axios from 'axios';
import { Dept } from 'model/system/dept.model';
import { CrudPutAction } from 'reducers/redux-action.type';

const entity = 'user';
const apiUrl = '/api/yumei-mall-auth/user';
const standardReducerImpl = new StandardReducerImpl<User>(entity, defaultUser, apiUrl);
export type UserState = StandardState<User>;
export const user = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

const tbtMallAuth = '/api/yumei-mall-auth'
//所属岗位
export const getStation = async () => {
  const axiosResponse = await axios.get<any>(`${tbtMallAuth}/post`);
  return axiosResponse.data;
}

//所属部门
export const getDept = async () : Promise<Dept[]> => {
  const axiosResponse = await axios.get<any>(`${tbtMallAuth}/dept`);
  return axiosResponse.data;
}

//重置密码 
export const resetPassword = async (userIds: string | number) => {
  const axiosResponse = await axios.post<any>(`${apiUrl}/reset-password?userIds=${userIds}`);
  return axiosResponse?.data;
}

//后台注销
export const cancellationBackend: CrudPutAction<any> = entity => async dispatch => {
  const requestUrl = `${apiUrl}/logout/${entity.id}/${entity.scope}`;
  return await dispatch({
    type: STANDARD_ACTION_TYPES.UPDATE,
    payload: axios.delete(requestUrl),
  })
};

//修改密码
export const changePassword = async (params: any) => {
  const res = await axios.post<any>(`${apiUrl}/update-password?userId=${params.userId}&oldPassword=${params.oldPassword}&newPassword=${params.newPassword}&confirmPassword=${params.confirmPassword}`, {...params});
  return res?.data;
}