import React, {FC} from "react"
import {RouteComponentProps} from '@reach/router';
import {RootState} from 'reducers';
import {connect} from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import {ProFormGroup, ProFormSelect, ProFormSwitch, ProFormText} from "@ant-design/pro-form";
import {createEntity, getEntity, reset, updateEntity} from "./tenant.reducer";
import { Tenant } from "model/system/tenant";
import { platform } from "model/store/seller.model";

export interface TenantEditProps extends EditStateAndDispatchProps<Tenant>, StateProps, DispatchProps, RouteComponentProps {
}

const TenantEdit: FC<TenantEditProps> = (props) => {

    return (
        <EditPage<Tenant>
            title = "商城"
            containIdCreat={props?.entity?.id ? false : true}
            {...props}
        >
            <ProFormGroup>
                <ProFormText
                    width='md'
                    name = "id"
                    label = "租户ID"
                    disabled={!!props?.entity?.id}
                    rules = {[{required: true}]}
                />
                <ProFormText
                    width='md'
                    name = "name"
                    label = "租户名称"
                    rules = {[{required: true}]}
                />
                <ProFormSelect mode="multiple" width="md" name='platformIds' label="商城平台代码" options={Object.entries<string>(platform).map(([value, label]) => ({ value, label }))} />
                <ProFormSwitch
                    name='defaultSchema'
                    label="是否默认租户"
                    initialValue={false}
                    fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否'
                    }}
                />
                <ProFormSwitch
                    hidden={true}
                    name='disabled'
                    label="默认标识"
                    initialValue={false}
                    fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否'
                    }}
                />
                <ProFormSwitch
                    hidden={true}
                    name='initialized'
                    label="默认标识"
                    initialValue={false}
                    fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否'
                    }}
                />
            </ProFormGroup>
        </EditPage>);
};

const mapStateToProps = ({tenant}: RootState) => ({
  entity: tenant.entity,
  updateSuccess: tenant.updateSuccess,
  updating: tenant.updating,
  createSuccess: tenant.createSuccess,
});

const mapDispatchToProps = {
    getEntity,
    createEntity,
    updateEntity,
    reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TenantEdit);
