import {BaseModel, DisableModel} from 'model/base.model';

export interface Wallet extends BaseModel, DisableModel {
  id?: number;
  customerId?: number;
  userId?: number;
  accountType?: string;
  amount?: number;
  walletDetails?: any
 
}

export const defaultWallet: Readonly<Wallet> = {
};
