import { FC, ReactNode } from "react";
import { Button, Form, Input, Tooltip,Image } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SendOutGoodsDetails from "./sendOutGoods-details";
import { deleteEntity, getEntities, getEntity } from "./sendOutGoods.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SendOutGoodsEdit from "./sendOutGoods-edit";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
import { SendOutGoods } from "model/scanCodeOrder/sendOutGoods.model";
import { ModalForm } from "@ant-design/pro-form";

const shipPimColumns = [{
  title: '商品名称',
  dataIndex: 'productName',
  width: 150,
}, {
  title: '商品图片',
  dataIndex: '',
  width: 80,
  render: (dom:any, record:any) => {
    return  <Image src={record.goodUrl} width={80}></Image>
  }
}, {
  title: '商品编码',
  dataIndex: 'itemCode',
  width: 100,
}, {
  title: '品牌id',
  dataIndex: 'brandId',
  width: 100,
}, {
  title: '价格',
  dataIndex: 'retailPrice',
  width: 60,
}, {
  title: '数量',
  dataIndex: 'quantity',
  width: 60,
}, {
  title: '码集合',
  dataIndex: '',
  width: 300,
  render: (dom: any, record: any) => {
    return <div className="codeRegion">{record.itemUniqueNo.map((item: any, index: number) => {
      return <div className={`codeBox ${record.itemUniqueNo.length % (index + 1) === 1 && 'noneX'}`}>
        <p>物流码：{item.scode}</p>
        <p>sku编码：{item.skuCode}</p>
        <p>规格名称：{item.specName}</p>
        <p>规格编码：{item.specCode}</p>
      </div>
    })}</div>
  },
}]

export const sendOutGoodsRowActions = [
  (dom: ReactNode, record: any) => <ModalForm
    title={'商品详情'}
    trigger={<Tooltip title={'商品详情'} key="AuditReject"><Button type="link" size="small" >商品详情</Button></Tooltip>}
    modalProps={{
      destroyOnClose: true,
    }}
    width={1000}
    preserve={false}
    onFinish={async () => {
      return true;
    }}
  >
    <ProTable<any>
      columns={shipPimColumns}
      rowKey="id"
      dataSource={record.shipmentLine || []}
      options={false}
      bordered
      search={false}
      pagination={false}
    />
  </ModalForm>
]

export interface SendOutGoodsListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const SendOutGoodsList: FC<SendOutGoodsListProps> = (props) => {

  const columns: ProColumns<SendOutGoods>[] = [{
    title: '订单号',
    dataIndex: 'shipmentNo',
    width: 200,
  }, {
    title: '发货人姓名',
    dataIndex: 'shipNodeName',
    width: 200,
  }, {
    title: '发货人授权码',
    dataIndex: 'shipNodeCode',
    width: 200,
  }, {
    title: '收货人姓名',
    dataIndex: 'receiveNodeName',
    width: 200,
  }, {
    title: '收货人授权码',
    dataIndex: 'receiveNodeCode',
    width: 200,
  }, {
    title: '下单时间',
    dataIndex: 'shipTime',
    width: 200,
  }, {
    title: '物流码',
    dataIndex: '',
    width: 200,
    render: (dom, record) => {
      return <>{record.shipmentLine.concat().map((e: any) => {
        return <>{e.itemUniqueNo.map((item: any) => {
          return <p>{item.scode}</p>
        })}</>
      })}</>
    },
  },]


  const searchFields = [{
    label: '订单号',
    name: 'shipmentNo.equals',
  },{
    label: '发货人',
    name: 'shipNodeCode.equals',
  },{
    label: '收货人',
    name: 'receiveNodeName.equals',
  },{
    label: '物流码',
    name: 'text.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SelectSearch placeholder='店铺' required={false} name="storeId.equals" width="md" labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
        <div className="wd10"></div>
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<SendOutGoods>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      togetherParams={{ 'shipmentType.equals': 'Transfer' }}
      detailPage={<SendOutGoodsDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      rowActions={sendOutGoodsRowActions}
      hideCreateButton
      hideDeleteButton
      hideEditButton
      editPage={(params: any) => <SendOutGoodsEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ sendOutGoods }: RootState) => ({
  entity: sendOutGoods.entity,
  entities: sendOutGoods.entities,
  loading: sendOutGoods.loading,
  updateSuccess: sendOutGoods.updateSuccess,
  createSuccess: sendOutGoods.createSuccess,
  total: sendOutGoods.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SendOutGoodsList);