import axios from 'axios';
import { defaultOrderAfterSales, OrderAfterSales } from 'model/procure/order-after-sales.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'after-sale-order';
const apiUrl = '/api/yumei-mall-purchase/purchase-order/refund-reason';
const standardReducerImpl = new StandardReducerImpl<OrderAfterSales>(entity, defaultOrderAfterSales, apiUrl);
export type OrderAfterSalesState = StandardState<OrderAfterSales>;
export const orderAfterSales = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchOrderAfterSalesList = async (params?: any): Promise<OrderAfterSales[]> => {
  const paramsData = {
    size: 20,
    ...params
  };
  const axiosResponse = await axios.get(`${apiUrl}`, { params: { ...paramsData } });
  return axiosResponse.data || [];
}