import { DirectAscent, defaultDirectAscent } from 'model/dealer/directAscent.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'DirectAscent';
const apiUrl = '/api/yumei-mall-post/brand-activity';
const standardReducerImpl = new StandardReducerImpl<DirectAscent>(entity, defaultDirectAscent, apiUrl);
export type DirectAscentState = StandardState<DirectAscent>;
export const directAscent = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

