import { FC, ReactNode, useState } from "react";
import { Form, Input, Tag, Tooltip, Button } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import ListDetails from "./topic-details";
import { deleteEntity, getEntities, getEntity } from "./topic.reducer";
import { RouteComponentProps } from "@reach/router";
import { ProFormDateTimeRangePicker, ProFormText } from "@ant-design/pro-form";
import PostEdit from "./topic-edit";
import './index.less'
import moment from "moment";
import BindCoursesDialog from './bindCoursesDialog'

export interface PostListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
  isModalVisible?: boolean;
}

export const columns: ProColumns[] = [
  {
    title: 'ID',
    dataIndex: 'id',
    width: 90
  },
  {
    title: '专题名称',
    dataIndex: 'lessonName',
    valueType: 'text',
    width: 150,
    ellipsis: true
  },
  {
    title: '专题描述',
    dataIndex: 'introductionTxt',
    valueType: 'text',
    ellipsis: true


  },
  // {
  //   title: '专题封面',
  //   dataIndex: 'pictures',
  //   width: 80,
  //   render: ((res, record) => {
  //     const picture = record.coverImg && <Image alt='' style={{ width: '40px', height: 'auto' }} src={record.coverImg} />;
  //     const video = record.introductionVideoUrl && <Image alt='' style={{ width: '40px', height: 'auto' }} src={record.introductionVideoUrl} />;
  //     return picture || video
  //   })
  // },
 {
    title: '是否启用',
    dataIndex: 'isScheduled',
    valueType: 'text',
    width: 80,
    render: (dom, record) => {
      return <Tag color={record.switchOn === 1 ? '#1890ff' : '#f50'}>{record.switchOn === 1 ? '开启' : '禁用'}</Tag>
    }
  }, {
    title: '创建时间',
    dataIndex: 'createTime',
    width: 100,
    valueType: 'dateTime'
  }];


const PostList: FC<PostListProps> = (props) => {
  const [searchOnValuesChange, setSearchOnValuesChange] = useState({});
  let [record, setRecord] = useState<any>({});
  let [bindCoursesDialogOpen, setBindCoursesDialogOpen] = useState<any>(false);

  const quickFilterPanel = (
    <Form.Item>

      <Input.Group compact>
        <ProFormText
          name={['lessonName.contains']}
          placeholder="专题名称"
        />
        <div className="wd10"></div>
        <ProFormDateTimeRangePicker width={'md'} label="创建时间段" placeholder={['创建开始时间', '创建结束时间']} name='time' fieldProps={{
          format: (value) => value.format('YYYY-MM-DD HH:mm:ss'),
        }} />

      </Input.Group>
    </Form.Item>
  );

  const bindCoursesButtonRender: any = (dom: ReactNode, record: any) => {
    return <Tooltip title="关联课程">
      <Button type="link" size="small" onClick={() => {
        setRecord(record)
        setBindCoursesDialogOpen(true)
      }}>关联课程</Button>
    </Tooltip>
  };


  return (<>
    <ListViewPage
      key={'id'}
      scrollConfig={{ x: 1200 }}
      columns={columns}
      rowActions={[bindCoursesButtonRender]}
      quickFilterPanel={quickFilterPanel}
      togetherParams={searchOnValuesChange}
      onValuesChange={(e: any) => {
        let data:any = {}
        if (e.time && e.time.length > 0) {
          const dateFormat = 'YYYY-MM-DD HH:mm:ss';
           data['crateTime.greaterThanOrEqual'] = moment(new Date(e.time[0])).format(dateFormat)
           data['crateTime.lessThanOrEqual'] = moment(new Date(e.time[1])).format(dateFormat)
        }else {
          data['crateTime.greaterThanOrEqual'] = undefined
          data['crateTime.lessThanOrEqual']  = undefined
        }
        setSearchOnValuesChange(data)
      }}
      detailPage={<ListDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      showResetButton={false}
      sort='lastModifiedDate,desc'
      createButtonText='添加专题'
      editPage={(params) => <PostEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>

      <BindCoursesDialog visible={bindCoursesDialogOpen} record={record} onCancel={() => setBindCoursesDialogOpen(false)} onRecharge={(data: any) => {
        // editePwd(data).then((e: any) => {
        //   message.success('修改成功')
        //   setKey(++key)
        //   setEditePwdDialogOpen(false);
        // }).catch((e: any) => {
        //   message.error(e.response.data.error || '系统异常')
        // })

      }} />
      </>
  );
};

const mapStateToProps = ({ examtopic }: RootState) => ({
  entity: examtopic.entity,
  entities: examtopic.entities,
  loading: examtopic.loading,
  updateSuccess: examtopic.updateSuccess,
  createSuccess: examtopic.createSuccess,
  total: examtopic.total
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostList);