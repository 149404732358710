import React, { FC, } from 'react';
import { Form, Radio, Slider, Popover } from 'antd';
import { SketchPicker } from 'react-color';
import '../style/auxiliary_line.less';

export interface Props {
    dataField?: any
    index: number
}
interface SetProps {
    dataField?: any
    id?: string
    methods?: any
    selectedInfo?: any
    otherInfo?: any
}

const AuxiliaryLine: FC<Props> = ({ dataField }) => {
    return (

        <div className="auxiliary-line-container" >
            <div className="line-" style={
                {
                    margin: `0 ${dataField.line_margin}px`,
                    borderBottom: `${dataField.line_height}px ${dataField.line_style} ${dataField.line_color}`,
                }
            }></div>
        </div>
    )
}

export const AuxiliaryLineSet: FC<SetProps> = ({ dataField, id, methods }) => {

    return <div className="auxiliary-line-setting">
        <div className="set-form">
            <Form
                initialValues={
                    dataField
                }
                onValuesChange={
                    (changeItem, allProps) => {
                        methods.updateModuleData({
                            ...dataField,
                            ...allProps
                        }, id)
                    }
                }
            >
                <Form.Item label="外边距" name="line_margin">
                    <Slider max={30} />
                </Form.Item>

                <Form.Item label="线高度" name="line_height">
                    <Slider min={1} max={10} />
                </Form.Item>

                <Form.Item label="线风格" name="line_style">
                    <Radio.Group>
                        <Radio value="solid">实线</Radio>
                        <Radio value="dashed">虚线</Radio>
                        <Radio value="dotted">点线</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item label="线颜色" >
                    {/* <Slider max={10} /> */}
                    <Popover content={
                        <SketchPicker
                            color={dataField.line_color}
                            onChange={
                                (e: any) => {
                                    methods.updateModuleData({
                                        ...dataField,
                                        line_color: e.hex
                                    }, id)
                                }
                            }
                        />
                    } title="" trigger="click">
                        <p className="set-color-hover" style={
                            {
                                backgroundColor: dataField.line_color
                            }
                        }></p>
                    </Popover>

                </Form.Item>

            </Form>
        </div>
    </div>
}

export default AuxiliaryLine