import { FC, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./list.reducer";
import { Col, Form, Row } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Contract } from "model/contract/contractList.model";
import { ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchLevelList, fetchStoreList } from "pages/dealer/unit/unit.reducer";
import UploadImgOne from "components/upload-img/upload-img-one";
import { useEnumValue } from "common/enum/use-enum-value";
;
export interface ContractEditProps extends EditStateAndDispatchProps<Contract>, StateProps, DispatchProps, RouteComponentProps {
}

const ContractEdit: FC<ContractEditProps> = props => {
  const {
    ...editProps
  } = props;

  const [form] = Form.useForm();
  const [storeName, setStoreName] = useState<any>({});
  const [levelNo, setLevelNo] = useState<any>({});
  const { convertEnumToOptionType } = useEnumValue();

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<Contract>((resolve, reject) => {
      if (res) {
        const newRes = {
          ...res,
          levelNo,
          storeName
        };
        resolve(newRes);
      }
    })
  }


  return (
    <EditPage<Contract>
      {...editProps}
      title="合同模板"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={5}>
          <ProFormText
            width="md"
            name="contractName"
            label="标题"
          />
        </Col>
        <Col span={1} />
        <Col span={5}>
          <ProFormSelect
            options={convertEnumToOptionType('templateType')}
            name="type"
            label="类型"
          />
        </Col>
        <Col span={1} />
        <Col span={24} />
        <Col span={5}>
          <Form.Item name="storeId" label="店铺">
            <SelectSearch width={'md'} optionKey="name" fetchOptionKey={setStoreName} required={false} valueType="number" name="storeId" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <Form.Item name="levelId" label="等级">
            <SelectSearch required={false} name="levelId" optionKey="levelNo" fetchOptionKey={setLevelNo} placeholder="等级" labelValue="levelName" defaultParams='' fetchFunc={fetchLevelList} searchParams='levelName.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <ProFormText
            width="md"
            name="effectiveTime"
            label="有效期（天）"
          />
        </Col>
        <Col span={1} />
        <Col span={24}>
          <Form.Item name="fileUrl" label="上传文件" >
            <UploadImgOne
              listType="picture-card"
              Immediate={true}
              upType='any'
              title="上传"
            />
          </Form.Item>
        </Col>
        <Col span={24}>
          <ProFormTextArea
            name="remark"
            allowClear={true}
            label="备注"
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ contract }: RootState) => ({
  entity: contract.entity,
  updateSuccess: contract.updateSuccess,
  updating: contract.updating,
  createSuccess: contract.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractEdit);
