import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./user.reducer";
import { ActiveUser } from "model/customerCore/active-user.model";

export interface ActiveUserActiveUser extends EditStateAndDispatchProps<ActiveUser>, StateProps, DispatchProps, RouteComponentProps {
}

const ActiveUserEdit: FC<ActiveUserActiveUser> = (props) => {

  return (
    <EditPage<ActiveUser>
      title="活跃用户"
      {...props}
    >

    </EditPage>);
};

const mapStateToProps = ({ activeUser }: RootState) => ({
  entity: activeUser.entity,
  updateSuccess: activeUser.updateSuccess,
  updating: activeUser.updating,
  createSuccess: activeUser.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActiveUserEdit);
