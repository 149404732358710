import { FC, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./achievement.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Achievement, bonusComputeStrategy, computeType } from "model/product/achievement.model";
import { Col, Form, Row, Space } from "antd";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import Title from "antd/lib/typography/Title";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
export interface AchievementEditProps extends EditStateAndDispatchProps<Achievement>, StateProps, DispatchProps, RouteComponentProps {
}

export const computeStrategy: any = {
  'Default': <Space size={20} wrap>
    <ProFormDigit name={['config', "a"]} label="A档返点" />
    <ProFormDigit name={['config', "b"]} label="B档返点" />
    <ProFormDigit name={['config', "c"]} label="C档返点" />
    <ProFormDigit name={['config', "d"]} label="D档返点" />
    <ProFormDigit name={['config', "e"]} label="E档返点" />
    <ProFormDigit name={['config', "f"]} label="F档返点" />
    <ProFormDigit name={['config', "f1"]} label="F1档返点" />
  </Space>,
  'FixedAmount': <Space size={20} wrap>
    <ProFormDigit width={'xs'} name={['config', "selfReturnAmount"]} label="个人固定返点金额" />
    <ProFormDigit width={'xs'} name={['config', "parentReturnAmount"]} label="上级固定返点金额" />
    <ProFormDigit width={'xs'} name={['config', "familyReturnAmount"]} label="家族固定返点金额" />
    <ProFormDigit width={'xs'} name={['config', "regionReturnAmount"]} label="大区固定返点金额" />
  </Space>,
  'DistributorRatio': <Space size={20} wrap>
    <ProFormSelect width={'md'} name={['config', "computeType"]} options={Object.entries<string>(computeType).map(([value, label]) => ({ value: Number(value), label }))} label="计算类型" />
    <ProFormDigit name={['config', "distributorAllocateRatio"]} label="分配给代理计算比例" />
    <ProFormDigit name={['config', "selfRatio"]} label="个人返点比例" />
    <ProFormDigit name={['config', "parentRatio"]} label="上级返点比例" />
    <ProFormDigit name={['config', "regionRatio"]} label="大区返点比例" />
    <ProFormDigit name={['config', "familyRatio"]} label="家族返点比例" />
  </Space>
};

const AchievementEdit: FC<AchievementEditProps> = props => {
  const { convertEnumToOptionType } = useEnumValue();
  const {
    ...editProps
  } = props;

  const [platform, setPlatform] = useState('');

  const beforeUpdateEntity = (res?: Achievement) => {
    return new Promise<any>((resolve, reject) => {
      if (res) {
        res.platform = platform === '' ? res.platform : platform;
        resolve(res);
      }
    })
  };

  return (
    <EditPage<Achievement>
      title="商品业绩模版"
      beforeUpdateEntity={beforeUpdateEntity}
      {...editProps}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            name="name"
            label="业绩名称"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <Form.Item name={'storeId'}>
            <SelectSearch optionKey="platform" fetchOptionKey={setPlatform} name="storeId" width="md" label='商家' labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormDigit
            name="achievementDiscount"
            label="业绩折扣"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            name="bonusCategory"
            label="奖金分类"
            rules={[{ required: true }]}
            options={convertEnumToOptionType('bonusCategory')}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            name="bonusSubCategory"
            label="奖金子分类"
            rules={[{ required: true }]}
            options={convertEnumToOptionType('bonusSubCategory')}
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormText
            name="bonusTipType"
            label="奖金提示类型"
            rules={[{ required: true }]}
          />
        </Col><Col span={1} />
        <Col>
          <ProFormSwitch
            name="ignored"
            label="是否忽略计算奖金"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col><Col span={1} />
        <Col>
          <ProFormSwitch
            name="computeEducation"
            label="是否计算教育奖"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col><Col span={1} />
        <Col>
          <ProFormSwitch
            name="special"
            label="是否特供"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col><Col span={1} />
        <Col>
          <ProFormSwitch
            name="calculatePoint"
            label="是否计算庭豆"
            initialValue={false}
            rules={[{ required: true }]}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col><Col span={1} />
        <Col span={24}>
          <Title level={5}>奖金配置</Title>
          <ProFormSelect width={'md'} name={['config', "bonusComputeStrategy"]} label='奖金策略' options={Object.entries<string>(bonusComputeStrategy).map(([value, label]) => ({ value, label }))} />
          <ProFormDependency name={[['config', "bonusComputeStrategy"]]}>
            {({ config }) => computeStrategy[config.bonusComputeStrategy]}
          </ProFormDependency>
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ achievement }: RootState) => ({
  entity: achievement.entity,
  updateSuccess: achievement.updateSuccess,
  updating: achievement.updating,
  createSuccess: achievement.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AchievementEdit);
