import { BaseModel } from "../base.model"; 

export interface Client extends BaseModel {
    accessTokenValidity?: number,
    authorities?: string,
    authorizedGrantTypes?: string,
    autoapprove?: string,
    clientId?: string,
    clientSecret?: string,
    createDept?: number,
    createTime?: string,
    createUser?: number,
    isDeleted?: number,
    refreshTokenValidity?: number,
    resourceIds?: string,
    scope?: any,
    status?: number,
    updateTime?: string,
    updateUser?: number,
    webServerRedirectUri?: string
}

export const defaultClient: Readonly<Client> = {};