import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./rate.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ProFormDatePicker, ProFormDigit, ProFormGroup, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { Rate } from "model/system/rate.model";
export interface RateEditProps extends EditStateAndDispatchProps<Rate>, StateProps, DispatchProps, RouteComponentProps {
}

const RateEdit: FC<RateEditProps> = props => {
  // const {} = props;
  const { convertEnumToOptionType } = useEnumValue();

  return (
    <EditPage<Rate>
      title="汇率"
      {...props}
    >
      <ProFormSelect 
         name="currencyType"
         label="货币类型"
         options={convertEnumToOptionType('CurrencyType')}
      />
      <ProFormGroup>
      <ProFormDigit
        name="exchangeRate"
        label="汇率"
        fieldProps={{ precision: 4 }}
        rules={[{ required: true }]}
      />
      <ProFormDatePicker
        name="startDate"
        label="生效时间"
        rules={[{ required: true }]}
      />
      </ProFormGroup>
    </EditPage>);
};

const mapStateToProps = ({ rate }: RootState) => ({
  entity: rate.entity,
  updateSuccess: rate.updateSuccess,
  updating: rate.updating,
  createSuccess: rate.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RateEdit);
