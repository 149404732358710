import { FC, useEffect, useState } from "react"
import { Product } from 'model/product/product.model';
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import {
  copyEntity,
  createEntity,
  getEntity,
  reset,
  updateEntity,
} from './product.reducer';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Col, Divider, Form, Row, Spin, message } from "antd";
import { Base64 } from 'js-base64';
import SkuEditList from "./sku-edit-list";
import SpecEditList from "./spec-edit-list";
import { fetchBrandList } from "../brand/brand.reducer";
import ProCard from "@ant-design/pro-card";
import { uploadImage } from "utils/file-utils";
import ProductEditCardPicture from "./product-edit-card-picture";
import { useParamGeneral } from "components/useParamGenera";
import ProductEditCardBasic from "./product-edit-card-basic";
import { CrudCopyGetAction } from "reducers/redux-action.type";
import { ProFormDependency, ProFormDigit, ProFormSelect, ProFormSwitch } from "@ant-design/pro-form";
import { fetchLevelList } from "../pricingRules/pricingRules.reducer";
import { internationalList } from "components/IntelFormText";
import Title from "antd/lib/typography/Title";
import { useEnumValue } from "common/enum/use-enum-value";

export interface PictureInternationObj {
  [key: string]: string | undefined;
}

export interface ProductEditProps extends EditStateAndDispatchProps<Product>, StateProps, DispatchProps, RouteComponentProps {
  copyEntity: CrudCopyGetAction<Product>;
}

const ProductEdit: FC<ProductEditProps> = (props) => {
  const { entity,text } = props;
  const [form] = Form.useForm();
  const [specListData, setSpecListData] = useState<any>([]);//specList 总数据
  const [title, setTitle] = useState('');
  const [entityData, setEntityData] = useState<Product>({});
  const { intlArr, defaultLanguage } = useParamGeneral();
  const [platform, setPlatform] = useState<string>('');
  const [deliveryTemplateId, setDeliveryTemplateId] = useState<string>('');
  const [levelList, setLevelList]: any = useState([]); // 等级列表
  const { convertEnumToOptionType } = useEnumValue();
  const [spinning, setSpinning] = useState(true);

  //图片国际化
  const [pictureObj, setPictureObj] = useState<Product>({});//图片数据
  const [mainPicInternational, setMainPicInternational] = useState<any>({});//主图
  const [picInternational, setPicInternational] = useState<any>({});//配图
  const [bannerInternational, setBannerInternational] = useState<any>({});//banner图
  const [videoInternational, setVideoInternational] = useState<any>({});//视频
  const [detailInternational, setDetailInternational] = useState<any>({});//详情
  const [categoryIdPathMini, setCategoryIdPathMini] = useState<any>([]);//商品类目细分数据
  const [regionTemplate, SetRegionTemplate] = useState<string>('');

  // 获取等级列表
  const getLevelList = async () => {
    const unitRequest = await fetchLevelList();
    let data = unitRequest.map((item: any) => ({
      value: item?.levelNo,
      label: internationalList(item?.levelName)
    }))
    setLevelList(data)
  }

  const internationalVal = (interObj: PictureInternationObj) => (intlArr.length === 1 && interObj[defaultLanguage]) || JSON.stringify(interObj);

  let timeout: any = null;
  const handleChange = (entity: any) => {
    if (timeout) {
      clearTimeout(timeout);
      timeout = null;
    };
    const fake = async () => {
      //标题
      setTitle('商品信息' + (entity.id ? ' SPU:' + entity.id : ''));
      setCategoryIdPathMini([]);
      SetRegionTemplate('');
      setDeliveryTemplateId('');
      setMainPicInternational({});
      setPicInternational({});
      setBannerInternational({});
      setVideoInternational({});
      setDetailInternational({});
      if (entity.name) {
        getLevelList();
        entity.platform && setPlatform(entity.platform || '')
        const entityCopy = JSON.parse(JSON.stringify(entity));
        entityCopy.categoryIdPath = entityCopy.categoryIdPath ? entityCopy.categoryIdPath.split(",") : [];//商品类目
        entityCopy.classifyIdPath = entityCopy.classifyIdPath ? entityCopy.classifyIdPath.split(",") : [];//平台商品分类
        entityCopy.regionTemplate = entityCopy.regionTemplate ? entityCopy.regionTemplate : null;
        entityCopy.onlineClassifyId === '-1' && (entityCopy.onlineClassifyId = '');
        entityCopy.mainPicture = entity.mainPicture ? entity.mainPicture : null;
        entityCopy.banner = entity.banner ? entity.banner : null;
        entityCopy.video = entity.video ? entity.video : null;
        entityCopy.pictures = entity.pictures ? entity.pictures : null;
        entityCopy.detail = entity.detail ? entity.detail : null;
        setEntityData(entityCopy);
        setPictureObj({
          mainPicture: entityCopy.mainPicture,
          pictures: entityCopy.pictures,
          banner: entityCopy.banner,
          video: entityCopy.video,
          detail: entityCopy.detail,
        });
        SetRegionTemplate(entityCopy.regionTemplate || '');
        setSpinning(false);
      } else {
        !entity.id && setSpinning(false);
        getLevelList();
        setEntityData({});
        setPictureObj({});
      }
    };
    timeout = setTimeout(fake, 500);
  };

  useEffect(() => {
    !spinning && setSpinning(true);
    setEntityData({});
    setPictureObj({});
    handleChange(entity);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [text,entity]);

  useEffect(() => {
    const data = form.getFieldsValue();
    const newData = {
      ...entityData,
      ...data,
    };
    setEntityData({
      ...newData,
      ...pictureObj,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [pictureObj])

  const uploadImg = (res?: any) => {
    res && (res.pricingRules = {
      ...entity?.pricingRules,
      ...res.pricingRules,
      id: entity?.pricingRules?.id || null,
      storeId: entity?.pricingRules?.storeId || res.sellerId,
      name: entity?.pricingRules?.name || res.name,
    });
    res && (res.classify = {});
    res && (res.category = {});
    res && (res.platform = platform);
    (res && (regionTemplate === 'empty' || regionTemplate === '')) && (res.regionTemplate = { id: '' });
    (res && deliveryTemplateId === 'empty') && (res.deliveryTemplateId = '');
    let classifyIdPath: any = res?.classifyIdPath ? res && JSON.parse(JSON.stringify(res.classifyIdPath)) : [];
    if (classifyIdPath.length > 0 && !classifyIdPath[0] && res) {
      classifyIdPath = []
      res.classifyIdPath = ''
      delete res.classify
    } else if (classifyIdPath.length === 0) {
      res.classifyIdPath = ''
      delete res.classify
    };

    // if (classifyIdPath.length !== 2) {
    //   message.error('请选择 平台商品分类子类');
    //   return new Promise<Product>((resolve, reject) => reject());
    // };
    res && res.splitOrderType && (!res.splitOrderType.id && delete res.splitOrderType);
    const category = res && JSON.parse(JSON.stringify(res.categoryIdPath));
    (res && typeof (res.categoryIdPath) !== 'string' && res.categoryIdPath?.length) && (res.categoryIdPath = res.categoryIdPath[0] + ',' + res.categoryIdPath[1]);
    (res && typeof (res.classifyIdPath) !== 'string' && classifyIdPath?.length > 0) && (res.classifyIdPath = res.classifyIdPath[0] + ',' + res.classifyIdPath[1]);
    (res && typeof (classifyIdPath) !== 'string' && classifyIdPath?.length) && (res.classify && (res.classify.id = classifyIdPath[1]));
    (res && res.id && (['SOLD_OUT', 'SHELF_DOWN'].indexOf(res.status || '') > -1) && (res.status = 'PENDING'));
    const categoryName = categoryIdPathMini.filter((e: any) => e.value === String(category[1]));
    if (categoryName.length === 0) {
      message.error('请选择正确的商品类目');
      return new Promise<Product>((resolve, reject) => reject());
    } else {
      (res && typeof (category) !== 'string' && category?.length) && (res.category && (res.category = {
        id: category[1],
        name: categoryIdPathMini.length > 0 ? categoryName[0].label : ''
      }));
    };
    return new Promise<Product>((resolve, reject) => {
      if (!res?.items || res?.items.length === 0 || res?.items.concat().filter((e: any) => !e.code || e.code === '').length > 0) {
        message.error('请检查sku列表！');
        reject();
      } else {
        res.items = res.items.concat().map((e: any) => {
          !Number(e.id) && delete e.id;
          return e;
        });
      };
      if (res && res.specList) {
        res.specList.forEach((e: any) => {
          if (e.title === '' || e.specValueList?.length === 0) {
            message.error('规格名或规格值不可为空！')
            reject();
          };
        });
      } else {
        message.error('请检查商品规格列表！')
        reject();
      };
      const brandFunc = (brandData: any) => {
        fetchBrandList({ 'id.equals': brandData.brand?.id || '' }).then(e => {
          e.length > 0 && (brandData.brand = {
            ...brandData.brand,
            brandName: e[0].brandName
          });
          resolve(brandData);
        }).catch(e => {
          message.error('品牌错误，请重试！');
          reject();
        })
      }
      //规格值图片
      const newArr: any = [];
      const specFun = async (resSpec: Product) => {
        if (!resSpec.specList || (resSpec.specList.length === 0 || !resSpec.specList[0].title)) {
          message.error('商品规格列表 输入有误，请核验！'); reject();
        } else if (!resSpec.items || (resSpec.items && resSpec.items.length === 0)) {
          message.error('请选择Sku！'); reject();
        } else {
          const specValueList = resSpec?.specList && resSpec?.specList[0]?.specValueList;
          const length = newArr.length;
          const item = specValueList && specValueList[length];
          if (item && typeof (item.imgUrl) !== 'string' && (item.imgUrl && item.imgUrl[0])) {
            //规格图需上传
            const resSpecImg: any = item.imgUrl;
            const newDate = new Date().getTime() + '.png';
            uploadImage(resSpecImg[0].originFileObj, 'product/img-' + newDate).then(imageName => {
              const newData = resSpec;
              newData.specList &&
                newData.specList[0].specValueList &&
                (newData.specList[0].specValueList[length].imgUrl = imageName);
              if (specValueList && (length === specValueList.length - 1)) {
                brandFunc(newData);
              } else {
                newArr.push(0);
                specFun(newData);
              }
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            const newData = resSpec;
            const Fun = (r: Product) => {
              if (specValueList && length === specValueList.length - 1) {
                brandFunc(r);
              } else {
                newArr.push(0);
                specFun(r);
              };
            };
            if (item && typeof (item.imgUrl) !== 'string') {
              newData.specList && newData.specList[0].specValueList && (newData.specList[0].specValueList[length].imgUrl = '');
              Fun(newData);
            } else {
              Fun(newData);
            }
          }
        }
      };

      //主图视频
      const videoArr = [];
      const videoObj: PictureInternationObj = {};
      const videoFun = (resVideo: Product) => {
        // const video: any = resVideo.video;
        const video: any = videoInternational[intlArr[videoArr.length]]?.concat();
        videoArr.push('0');
        if (video && video.length > 0 && typeof (video) !== 'string') {
          if (video[0].originFileObj) {
            //视频需上传
            const newDate = new Date().getTime() + '.mp4';
            uploadImage(video[0].originFileObj, 'video/video-' + newDate).then(imageName => {
              videoObj[intlArr[videoArr.length - 1]] = imageName;
              if (videoArr.length === intlArr.length) {
                resVideo.video = internationalVal(videoObj);
                specFun(resVideo);
              } else {
                videoFun(resVideo);
              };
            }).catch(() => { message.error('视频上传失败'); reject() });
          } else {
            // 视频已上传且未修改
            videoObj[intlArr[videoArr.length - 1]] = video[0].url;
            if (videoArr.length === intlArr.length) {
              resVideo.video = internationalVal(videoObj);
              specFun(resVideo);
            } else {
              videoFun(resVideo);
            };
          }
        } else {
          video && video.length > 0 && (videoObj[intlArr[videoArr.length - 1]] = video);
          if (videoArr.length === intlArr.length) {
            resVideo.video = internationalVal(videoObj);
            specFun(resVideo);
          } else {
            videoFun(resVideo);
          };
        }
      }

      //商品配图
      const picturesArr = [];
      const picturesObj: PictureInternationObj = {};
      const picturesFun = (resPictures: Product) => {
        console.log('picturesFun')
        const picturesData: any = picInternational[intlArr[picturesArr.length]]?.concat();
        picturesArr.push('0');
        if (picturesData && picturesData.length > 0 && typeof (picturesData) !== 'string') {
          let picturesText = '', successList: any = [];
          // 配图已更新或需上传 递归解决上传
          const uploadPictures = () => {
            const newDate = new Date().getTime() + '.png';
            if (picturesData[successList.length].originFileObj) {
              uploadImage(picturesData[successList.length].originFileObj, 'product/img-' + newDate).then(imageName => {
                successList.push(1);
                if (successList.length === picturesData.length) {
                  picturesText += imageName;
                  picturesObj[intlArr[picturesArr.length - 1]] = picturesText;
                  if (picturesArr.length === intlArr.length) {
                    resPictures.pictures = internationalVal(picturesObj);
                    videoFun(resPictures);
                  } else {
                    picturesFun(resPictures);
                  };
                } else {
                  picturesText += imageName + ',';
                  uploadPictures();
                }
              }).catch(() => { message.error('图片上传失败'); reject() });
            } else {
              successList.push(1);
              if (successList.length === picturesData.length) {
                picturesText += (picturesData[successList.length - 1].url);
                picturesObj[intlArr[picturesArr.length - 1]] = picturesText;
                if (picturesArr.length === intlArr.length) {
                  resPictures.pictures = internationalVal(picturesObj);
                  videoFun(resPictures);
                } else {
                  picturesFun(resPictures);
                };
              } else {
                picturesText += (picturesData[successList.length - 1].url) + ',';
                uploadPictures();
              }
            }
          }; uploadPictures();
        } else {
          // 无配图
          picturesData && picturesData.length > 0 && (picturesObj[intlArr[picturesArr.length - 1]] = picturesData);
          if (picturesArr.length === intlArr.length) {
            resPictures.pictures = internationalVal(picturesObj);
            videoFun(resPictures);
          } else {
            picturesFun(resPictures);
          };
        }
      }

      //banner图
      const bannerArr = [];
      const bannerObj: PictureInternationObj = {};
      const bannerFun = (resBanner: Product) => {
        console.log('bannerFun')
        const banner: any = bannerInternational[intlArr[bannerArr.length]]?.concat();
        bannerArr.push('0');
        if (banner && banner.length > 0 && typeof (banner) !== 'string') {
          if (banner[0].originFileObj) {
            //banner需上传
            const newDate = new Date().getTime() + '.png';
            uploadImage(banner[0].originFileObj, 'product/img-' + newDate).then(imageName => {
              bannerObj[intlArr[bannerArr.length - 1]] = imageName;
              if (bannerArr.length === intlArr.length) {
                resBanner.banner = internationalVal(bannerObj);
                picturesFun(resBanner);
              } else {
                bannerFun(resBanner);
              };
            }).catch(() => { message.error('图片上传失败'); reject() });
          } else {
            //banner已上传且未修改
            bannerObj[intlArr[bannerArr.length - 1]] = banner[0].url;
            if (bannerArr.length === intlArr.length) {
              resBanner.banner = internationalVal(bannerObj);
              picturesFun(resBanner);
            } else {
              bannerFun(resBanner);
            };
          }
        } else {
          banner && banner.length > 0 && (bannerObj[intlArr[bannerArr.length - 1]] = banner);
          if (bannerArr.length === intlArr.length) {
            resBanner.banner = internationalVal(bannerObj);
            picturesFun(resBanner);
          } else {
            bannerFun(resBanner);
          };
        }
      }

      //商品主图
      const mainArr = [];
      const mainObj: PictureInternationObj = {};
      const mainPictureFun = (resMain: Product) => {
        console.log('mainPictureFun')
        const mainPicture: any = mainPicInternational[intlArr[mainArr.length]]?.concat();
        mainArr.push('0');
        if (mainPicture && mainPicture.length > 0 && typeof (mainPicture) !== 'string') {
          if (mainPicture[0].originFileObj) {
            //主图需上传
            const newDate = new Date().getTime() + '.png';
            uploadImage(mainPicture[0].originFileObj, 'product/img-' + newDate).then(imageName => {
              mainObj[intlArr[mainArr.length - 1]] = imageName;
              if (mainArr.length === intlArr.length) {
                resMain.mainPicture = internationalVal(mainObj);
                bannerFun(resMain);
              } else {
                mainPictureFun(resMain);
              };
            }).catch(() => { message.error('图片上传失败'); reject(); })
          } else {
            //主图已上传且未修改
            mainObj[intlArr[mainArr.length - 1]] = mainPicture[0].url;
            if (mainArr.length === intlArr.length) {
              resMain.mainPicture = internationalVal(mainObj);
              bannerFun(resMain);
            } else {
              mainPictureFun(resMain);
            };
          }
        } else {
          mainPicture && mainPicture.length > 0 && (mainObj[intlArr[mainArr.length - 1]] = mainPicture);
          // if (mainPicture && mainPicture.length === 0) {
          //   message.error('商品主图不可为空');
          //   reject();
          // };
          if (mainArr.length === intlArr.length) {
            resMain.mainPicture = internationalVal(mainObj);
            bannerFun(resMain);
          } else {
            mainPictureFun(resMain);
          };
        };
      }

      //详情
      if (res) {
        // detailInternational
        const detailArr = [];
        const detailObj: any = {};
        const detailFunc = () => {
          console.log('detailFunc')
          const detail: any = !!detailInternational ? detailInternational[intlArr[detailArr.length]]?.concat() : [];
          detailArr.push('0');
          if (detail && detail.length > 0 && typeof (detail) !== 'string') {
            let detailSuccessList = [];
            let detailBs64 = '';
            const uploadDetail = () => {
              const newDate = new Date().getTime();
              if (detail[detailSuccessList.length].originFileObj) {
                uploadImage(detail[detailSuccessList.length].originFileObj, 'product/img-' + newDate).then(imageName => {
                  detailBs64 += '<img style="max-width:100%;" src="' + imageName + '" alt="" />';
                  detailSuccessList.push(1);
                  if (detailSuccessList.length === detail.length) {
                    detailObj[intlArr[detailArr.length - 1]] = Base64.encode('<p>' + detailBs64 + '</p>');
                    if (detailArr.length === intlArr.length) {
                      res && (res.detail = internationalVal(detailObj))
                      res && mainPictureFun(res);
                    } else {
                      detailFunc();
                    };
                  } else {
                    uploadDetail();
                  }
                }).catch(() => { message.error('图片上传失败'); reject() });
              } else {
                detailBs64 += '<img style="max-width:100%;" src="' + detail[detailSuccessList.length].url + '" alt="" />';
                detailSuccessList.push(1);
                if (detailSuccessList.length === detail.length) {
                  //完成
                  detailObj[intlArr[detailArr.length - 1]] = Base64.encode('<p>' + detailBs64 + '</p>');
                  if (detailArr.length === intlArr.length) {
                    res && (res.detail = internationalVal(detailObj));
                    res && mainPictureFun(res);
                  } else {
                    detailFunc();
                  };
                } else {
                  uploadDetail();
                }
              }
            }; uploadDetail();
          } else {
            detail && detail.length > 0 && (detailObj[intlArr[detailArr.length - 1]] = detail);
            if (detailArr.length === intlArr.length) {
              res && (res.detail = internationalVal(detailObj));
              res && (mainPictureFun(res));
            } else {
              detailFunc();
            };
          }
        }; detailFunc();
      }
    });
  }

  return (
    <EditPage<Product>
      title={title}
      beforeUpdateEntity={uploadImg}
      form={form}
      text={props.text}
      ignoreArr={['brand']}
      {...props}
      entity={entityData}
    >
     <Spin spinning={spinning} tip="Loading">
      <ProductEditCardBasic
        form={form}
        entity={entityData}
        setRegionTemplate={SetRegionTemplate}
        setDeliveryTemplateId={setDeliveryTemplateId}
        categoryIdPathMini={categoryIdPathMini}
        setCategoryIdPathMini={setCategoryIdPathMini}
        setPlatform={setPlatform}
        platform={platform}
      />
      <ProCard
        title="商品规格列表"
        bordered
        headerBordered
        collapsible
        defaultCollapsed
        style={{
          marginBottom: 16,
          minWidth: 400,
          maxWidth: '100%',
        }}
      >
        <Form.Item name="specList">
          <SpecEditList value={entity.specList} specListDataChange={setSpecListData} />
        </Form.Item>
        <Divider />
        <Title level={5}>定价规则</Title>
        {!!levelList && <Row>
          <Col span={3} style={{ marginRight: '20px' }}>
            <ProFormSelect
              rules={[{ required: true }]}
              options={convertEnumToOptionType('pricingRuleType') || []}
              name={["pricingRules", 'ruleType']}
              initialValue={'fixedPrice'}
              label='规则类型'
              placeholder='规则类型'
            />
          </Col>
          <ProFormDependency name={['pricingRules', 'ruleType']}>
            {({ pricingRules }) => {
              return pricingRules.ruleType === 'fixedDiscount' && <>
                <Col span='3'>
                  <ProFormSelect
                    rules={[{ required: true }]}
                    options={convertEnumToOptionType('roundModeType') || []}
                    name={["pricingRules", 'roundMode']}
                    label='取整方式'
                    placeholder='取整方式'
                  />
                </Col>
                <Col span='1'></Col>
                <Col span='3'>
                  <ProFormDigit
                    rules={[{ required: true }]}
                    name={["pricingRules", 'scala']}
                    label="保留小数位数"
                    placeholder="保留小数位数"
                  />
                </Col>
                <Col span='1'></Col>
                <Col span={3}>
                  <ProFormSwitch
                    name={["pricingRules", 'isRound']}
                    label="是否尾数处理"
                    initialValue={false}
                    fieldProps={{
                      checkedChildren: '是',
                      unCheckedChildren: '否'
                    }}
                  />
                </Col>
              </>
            }}
          </ProFormDependency>
          <Col span={24} />
          {levelList.map((e: any) => {
            return <Col span={3} style={{ marginRight: '20px' }}>
              <ProFormDigit
                name={["pricingRules", 'ruleDetailMap', e.value, 'discount']}
                label={e.label}
                width="sm"
                initialValue={0}
              />
            </Col>
          })}
        </Row>}
        <Form.Item name="items">

          {/* <SkuEditList specListData={specListData} key={key} specListDataChange={(value: any) => {
            
            form.setFieldsValue({
              items: value
            })
            setKey(++key)
          }} /> */}
          <SkuEditList specListData={specListData} />


        </Form.Item>
      </ProCard>
      {pictureObj && <ProductEditCardPicture
        entity={entity}
        pictureObjData={pictureObj}
        setPictureObj={setPictureObj}
        mainPicInternational={mainPicInternational}
        setMainPicInternational={setMainPicInternational}
        picInternational={picInternational}
        setPicInternational={setPicInternational}
        bannerInternational={bannerInternational}
        setBannerInternational={setBannerInternational}
        videoInternational={videoInternational}
        setVideoInternational={setVideoInternational}
        detailInternational={detailInternational}
        setDetailInternational={setDetailInternational}
      />}
      </Spin>
    </EditPage>);
};

const mapStateToProps = ({ product }: RootState) => ({
  entity: product.entity,
  updateSuccess: product.updateSuccess,
  updating: product.updating,
  createSuccess: product.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
  copyEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ProductEdit);