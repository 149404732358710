import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import PayDetails from "./pay-details";
import { activate, deleteEntity, getEntities, getEntity } from "./pay.reducer";
import { RouteComponentProps } from "@reach/router";
import { Button, Form, Input, Popconfirm, Tooltip } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import PayEdit from "./pay-edit";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { Pay } from "model/system/pay.model";
import { ProFormSelect } from "@ant-design/pro-form";


export interface PayListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
export const columns: ProColumns<Pay>[] = [{
  title: 'Id',
  dataIndex: 'id',
  valueType: 'text',
  hideInTable: true,
}, {
  title: '支付方式名称',
  dataIndex: 'payName',
  valueType: 'text',
}, {
  title: '支付方式编码',
  dataIndex: 'payCode',
  valueType: 'text',
}, {
  title: '主体名称',
  dataIndex: ['payeeEnterprise', 'name'],
  valueType: 'text',
},  {
  title: '主体编码',
  dataIndex: ['payeeEnterprise', 'code'],
  valueType: 'text',
}, {
  title: '执行策略类名',
  dataIndex: 'strategyBeanId',
  valueType: 'text',
}, {
  title: '调用方式',
  dataIndex: 'tradeType',
  valueType: 'text',
}, {
  title: '排序',
  dataIndex: 'sort',
  valueType: 'text',
}, {
  title: '启用状态',
  dataIndex: 'enabled',
  valueType: 'text',
  render: (dom: any) => dom ? '启用' : '禁用'
}]

const PayList: FC<PayListProps> = (props) => {
  const { activate } = props;
  const searchFields: SearchField[] = [{
    label: '支付方式编码',
    name: 'payCode.contains',
  }, {
    label: '调用方式',
    name: 'tradeType.contains',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          width="sm"
          name="enabled.equals"
          placeholder={'启用状态'}
          options={[{ value: 'true', label: '启用' }, { value: 'false', label: '禁用' }]}
        />
        <SearchBox
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [
    (dom: ReactNode, record: Pay) => {
      return <Popconfirm
        key="audit"
        placement="topRight"
        title={record.enabled ? '你确定要禁用吗？' : '你确定要启用吗？'}
        onConfirm={() => {
          record.enabled ? activate({ id: record.id, type: false }) : activate({ id: record.id, type: true });
        }}
      >
        <Tooltip title={record.enabled ? '立即禁用' : '立即启用'} key={record.id}>
          <Button type="link">{record.enabled ? '禁用' : '启用'}</Button>
        </Tooltip>
      </Popconfirm>
    }
  ];

  return (
    <ListViewPage<Pay>
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<PayDetails navigate={props.navigate} columns={columns} />}
      drawerWidth="1000"
      sort=""
      rowActions={rowActions}
      detailsIndex={1}
      editPage={(params: any) => <PayEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ pay }: RootState) => ({
  entity: pay.entity,
  entities: pay.entities,
  loading: pay.loading,
  updateSuccess: pay.updateSuccess,
  createSuccess: pay.createSuccess,
  total: pay.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  activate
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayList);