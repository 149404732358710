import React from 'react';
import { RouteComponentProps, Router } from "@reach/router";
import PlatformList from './platform-list';
import PlatformEdit from "./platform-edit";

const Platform: React.FC<RouteComponentProps> = () => (
  <Router>
    <PlatformList path="/" />
    <PlatformEdit path=":id/edit" key="edit"/>
    <PlatformEdit path="new"  key="new"/>
  </Router>
);
export default Platform;
