import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import PointDetails from './point-details';
import PointEdit from './point-edit';
import PointList from './point-list';

const Point: React.FC<RouteComponentProps> = () => (
  <Router>
    <PointList path="/" />
    <PointDetails path=":id" />
    <PointEdit path=":id/edit" />
    <PointEdit path="new" />
  </Router>
);
export default Point;
