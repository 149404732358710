import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import PhysicalStores from './physicalStores';
import LifePavilion from './lifePavilion';
import ExcellentStore from './excellentStore';
import TrainingList from './trainingList';
import './index.less'

const DealerStore: React.FC<RouteComponentProps> = () => (
  <Router>
    <PhysicalStores path="physicalStores/*" />
    <ExcellentStore path="excellentStore/*" />
    <LifePavilion path="lifePavilion/*" />
    <TrainingList path="trainingList/*" />
  </Router>
);
export default DealerStore;
