import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import OrderCancelDetails from './order-cancel-details';
import OrderCancelEdit from './order-cancel-edit';
import OrderCancelList from './order-cancel-list';

const OrderCancel: React.FC<RouteComponentProps> = () => (
  <Router>
    <OrderCancelList path="/" />
    <OrderCancelDetails path=":id" />
    <OrderCancelEdit path=":id/edit" />
    <OrderCancelEdit path="new" />
  </Router>
);
export default OrderCancel;
