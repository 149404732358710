import React, { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./publisher.reducer";
import { Col, Form, message, Row } from 'antd';
import UploadImgComplex from "components/upload-img/upload-img-complex";
import { uploadImage } from "utils/file-utils";
import IntelFormText from "components/IntelFormText";
import { Publisher } from "model/video/Publisher.model";

export interface CommentEditProps extends EditStateAndDispatchProps<Publisher>, StateProps, DispatchProps, RouteComponentProps {
}

const CommentEdit: FC<CommentEditProps> = (props) => {
  const { entity } = props;
  const [picturesArr, setPicturesArr] = useState([]);
  const [form] = Form.useForm();

  useEffect(() => {
    if (entity.id) {
      setPicturesArr(entity.avatar);
    }
  }, [entity]);

  const beforeUpdateEntity = (res?: Publisher) => {
    return new Promise<Publisher>((resolve, reject) => {
      if (res) {
        //素材图片上传
        const picturesData: any = res.avatar?.concat();
        if (picturesData && picturesData.length > 0 && picturesData[0].url) {
          res.avatar = picturesData[0].url;
          resolve(res)
          return false
        }
        if (picturesData && picturesData.length > 0) {
          let picturesText: any = [], successList: any = [];
          // 配图已更新或需上传 递归解决上传
          const uploadPictures = () => {
            const newDate = new Date().getTime();
            if (picturesData[successList.length].originFileObj) {
              uploadImage(picturesData[successList.length].originFileObj, 'material/img-' + newDate).then(imageName => {
                successList.push(1);
                picturesText.push({ pictureUrl: imageName, type: 'picture' });
                if (successList.length === picturesData.length) {
                  res.avatar = imageName;
                  resolve(res)
                } else {
                  uploadPictures();
                }
              }).catch(() => { message.error('图片上传失败'); reject() });
            } else {
              successList.push(1);
              const id = (picturesArr.filter((e: any) => e.pictureUrl === picturesData[successList.length - 1].url)[0] as any).id;
              picturesText.push({ pictureUrl: picturesData[successList.length - 1].url, type: 'picture', id });
              if (successList.length === picturesData.length) {
                res.avatar = picturesText;
                resolve(res)
              } else {
                uploadPictures();
              }
            }
          }; uploadPictures();
        } else {
          // 无配图
          res.avatar = ''
          resolve(res)
        }
      };
    })
  };
  return (
    <EditPage<Publisher>
      title="发布人"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      {/* <Row>
        <Col span={24}>
          <ProFormText
            rules={[{ required: true }]}
            name="code"
            label="用户编码"
          />
        </Col>
      </Row> */}
      <Row>
        <Col span={24}>
          <IntelFormText
            label='昵称'
            name="name"
            required={true}
            form={form}
            fieldProps={{
              maxLength: 10
            }}
            value={props.entity.name || ''}
          />
        </Col>
      </Row>
      <Row>
        <Col span={24}>
          <Form.Item name="avatar" label="头像" required={true}>
            <UploadImgComplex
              className={entity.avatar?.length === 1 || entity.avatar  ? 'hideUpdate' : 'pro-upload-picture-box'}
              listType="picture-card"
              title="上传"
              extra="建议尺寸：800*800像素，上传1张"
              maxCount={1}
            />
          </Form.Item>
        </Col>
      </Row>

    </EditPage>)
};



const mapStateToProps = ({ publisher }: RootState) => ({
  entity: publisher.entity,
  updateSuccess: publisher.updateSuccess,
  updating: publisher.updating,
  createSuccess: publisher.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(CommentEdit);
