import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ReturnGoodsDetails from './returnGoods-details';
import ReturnGoodsEdit from './returnGoods-edit';
import ReturnGoodsList from './returnGoods-list';

const ReturnGoods: React.FC<RouteComponentProps> = () => (
  <Router>
    <ReturnGoodsList path="/" />
    <ReturnGoodsDetails path=":id" />
    <ReturnGoodsEdit path=":id/edit" />
    <ReturnGoodsEdit path="new" />
  </Router>
);
export default ReturnGoods;
