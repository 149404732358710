import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ClientDetails from './client-details';
import ClientList from './client-list';

const AppClient: React.FC<RouteComponentProps> = () => (
  <Router>
    <ClientList path="/" />
    <ClientDetails path=":id" />
  </Router>
);
export default AppClient;
