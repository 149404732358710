import axios from 'axios';
import { defaultResources, Resources } from 'model/system/resources.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'resources';
const apiUrl = '/api/yumei-mall-auth/restful-url';
const standardReducerImpl = new StandardReducerImpl<Resources>(entity, defaultResources, apiUrl);
export type ResourcesState = StandardState<Resources>;
export const resources = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchResourcesList = (params: any) => axios.get(apiUrl, { params });