import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./sendOutGoods.reducer";
import { Form } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { SendOutGoods } from "model/scanCodeOrder/sendOutGoods.model";
;
export interface SendOutGoodsEditProps extends EditStateAndDispatchProps<SendOutGoods>, StateProps, DispatchProps, RouteComponentProps {
}

const SendOutGoodsEdit: FC<SendOutGoodsEditProps> = props => {
  const {
    ...editProps
  } = props;

  const [form] = Form.useForm();


  return (
    <EditPage<SendOutGoods>
      title="发货订单"
      form={form}
      {...editProps}
    >
    </EditPage>);
};

const mapStateToProps = ({ sendOutGoods }: RootState) => ({
  entity: sendOutGoods.entity,
  updateSuccess: sendOutGoods.updateSuccess,
  updating: sendOutGoods.updating,
  createSuccess: sendOutGoods.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SendOutGoodsEdit);
