import axios from 'axios';
import { Contract, defaultContract } from 'model/contract/contractList.model';
import { ApiUrl, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'Contract';
const apiUrl:ApiUrl = {
    listUrl:'/api/yumei-mall-customer/esign/contract/template/list',
    createUrl:'/api/yumei-mall-customer/esign/contract/template/add'
};
const standardReducerImpl = new StandardReducerImpl<Contract>(entity, defaultContract, apiUrl);
export type ContractState = StandardState<Contract>;
export const contract = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const fetchDocTemplate = async (id?: any) => {
    const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/esign/contract/template/edit-url/${id}`);
    return axiosResponse.data;
};

export const fetchParameter = async (id?: any) => {
    const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/esign/contract/template/components/${id}`);
    return axiosResponse.data;
};

export const createUrl = async (id?: any) => {
    const axiosResponse = await axios.get<any>(`/api/yumei-mall-customer/esign/contract/template/create-url/${id}`);
    return axiosResponse.data;
};