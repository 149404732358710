import axios from 'axios';
import { defaultBankStatement, BankStatement } from 'model/financial/bankStatement.model';
import { CrudUploadAction } from 'reducers/redux-action.type';
import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'bankStatement';
const apiUrl = '/api/yumei-mall-finance/bank-flow';
const standardReducerImpl = new StandardReducerImpl<BankStatement>(entity, defaultBankStatement, apiUrl);
export type BankStatementState = StandardState<BankStatement>;
export const bankStatement = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 详情
export const bankFlowDetail = async (id?: any): Promise<any[]> => {
    return await axios.get(`/api/yumei-mall-finance/bank-flow/${id}`);
}

// 查询收款人授权码所有关系列表
export const payerCodeLevel = async (data:any): Promise<any[]> => {
    return await axios.post(`/api/yumei-mall-finance/bank-flow/payer-code/level`, data);
}



// 确认正确
export const confirm = async (data?: any): Promise<any[]> => {
    return await axios.post(`/api/yumei-mall-finance/bank-flow/payer-code/confirm`, data);
}

// 流水分配
export const flowDistribution = async (params?: any): Promise<any[]> => {
    return await axios.post(`/api/yumei-mall-finance/bank-flow/${params.id}/allocation`, params);
}

//批量导入流水
export const importBankFlowEntities: CrudUploadAction<BankStatement> = entity => async dispatch => {
    const requestUrl = `/api/yumei-mall-finance/bank-flow/batch`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.post(requestUrl, entity),
    });
};

// 模板列表
export const bankTemplate = async (): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-finance/bank-flow/template/select-list`);
    return axiosResponse.data;
}

// 获取模板详情
export const bankTemplateByID = async (id?: number): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-finance/bank-flow/template/${id}`);
    return axiosResponse.data;
}

// 获取流水分配详情
export const flowDistributionDtail = async (id?: number): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-finance/bank-flow/${id}`);
    return axiosResponse.data;
}

export const earestSeller = async (storeId?: number, payerCode?: any): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-customer/customer/nearest-seller-customer?storeId=${storeId}&code=${payerCode}`);
    return axiosResponse.data;
}



// 店铺列表
export const platformList = async (): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-store/seller/platform-list`);
    return axiosResponse.data;
}


// 复核
export const checkFlow = async (params?: any): Promise<any[]> => {
    return await axios.post(`/api/yumei-mall-finance/bank-flow/${params}/check`);
    // return axiosResponse;
}



