import { FC, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./article.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Article, showPlace } from "model/dealer/article.model";
import { ProFormDigit, ProFormSelect, ProFormText } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import SelectSearch from "components/select-search";
import { fetchArticleCategorySelectList } from "../assort/assort.reducer";
import EditorControl from "components/editor-control";
import { IDomEditor } from "@wangeditor/editor";
export interface ArticleEditProps extends EditStateAndDispatchProps<Article>, StateProps, DispatchProps, RouteComponentProps {
}

const ArticleEdit: FC<ArticleEditProps> = props => {
  const [form] = Form.useForm();
  const { entity } = props;
  const { convertEnumToOptionType } = useEnumValue();
  const [parentEditor, setParentEditor] = useState<IDomEditor | null>(null);
  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve) => {
      if (res) {
        parentEditor && (res.content = parentEditor.getHtml());
        resolve(res);
      }
    })
  };
  return (
    <EditPage<Article>
      title="文章"
      form={form}
      beforeUpdateEntity={beforeUpdateEntity}
      {...props}
    >
      <Row>
        <Col span={11}>
          <Form.Item name={['category', 'id']}>
            <SelectSearch required={true} valueType="number" width={'md'} name={['category', 'id']} label="文章分类" placeholder="文章分类" labelValue="name" defaultParams={'id.equals'} fetchFunc={fetchArticleCategorySelectList} searchParams='name.contains' />
          </Form.Item>
        </Col><Col span={1} />
        <Col span={11}>
          <ProFormText
            rules={[{ required: true }]}
            width="md"
            name="title"
            label="文章标题"
          /></Col><Col span={1} />
        <Col span={11}>
          <ProFormDigit
            width="md"
            name="sort"
            label="排序值"
          />
        </Col><Col span={1} />
        <Col span={11}>
          <ProFormSelect
            name="status"
            label="文章状态"
            width="md"
            initialValue={'SHOW'}
            options={convertEnumToOptionType('articleStatus')}
            placeholder="分类状态"
          />
        </Col><Col span={1} />
        <Col span={11}>
          <ProFormSelect
            name="showPlace"
            label="显示位置"
            width={'md'}
            initialValue={'RESTS'}
            options={Object.entries<string>(showPlace).map(([value, label]) => ({ value: (value), label }))}
          />
        </Col><Col span={1} />
        <Col span={24}>
          <EditorControl value={entity.content || ''} onChange={setParentEditor} />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ article }: RootState) => ({
  entity: article.entity,
  updateSuccess: article.updateSuccess,
  updating: article.updating,
  createSuccess: article.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ArticleEdit);
