import { FC, ReactNode, useState } from "react";
import { Button, Form, Input, Tooltip, message, Image } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SecondReviewDetails from "./secondReview-details";
import { deleteEntity, examineSecond, getEntities, getEntity } from "./secondReview.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SecondReviewEdit from "./secondReview-edit";
import { SecondReview, isRelatives, secondReviewStatus } from "model/dealer/secondReview.model";
import { ModalForm, ProFormDateTimePicker, ProFormDependency, ProFormSelect, ProFormTextArea } from "@ant-design/pro-form";
import DecryptText from "../customer/detailtables/decryptText";

export interface SecondReviewListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<SecondReview>[] = [{
  title: '主账号信息',
  dataIndex: 'applyCustomName',
  width: 180,
  render: (dom, record: any) => {
    return <>
      <p>{record.applyCustomName}</p>
      <p>{record.applyCustomNo}</p>
      <p>******<DecryptText title='身份证号码' id={record.id} data={record?.applyIdCard} /></p>
    </>
  },
}, {
  title: '申请人等级',
  dataIndex: 'appliedCustomName',
  width:280,
  render: (dom, record: any) => {
    const arr = record?.applyBrandList ? record?.applyBrandList.split(',') : [];
    return <>
      {arr.map((e: any) => (<p style={{width:'280px'}}>{e}</p>))}
    </>
  },
}, {
  title: '继承账号信息',
  dataIndex: 'appliedCustomName',
  width: 180,
  render: (dom, record: any) => {
    return <>
      <p>{record.appliedCustomName}</p>
      <p>{record.appliedCustomNo}</p>
      <p>******<DecryptText title='身份证号码' id={record.id} data={record?.appliedIdCard} /></p>
    </>
  },
}, {
  title: '继承等级',
  dataIndex: 'appliedBrandList',
  width: 180,
  render: (dom, record: any) => {
    const arr = record?.appliedBrandList ? record?.appliedBrandList.split(',') : []
    return <>
      {arr.map((e: any) => (<p>{e}</p>))}
    </>
  },
}, {
  title: '亲属和非亲属',
  dataIndex: 'isRelatives',
  width: 120,
  valueEnum: isRelatives,
  ellipsis:true,
}, {
  title: '户主页',
  dataIndex: 'householderImg',
  width: 100,
  render: (dom, record: any) => <Image alt='' height={80} width={80} style={{padding:'5px',boxSizing:'border-box'}} src={record.householderImg} />
}, {
  title: '子女页',
  dataIndex: 'sonImg',
  width: 100,
  render: (dom, record: any) => <Image alt='' height={80} width={80} style={{padding:'5px',boxSizing:'border-box'}} src={record.sonImg} />
},  {
  title: '证件照',
  dataIndex: 'proveImgList',
  width: 100,
  render: (dom, record: any) => record.proveImgList && record.proveImgList.map((e:any) => <Image alt='' height={80} width={80} style={{padding:'5px',boxSizing:'border-box'}} src={e} />)
}, {
  title: '申请时间',
  dataIndex: 'applyTime',
  width: 100,
}, {
  title: '审核时间',
  dataIndex: 'examineTime',
  width: 100,
}, {
  title: '状态',
  dataIndex: 'status',
  valueEnum: secondReviewStatus,
  width: 100,
}, {
  title: '拒绝原因',
  dataIndex: 'examineMessage',
  ellipsis:true,
  width: 100,
}];


const SecondReviewList: FC<SecondReviewListProps> = (props) => {
  const [refreshFlag, setRefreshFlag] = useState(0);
  const searchFields = [{
    label: '主账号姓名',
    name: 'applyCustomName.equals',
  }, {
    label: '主账号授权号',
    name: 'applyCustomNo.equals',
  }, {
    label: '主账号身份证号',
    name: 'applyIdCard.equals',
  }, {
    label: '主账号手机号',
    name: 'applyPhone.equals',
  }, {
    label: '继承账号授权号',
    name: 'appliedCustomNo.equals',
  }, {
    label: '继承账号姓名',
    name: 'appliedCustomName.equals',
  }, {
    label: '继承账号身份证号',
    name: 'appliedIdCard.equals',
  }, {
    label: '继承账号手机号',
    name: 'appliedPhone.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          options={Object.entries<string>(secondReviewStatus).map(([value, label]) => ({ value: Number(value), label }))}
          name={'status.equals'}
          placeholder="审核状态"
        />
        <ProFormSelect
          options={Object.entries<string>(isRelatives).map(([value, label]) => ({ value: Number(value), label }))}
          name={'isRelatives.equals'}
          placeholder="亲属和非亲属"
        />
        <ProFormSelect
          options={Object.entries<string>(secondReviewStatus).map(([value, label]) => ({ value: Number(value), label }))}
          name={'status.equals'}
          placeholder="审核状态"
        />
        <ProFormDateTimePicker
          name="examineTime.greaterThanOrEqual"
          placeholder="审核开始时间"
        />
        <ProFormDateTimePicker
          name="examineTime.lessThanOrEqual"
          placeholder="审核结束时间"
        />
      </Input.Group>
    </Form.Item>
  );

  const rowActions = [(dom: any, record: any) => {
    return <ModalForm
      title={'审核申请单'}
      trigger={<Tooltip title={'审核'} key="AuditReject"><Button disabled={record.status !== 1} type="link" size="small" >审核</Button></Tooltip>}
      modalProps={{
        destroyOnClose: true,
      }}
      disabled={record.status !== 1}
      preserve={false}
      onFinish={async (data: any) => {
        const params = {
          id: record.id,
          ...data,
        }
        return new Promise<boolean>((resolve, reject) => {
          examineSecond(params).then((e: any) => {
            message.success('审核成功');
            setRefreshFlag(new Date().getTime());
            resolve(true);
          }).catch((error: any) => {
            message.error(error?.response?.data?.detail);
            resolve(false);
          })
        });
      }}
    >
      <ProFormSelect
        width={'md'} name={'status'}
        options={[{
          value: '1',
          label: "通过"
        }, {
          value: '2',
          label: "拒绝"
        }]}
        label="审核状态"
      />
      <ProFormDependency name={['status']}>
        {({ status }) => {
          return status === '2' && <>
            <ProFormTextArea name="examineMessage" label="拒绝原因" />
          </>
        }}
      </ProFormDependency >
    </ModalForm>
  }]

  return (
    <ListViewPage<SecondReview>
      columns={columns}
      hideDeleteButton
      quickFilterPanel={quickFilterPanel}
      refreshFlag={refreshFlag}
      detailPage={<SecondReviewDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      hideCreateButton
      hideEditButton
      rowActions={rowActions}
      editPage={(params: any) => <SecondReviewEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ secondReview }: RootState) => ({
  entity: secondReview.entity,
  entities: secondReview.entities,
  loading: secondReview.loading,
  updateSuccess: secondReview.updateSuccess,
  createSuccess: secondReview.createSuccess,
  total: secondReview.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SecondReviewList);