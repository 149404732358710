import { BaseModel } from 'model/base.model';
export interface Exchange extends BaseModel {
    sellerMsg?:any;
    refundTotalAmount?: any;
    exchangeTotalAmount?: any;
    address?: any;
    type?: any;
    closeReasonDesc?:any;
    refundExpress?:any;
    lines?: any;
    orderState?: any;
    status?:any;
    packageAmount?: number;
    differenceAmount?: number;
    orderNo?:any;
    bookTime?:any;
    payTime?:any;
    auditReason?:any;
    autoHandleTime?: string;
    refundHandTime?: string;
    lossHandleTime?: string;
    adminMsg?:any;
    lossAmount?:any;
    buyer?:any;
    seller?:any;
    expressType?:any;
    buyerMsg?:any;
    shipTime?: any;
    receiveTime?: any;
    payTypeName?: any;
    payAmount?: any;
    accpTxno?: any;
    lossPayTypeName?: any;
    lossOrderNo?: any;
    lossPayTime?: any;
    lossAccpTxno?: any;
}

export const defaultExchange: Readonly<Exchange> = {
};