import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import './index.less'
import BonusList from './list/bonus-list';
import RewardList from './reward/reward-list';
import SummaryList from './summary';
import Withdrawal from './withdrawal';
import AssessmentDetails from './assessmentDetails'

const Summary: React.FC<RouteComponentProps> = () => (
    <Router>
        <BonusList path="list/*" />
        <RewardList path="reward/*" />
        <SummaryList path="summary/*" />
        <Withdrawal path="withdrawal/*" />
        <AssessmentDetails path="assessmentDetails/*" />
    </Router>
);
export default Summary;
