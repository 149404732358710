import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import VersionDetails from './version-details';
import VersionEdit from './version-edit';
import VersionList from './version-list';

const AppVersion: React.FC<RouteComponentProps> = () => (
  <Router>
    <VersionList path="/" />
    <VersionDetails path=":id" />
    <VersionEdit path=":id/edit" />
    <VersionEdit path="new" />
  </Router>
);
export default AppVersion;
