import { Permission, defaultPermission } from 'model/system/permission.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'permission';
const apiUrl = '/api/yumei-mall-auth/permission';
const standardReducerImpl = new StandardReducerImpl<Permission>(entity, defaultPermission, apiUrl);
export type permissionState = StandardState<Permission>;
export const permission = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;