/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { Key, ReactNode, useCallback, useEffect, useState } from "react"
import { Affix, Button, Divider, Drawer, Dropdown, Menu, Pagination, Popconfirm, Space, TablePaginationConfig, Tabs, Tooltip, Typography } from "antd";
import { CrudDeleteAction, CrudGetAction, CrudGetAllAction, } from 'reducers/redux-action.type';
import { RouteComponentProps, useLocation } from "@reach/router";
import { BaseModel } from 'model/base.model';
import { defaultPageSize as defaultSize, FilterCriteria, Pagination as PaginationProps } from 'components/model/share.model';
import { PageContainer } from '@ant-design/pro-layout';
import QuickFilter from 'components/filter/quick-filter';
import AdvanceFilter from 'components/filter/advance-filter';
import { ArrowUpOutlined, CopyOutlined, DeleteOutlined, DownloadOutlined, EditOutlined, PlusOutlined, WarningOutlined } from '@ant-design/icons/lib';
import ProTable, { ProColumns, ProTableProps } from "@ant-design/pro-table";
import './list-view-page.less';
import { SorterResult } from "antd/lib/table/interface";
import DetailsPage from './details-page';
import ProDescriptions, { ProDescriptionsItemProps } from '@ant-design/pro-descriptions';
import qs from "qs";
import ExportDataDialog from "export/export-data-dialog";
import useDeepCompareEffect from 'use-deep-compare-effect';
import AuthButton from "common/auth/auth-button";
import Authorized from "common/auth/authorized";
import moment from 'moment';
import ClearEntitiesList from "pages/pim/clearEntities/clear-entities-operate";
import menu from "layouts/menu";
import { Storage } from 'utils/storage-util';

export const goTop = () => {
  document.body.scrollTop = document.documentElement.scrollTop = 0;
}

export const backToTop = () => {
  var currentScroll = document.documentElement.scrollTop || document.body.scrollTop;
  if (currentScroll > 0) {
    window.requestAnimationFrame(backToTop);
    window.scrollTo(0, currentScroll - (currentScroll / 5));
  }
}

// 下载文件
export const createDocH5Download = (arrayBuffer: any, fileName: any) => {
  const elink = document.createElement('a');
  elink.download = (fileName || 'file') + '.xlsx';
  elink.style.display = 'none';
  elink.href = window.URL.createObjectURL(new Blob([arrayBuffer]));
  document.body.appendChild(elink);
  elink.click();
  document.body.removeChild(elink);
}

interface MapStateToProps<T extends BaseModel> {
  entity: T;
  entities: T[];
  loading: boolean;
  updateSuccess: boolean;
  total: number;
  createSuccess?: boolean;
  showLogin?: boolean;
}

interface MapDispatchToProps<T extends BaseModel> {
  getEntity: CrudGetAction<T>;
  getEntities: CrudGetAllAction<T>;
  deleteEntity: CrudDeleteAction<T>;
}

export interface ListViewPageProps<T extends BaseModel, ValueType = "text">
  extends RouteComponentProps,
  MapStateToProps<T>,
  MapDispatchToProps<T>,
  Omit<ProTableProps<T, Record<string, any>, ValueType>, 'columns' | 'loading'> {
  detailsIndex?: number | false;
  columns?: ProColumns<T, ValueType>[];
  downloadColumns?: ProColumns<T, ValueType>[];

  downMoreOperateColumns?: ProColumns<T, ValueType>[];
  downMoreOperateExportData?: any;
  downMoreOperateDataSource?: any;

  detailPage?: ReactNode;
  editPage?: (params: any) => ReactNode;
  editDisabled?: (record: T) => boolean;

  actions?: ReactNode[];
  rowActions?: ((dom: ReactNode, record: T, index: number) => ReactNode)[];
  rowActionsMore?: ((dom: ReactNode, record: T, index: number) => ReactNode)[];
  ShowListTime?: ReactNode;

  hideActionColumn?: boolean;
  hideEditButton?: boolean;
  hideDeleteButton?: boolean;
  hideCreateButton?: boolean;
  hideNavBar?: boolean;  //table顶部导航栏

  showDownload?: boolean;
  showCopyButton?: boolean;
  showSearchButton?: boolean;
  hideDPCreationAction?: boolean;

  filters?: FilterCriteria;
  defaultFilters?: FilterCriteria;
  sort?: string;
  togetherParams?: any; //默认携带的参数
  permanentParams?: any; //不会改变且一直需要携带的参数
  onValuesChange?: any;  // 搜索入参变化事件
  order?: 'descend' | 'ascend';
  pageSizeOptions?: string[];

  quickFilterPanel?: ReactNode | ReactNode[];
  advanceFilterPanel?: ReactNode;

  children?: ReactNode;
  deleteTitle?: string;
  tableAlertOptionRender?: any;
  refreshFlag?: number;
  expandable?: {};
  drawerWidth?: any;
  downloadEntities?: T[];
  downloadAllEntities?: any;//下载全部数据时 是否对当前页数据处理
  downloadEntityName?: string;
  downloadAllEntitiesParams?: any;
  downloadText?: string;
  isDownloadAll?: boolean;
  downloadBtnName?: string;
  downloadAllBtnName?: string;
  isDisabled?: boolean;
  onClickDownload?: any;
  tableClassName?: string;
  pageContainerTitle?: string | boolean;
  clickRowHandle?(id: any): void;
  canDelete?: (row: T) => boolean;
  isInitCurrentPage?: boolean;
  DPCreateAuth?: string;
  scrollConfig?: { x?: number, y?: number };
  disableDefaultRequest?: boolean;
  changeRequestState?: any;
  togetherParamsFunc?: any;
  fetchSearchParams?: any;
  stockDownloadFunc?: any;
  stockDownloadText?: string;

  renovate?: boolean;//手动刷新

  showLogin?: boolean;//token过期

  mustFilter?: boolean;
  mustFilterArr?: string[];
  actionColumnWidth?: number;
  showResetButton?: boolean;
  createButtonText?: string;
}

const ListViewPage = <T extends BaseModel, ValueType = 'text'>(props: ListViewPageProps<T, ValueType>) => {
  const {
    detailsIndex = 0,
    hideDPCreationAction = true,
    createButtonText,
    DPCreateAuth,
    detailPage,
    showResetButton = false,
    editPage,
    mustFilter = false,
    mustFilterArr,
    editDisabled,
    updateSuccess,
    createSuccess,
    renovate,
    filters,
    defaultFilters,
    sort = 'lastModifiedDate,desc',
    downloadEntityName,
    deleteTitle,
    togetherParams = {},
    permanentParams = {},
    quickFilterPanel,
    advanceFilterPanel,
    navigate,
    getEntities,
    getEntity,
    deleteEntity,
    entities,
    total,
    loading,
    actions,
    rowActions,
    expandable,
    drawerWidth,
    downloadColumns,
    downloadEntities,
    downloadAllEntities,
    downloadAllEntitiesParams = {},
    onValuesChange,
    isDownloadAll,
    downloadBtnName,
    isDisabled,
    onClickDownload,
    downMoreOperateColumns,
    downMoreOperateExportData,
    downMoreOperateDataSource,
    tableClassName,
    showDownload = false,
    pageContainerTitle,
    columns,
    refreshFlag,
    clickRowHandle,
    pageSizeOptions,
    canDelete,
    isInitCurrentPage,
    rowActionsMore,
    showCopyButton,
    disableDefaultRequest,
    changeRequestState,
    scrollConfig,
    showSearchButton,
    downloadAllBtnName,
    ShowListTime = false,
    fetchSearchParams,
    togetherParamsFunc = () => '',
    stockDownloadFunc,
    stockDownloadText,
    showLogin = false,
    actionColumnWidth = 120,
    downloadText,
    ...restTableProps
  } = props;

  const [downloadType, setDownloadType] = useState(false);
  const [searchParams, setSearchParams] = useState<any>();
  const [tabsSearchParams, setTabsSearchParams] = useState<any>();
  const [activeKey, setActiveKey] = useState('0');
  const [items, setItems] = useState<any>([]);
  const [tabIdentifying, setTabIdentifying] = useState(0);

  useEffect(() => {
    if (items && items.length > 0) {
      const arr = items.concat().filter((e: any) => e.key + '' === activeKey + '');
      const newTabsSearchParams: any = (arr.length > 0) ? (arr[0]?.params?.searchParams || 0) : {};
      setTabsSearchParams(newTabsSearchParams);
    };
  }, [activeKey, items])

  const parseSearch = useCallback((search: string): PaginationProps & {
    [index: string]: any
  } => {
    const params = qs.parse(search, { ignoreQueryPrefix: true });
    const { page, ...restParams } = params;
    const currentPage = parseInt((page && page.toString()) || '', 10);
    // 合并默认参数.
    return {
      size: defaultSize,
      page: currentPage || 0,
      sort,
      ...restParams,
      ...permanentParams,
      ...togetherParams
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const location = useLocation();
  const initParams = parseSearch(location.search || '');
  const [paginationAndFilter, setPaginationAndFilter] = useState<PaginationProps & FilterCriteria>({
    ...defaultFilters,
    ...initParams
  });

  useEffect(() => {
    loading && showLogin && navigate && navigate('/login');
  }, [navigate, loading, showLogin]);

  // 触发Tabs状态，page初始化0
  useEffect(() => {
    setPaginationAndFilter({
      ...paginationAndFilter,
      page: 0,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isInitCurrentPage, createSuccess])

  // 数据创建成功，刷新数据
  useEffect(() => {
    const request = () => {
      const filterObj = { ...paginationAndFilter, ...filters };
      const newFilterObj: FilterCriteria = {};
      const objArr = Object.keys(filterObj);
      objArr.forEach((key, index) => {
        const val = filterObj[key];
        if (val !== '' && key !== 'searchText') {
          newFilterObj[key] = val;
        };
        (index === objArr.length - 1) && getEntities(newFilterObj);
        fetchSearchParams && (index === objArr.length - 1) && fetchSearchParams(newFilterObj);
      });
    }
    (createSuccess || renovate) && request();

    updateSuccess && setTimeout(() => {
      request();
    }, 100);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [createSuccess, updateSuccess, renovate]);

  const hasDuplicates = (array: any) => {
    var obj: any = {};
    for (var i = 0; i < array.length; i++) {
      if (obj.hasOwnProperty(array[i])) {
        return true;
      }
      obj[array[i]] = true;
    }
    return false;
  };


  const defaultRequest = () => {

    //处理标签是否有重复的key 重复key可能导致标签多选、点击报错、点击卡死的等问题
    const tabsData = JSON.parse(Storage.session.get("tabsData") || '[]');
    const keyArr = tabsData.concat().map((e: any) => e.key);
    if (hasDuplicates(keyArr)) {
      const newTabsData = tabsData.concat().map((e: any, index: number) => ({
        ...e,
        key: index + ''
      }));
      Storage.session.set("tabsData", JSON.stringify(newTabsData));
    };


    const filterObj = { ...paginationAndFilter, ...filters };
    const newFilterObj: FilterCriteria = {};
    const objArr = Object.keys(filterObj);
    objArr.forEach((key, index) => {
      const val = filterObj[key];
      if (val !== '' && key !== 'searchText') {
        newFilterObj[key] = val;
      };
      if (index === objArr.length - 1) {
        setSearchParams(newFilterObj);
        fetchSearchParams && fetchSearchParams(newFilterObj);

        //存tab搜索数据
        //tabIdentifying为0时候 属默认请求,不做处理;为1时候 为搜索请求 对存储的tabsData做变更处理.
        if (tabIdentifying !== 0) {

          const newTbsData = tabsData.concat().map((item: any, index: number) => {
            return (item.params.url === location.pathname) ? {
              ...item,
              params: {
                ...item.params,
                searchParams: newFilterObj,
              }
            } : item;
          });
          Storage.session.set("tabsData", JSON.stringify(newTbsData));
          getEntities(newFilterObj);
        };
        setTimeout(() => {
          !!refreshFlag && getEntities(newFilterObj);
        }, 300);
      };
    });
  };

  useDeepCompareEffect(() => {
    setTabIdentifying(new Date().getTime());
    !disableDefaultRequest && defaultRequest();
  }, [paginationAndFilter, getEntities, filters, refreshFlag]);

  useDeepCompareEffect(() => {
    disableDefaultRequest && changeRequestState && changeRequestState(false) && defaultRequest();
  }, [paginationAndFilter]);

  // 过滤器面板
  const handleFilterChange = (value: FilterCriteria) => {
    setTabIdentifying(1);
    const keys = value ? Object.keys(value) : [];
    const newAllValues = JSON.parse(JSON.stringify(value));
    const newPaginationAndFilter = JSON.parse(JSON.stringify(paginationAndFilter));

    keys.forEach(e => {
      const arr = e.split('.YMD');
      const arr2 = e.split('.YM');
      const arrKey = arr.length > 1 ? arr[0] : (e && arr2.length > 1 ? arr2[0] : e);
      (!value[e] || value[e] === '') && (delete newPaginationAndFilter[e]);
      if (value[e]) {
        let format = '';
        arr.length > 1 ? (format = 'YYYY-MM-DD') : (arr2.length > 1 ? (format = 'YYYYMM') : (format = 'YYYY-MM-DD HH:mm:ss'));
        (arr.length > 1 || arr2.length > 1) && delete newAllValues[e];
        typeof (value[e]) === 'object' && value[e] && !(value[e] instanceof Array) && (newAllValues[arrKey] = moment(value[e]).format(format));
        if (arr.length > 1 || arr2.length > 1) {
          typeof (value[e]) === 'string' && (newAllValues[arrKey] = moment(value[e]).format(format));
        }
      } else {
        (arr.length > 1 || arr2.length > 1) && delete newPaginationAndFilter[arrKey];
      }
    });
    setPaginationAndFilter({
      ...newPaginationAndFilter,
      ...newAllValues,
      ...filters,
      ...permanentParams,
      page: 0,
      size: defaultSize,
      sort,
      searchText: new Date() + '',
      ...togetherParams
    });
  };

  const [advanceFilterVisible, setAdvanceFilterVisible] = useState(false);
  const handleCollapseAdvanceFilter = (collapse: boolean) => {
    setAdvanceFilterVisible(!collapse);
  };

  const handleDelete = useCallback((entity: T) => {
    deleteEntity(entity.id);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [deleteEntity]);

  // const editButtonRender = (dom: ReactNode, record: T): ReactNode => (
  //     !props.hideEditButton &&
  //     <Button type="link" size="small"
  //         onClick={() => {
  //             navigate && navigate(`${record.id}/edit`);
  //         }}
  //         icon={<EditOutlined />}
  //         disabled={canEdit && canEdit?.(record)}
  //     ></Button>
  // );

  const [editModal, setEditModal] = useState<any>({ text: '', id: 0, copy: false });
  const editButtonRender = (dom: ReactNode, record: T): ReactNode => (
    !props.hideEditButton &&
    <Authorized authority={'edit'}>
      <Tooltip title="编辑" key="edit">
        <Button type="link" size="small" style={{ padding: '0' }} disabled={editDisabled && editDisabled(record)}
          onClick={() => {
            editPage ? setEditModal({ text: new Date(), id: record.id as any }) : navigate && navigate(`${record.id}/edit`);
          }
          }
        > <EditOutlined /> </Button>
      </Tooltip>
    </Authorized>
  );

  const copyButtonRender = (dom: ReactNode, record: T): ReactNode => (
    showCopyButton &&
    <Authorized authority={'copy'}>
      <Tooltip title="复制" key="copy">
        <Button type="link" size="small" onClick={() => {
          editPage ? setEditModal({ text: new Date(), id: record.id as any, copy: true }) : navigate && navigate(`${record.id}/edit`);
        }} icon={<CopyOutlined />} />
      </Tooltip>
    </Authorized>
  );

  const creationAction = (
    <>{!props.hideCreateButton && <AuthButton authority={"create"}
      type="primary"
      onClick={() => {
        editPage ? setEditModal({ text: new Date(), id: undefined }) : navigate && navigate('new', { state: { from: window.location.href } });
      }}
      icon={<PlusOutlined />}
    > {createButtonText ? createButtonText : '创建'}</AuthButton>}</>
  );

  //创建按钮展示控制
  //displayPermissionsCreationAction
  const DPCreationAction = (
    <>{!hideDPCreationAction && <AuthButton authority={'create'}
      type="primary"
      onClick={() => {
        editPage ? setEditModal({ text: new Date(), id: undefined }) : navigate && navigate('new', { state: { from: window.location.href } });
      }}
      icon={<PlusOutlined />}
    > 创建</AuthButton>}</>
  );

  const deleteButtonRender = (dom: ReactNode, record: T): ReactNode => (
    !props.hideDeleteButton &&
    <Authorized authority={'delete'}>
      <Tooltip title="删除" key="delete">
        <Popconfirm
          title={deleteTitle ? deleteTitle : "你确定要删除这条数据吗？"}
          icon={<WarningOutlined />}
          disabled={canDelete && !canDelete(record)}
          onConfirm={() => handleDelete(record)}
        >
          <Button
            danger
            type="link" size="small"
            disabled={canDelete && !canDelete(record)}
            icon={<DeleteOutlined />}
          />
        </Popconfirm>
      </Tooltip>
    </Authorized>);
  const standardRowActions = [copyButtonRender, editButtonRender, deleteButtonRender];

  const allRowActions = (rowActions || []).concat(standardRowActions).concat(rowActionsMore || []);

  const actionColumn: ProColumns<T, ValueType> = {
    title: '操作',
    valueType: 'option',
    key: 'action',
    align: 'left',
    fixed: 'right',
    // width: ((allRowActions && allRowActions.length) || 0) * 40,
    width: actionColumnWidth,
    render: (dom: ReactNode, record: T, index: number) => (
      <Space size={0} key="actionSpace" split={<Divider type="vertical" />} wrap={true}>
        {allRowActions.map(actionRender => actionRender(dom, record, index))}
      </Space>
    ),
  };

  // const handleCreate = () => {
  //     navigate && navigate('new', { state: { from: window.location.href } });
  // };

  // const creationAction = (
  //     <>{!props.hideCreateButton &&
  //         <Button
  //             type="primary"
  //             onClick={handleCreate}
  //             icon={<PlusOutlined />}
  //         >
  //             创建
  //         </Button>
  //     }
  //     </>
  // );

  //下载全部回调
  const exportData = async (downLoadText?: string) => {
    let page = 0;
    let size = 100;
    let allData: any[] = [];

    do {
      const params: any = {
        ...filters, ...paginationAndFilter, sort,
        ...permanentParams,
        page,
        size,
        ...togetherParams
      };

      //去除值为空的key
      const newFilterObj: FilterCriteria = {};
      const objArr = Object.keys(params);
      objArr.forEach((key, index) => {
        const val = params[key];
        if (val !== '') {
          newFilterObj[key] = val;
        };
      });

      const getData: any = getEntities && await getEntities(newFilterObj, true);
      let newData = [];
      if (downMoreOperateExportData && downLoadText && downLoadText === 'order') {
        newData = downMoreOperateExportData ? downMoreOperateExportData(getData.value.data.data.records) : getData.value.data.data.records;
      } else {
        newData = downloadAllEntities ? downloadAllEntities(getData.value.data, downloadAllEntitiesParams) : getData.value.data;
      };
      console.log(newData)
      allData = allData.concat(newData);
      ++page;

    } while ((page - 1) * size < total);
    return allData
  }

  const standardActions = [
    creationAction,
    DPCreationAction,
    showDownload &&
    <ExportDataDialog
      columns={downloadColumns || props.columns}
      dataSource={downloadEntities || entities}
      exportData={exportData}
      downloadText={downloadText}
      downloadBtnName={downloadBtnName}
      downloadAllBtnName={downloadAllBtnName}
      isDownloadAll={isDownloadAll}
      onClickFun={onClickDownload}
      isDisabled={isDisabled}
      downMoreOperateColumns={downMoreOperateColumns}
      downMoreOperateDataSource={downMoreOperateDataSource}
      entityName={downloadEntityName}
    />,
    stockDownloadFunc && <Dropdown overlay={
      <Menu>
        <Menu.Item key="stockDownloadDefault" >
          <Button loading={downloadType} disabled={downloadType} onClick={() => {
            setDownloadType(true);
            stockDownloadFunc(searchParams || {}).then((res: any) => {
              createDocH5Download(res.data, stockDownloadText);
              setDownloadType(false);
            });
          }}>全部下载</Button>
        </Menu.Item>
      </Menu>
    }>
      <Button >
        下载 <DownloadOutlined />
      </Button>
    </Dropdown>,
  ];

  const handleTableChange = (tablePaginationConfig: TablePaginationConfig,
    filters: Record<string, (Key | boolean)[] | null>,
    sorter: SorterResult<T> | SorterResult<T>[]) => {
    setTabIdentifying(new Date().getTime());

    if (sorter) {
      if (sorter instanceof Array) {
        // 只支持单列排序
        sorter = sorter[0];
      }
    };

    const sortQuery = (sorter?.field && sorter?.order)
      ? `${sorter?.field.toString()}${sorter?.order === 'descend' ? ',desc' : ''}`
      : '';
    setPaginationAndFilter({
      ...paginationAndFilter,
      sort: !sortQuery ? sort : sortQuery,
      ...permanentParams,
      size: tablePaginationConfig.pageSize,
      page: tablePaginationConfig.current ? tablePaginationConfig.current - 1 : 0,
      ...togetherParams,
    });
  };

  const buildColumns = (columns: ProColumns<T, ValueType>[],
    detailsIndex: number | false,
    action: ProColumns<T, ValueType>): ProColumns<T, ValueType>[] => {
    // || allRowActions[0].length === 0
    let newColumns = (props.hideActionColumn) ? [...columns] : [...columns]?.concat(action);
    if (detailsIndex !== false) {
      const { render, ...rest } = newColumns[detailsIndex];
      const columnRender = (dom: React.ReactNode, entity: T, index: number, action: any, schema: any): ReactNode =>
        <Typography.Link
          onClick={() => {
            if (!clickRowHandle) {
              entity.id && getEntity(entity.id, togetherParamsFunc(entity));
              setDetailsVisible(true);
            } else {
              clickRowHandle(entity.id);
              setDetailsVisible(true);
            }
          }}
        >
          {dom}
          {/*{render!(dom, entity, index, action, schema)}*/}
          {/*{(render && render!(dom, entity, index, action, schema)) || dom}*/}
          {/*{render && render(dom, entity, index, action, schema) || dom}*/}
          {/*{(!!render && render(dom, entity, index, action, schema)) || dom}*/}
        </Typography.Link>;

      newColumns.splice(detailsIndex, 1, {
        ...rest,
        render: columnRender
      } as ProColumns<T, ValueType>);
    }

    return newColumns;
  }

  const [detailsVisible, setDetailsVisible] = useState(false);
  useEffect(() => {
    //关闭详情 详情回到顶部
    goTop();
  }, [detailsVisible]);

  const TabsOnChange = (newActiveKey: string) => {
    setActiveKey(newActiveKey + '');
    const url = items.concat().filter((e: any) => e.key === newActiveKey + '')[0].params.url;
    navigate && navigate(url);
  };

  const searchActiveKey = (newPanes: any) => {
    const pathname = location.pathname;
    const key = newPanes.concat().filter((e: any) => e.params.url === pathname)?.[0]?.key || '0';
    setActiveKey(key);
  }

  const add = (params: any) => {
    const tabsData = JSON.parse(Storage.session.get("tabsData") || '[]');
    const newPanes = (tabsData && tabsData.length > 0) ? tabsData : [];
    const newActiveKey = newPanes.length;
    setItems(newPanes);
    searchActiveKey(newPanes);
    if (newPanes.concat().filter((item: any) => item.params.url === params.url).length === 0) {
      newPanes.push({ label: params.title, children: '', key: newActiveKey + '', params: params });
      setItems(newPanes);
      searchActiveKey(newPanes);
      Storage.session.set("tabsData", JSON.stringify(newPanes));
      getEntities({
        ...paginationAndFilter,
        ...filters,
      });
    } else {
      const filterParams = newPanes.concat().filter((item: any) => item.params.url === params.url)?.[0]?.params?.searchParams || {};
      filterParams && getEntities({
        ...paginationAndFilter,
        ...filters,
        ...filterParams,
      });
    }
  };

  const remove = (targetKey: string) => {
    const newItems = items.concat().filter((e: any) => e.key !== targetKey);
    setItems(newItems);
    Storage.session.set("tabsData", JSON.stringify(newItems));
  };

  const onEdit = (
    targetKey: any,
    action: any,
  ) => {
    if (action === 'add') {
      // add();
    } else {
      remove(targetKey);
    }
  };

  useEffect(() => {

    //页面刷新回到顶部
    goTop();

    setTabIdentifying(0);
    const menuItem: any = menu.concat().filter((item: any) => {
      const pathOne = location.pathname.split('/')?.[1] || '';
      return item?.path === '/' + pathOne
    });
    // const titleOne = menuItem.length > 0 ? menuItem[0].name : '';
    const titleTwo = menuItem.length > 0 ? menuItem[0].children.concat().filter((item: any) => {
      const pathTwo = location.pathname.split('/')?.[2] || '';
      return item?.path === pathTwo
    })?.[0]?.name : '';
    // add({ title: titleOne + '-' + titleTwo, url: location.pathname });
    add({ title: titleTwo, url: location.pathname });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location.pathname]);

  return (
    <div className="content">
      <div className={`content_center ${!props.hideNavBar ? '':'marginTop0'}`}>
      {!props.hideNavBar &&
        <div className="content_top">
          <div className="box">
            <Tabs
              size="small"
              type="editable-card"
              onChange={TabsOnChange}
              activeKey={activeKey}
              onEdit={onEdit}
              items={items}
              // tabBarStyle={{width:'85vw',height:'30px'}}
              tabBarGutter={4}
              tabBarExtraContent={<Button className="deleteAll" onClick={() => {
                setItems([]);
                Storage.session.set("tabsData", JSON.stringify([]));
              }} danger icon={<DeleteOutlined />}>删除全部</Button>}
              hideAdd
            />
          </div>
        </div>
      }
        <PageContainer
          // title={pageContainerTitle}
          title={' '}
          breadcrumb={{}}
          content={advanceFilterPanel && advanceFilterVisible &&
            <AdvanceFilter
              filters={paginationAndFilter}
              onFilterChange={handleFilterChange}
            >
              {advanceFilterPanel}
            </AdvanceFilter>}
          extra={quickFilterPanel &&
            <QuickFilter
              loading={loading}
              onValuesChange={onValuesChange}
              tabsSearchParams={tabsSearchParams}
              filters={paginationAndFilter}
              showResetButton={showResetButton}
              hasAdvance={(advanceFilterPanel && true) || false}
              onFilterChange={handleFilterChange}
              showSearchButton={showSearchButton}
              mustFilter={mustFilter}
              mustFilterArr={mustFilterArr}
              onCollapseAdvanceFilter={handleCollapseAdvanceFilter}
            >
              {quickFilterPanel}
            </QuickFilter>}
        >
          <ClearEntitiesList getEntities={getEntities} disableDefaultRequest={disableDefaultRequest} />
          {ShowListTime && <p className="ListTime">最后更新时间：{(entities?.[0] as any)?.createTime}</p>}
          <ProTable<T, Record<string, any>, ValueType>
            bordered
            size="small"
            tableClassName={tableClassName}
            rowKey="id"
            search={false}
            scroll={scrollConfig || { x: 1200 }}
            dateFormatter="string"
            columns={buildColumns(props.columns ? props.columns.map((e, index) => ({ ...e, fixed: index < 2 ? 'left' : e.fixed })) : [], detailsIndex, actionColumn).map(e => ({
              ...e,
              width: e.width ? e.width : 100
            }))}
            toolbar={{
              actions: [...standardActions, ...(actions || [])],
            }}
            tableAlertRender={({ selectedRowKeys, onCleanSelected }) => (
              <Space size={24}>
                <span>已选 {selectedRowKeys.length} 项
                  <Button size="small" type='link' style={{ marginLeft: 8 }} onClick={onCleanSelected}>
                    取消选择
                  </Button>
                </span>
              </Space>)
            }
            tableAlertOptionRender={props.tableAlertOptionRender}
            options={{
              reload: () => {
                const filterObj = { ...paginationAndFilter, ...filters, sort, ...permanentParams, ...togetherParams };
                const newFilterObj: FilterCriteria = {};
                const objArr = Object.keys(filterObj);
                objArr.forEach((key, index) => {
                  const val = filterObj[key];
                  if (val !== '' && key !== 'searchText') {
                    newFilterObj[key] = val;
                  };
                  (index === objArr.length - 1) && getEntities(newFilterObj);
                });
              }
            }}
            dataSource={entities}
            loading={loading}
            onChange={handleTableChange}
            // pagination={{
            //   size: paginationAndFilter.pageSize,
            //   current: (paginationAndFilter.page ? paginationAndFilter.page + 1 : 1),
            //   total: props.total,
            //   pageSizeOptions: pageSizeOptions
            // }}
            pagination={false}
            expandable={expandable}
            {...restTableProps}
          />
          <Affix offsetBottom={0}>
            <div className="page_box">
              <Pagination
                total={props.total}
                size={paginationAndFilter.pageSize}
                current={(paginationAndFilter.page ? paginationAndFilter.page + 1 : 1)}
                showSizeChanger
                defaultPageSize={20}
                onChange={(current, pageSize) => (
                  handleTableChange({ pageSize, current }, [] as any, [] as any)
                )}
                pageSizeOptions={pageSizeOptions}
                showTotal={(total, range) => {
                  return <p>当前：<span className="pageName">{range[0]}</span>-<span className="pageName">{range[1]}</span> 条；共：<span className="pageName">{total}</span> 条</p>
                }}
              />
            </div>
          </Affix>
          <Affix offsetBottom={80}>
            <div onClick={() => { backToTop() }} className="goTop"><ArrowUpOutlined style={{ color: '#fff' }} /></div>
          </Affix>

          <Drawer
            visible={detailsVisible}
            onClose={() => {
              setDetailsVisible(false);
            }}
            width={drawerWidth || '84vw'}
          >
            {detailPage ||
              <DetailsPage<T, ValueType> entity={props.entity}>
                <ProDescriptions<T, ValueType>
                  columns={props.columns as ProDescriptionsItemProps<T, ValueType>[]}
                  dataSource={props.entity}
                />
              </DetailsPage>
            }

          </Drawer>

          {/* 弹框-新建编辑 */}
          {editPage && editPage(editModal)}

          {props.children}
        </PageContainer>
      </div>
    </div>
  );
};

export default ListViewPage;