import {BaseModel} from "../base.model";

export interface Evaluation extends BaseModel {
    visitedImg?: string;
    isVisitedStore?: string;
    visitAffiliatedCompany?: string;
    visitedAffiliatedCompany?: string;
    visitPhone?: string;
    visitedPhone?: string;
}
export const defaultEvaluation:  Readonly<Evaluation> = {};