import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import EvaluationDetails from './evaluation-details';
import EvaluationEdit from './evaluation-edit';
import EvaluationList from './evaluation-list';

const Evaluation: React.FC<RouteComponentProps> = () => (
  <Router>
    <EvaluationList path="/" />
    <EvaluationDetails path=":id" />
    <EvaluationEdit path=":id/edit" />
    <EvaluationEdit path="new" />
  </Router>
);
export default Evaluation;
