
import { Statistic } from 'antd';

const { Countdown } = Statistic;

interface DeadlineProps {
    deadline: number;
}

const Deadline = (props:DeadlineProps) => {
    return (<Countdown title="" value={props.deadline} format="D 天 H 时 m 分 s 秒" />)
};

export default Deadline;