import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPost } from "model/video/post.model";
import { CrudDeleteAction, CrudGetAction, CrudPostAction, CrudPutAction } from 'reducers/redux-action.type';
import axios from 'axios';
import { cleanEntity } from 'utils/entity-utils';

const entity = 'course';
const apiUrl = '/api/yumei-mall-content/lessonSon/findAllSonByPage';

const standardReducerImpl = new StandardReducerImpl<any>(entity, defaultPost, apiUrl);
export type CourseState = StandardState<any>;
export const course = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
// export const getEntity = standardReducerImpl.getEntity;
// export const createEntity = standardReducerImpl.createEntity;
// export const updateEntity = standardReducerImpl.updateEntity;
// export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 查询详情
export const getEntity: CrudGetAction<any> = (id: any, togetherParams: any) => {
    let apiUrl = `/api/yumei-mall-content/lessonSon/findLessonSonItem/${id}`
    const requestUrl = (apiUrl as any)?.detailUrl ? `${(apiUrl as any).detailUrl + togetherParams}` : `${apiUrl}`;
    return {
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH),
        payload: axios.get<any>(requestUrl),
    };
};

// 获取全部课程
export const getAllSonCourse = async (): Promise<any[]> => {
    const axiosResponse = await axios.get(`/api/yumei-mall-content/lessonSon/findAllSonByPage`, { params: { page: 0, size: 10000 } });
    return axiosResponse.data || [];
}

// 获取全部课程
export const fetchAllSonCourse = async (params:any): Promise<any[]> => {
    let data = {
        ...params,
        'title.contains': params.title
    }
    delete data.title
    const axiosResponse:any = await axios.get(`/api/yumei-mall-content/lessonSon/findAllSonByPage`, { params: data });
    return axiosResponse;
}
  


// 添加
export const createEntity: CrudPostAction<any> = entity => async dispatch => {
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
        payload: axios.post('/api/yumei-mall-content/lessonSon/addLessonSon', cleanEntity(entity)),
    });
};

// 修改
export const updateEntity: CrudPutAction<any> = (entity) => async dispatch => {
    let apiUrl = '/api/yumei-mall-content/lessonSon/updateLessonSon'
    const requestUrl = (apiUrl as any)?.editUrl ? `${(apiUrl as any).editUrl + (entity as any).updateEntityParams}` : `${apiUrl}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
        payload: axios.put(requestUrl, cleanEntity(entity)),
    })
};

// 删除
export const deleteEntity: CrudDeleteAction<any> = (id) => async dispatch => {
     let apiUrl = '/api/yumei-mall-content/lessonSon/delLessonSon'
    const requestUrl = (apiUrl as any)?.deleteUrl ? `${(apiUrl as any).deleteUrl}` : `${apiUrl}`;
    return await dispatch({
        type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.CREATE),
        payload: axios.post(requestUrl, {
            id 
        }),
    });
};

