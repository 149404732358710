import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import { getTenantList } from "./swichtenant.reducer";
import { useEffect, useState } from "react";
import { Form, message } from "antd";
import { Storage } from 'utils/storage-util';
import {navigate} from '@reach/router';

const SwitchTenant = (props: any) => {
    let { visible, onCancel, onSuccess } = props
    const [form] = Form.useForm();
    const [tenantList, setTenantList] = useState<any>([]);
    const formItemLayout = {
        labelCol: {
            xs: { span: 24 },
            sm: { span: 4 },
        },
        wrapperCol: {
            xs: { span: 24 },
            sm: { span: 20 },
        },
    };

    useEffect(() => {
        getTenantList().then((e: any) => {
            let data = e.map((item: any) => ({
                value: item?.id,
                label: item?.name,
                tenantPlatform: item.tenantPlatform
            }))
            setTenantList(data)
        });

    }, [visible])


    return (
        <ModalForm
            title="选择租户"
            visible={visible}
            onVisibleChange={(e) => {
                const accountClient = Storage.session.get("x-tenant");
                if (!e && !accountClient) {
                    message.error('租户不能为空')
                    return false
                } else if (!e && accountClient) {
                    onCancel()
                }
            }}
            width={600}
            form={form}
            {...formItemLayout}
            layout='horizontal'
            preserve={false}
            modalProps={{
                maskClosable: false
            }}

            onFinish={async (data: any) => {
                let row: any = tenantList.find((item: any) => item.value === data.tentType)
                if (row) {
                    Storage.session.set("x-tenant-data", row || []);
                    Storage.session.set("x-tenant", data.tentType);
                    Storage.session.set("tabsData", JSON.stringify([]));
                    let title = row.label || '商城';
                    onSuccess(title);
                    navigate(0);
                };
                
                return true;
            }}
        >
            <ProFormSelect
                label="租户"
                rules={[{ required: true }]}
                name={'tentType'}
                options={tenantList}
            />
        </ModalForm>
    )
}

export default SwitchTenant;