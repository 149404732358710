import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultProduct } from 'model/product/product.model';
import axios from "axios";
import { Region } from 'model/product/region.model';
import { FilterCriteria } from 'components/model/share.model';

const entity = 'region';
const apiUrl = '/api/yumei-mall-pim/region/template';
const standardReducerImpl = new StandardReducerImpl<Region>(entity, defaultProduct, apiUrl);
export type RegionState = StandardState<Region>;
export const region = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const getRegions = async (code?: string) => {
  const requestUrl = `/api/yumei-mall-region/region/tree?code=${code}`;
  const data = await axios.get(requestUrl, {
    params: {}
  })
  let option: any = [];
  await (option = data?.data[0]?.children.map((e: any) => {
    return {
      value: e.code,
      label: e.name,
      children: e.children && e.children.map((re: any) => {
        return {
          value: re.code,
          label: re.name,
          children: re.children && re.children.map((res: any) => {
            return {
              value: res.code,
              label: res.name,
            }
          })
        }
      })
    }
  }))
  return option
}

//销售区域模版列表
export const getRegionsList = async (sellerId?: string | number, templateName?: string) => {
  const filterObj: any = { size: 20, page: '0', 'sellerId.equals': sellerId, 'disabled.equals': false, 'templateName.contains': templateName };
  const newFilterObj: FilterCriteria = {};
  const objArr = Object.keys(filterObj);
  await objArr.forEach((key, index) => {
    const val = filterObj[key];
    if (val !== '') {
      newFilterObj[key] = val;
    };
  });
  return await axios.get<any>(apiUrl, { params: newFilterObj });
}

//销售区域模版全局列表
export const getRegionsOverallSituationList = async () => {
  return await axios.get<any>(`${apiUrl}/is/overallSituation`);
}