import { message } from 'antd';
import { internationalList } from 'components/IntelFormText';
import { TreeData } from 'components/model/form.model';
import {BaseModel, EnumValue} from 'model/base.model';

export const toSelectOptionsType = <T extends BaseModel>(entities: T[], getDisplayName: (record: T) => string) =>
  entities.map(item => ({
    value: item.id || -1,
    label: internationalList(getDisplayName(item) || '') ,
    item
  }));

export const toOptionsType = (values: {[index: string]: string}) => Object.entries(values).map(([key, value])=> ({value: key, label: value}));

export const enumToOptionsType = (values: EnumValue[]) => (values || []).map((value)=> ({
  value: value.name,
  label: value.desc || '',
}));
export const DateFormat = function (date:Date, fmt:string) {
  var o:any = {
    'M+': date.getMonth() + 1, //月份
    'd+': date.getDate(), //日
    'h+': date.getHours(), //小时
    'm+': date.getMinutes(), //分
    's+': date.getSeconds(), //秒
    'q+': Math.floor((date.getMonth() + 3) / 3), //季度
    'S': date.getMilliseconds() //毫秒
  }
  if (/(y+)/.test(fmt))
    fmt = fmt.replace(RegExp.$1, (date.getFullYear() + '').substr(4 - RegExp.$1.length))
  for (var k in o)
    if (new RegExp('(' + k + ')').test(fmt))
      fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length)))
  return fmt
}
export const toTreeData = <T extends BaseModel>(
  entities: T[],
  getChildren: (entity: T) => T[],
  getDisplayName?: (entity: T) => string): TreeData => entities.map(item => ({
    key: item?.id?.toString() || '',
    value: item?.id?.toString() || '',
    title: (getDisplayName && getDisplayName(item)) || item?.id?.toString() || '',
    children: toTreeData(getChildren(item), getChildren, getDisplayName),
    item: item,
  })
);

// export const treeFind = <T extends TreeModel<T>>(items: T[], id: string): T | undefined => {
//
//   let result = undefined;
//   items.forEach(item => {
//     if (item.id === id) {
//       result = item;
//       return;
//     }
//
//     if (item.children) {
//       result = treeFind<T>(item.children, id);
//       if (result) {
//         return;
//       }
//     }
//   })
//
//   return result;
// };

export const formatFileSize = (fileSize?: number) => {
  if (!fileSize) {
    return "0 Bytes";
  }

  const unitArr = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB'];
  const index = Math.floor(Math.log(fileSize) / Math.log(1024));
  const size = (fileSize / Math.pow(1024, index)).toFixed(2);
  return size + unitArr[index];
}
// export const toTreeObjectData = <T extends BaseModel>(entities: T[], getChildren: (entity: T) => T[]) : TreeData => entities.map(item => ({
//     value: T,
//     title: item.name || '',
//     children: toTreeData(getChildren(item), getChildren),
//     item
//   })
// );

export const userIdsUnique = (arr:any) => {
  if (!Array.isArray(arr)) {
      message.error('type error!')
      return
  }
  return Array.from(new Set(arr))
}
//解析国际化参数
export const getGlobalKeyValue = (value: string,language?: string) => {
  language = language || 'zh-CN'
  try {
    let parseValue = JSON.parse(value);
    if(parseValue && parseValue[language]){
      return parseValue[language];
    }

  }
  catch(err){
    return value;
  }
}