import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';
import {defaultAdvertisement, advertisementModel } from "model/market/advertisement.model";
import axios from 'axios';
import { CrudGetAllAction } from 'reducers/redux-action.type';

const entity = 'level';
const apiUrl = '/api/yumei-mall-train/activity';
const standardReducerImpl = new StandardReducerImpl<advertisementModel>(entity, defaultAdvertisement, apiUrl);
export type SalonApplicationState = StandardState<advertisementModel>;
export const salonApplication = standardReducerImpl.buildStandardReducer();

// export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


export const getEntities: CrudGetAllAction<any> = (params?: any) => dispatch => {
  let url =`/api/yumei-mall-train/activity/findByPage?size=${params.size}&page=${params.page}`
  return dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.FETCH_LIST),
    payload: axios.post<any>(url, params)
  });
};


// 上传图片素材
export const uploadImages = async (params:any) => {
  const requestUrl = `/api/yumei-mall-train/activity`;
  const axiosResponse = await axios.put<any>(requestUrl, params);
  console.log(axiosResponse)
  return axiosResponse.data;
}









