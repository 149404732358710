import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import Post from './post';
import './index.less'
import Topic from './topic';
import VideoCategory from './category';
import Publisher from './publisher';

const Video: React.FC<RouteComponentProps> = () => (
    <Router>
        <Post path="post/*" />
        <Topic path="topic/*" />
        <VideoCategory path="category/*" />
        <Publisher path="publisher/*" />
    </Router>
);
export default Video;
