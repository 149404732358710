import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import DealerInfoDetails from './dealerInfo-details';
import DealerInfoList from './dealerInfo-list';
import './index.less'

const DealerInfo: React.FC<RouteComponentProps> = () => (
  <Router>
    <DealerInfoList path="/" />
    <DealerInfoDetails path=":id" />
  </Router>
);
export default DealerInfo;
