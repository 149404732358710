import { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./payee-enterprise.reducer";
import { Col, Row } from "antd";
import { ProFormText } from "@ant-design/pro-form";
import { PayeeEnterprise } from "model/system/payee-enterprise.model";
export interface PayeeEnterpriseEditProps extends EditStateAndDispatchProps<PayeeEnterprise>, StateProps, DispatchProps, RouteComponentProps {
}

const PayeeEnterpriseEdit: FC<PayeeEnterpriseEditProps> = props => {
  return (
    <EditPage<PayeeEnterprise>
      title="主体"
      {...props}
      entity={{ 
        ...props.entity,
        id: props.entity.code
      }}
    >
      <Row>
        <Col span={11}>
          <ProFormText
            name="code"
            label="主体编码"
            width="md"
            rules={[{
              required: true,
              pattern: RegExp(/^[a-zA-Z0-9_]+$/i),
              message: '仅限输入英文数字下划线，不可有空格及符号'
            }]}
            disabled={props.entity.code ? true : false}
          />
        </Col>
        <Col span={1}></Col>
        <Col span={11}>
          <ProFormText
            name="name"
            label="主体名称"
            width="md"
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ payeeEnterprise }: RootState) => ({
  entity: payeeEnterprise.entity,
  updateSuccess: payeeEnterprise.updateSuccess,
  updating: payeeEnterprise.updating,
  createSuccess: payeeEnterprise.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PayeeEnterpriseEdit);
