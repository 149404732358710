import { BaseModel } from "../base.model";

export interface LifePavilion extends BaseModel {
    doorHeadPictureUrl?: string
    marriageCertificatePictureUrl?: string
    licPictureUrl?: string
    transferAccountPictureUrl?: string
    displayPictureUrl?: string
    planeSizePictureUrl?: string
    idCardFrontPictureUrl?: string
    idCardOppositePictureUrl?: string
    inStorePictureUrl?: string
    insideStorePictureUrl?: string
    storePictureUrl?: string
    myExitPictureUrl?: string
    trainRecords?: any
    times?: any
    authorizedStoresName?: any
}


export const defaultLifePavilion: Readonly<LifePavilion> = {};