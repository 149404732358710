import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { createEntity, getEntity, reset, updateEntity } from "./address.reducer";
import { ProFormSwitch, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import { Address } from "model/store/address.model";
import { Col, Form, Input, Row, Select } from "antd";
import { fetchStoreList } from "../store/store.reducer";
import { Storage } from 'utils/storage-util';
import { queryBladeSystemSelect } from "pages/dealer/unit/unit.reducer";
import SelectSearch from "components/select-search";
import { ProvinceCode } from "model/procure/order.model";
const { Option } = Select;

export interface AddressEditProps extends EditStateAndDispatchProps<Address>, StateProps, DispatchProps, RouteComponentProps {
}

const AddressEdit: FC<AddressEditProps> = props => {
  const [form] = Form.useForm();
  const { entity } = props;
  const [provinceSelectData, setProvinceSelectData] = useState<ProvinceCode[]>([]);//省
  const [citySelectData, setCitySelectData] = useState<ProvinceCode[]>([]);//市
  const [districtSelectData, setDistrictSelectData] = useState<ProvinceCode[]>([]);//区

  useEffect(() => {
    setDataFun();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entity]);

  const setDataFun = async () => {
    const xTenant = Storage.session.get("x-tenant");
    const regionObj: any = {
      tmm: ['00', '11', '1101'],
      ttw: ['01', '0101', '010101'],//台湾
      tml: ['N3', 'N301', 'N30101'],//马来西亚
      tsg: ['02', '0201', '020101'],//新加坡
    };
    const regionKey: 'tmm' | 'ttw' | 'tml' | 'tsg' = regionObj[xTenant] ? xTenant : 'tmm';
    setProvinceSelectData(await getBladeSystemSelect(regionObj[regionKey][0]));
    setCitySelectData(await getBladeSystemSelect(regionObj[regionKey][1]));
    setDistrictSelectData(await getBladeSystemSelect(regionObj[regionKey][2]));
  };

  //联系地址
  const getBladeSystemSelect = async (code: string | number) => {
    const res = await queryBladeSystemSelect(code);
    return (res && res.length > 0 && res) || [];
  }

  const handleProvinceChange = async (value: any, option: any) => {
    form.setFieldsValue({ city: '', district: '' });
    setCitySelectData(await getBladeSystemSelect(value));
    setDistrictSelectData([]);
  };

  const handleCityChange = async (value: any, option: any) => {
    form.setFieldsValue({ district: '' });
    setDistrictSelectData(await getBladeSystemSelect(value));
  };
  const UpdateEntity = (data?: Address) => {
    const provinceName = provinceSelectData.filter(e => e.code === data?.province)[0].name || '';
    const cityName = citySelectData.filter(e => e.code === data?.city)[0].name || '';
    const districtName = districtSelectData.filter(e => e.code === data?.district)[0].name || '';
    data && (data.mergeName = provinceName + cityName + districtName);
    data && (data.country = data.country || '');
    return new Promise<Address>((resolve, reject) => data && resolve(data))
  }

  return (
    <EditPage<Address>
      title="退货地址"
      beforeUpdateEntity={UpdateEntity}
      form={form}
      {...props}
    >
      <Row>
        <Col span="7">
          <Form.Item name={'sellerId'}>
            <SelectSearch name="sellerId" label='店铺名称' defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col>
        <Col span="1"></Col>
        <Col span="7">
          <ProFormText
            name="mobile"
            label="手机号"
            rules={[{ required: true }]}
            width="sm"
          />
        </Col>
        <Col span="1"></Col>
        <Col span="7">
          <ProFormText
            name="zipcode"
            label="邮政编码"
            width="sm"
          />
        </Col>
        <Col span="7">
          <ProFormText
            name="consignee"
            label="收件人"
            rules={[{ required: true }]}
            width="sm"
          />
        </Col>
        <Col span="1"></Col>
        <Col span="12">
          <Form.Item label='收件地址选择'>
            <Input.Group compact>
              <Form.Item name={['province']} noStyle rules={[{ required: true }]}>
                <Select style={{ width: 100 }} onChange={handleProvinceChange} placeholder="省" 
                  showSearch
                  filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                >
                  {provinceSelectData && provinceSelectData.map((province: ProvinceCode) =>
                    <Option key={province.code} value={String(province.code)}>{province.name}</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item name={['city']} noStyle rules={[{ required: true }]}>
                <Select style={{ width: 100 }} onChange={handleCityChange} placeholder="市"
                  showSearch
                  filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                >
                  {citySelectData && citySelectData.map((city: ProvinceCode) =>
                    <Option key={city.code} value={String(city.code)}>{city.name}</Option>
                  )}
                </Select>
              </Form.Item>
              <Form.Item name={['district']} noStyle rules={[{ required: true }]}>
                <Select style={{ width: 100 }} placeholder="区" 
                  showSearch
                  filterOption={(input, option) => (option!.children as unknown as string).toLowerCase().includes(input.toLowerCase())}
                >
                  {districtSelectData && districtSelectData.map((district: ProvinceCode) =>
                    <Option key={district.code} value={String(district.code)}>{district.name}</Option>
                  )}
                </Select>
              </Form.Item>
            </Input.Group>
          </Form.Item>
        </Col>
        <Col span="4">
          <ProFormSwitch name="isDefault"
            label="是否默认"
            width='sm'
            initialValue={0}
            getValueFromEvent={value => (value && 1) || 0}
            getValueProps={value => ({ checked: value === 1 ? true : false })}
            fieldProps={{
              checkedChildren: '是',
              unCheckedChildren: '否'
            }}
          />
        </Col>
        <Col span="15">
          <ProFormTextArea
            name="address"
            label="详细地址"
            rules={[{ required: true }]}
          />
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ address }: RootState) => ({
  entity: address.entity,
  updateSuccess: address.updateSuccess,
  updating: address.updating,
  createSuccess: address.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AddressEdit);
