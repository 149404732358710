import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import SealAuthDetails from "./sealAuth-details";
import { deleteEntity, getEntities, getEntity } from "./sealAuth.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import SealAuthEdit from "./sealAuth-edit";
import { SealAuth, SealAuthAuthStatus, SealAuthStatus } from "model/contract/sealAuth.model";

export interface SealAuthListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<SealAuth>[] = [{
  title: 'id',
  dataIndex: 'id',
  ellipsis: true,
  width: 60,
}, {
  title: '授权码',
  dataIndex: 'account',
  width: 100,
}, {
  title: '公司名称',
  dataIndex: 'orgName',
  width: 100,
}, {
  title: '公司id',
  dataIndex: 'orgId',
  width: 100,
}, {
  title: '合同印章名称',
  dataIndex: 'sealName',
  width: 100,
}, {
  title: '合同印章认证id',
  dataIndex: 'sealAuthBizId',
  width: 120,
}, {
  title: '合同印章id',
  dataIndex: 'sealId',
  width: 100,
}, {
  title: '授权公司名称',
  dataIndex: 'authorizedOrgName',
  width: 100,
}, {
  title: '授权公司统一信用代码',
  dataIndex: 'authorizedOrgIdCardNum',
  width: 100,
}, {
  title: '有效时间',
  dataIndex: 'effectiveTime',
  width: 100,
}, {
  title: '过期时间',
  dataIndex: 'expireTime',
  width: 100,
}, {
  title: '创建时间',
  dataIndex: 'createTime',
  width: 100,
}, {
  title: '更新时间',
  dataIndex: 'updateTime',
  width: 100,
}, {
  title: '审核状态',
  dataIndex: 'authStatus',
  valueEnum: SealAuthAuthStatus,
  width: 100,
}, {
  title: '合同状态',
  dataIndex: 'status',
  valueEnum: SealAuthStatus,
  width: 100,
}];


const SealAuthList: FC<SealAuthListProps> = (props) => {
  const searchFields = [{
    label: 'id',
    name: 'id.equals',
  },{
    label: '授权码',
    name: 'account.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<SealAuth>
      columns={columns}
      hideDeleteButton
      sort=''
      hideCreateButton
      hideEditButton
      hideActionColumn
      quickFilterPanel={quickFilterPanel}
      detailPage={<SealAuthDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <SealAuthEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ sealAuth }: RootState) => ({
  entity: sealAuth.entity,
  entities: sealAuth.entities,
  loading: sealAuth.loading,
  updateSuccess: sealAuth.updateSuccess,
  createSuccess: sealAuth.createSuccess,
  total: sealAuth.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SealAuthList);