import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import RoleDetails from './role-details';
import RoleEdit from './role-edit';
import RoleList from './role-list';

const Role: React.FC<RouteComponentProps> = () => (
  <Router>
    <RoleList path="/" />
    <RoleDetails path=":id" />
    <RoleEdit path=":id/edit" />
    <RoleEdit path="new" />
  </Router>
);
export default Role;
