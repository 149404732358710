import { BaseModel, DisableModel } from 'model/base.model';

export interface AuthFlow extends BaseModel, DisableModel {

}

export const AuthFlowOrgIdCardType = {
    'CRED_ORG_USCC': '统一社会信用代码',
    'CRED_ORG_REGCODE': '工商注册号'
}
export const AuthLegalRepIdCardType = {
    'CRED_PSN_CH_IDCARD':'中国大陆居民身份证',
    'CRED_PSN_CH_HONGKONG':'香港来往大陆通行证',
    'CRED_PSN_CH_MACAO':'澳门来往大陆通行证',
    'CRED_PSN_CH_TWCARD':'台湾来往大陆通行证',
    'CRED_PSN_PASSPORT':'护照'
}
export const AuthType = {
    'ORG':'机构实名认证',
    'PSN':'个人实名认证'
}
export const AuthRealNameStatus = {
    '0':'未实名',
    '1':'已实名'
}
export const AuthorizedStatus = {
    '0':'流程过期失效',
    '1':'已授权',
    '2':'授权中',
    '3':'审批未通过'
}


export const defaultAuthFlow: Readonly<AuthFlow> = {
};
