import { Form, Input } from "antd";
import SearchBox, { SearchField } from "components/search-box";
import { priceRulesModel } from "model/product/pricingRules";
import { FC, ReactNode } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import PricingRulesDetails from "./advertisement-details";
import { deleteEntity, getEntities, getEntity } from "./advertisement.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import PricingRulesEdit from "./advertisement-edit";
import { Space, Image } from "antd";
import { internationalList } from "components/IntelFormText";
import defaultPim from '../../../assets/defaultPim.png';
import { useEnumValue } from "common/enum/use-enum-value";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";

export interface LevelListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const AdvertisementList: FC<LevelListProps> = (props) => {
  const { convertEnumToOptionType, getEnumValueList } = useEnumValue();

  const columns: ProColumns<priceRulesModel>[] = [{
    title: '广告ID',
    dataIndex: 'id',
  },
  {
    title: '广告信息',
    width: 300,
    dataIndex: 'name',
    render: ((dom: any, record: any) => {
      return <Space className="adv-img">
        <Image className="img" src={internationalList(record.picture || '') || defaultPim} />
        <span>{record.name}</span>
      </Space>
    })
  },
  {
    title: '广告位置',
    dataIndex: 'showPosition',
    width: 250,
    render: (dom, record: any) => {
      let data: any = convertEnumToOptionType('bannerShowPosition').find((item: any) => item.value === record.showPosition)
      return data?.label
    }
  },
  {
    title: '展示人员类型',
    dataIndex: 'showType',
    width: 250,
    render: (dom, record: any) => {
      let data: any = getEnumValueList('bannerShowType').find((item: any) => item.value === record.showType)
      return data?.desc
    }
  }, {
    title: '排序值',
    dataIndex: 'sort',
    width: 250
  }, {
    title: '显示时间段',
    dataIndex: 'startTime',
    width: 250,
    render: (dom, record: any) => {
      return <div><p>{record.startDate}</p>  <p>{record.endDate}</p>  </div>
    }
  },
    {
      title: '状态',
      dataIndex: 'showStatus',
      width: 250,
      render: (dom, record: any) => { 
        return  record.showStatus === 1 ? '待显示' : record.showStatus === 2 ? '显示中':record.showStatus === 3 ? '已过期':''
      }
    }
  ];

  const searchFields: SearchField[] = [
    {
      label: '广告名称',
      name: 'name.contains'
    }, {
      label: '广告ID',
      name: 'no.contains',
    }
  ];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={convertEnumToOptionType('bannerShowPosition') || []}
          name="showPosition.equals"
          label='广告位置'
          placeholder='广告位置'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={getEnumValueList('bannerShowType').map((e: any) => ({
            label: e.desc,
            value: e.value
          }))}
          name="showType.equals"
          label='显示人员类型'
          placeholder='显示人员类型'
        />
        <div className="wd10"></div>
        <ProFormSelect
          options={[{
            label: '待显示',
            value: 1
          }, {
            label: '显示中',
            value: 2
          }, {
            label: '已过期',
            value: 3
          }]}
          name="showStatus"
          label='状态'
          placeholder='状态'
        />
        <div className="wd10"></div>

        <ProFormDateTimePicker
          name="startDate.greaterThanOrEqual"
          placeholder="显示开始时间"
        />
        <ProFormDateTimePicker
          name="endDate.lessThanOrEqual"
          placeholder="显示结束时间"
        />
      </Input.Group>
    </Form.Item>
  );


  return (
    <ListViewPage<priceRulesModel>
      sort='sort,lastModifiedDate,desc'
      columns={columns}
      quickFilterPanel={quickFilterPanel}
      detailPage={<PricingRulesDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={0}
      editPage={(params: any) => <PricingRulesEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ pricingRules }: RootState) => ({
  entity: pricingRules.entity,
  entities: pricingRules.entities,
  loading: pricingRules.loading,
  updateSuccess: pricingRules.updateSuccess,
  createSuccess: pricingRules.createSuccess,
  total: pricingRules.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(AdvertisementList);