import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import FlowTemplateDetails from './flowTemplate-details';
import FlowTemplateEdit from './flowTemplate-edit';
import FlowTemplateList from './flowTemplate-list';

const Level: React.FC<RouteComponentProps> = () => (
  <Router>
    <FlowTemplateList path="/" />
    <FlowTemplateDetails path=":id" />
    <FlowTemplateEdit path=":id/edit" />
    <FlowTemplateEdit path="new" />
  </Router>
);
export default Level;
