import {BaseModel, DisableModel} from 'model/base.model';

export interface advertisementModel extends BaseModel, DisableModel {
    name?: string;
    showUserDto?: any;
    pictures?: any;
    rankTimes?: any[];
    startDate?: string;
    endDate?: string
}

export const defaultAdvertisement: Readonly<advertisementModel> = {
};
