import { Equity, defaultEquity } from 'model/customerCore/equity.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'equity';
const apiUrl = '/api/yumei-mall-customer/customer/right';
const standardReducerImpl = new StandardReducerImpl<Equity>(entity, defaultEquity, apiUrl);
export type EquityState = StandardState<Equity>;
export const equity = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;