import { FC, ReactNode, useEffect, useRef, useState } from "react";
import { ActionType, ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import { Input, Tooltip, Image, Space, Tabs, Form, message, Alert, Button } from 'antd';
import UnitDetails from "./unit-details";
import { deleteEntity, getEntities, getEntity, fetchLevelList, auditRefuse, recharge, auditDetail, fetchStoreList } from "./unit.reducer";
import { RouteComponentProps } from "@reach/router";
import { Unit, UnitStatus, signStatusObj } from "model/dealer/unit.model";
import ListViewPage from "layouts/page-wraper/list-view-page";
import UnitEdit from "./unit-edit";
import { ModalForm, ProFormRadio, ProFormTextArea, ProFormDateTimePicker } from "@ant-design/pro-form";
import examineImg from '../../../assets/examine.png';
import headImg from '../../../assets/head.png';
import { internationalList } from "components/IntelFormText";
import SearchBox, { SearchField } from "components/search-box";
import TabPane from "@ant-design/pro-card/lib/components/TabPane";
import SelectSearch from "components/select-search";
import DecryptText from "../customer/detailtables/decryptText";

export interface UnitListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const searchFields: SearchField[] = [{
  label: '用户姓名',
  name: 'name.equals',
}, {
  label: '微信号',
  name: 'wechatId.equals',
}, {
  label: '身份证号',
  name: 'credentialsNo.equals',
}, {
  label: '推荐人姓名',
  name: 'sourceName.equals',
}, {
  label: '申请授权码',
  name: 'authorizationCode.equals',
}, {
  label: '推荐授权码',
  name: 'sourceAuthorizationCode.equals',
}];

const quickFilterPanel = (
  <Input.Group compact>
    <SelectSearch required={false} name="storeId.equals" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
    <ProFormDateTimePicker
      name="applyTime.greaterThanOrEqual"
      placeholder="申请开始时间"
    />
    <ProFormDateTimePicker
      name="applyTime.lessThanOrEqual"
      placeholder="申请结束时间"
    />
    <div className="wd10" />

    <ProFormDateTimePicker
      placeholder="审核开始时间"
      name="auditTime.greaterThanOrEqual"
    />
    <ProFormDateTimePicker
      placeholder="审核结束时间"
      name="auditTime.lessThanOrEqual"
    />
    <SelectSearch required={false} name="levelId.equals" placeholder="等级" labelValue="levelName" defaultParams='' fetchFunc={fetchLevelList} searchParams='levelName.equals' />
    <SearchBox
      width={300}
      fields={searchFields}
    />
    <div className="wd10" />
  </Input.Group>

);

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 4 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 20 },
  },
};

const UnitList: FC<UnitListProps> = (props) => {
  const [form] = Form.useForm<{}>();
  let [key, setKey] = useState<any>(0);
  const actionRef = useRef<ActionType>();
  const [status, setStatus] = useState<string>('Created');
  let [toExamineDialog, setToExamineDialog] = useState<any>(false);
  let [record, setRecord] = useState<any>({});
  let [okText, setOkText] = useState<any>('确认');

  const columns: ProColumns<Unit>[] = [{
    title: 'Id',
    dataIndex: 'id',
    valueType: 'text',
    width: 80,
  }, {
    title: '基本信息',
    dataIndex: 'name',
    valueType: 'text',
    width: 300,
    render: (text, row: Unit) => {
      return <div className='query-order-name' style={{ width: 300 }}>
        <Image
          width={60}
          src={row?.avatar || headImg}
        />
        <div style={{ marginLeft: '18px' }}>
          <p><span className="boldlabel">姓名:</span> {row.name}</p>
          <p><span className="boldlabel">手机号:</span> ******<DecryptText title='手机号' id={row.id} data={row?.phone} /></p>
          <p><span className="boldlabel">微信:</span> {row?.wechatId}</p>
          <p><span className="boldlabel">身份证:</span> ******<DecryptText title='身份证' id={row.id} data={row?.credentialsNo} /></p>
        </div>
      </div>
    }
  },
  // {
  //   title: '经营信息',
  //   dataIndex: 'addressApplication',
  //   valueType: 'text',
  //   render: (text, row: Unit, index) => {
  //     let adr = row?.addressApplication?.provinceName + row?.addressApplication?.cityName + row?.addressApplication?.districtName + row?.addressApplication?.address
  //     return <div className='query-order-name'>
  //       <div>
  //         <p><span className="boldlabel">经营区域: </span> 海外</p>
  //         <p className="hideone"><span className="boldlabel">注册地址: </span>   <Tooltip title={adr}>{adr} </Tooltip></p>
  //       </div>
  //     </div>
  //   }
  // },
  {
    title: '申请等级',
    dataIndex: 'levelName',
    valueType: 'text',
    width: 110,
    render: (text, row: Unit) => {
      return <div>{internationalList(row.levelName)}</div>
    }
  },
  {
    title: '申请授权码',
    dataIndex: 'authorizationCode',
    valueType: 'text',
    width: 110,
    render: (text, row: Unit) => {
      return <div style={{ width: 110 }}>{row.authorizationCode}</div>
    }
  },

  {
    title: '推荐信息',
    dataIndex: 'sourceName',
    width: 150,
    ellipsis: true,
    render: (text, row: Unit) => {
      return <div className='source-name'>
        <Tooltip key="advanced-filter" 
        title={<>
             <p>推荐人姓名: {row.sourceName || '-'}</p>
             <p>推荐授权码: {row.sourceAuthorizationCode || '-'}</p>
           </>}>
          <div className="hide-lineone"><span className="boldlabel">推荐人姓名:</span> {row.sourceName || '-'}</div>
        <div><span className="boldlabel">推荐授权码:</span> {row.sourceAuthorizationCode || '-'}</div>
        </Tooltip>
      </div>
    }
  }, {
    title: '账户余额',
    dataIndex: 'rechargeMap',
    valueType: 'text',
    width: 140,
    render: (text, row: Unit) => {
      return <div className='query-order-name'>
        <div>
          <p><span className="boldlabel">货款:</span> {row?.rechargeMap?.Goods?.amount || 0} </p>
          <p><span className="boldlabel">保证金:</span> {row?.rechargeMap?.CashDeposit?.amount || 0}</p>
          <p><span className="boldlabel">辅料款:</span> {row?.rechargeMap?.Accessories?.amount || 0}</p>
          <p><span className="boldlabel">培训费:</span> {row?.rechargeMap?.TrainingExpense?.amount || 0}</p>
        </div>
      </div>
    }
  }, {
    title: '店铺',
    dataIndex: 'storeName',
    valueType: 'text',
    ellipsis: true,
    width: 100
  },
  // {
  //   title: '授权品牌',
  //   dataIndex: 'brandDetails',
  //   width: 200,
  //   render: (data: any, record:any) => {
  //     return record.brandDetails?.length > 0 && record.brandDetails.map((item: any) => {
  //       return <Tag color="#1890FF" style={{marginBottom:'3px'}}>
  //         {internationalList(item.brandName)}
  //       </Tag>
  //     })
  //   }
  // },
  {
    title: '审核状态',
    dataIndex: 'status',
    valueType: 'text',
    width: 90,
    render: (text, row: Unit) => {
      if (text === 'AuditSuccess') {
        return <div style={{ color: '#70B603', width: 90 }}>审核成功</div>
      } else if (text === 'Created') {
        return <div style={{ color: '#000000', width: 90 }}>待审核</div>
      } else if (text === 'AuditFail') {
        return <div>
          <p style={{ color: '#EC808D', width: 90 }}>审核拒绝</p>
          <p>{row?.auditDetails[0].reason || ''}</p>
        </div>
      } else if (text === 'Closed') {
        return <div style={{ color: '#000000', width: 90 }}>关闭</div>
      } else if (text === 'Completed') {
        return <div style={{ color: '#000000', width: 90 }}>完成</div>
      }
    }
  }, {
    title: '审核时间',
    dataIndex: 'auditTime',
    valueType: 'text',
    width: 150
  }, {
    title: '申请时间',
    dataIndex: 'applyTime',
    valueType: 'text',
    width: 150
  }]

  // const [open, setOpen] = useState(false);
  // const [record, setRecord]: any = useState({});
  // 打开c充值弹框
  // const showModal = async (record: any) => {
  //   setRecord({ ...record })
  //   setOpen(true);
  // };

  // 充值
  // const rechargeButtonRender: any = (dom: ReactNode, record: Unit): ReactNode => {
  //   return (
  //     record.status === 'Created' || record.status === 'AuditFail' ? <Tooltip title="充值">
  //       <Image src={RechargeImg} width={20} preview={false} className="acimg" onClick={() => showModal(record)} />
  //     </Tooltip> : ''
  //   )
  // };

  // 关闭充值弹框
  // const hideModal = () => {
  //   setOpen(false);
  //   setRecord({})
  // };

  const [isPassTip, setIsPassTip] = useState<any>('');

  useEffect(() => {

  }, [])


  // 打开审核
  const openToExamine = (record: any) => {
    setOkText('确认')
    setIsPassTip(false)
    auditDetail(record.id).then((e: any) => {
      setRecord({
        ...record,
        ...e
      })
      setToExamineDialog(true)
    }).catch((e) => {
      message.error(e.response.data.message);
    })

  }

  // 审核
  const activateButtonRender: any = (dom: ReactNode, record: Unit) => {
    return record.status === 'Created' || record.status === 'Recharged' ? <Tooltip title="审核">
      <Image src={examineImg} width={20} preview={false} className="acimg" onClick={() => openToExamine(record)} />
    </Tooltip> : ''
  };

  const additionActions = [
    <Space className="status-filter">
      <Tabs defaultActiveKey={status} onChange={status => setStatus(status)} activeKey={status}>
        {Object.entries<string>(UnitStatus).map(([value, label]) => <TabPane tab={label} key={value}></TabPane>)}
      </Tabs>
    </Space>,
  ];

  return (
    <>
      <ListViewPage<Unit>
        scrollConfig={{ x: 1200 }}
        key={key}
        actionRef={actionRef}
        hideActionColumn={false}
        hideDeleteButton={true}
        hideCreateButton={false}
        actions={additionActions}
        filters={{ 'status.equals': status }}
        rowActions={[activateButtonRender]}
        quickFilterPanel={quickFilterPanel}
        columns={columns}
        detailPage={<UnitDetails navigate={props.navigate} columns={columns} />}
        editPage={(params: any) => <UnitEdit id={params.id} text={params.text} />}
        detailsIndex={0}
        {...props}
      >
        {props.children}
      </ListViewPage>
      {/* 充值弹框 */}
      {/* <RechargeModel visible={open} record={record} onCancel={() => {
        hideModal();
      }} onRecharge={(data: any) => {
        recharge(data)
        hideModal();
      }} /> */}

      <ModalForm
        title="审核"
        width={600}
        layout='horizontal'
        className="shModel"
        form={form}
        modalProps={{
          'okText': okText
        }}
        onVisibleChange={(e) => {
          if (!e) {
            setToExamineDialog(false)
          }
        }}
        {...formItemLayout}
        preserve={false}
        visible={toExamineDialog}
        onFinish={async (data: any) => {
          const obj = {
            reason: data.reason,
            status: data.status || '',
            brandAuthorizationApplication: {
              id: record.id
            }
          }
          auditRefuse(obj).then((e: any) => {
            setKey(++key)
            setToExamineDialog(false)
            return true;
          }).catch((e: any) => {
            message.error(e.response.data.message);
            return false
          })
        }}
      >

        {record.isDefaultParent && <Alert
          message="当前会员无最近6S经销商、充值是否归属到相应店铺的平台6S经销商下"
          type="warning"
          className="shtip"
          banner
        />
        }
        {<div className="info-wrapper">
          <div className="info-row">
            <div className="info"><span className="boldlabel">店铺名称: </span>{record.storeName ? record.storeName : '-'}</div>
            <div className="info"><span className="boldlabel">申请人授权码:</span> {record.authorizationCode ? record.authorizationCode : '-'}</div>
            <div className="info"><span className="boldlabel">申请等级: </span>{record.levelName ? internationalList(record.levelName) : '-'}</div>
          </div>

          <div className="info-row">
            <div className="info"><span className="boldlabel">邀请人授权码:</span> {record.sourceAuthorizationCode ? record.sourceAuthorizationCode : '-'}</div>
            <div className="info"><span className="boldlabel">邀请人等级:</span> {'-'}</div>
          </div>
          <div className="info-row">
            <div className="info"> <span className="boldlabel">充值金额:</span>（货款: {record?.rechargeMap?.Goods?.amount || 0} </div>
            <div className="info">保证金: {record?.rechargeMap?.CashDeposit?.amount || 0}</div>
            <div className="info">辅料款: {record?.rechargeMap?.Accessories?.amount || 0}</div>
            <div className="info">培训费: {record?.rechargeMap?.TrainingExpense?.amount || 0}）</div>
          </div>
          <div className="info-row">
            {record.levelType !== "Store" && <div className="info"><span className="boldlabel">距离最近的6S: </span>{record.nearestAuthorizationCode} / {record.nearestAuthorizationName} </div>}
          </div>
          <div className="info-row">
            <div className="info"> <span className="boldlabel">合同:</span>（合同标题: {record?.contractInfoDto?.title || '-'} </div>
            <div className="info">签署状态: {signStatusObj[record?.contractInfoDto?.signStatus] || '-'}）</div>
            <div className="info"> <Button type="primary" size="small" onClick={() => {
              if (record?.contractInfoDto?.contractOssUrls[0]) {
                window.open(record?.contractInfoDto?.contractOssUrls[0])
              } else {
                message.warn('暂无合同')
              }

            }}>查看合同</Button></div>
          </div>

        </div>}

        <ProFormRadio.Group
          label="处理状态"
          name={'status'}
          width='md'
          fieldProps={{
            onChange: (e: any) => {
              setOkText(e.target.label)
              if (e.target.label === '审核通过') {
                let totalMoney = parseFloat(record?.rechargeMap?.Goods?.amount || 0) + parseFloat(record?.rechargeMap?.CashDeposit?.amount || 0) + parseFloat(record?.rechargeMap?.Accessories?.amount || 0) + parseFloat(record?.rechargeMap?.TrainingExpense?.amount || 0)
                setIsPassTip(totalMoney === 0 ? true : false)
              } else {
                setIsPassTip(false)
              }

            }
          }}
          rules={[{ required: true }]}
          extra={
            isPassTip && <div className="redColor">充值金额为0，是否确认审核通过</div>
          }
          options={[
            {
              label: '审核通过',
              value: 'AuditSuccess',
            }, {
              label: '审核拒绝',
              value: 'AuditFail',
            }
          ]}
        />

        <ProFormTextArea
          name="reason"
          allowClear={true}
          label="审核备注"
        />
      </ModalForm>
    </>


  );
};

const mapStateToProps = ({ unit }: RootState) => ({
  entity: unit.entity,
  entities: unit.entities,
  loading: unit.loading,
  updateSuccess: unit.updateSuccess,
  total: unit.total,
  createSuccess: unit.createSuccess,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  recharge
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(UnitList);