import {BaseModel, DisableModel} from 'model/base.model';
import { Order, SpecName } from './order.model';

/**
 * 售后订单信息
 */
export interface AfterSales extends BaseModel, DisableModel, Omit<OrderLine, 'id'>{
    afterSaleType?: string;
    contactMobile?: string;
    refundPoint?: number;
    expireTime?: string;
    manualOrderState?: Number;
    manualOrderId?: string;
    expressState?: number | string;
    finishTime?: string;
    id?: number | string;
    initiationTime?: string;
    memberId?: number;
    order?: Order;
    orderLine?: OrderLine;
    orderNo?: string;
    outTradeNo?: string;
    quantity?: number;
    receiveAddressId?: number;
    records?: RecordsItem[];
    refundAddressId?: number;
    refundAmount?: number;
    refundChannel?: string;
    refundCode?: string;
    refundExplain?: string;
    refundExpress?: string;
    refundExpressState?: number;
    refundFreight?: boolean;
    refundFreightAmount?: number;
    availablePointNum?: number;
    refundReason?: string;
    refundReasonDesc?: string;
    refundTypeId?: number;
    refundTypeName?: string;
    refundVoucher?: string;
    refuseReason?: string;
    sellerId?: number;
    serviceNo?: string;
    serviceState?: number;
    signTime?: string;
    sort?: number;
    status?: string;
    offLineRefund?: boolean | string;
    saleOrderList?: saleOrderListItem;
    orderId?: string | number;
}

interface saleOrderListItem {
  id?: string | number;
  quantity?: number;
}

interface PackageListItem {
    deliveryText?: string;
    edit?: boolean;
    expressCode?: string;
    expressName?: string;
    expressNo?: string;
    expressTime?: string;
    expressType?: number;
    finished?: number;
    id?: number;
    orderNo?: string;
    quantity?: number;
    sort?: number;
    thirdPartyDelivery?: boolean;
}
interface RewardDetailsItem {
    couponCode?: string;
    discount?: number;
    gifts?: Gifts;
    name?: string;
    promotionId?: number;
    promotionType?: string;
}
interface Gifts {
    additionalProp1?: number;
    additionalProp2?: number;
    additionalProp3?: number;
}

interface OrderLine {
    actualPrice?: number;
    amount?: number;
    cartId?: number;
    commented?: boolean;
    cosPrice?: number;
    discount?: number;
    discountByCashCoupon?: number;
    discountByCoupon?: number;
    discountByPromotion?: number;
    freeGift?: boolean;
    id?: number | string;
    memberPrice?: number;
    packageList?: PackageListItem[];
    payAmount?: number;
    picture?: string;
    postage?: number;
    price?: number | string;
    productCode?: string;
    productId?: number;
    productName?: string;
    quantity?: number;
    refundAmount?: number;
    refundQuantity?: number;
    returnable?: boolean;
    rewardDetails?: RewardDetailsItem[];
    roomId?: string;
    serviceState?: number;
    shipped?: boolean;
    skuCode?: string;
    skuId?: number;
    specName?: SpecName[];
    subtotal?: number;
}
export interface RecordsItem {
    id?: number;
    lastModifiedDate?: string;
    operationContent?: string;
    operationContentJson?: any;
    operationTitle?: string;
    operationType?: number;
    remark?: string;
    sort?: number;
}

/**
 * 发货状态
 */
 export const expressState = {
  0: '未发货',
  1: '已发货'
};

/**
 * 退货物流状态
 */
export const exprerefundExpressStates = {
  1: '未签收',
  2: '已签收',
  3: '无物流信息'
};

/**
 * 售后方式
 */
export const afterSaleType= {
  1: '仅退款',
  2: '退货退款',
  3: '换货',
  4: '维修'
};

export const defaultAfterSales: Readonly<AfterSales> = {
};