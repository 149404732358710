import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import DirectAscentDetails from "./directAscent-details";
import { deleteEntity, getEntities, getEntity } from "./directAscent.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import DirectAscentEdit from "./directAscent-edit";
import { DirectAscent } from "model/dealer/directAscent.model";
import { ProFormDateTimePicker, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";

export interface DirectAscentListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<DirectAscent, 'activityType'>[] = [{
  title: '授权号',
  dataIndex: 'customerCode',
}, {
  title: '用户姓名',
  dataIndex: 'customerName',
}, {
  title: '升级类型',
  dataIndex: 'activityType',
  valueType: 'activityType',
}, {
  title: '下单总金额',
  dataIndex: 'orderAmount',
}, {
  title: '贷款充值情况',
  dataIndex: 'goodsAmount',
}, {
  title: '保证金金额',
  dataIndex: 'cashDepositAmount',
}, {
  title: '培训费余额',
  dataIndex: 'trainingExpenseAmount',
}, {
  title: '升级时间',
  dataIndex: 'upgradeDate',
}];


const DirectAscentList: FC<DirectAscentListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();
  const searchFields = [{
    label: '授权码',
    name: 'code.equals',
  }, {
    label: '用户姓名',
    name: 'name.equals',
  },];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <SearchBox
          fields={searchFields}
        />
        <ProFormSelect
          width={'md'} 
          name={'activityType.equals'}
          options={convertEnumToOptionType('activityType')}
          placeholder="升级类型"
        />
        <ProFormDateTimePicker
          name="upgradeDate.greaterThanOrEqual"
          placeholder="升级开始时间"
        />
        <ProFormDateTimePicker
          name="upgradeDate.lessThanOrEqual"
          placeholder="升级结束时间"
        />
        <div className="wd10" />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<DirectAscent, 'activityType'>
      columns={columns}
      hideDeleteButton
      quickFilterPanel={convertEnumToOptionType('activityType').length > 0 && quickFilterPanel}
      detailPage={<DirectAscentDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false}
      editPage={(params: any) => <DirectAscentEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ directAscent }: RootState) => ({
  entity: directAscent.entity,
  entities: directAscent.entities,
  loading: directAscent.loading,
  updateSuccess: directAscent.updateSuccess,
  createSuccess: directAscent.createSuccess,
  total: directAscent.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(DirectAscentList);