import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './topic.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Topic } from "model/video/topic.model";

export interface TopicDetailsProps extends DetailsStateAndDispatchProps<Topic,'materialResourceLimitType'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const TopicDetails: FC<TopicDetailsProps> = (props: any) => {

  return (
    <DetailsPage<Topic,'materialResourceLimitType'>
      title='话题详情'
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Topic,'materialResourceLimitType'>
        column={1}
        columns={props.columns}
        dataSource={props.entity}
      >
      </ProDescriptions>
    </DetailsPage>);
};

const mapStateToProps = ({ topic }: RootState) => ({
  entity: topic.entity,
});

const mapDispatchToProps = {
  getEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(TopicDetails);
