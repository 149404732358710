import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './material.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Post } from "model/video/post.model";
import { Col, Row, Image, Carousel } from "antd";

export interface PostDetailsProps extends DetailsStateAndDispatchProps<any>,
  RouteComponentProps, StateProps, DispatchProps {
}

const PostDetails: FC<PostDetailsProps> = (props: any) => {
  const { entity } = props;

  return (
    <DetailsPage<Post>
      title='素材详情'
      hideEdit={true}
      {...props}
    >
      <Row>
        <Col span={12} style={{ paddingRight: '30px' }}>
          {entity.videos && entity.videos.length > 0 && <video style={{ width: '300px' }} src={entity.videos && entity.videos?.[0]?.mediaUrl} controls />}
          {entity.pictures && entity.pictures.length > 0 && <Carousel autoplay dots className="product-carousel">
            {entity.pictures.map((e: any) => {
              return <Image src={e.pictureUrl || ''} />
            })}
          </Carousel>}
        </Col>
        <Col span={12}>
          <ProDescriptions<Post>
            column={1}
            columns={props.columns}
            dataSource={props.entity}
          >
          </ProDescriptions>
        </Col>
      </Row>
    </DetailsPage>);
};

const mapStateToProps = ({ material }: RootState) => ({
  entity: material.entity,
});

const mapDispatchToProps = {
  getEntity
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PostDetails);
