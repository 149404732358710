import { FC } from "react";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity } from "./platform.reducer";
import { ProFormText, ProFormSelect } from '@ant-design/pro-form';
import { RouteComponentProps, navigate } from "@reach/router";
import { Decorate, tureOrFalse, pageTypeMapEnum, pageTypeMap } from "model/decorate/decorate.model";
import { Switch, Popconfirm, message, Input } from 'antd';
import { updateUseState } from '../api';

export interface DecorateListProps extends StateProps, DispatchProps, RouteComponentProps { }

const columns: ProColumns<Decorate>[] = [{
  title: 'Id',
  dataIndex: 'id',
  valueType: 'text',
  align: 'center'
},{
  title: '页面名称',
  dataIndex: 'title',
  valueType: 'text',
  align: 'center',
  render: (text, record: Decorate) => {
    return record.title
  }
}, {
  title: '页面类型',
  dataIndex: 'pageType',
  valueType: 'text',
  valueEnum: pageTypeMapEnum(),
  align: 'center'
}, {
  title: '是否默认',
  dataIndex: 'isDefault',
  valueType: 'text',
  valueEnum: tureOrFalse,
  search: false,
  align: 'center'
}, {
  title: '是否已使用',
  dataIndex: 'used',
  valueType: 'text',
  align: 'center',
  search: false,
  render: (e) => {
    if (e === true) {
      return '是'
    }
    return '否'
  }
}, {
  title: '创建时间',
  dataIndex: 'createdDate',
  valueType: 'text',
  search: false,
  align: 'center'
}, {
  title: '最近更新时间',
  dataIndex: 'lastModifiedDate',
  valueType: 'text',
  search: false,
  align: 'center'
}]

const RenderCopyH5Link: FC<{ record: any }> = ({ record }) => {
  return <div className="copy-link" onClick={()=>{
    let pageId = record.id
    let str = ''
    message.success("复制成功");
    if (process.env.NODE_ENV === 'development') {
       str = `https://h5-test.merrige.cn/H5/index.html#/pages/home/h5Link?pageId=${pageId}`
    }else {
      str = `https://app.merrige.cn/H5/index.html#/pages/home/h5Link?pageId=${pageId}`
    }
    return navigator?.clipboard?.writeText(str).catch((err) => {
      const el = document.createElement("textarea");
      el.value = str;
      document.body.appendChild(el);
      el.select();
      document.execCommand("copy");
      document.body.removeChild(el);
    });
  }}>复制链接</div>
}

const RenderSwitchState: FC<{ record: any }> = ({ record }) => {
  return <div className="switch-container">
    <Popconfirm title={`确认${!record.used === true ? '使用' : '取消使用'}“${record.title}”页？`} onConfirm={
      (e) => {
        updateUseState({
          id: record.id,
          used: !record.used
        }).then((res: any) => {
          if (res.data) {
            record.used = !record.used
            message.success(`页面${!record.used ? '取消使用' : '使用'}成功`);
          }
        })
      }
    }>
      <Switch checkedChildren="启用" onChange={
        (e) => {
        }
      } unCheckedChildren="关闭" checked={record.used} />
    </Popconfirm>
  </div>
}

const DecorateList: FC<DecorateListProps> = (props:any) => {
  const typeSelect: any = []
  for (let k in pageTypeMap) {
      typeSelect.push({
        label: pageTypeMap[k].title,
        value: k
      })
    
  }
  // 筛选条件
  const quickFilterPanel = (
    <div className="quick-filter" style={
      {
        display: 'flex',
        paddingRight: '10px'
      }
    }>
      <Input.Group compact>
        <ProFormText name='title.contains' placeholder='请输入页面名称' />
         <div className="wd10"></div>
        <ProFormSelect options={typeSelect} name='pageType.equals' placeholder='请选择页面类型' />
      </Input.Group>
    </div>
  );
  return (
    <ListViewPage<Decorate>
      columns={columns}
      // detailPage={<ListDetails  columns={columns} />}
      hideDeleteButton={false}
      hideEditButton={false}
      hideCreateButton={false}
      quickFilterPanel={
        quickFilterPanel
      }
      filters={{ 'queryType': 'All' }}
      // request={async (params = {}, sort, filter) => {
      //   // console.log(params,sort, filter);

      //   // return request<{
      //   //   data: GithubIssueItem[];
      //   // }>('https://proapi.azurewebsites.net/github/issues', {
      //   //   params,
      //   // });
      //   return new Promise(async (resolve) => {
      //     let res = await props.getEntities({
      //       page:((params.current || 1)-1),
      //       size:params.pageSize,
      //       'title.contains':params.title || '',
      //       'pageType.equals':params.pageType || ''
      //     })
      //     resolve(res)
      //   })
      // }}
      actionColumnWidth={160}
      rowActions={[(dom, record: any) => {
        return <RenderCopyH5Link record={record} />
      },
        (dom, record: any) => {
          return <RenderSwitchState record={record} />
        }
      ]}
      clickRowHandle={(id) => { navigate('/decorate/platform/' + id + '/edit') }}
      {...props}

    >
      {props.children}
    </ListViewPage >
  );
};

const mapStateToProps = ({ decorate }: RootState) => ({
  entity: decorate.entity,
  entities: decorate.entities,
  loading: decorate.loading,
  updateSuccess: decorate.updateSuccess,
  total: decorate.total,
  createSuccess: decorate.createSuccess,
});
const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(DecorateList)