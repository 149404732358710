import axios from 'axios';
import { Coupon, defaultCoupon } from 'model/market/coupon.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';

const entity = 'Coupon';
const apiUrl = '/api/yumei-mall-train/coupon';
const standardReducerImpl = new StandardReducerImpl<Coupon>(entity, defaultCoupon, apiUrl);
export type CouponState = StandardState<Coupon>;
export const coupon = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const cancelCoupon = async (id: string | number) => {
    const requestUrl = `/api/yumei-mall-train/coupon/cancel/${id}`;
    const res = await axios.put(requestUrl);
    return res.data;
}

export const cancelUserCoupon = async (id: string | number) => {
    const requestUrl = `/api/yumei-mall-train/user/coupon/cancel/${id}`;
    const res = await axios.put(requestUrl);
    return res.data;
}

export const fetchCouponUserList = async (params:any) => {
    const requestUrl = `/api/yumei-mall-train/user/coupon?couponId.equals=${params.id}`;
    const res = await axios.get(requestUrl,{params});
    return res;
}

export const sendCoupon = async (id: string | number,data:any,grantNum:number) => {
    const requestUrl = `/api/yumei-mall-train/user/coupon/allot/${id}/${grantNum}`;
    const res = await axios.post(requestUrl,data);
    return res.data;
}

