import { BaseModel } from "../base.model";

export interface Topic extends BaseModel {
    name?: string; //话题名称
    pic?: string; //话题图片
    content?: string; //话题引导语
    sort?: number; //排序
    isEnable?: boolean; //是否启用
    pageViews?: number; //浏览量
    totalPerson?: number; //话题参与人数
    totalPost?: number; //话题参与总篇数
    isHot?: boolean; //热门标记
    postVos?: any; //短视频/图文列表
    minDuration?: number; //短视频最小时长：整数,单位s
    type?: number; //发布类型:0 仅限短视频发布,1 仅限图文发布,2 all
}

export const TopicReleaseType: { [index: number]: string } = {
    0: '仅限短视频发布',
    1: '仅限图文发布',
    2: '全部'
};

export const defaultTopic: Readonly<Topic> = {};