import { STANDARD_ACTION_TYPES, StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultPost, Post } from "model/video/post.model";
import { CrudPostAction } from "reducers/redux-action.type";
import axios from "axios";

const entity = 'post';
const apiUrl = '/api/yumei-mall-post/publicity-material';

const standardReducerImpl = new StandardReducerImpl<Post>(entity, defaultPost, apiUrl);
export type PostState = StandardState<Post>;
export const post = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 发布人
export const getMaterialPublisher = async () => {
  const requestUrl = `/api/yumei-mall-post/material-publisher`;
  let res = await axios.get(requestUrl);
  return res.data || [];
};

// 上下线
export const changeOnline = async (record:any, status:any) => {
  const requestUrl = `/api/yumei-mall-post/publicity-material/${record.id}/${status}`;
  let res = await axios.get(requestUrl);
  return res.data || [];
};

// 素材分类
export const getMaterialCategory = async () => {
  const requestUrl = `/api/yumei-mall-post/material-category?level.equals=1`;
  let res = await axios.get(requestUrl);
  return res.data || [];
};

export const getAuditEntities = async (postId: any) => {
  const requestUrl = `/api/yumei-mall-post/material/resource/auditing`;
  let res = await axios.get(requestUrl, { params: { postId } });
  return res.data || [];
};

//批量展示不展示
export const updateHasShow: CrudPostAction<any> = (data) => async dispatch => {
  const requestUrl = `/api/yumei-mall-post/material/multi-show/${data.flag}`;
  return await dispatch({
    type: standardReducerImpl.actionType(STANDARD_ACTION_TYPES.UPDATE),
    payload: axios.post(requestUrl, data.ids),
  });
};

export const statusMap = [{
  label: '待发布',
  value: 'Wait'
},{
  label: '上线',
  value: 'Online'
},{
  label: '下线',
  value: 'Offline'
}]

export {
  apiUrl,
};
