import React from 'react';
import { RouteComponentProps, Router } from '@reach/router';
import Store from './store';
import './index.less'
import Address from './address';
import MarginRules from './marginRules';

const StoreManagement: React.FC<RouteComponentProps> = () => (
    <Router>
        <Store path="seller/*" />
        <Address path="address/*"/>
        <MarginRules path="/marginRules" />
    </Router>
);
export default StoreManagement;
