import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { createEntity, getEntity, reset, updateEntity } from "./contractTemplate.reducer";
import { Col, Form, Row } from "antd";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { ContractTemplate } from "model/contractAdmi/contractTemplate.model";
import ProForm, { ProFormList, ProFormSelect, ProFormText, ProFormTextArea } from "@ant-design/pro-form";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/store/store/store.reducer";
import { fetchLevelList } from "pages/dealer/unit/unit.reducer";
import { useEnumValue } from "common/enum/use-enum-value";
;
export interface ContractTemplateEditProps extends EditStateAndDispatchProps<ContractTemplate>, StateProps, DispatchProps, RouteComponentProps {
}

const ContractTemplateEdit: FC<ContractTemplateEditProps> = props => {
  const {
    entity
  } = props;

  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  const [entityData, setEntityData] = useState<any>({});
  const [storeName, setStoreName] = useState<any>({});
  const [levelName, setLevelName] = useState<any>({});

  useEffect(() => {
    const newComponentMap: any = [];
    const newKeys = entity.componentMap ? Object.keys(entity.componentMap) : [];
    newKeys.forEach((e, index) => {
      newComponentMap[index] = {
        title: e,
        componentId: entity.componentMap[e].componentId,
        componentName: entity.componentMap[e].componentName
      }
    });
    entity.componentMap = newComponentMap;
    setStoreName(entity.storeName);
    setLevelName(entity.levelName);
    setEntityData(entity);
  }, [entity])

  const beforeUpdateEntity = (data: any) => {
    return new Promise<ContractTemplate>((resolve, reject) => {
      if (data) {
        const newComponentMap: any = {};
        data.componentMap.forEach((e: any) => {
          newComponentMap[e.title] = {
            componentId: e.componentId,
            componentName: e.componentName
          };
        });
        data.componentMap = newComponentMap;
        data.levelName = levelName;
        data.storeName = storeName;
        resolve(data);
      };
    })
  };


  return (
    <EditPage<ContractTemplate>
      title=""
      form={form}
      {...props}
      entity={entityData as any}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={5}>
          <ProFormText
            width="md"
            name="title"
            label="标题"
          />
        </Col>
        <Col span={1} />
        <Col span={5}>
          <ProFormSelect
            name="type"
            options={convertEnumToOptionType('contractType')}
            placeholder="类型"
          />
        </Col>
        <Col span={1} />
        <Col span={5}>
          <Form.Item name="storeId" label="店铺">
            <SelectSearch width={'md'} optionKey="name" fetchOptionKey={setStoreName} required={false} valueType="number" name="storeId" placeholder="店铺" labelValue="name" defaultParams='' fetchFunc={fetchStoreList} searchParams='name.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={5}>
          <Form.Item name="levelId" label="等级">
            <SelectSearch width={'md'} optionKey="levelName" fetchOptionKey={setLevelName} required={false} name="levelId" valueType="number" placeholder="等级" labelValue="levelName" defaultParams='' fetchFunc={fetchLevelList} searchParams='name.contains' />
          </Form.Item>
        </Col>
        <Col span={1} />
        <Col span={24} />
        <Col span={7}>
          <ProFormTextArea
            width="md"
            name="docTemplateId"
            label="e签宝模板id"
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormTextArea
            width="md"
            name="fileId"
            label="e签宝文件id"
          />
        </Col>
        <Col span={1} />
        <Col span={7}>
          <ProFormTextArea
            width="md"
            name="fileDownloadUrl"
            label="下载路径"
          />
        </Col>
        <Col span={1} />
        <Col span={24} />
        <Col span={11} >
          <ProFormList
            label="甲方"
            name={'partyaSignAreas'}
            creatorButtonProps={{
              creatorButtonText: '添加',
            }}
            initialValue={[{}]}
            actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
          >
            {(meta, itemIndex, action) => <ProForm.Group size={10}>
              <ProFormText
                width="xs"
                name="pageNum"
                label="页码"
              />
              <ProFormText
                width="xs"
                label="X坐标"
                name="x"
              />
              <ProFormText
                width="xs"
                label="Y坐标"
                name="y"
              />
            </ProForm.Group>}
          </ProFormList>
        </Col>
        <Col span={1} />
        <Col span={11} >
          <ProFormList
            label="乙方"
            name={'partybSignAreas'}
            creatorButtonProps={{
              creatorButtonText: '添加',
            }}
            initialValue={[{}]}
            actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
          >
            {(meta, itemIndex, action) => <ProForm.Group>
              <ProFormText
                width="xs"
                name="pageNum"
                label="页码"
              />
              <ProFormText
                width="xs"
                label="X坐标"
                name="x"
              />
              <ProFormText
                width="xs"
                label="Y坐标"
                name="y"
              />
            </ProForm.Group>}
          </ProFormList>
        </Col>
        <Col span={1} />
        <Col span={24} >
          <ProFormList
            label="控件信息"
            name={'componentMap'}
            creatorButtonProps={{
              creatorButtonText: '添加控件信息',
            }}
            initialValue={[{}]}
            actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
          >
            {(meta, itemIndex, action) => <ProForm.Group>
              <ProFormText
                width="md"
                name="title"
                label="控件key值(唯一)"
              />
              <ProFormText
                width="md"
                name="componentId"
                label="控件id"
              />
              <ProFormText
                width="md"
                name="componentName"
                label="控件名称"
              />
            </ProForm.Group>}
          </ProFormList>
        </Col>
      </Row>
      <Row>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ contractTemplate }: RootState) => ({
  entity: contractTemplate.entity,
  updateSuccess: contractTemplate.updateSuccess,
  updating: contractTemplate.updating,
  createSuccess: contractTemplate.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ContractTemplateEdit);
