import axios from 'axios';
import { Storage } from 'utils/storage-util';

// 获取租户列表
export const getTenantList = async (params?: any) : Promise<any[]> => {
  // const axiosResponse = await axios.get<any[]>(`/api/yumei-mall-tenant/tenant/available-by-user`,{
  //   headers: {
  //     'Authorization': `Bearer ${Storage.session.get("loginData").access_token}`,
  //   },
  // });
  // return axiosResponse.data || [];
  return []
}


