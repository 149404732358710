import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import ComplaintDetails from './complaint-details';
import ComplaintEdit from './complaint-edit';
import ComplaintList from './complaint-list';

const Complaint: React.FC<RouteComponentProps> = () => (
  <Router>
    <ComplaintList path="/" />
    <ComplaintDetails path=":id" />
    <ComplaintEdit path=":id/edit" />
    <ComplaintEdit path="new" />
  </Router>
);
export default Complaint;
