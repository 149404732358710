import update from 'immutability-helper';
import axios from 'axios';
import { REQUEST, SUCCESS } from "../../../reducers/action-type.util";
import { navigate } from '@reach/router'
import { deepCloneObject, createModuleId } from 'model/decorate/decorate.model';
import { cloneDeep } from 'lodash';



interface InitialStateProps {
    insertPositionId: number | string
    pageConfig: ({ type: string, dataField: any, id?: any })[]
    pageConfigGlobal: { [key: string]: ({ type: string, dataField: any, id?: any })[] }
    pageData: { type: number | string, [key: string]: any }
    loading: boolean
    pageLoading: boolean
    settingModuleId: string // 设置中的模块id
    popupData?: { [key: string]: any }
    language: 'zh-CN' | 'zh-TW' | 'en',
    platform: string
};

const initialState: InitialStateProps = {
    insertPositionId: 'none',
    pageConfig: [],
    pageConfigGlobal: {},
    pageData: { type: 0, pageConfig: [] },
    loading: false,
    pageLoading: false,
    settingModuleId: 'none',
    popupData: {},
    language: 'zh-CN',
    platform: ''
}

const apiUrl = '/api/yumei-mall-content';
const ACTION_TYPE = {
    GETDETAIL: 'examdecorate/getDetail',
    UPDATEINSERT: 'examdecorate/updateInsertId',
    INSERTMODULE: 'examdecorate/insertModule',
    UPDATEPAGECONFIG: 'examdecorate/updatePageConfig',
    UPDATEMORESTATE: 'examdecorate/updateMoreState',
    SAVE: 'examdecorate/save',
    CREATE: 'examdecorate/CREATE',
    UPDATESETTINGMODULE: 'examdecorate/updateSettingModule',
    UPDATEMODULEDATA: 'examdecorate/updateModuleData',
    DELETEMODULE: 'examdecorate/deleteModule',
    CHANGELANGUAGE: 'examdecorate/chanageLanguage',
    CHANGEPLATFORM: 'examdecorate/changePlatform',
}

export const examDecorateEdit = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case ACTION_TYPE.UPDATEINSERT:
            return {
                ...state,
                insertPositionId: action.payload
            }
        case ACTION_TYPE.INSERTMODULE:
            const moduleData = {
                type: action.payload.type,
                dataField: {
                    ...(deepCloneObject(action.payload.defaultConfig)),
                    md_id: createModuleId(),
                },
                id: `${action.payload.type}_${+new Date()}`
            }
            let newPageConfig
            if (state.insertPositionId === '-1') {
                newPageConfig = [...state.pageConfig, moduleData]
            } else {
                let insertModule = state.pageConfig.filter((v: any) => `${v.id}` === state.insertPositionId)[0]
                let inserIndex = state.pageConfig.indexOf(insertModule)
                if (action.payload.isCopy) {
                    inserIndex++
                }
                newPageConfig = update(state.pageConfig,
                    {
                        $splice: [
                            [inserIndex, 0, moduleData]
                        ]
                    }
                )
            }
            state.pageConfigGlobal[state.language] = newPageConfig

            return {
                ...state,
                pageConfig: newPageConfig,
                insertPositionId: 'none'
            }

        case ACTION_TYPE.CHANGELANGUAGE:
            state.pageConfigGlobal[state.language] = cloneDeep(state.pageConfig)
            let changedPageConfig: any = [], newLanguage = action.payload;
            if (state.pageConfigGlobal[newLanguage] && state.pageConfigGlobal[newLanguage].length > 1) {
                changedPageConfig = state.pageConfigGlobal[newLanguage]

            } else {
                let copyPageConfig = Object.values(state.pageConfigGlobal)[0];
                state.pageConfigGlobal[newLanguage] = cloneDeep(copyPageConfig)
                changedPageConfig = state.pageConfigGlobal[newLanguage]
             
            }
            changedPageConfig.forEach((v: any, i: number) => {
                v.id = `${v.type}_${i}_${new Date().getTime()}`
            })
            return {
                ...state,
                language: newLanguage,
                pageConfig: changedPageConfig,
            }
        case ACTION_TYPE.CHANGEPLATFORM:
            return {
                ...state,
                platform: action.payload,
            }
        case REQUEST(ACTION_TYPE.GETDETAIL):
            return {
                ...state,
                loading: true
            }
        case REQUEST(ACTION_TYPE.SAVE):

            return {
                ...state,
                pageLoading: true
            }
        case REQUEST(ACTION_TYPE.CREATE):
            return {
                ...state,
                pageLoading: true
            }
        case SUCCESS(ACTION_TYPE.SAVE):
            return {
                ...state,
                pageLoading: false,
            }
        case SUCCESS(ACTION_TYPE.CREATE):
            setTimeout(() => {
                console.log('成功了')
                console.log(action.payload)
                // if (action.payload.data) {
                    navigate(`/examMng/examDecorate/${action.payload.data}/edit`, {
                        replace: true
                    })
                // }
            }, 1000)

            return {
                ...state,
                pageLoading: false,
            }
        case SUCCESS(ACTION_TYPE.GETDETAIL):
            let pageConfig = JSON.parse(action.payload.data.pageConfig)
           
            let newpageconfig:any = []
            pageConfig.forEach((item:any,index:any) => {
                newpageconfig.push({
                    type: item.type === 6 ?  'single_choice_question' : item.type === 7 ?  'multiple_choice_questions' : item.type === 1 ?  'title_text' :'',
                    id: item.type === 6 ?  'single_choice_question'+index : item.type === 7 ?  'multiple_choice_questions'+index : item.type === 1 ?  'title_text'+index :'',
                    dataField: {
                        analysis: item.correctDesc,
                        answer: item.correct,
                        questionScore: item.fraction,
                        title: item.name,
                        testquestions: item.examContentItemDtoList.map((qitem:any)=> {
                            return qitem.optionDescription
                        })
                    }
                })
            });

            let config = {
                id:"config_0",
                type: "config",
                dataField: {
                    ...action.payload.data.examItemVO,
                    bgColor:"#fgfgf9",
                    title: action.payload.data.title

                }
            }
            newpageconfig.unshift(config)
            console.log('999999999999999999999999999')
            console.log({
                ...state,
                pageData: action.payload.data,
                pageConfig: newpageconfig,
                loading: false,
                settingModuleId: 'none',
                insertPositionId: "none",
                language: "zh-CN",
                pageLoading: false,
                pageConfigGlobal: {
                    'zh-CN': newpageconfig
                }
            })
            console.log(action.payload.data)
            console.log('999999999999999999999999999')
            return {
                ...state,
                pageData: action.payload.data,
                pageConfig: newpageconfig,
                loading: false,
                settingModuleId: 'none',
                insertPositionId: "none",
                language: "zh-CN",
                pageLoading: false,
                pageConfigGlobal: {
                    'zh-CN': newpageconfig
                }
            }
        case ACTION_TYPE.UPDATEPAGECONFIG:


            return {
                ...state,
                pageConfig: action.payload
            }
        case ACTION_TYPE.UPDATEMORESTATE:
            if (action.payload && action.payload.pageConfig) {
                state.pageConfigGlobal[state.language] = action.payload.pageConfig
            }
            return {
                ...state,
                ...action.payload,
                pageConfigGlobal: state.pageConfigGlobal
            }

        case ACTION_TYPE.UPDATEMODULEDATA:
            let updateModule = state.pageConfig.filter((v: any) => `${v.id}` === action.payload.id)[0],
                upDataIndex = state.pageConfig.indexOf(updateModule),
                pageConfigUpdate = state.pageConfig.filter((v: any) => v)
            if (upDataIndex !== -1) {
                updateModule = {
                    ...updateModule,
                    dataField: action.payload.dataField,
                }
                pageConfigUpdate[upDataIndex] = updateModule
                state.pageConfigGlobal[state.language][upDataIndex] = updateModule
            }
            return {
                ...state,
                pageConfig: pageConfigUpdate,
                // pageConfigGlobal:state.pageConfigGlobal
            }
        case ACTION_TYPE.DELETEMODULE:
            let deleteModule = state.pageConfig.filter((v: any) => `${v.id}` === action.payload.id)[0],
                deleteIndex = state.pageConfig.indexOf(deleteModule);
            if (deleteIndex !== -1) {
                delete state.pageConfig[deleteIndex]
            }
            state.pageConfig = state.pageConfig.filter((v: any) => v)
            state.pageConfigGlobal[state.language] = state.pageConfig
            return {
                ...state,
            }
    }
    return state
}

interface MoveModuleProps {
    originIndex: number
    atIndex: number
    moduleData: {}
}



//获取页面数据
export const getPageDetail = (id: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    const requestUrl = `${apiUrl}/page-configuration/findConfigurationById/${id}`;
    return dispatch({
        type: ACTION_TYPE.GETDETAIL,
        payload: axios.get(requestUrl)
    })
}

//更新页面配置数据
export const updatePageConfig = (props: any) => (dispatch: (props: { type: string, payload: MoveModuleProps }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEPAGECONFIG,
        payload: props
    })
}

//更新插入位置索引
export const updateInsertPositionId = (id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    if (!id) return;
    return dispatch({
        type: ACTION_TYPE.UPDATEINSERT,
        payload: id
    })
}

//更新多项数据
export const updateMoreState = (props: { [key: string]: any }) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEMORESTATE,
        payload: props
    })
}

//插入数据
export const insertModule = (module: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.INSERTMODULE,
        payload: module
    })
}


//保存修改
export const savePageData = (pageData: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    if (pageData.id) {
        return dispatch({
            type: ACTION_TYPE.SAVE,
            payload: axios.post(`${apiUrl}/page-configuration/updateExamPageConfig`, pageData)
        })
    } else {
        return dispatch({
            type: ACTION_TYPE.CREATE,
            payload: axios.post(`${apiUrl}/page-configuration/addExamPageConfig`, pageData)
        })

    }
}


//更新模块数据
export const updateModuleData = (dataField: any, id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEMODULEDATA,
        payload: {
            dataField,
            id
        }
    })
}


//更新模块数据
export const deleteModule = (id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.DELETEMODULE,
        payload: {
            id
        }
    })
}

//修改语言
export const changeLanguage = (language: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.CHANGELANGUAGE,
        payload: language
    })
}

//修改平台
export const changePlatform = (platform: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.CHANGEPLATFORM,
        payload: platform
    })
}
