import React, { FC, memo, useState, useEffect } from 'react';
import { Form, Input, Select, Button, Popover, Switch, DatePicker } from 'antd';
import { pageTypeMap, pageTypeForPlatform, defaultLanguageMap } from 'model/decorate/decorate.model';
import { SketchPicker } from 'react-color';
import moment from 'moment';
import '../style/config.less';

interface SetProps {
  dataField?: any
  id?: string
  methods?: any
  selectedInfo?: any
  pageData: any
  pageConfig: any
  language?: string
  platform: string
  otherInfo?: any
}

export const ConfigSet: FC<SetProps> = memo(({ dataField, id, methods, pageData, pageConfig, language, platform }) => {
  const [pageDataStringify, setPageDataStringify] = useState("");
  const [showCode, setShowCode] = useState(false)
  const [dataFieldPrivate, setDataFieldPrivate] = useState<any>()
  const dateFormat = 'YYYY-MM-DD HH:mm:ss';
  const [updateTitleTimer, setUpdateTitleTimer] = useState<any>(0);
  useEffect(() => {
    setPageDataStringify(JSON.stringify({
      ...pageData,
      pageConfig: pageConfig
    }))

  }, [pageConfig, pageData])
  useEffect(() => {
    setDataFieldPrivate('')
    setTimeout(() => {
      setDataFieldPrivate(dataField)

    }, 300)
  }, [dataField])
  useEffect(() => {
  }, [dataFieldPrivate])
  let typeList: any = [],
    pageTypeForPlatformList: any = [],
    pageTypeList = pageTypeForPlatform[platform] || pageTypeForPlatform['taobuting'];
  for (let k in pageTypeMap) {
    if (pageTypeList.list.indexOf(k) !== -1) {
      pageTypeMap[k].type = k
      typeList.push(pageTypeMap[k])
    }
    // if (!typeMap[k].isHide) {
    //     typeList.push(typeMap[k])
    // }
  }
  for (let k in pageTypeForPlatform) {
    pageTypeForPlatformList.push({
      ...pageTypeForPlatform[k],
      platform: k
    })
  }
  const updateTitle = (value: string) => {
    if (updateTitleTimer) {
      // console.log('更新title timer存在')
      setDataFieldPrivate({
        ...dataFieldPrivate,
        title: value
      })
      clearTimeout(updateTitleTimer)
      setUpdateTitleTimer(0)
    }
    let timer = setTimeout(() => {
      methods.updateModuleData({
        ...dataFieldPrivate,
        title: value
      }, id)
      setUpdateTitleTimer(0)
    }, 500)
    setUpdateTitleTimer(timer)
  }
  return (

    <div className="config-set config">
      {
        dataFieldPrivate &&
        <>


          <Form.Item label="页面名称">
            <Input defaultValue={dataFieldPrivate.title} onBlur={
              (e) => {
                updateTitle(e.target.value)

              }
            } />
          </Form.Item>


          {
            defaultLanguageMap[platform][0].language === language &&
            <>
             
              <Form.Item label="页面类型">
                {/* <Input defaultValue={dataField.bgColor} onChange={
                   (e:any) => {
                       console.log(e)
                       if(/^#([A-F]|[a-f]|[0-9]){6}$/.test(e)){
                        methods.updateModuleData({
                            ...dataField,
                            bgColor:e
                        },id)
                       }
                       
                   }
               }/> */}
                <Select defaultValue={pageTypeMap[pageData.pageType] ? pageTypeMap[pageData.pageType].title : '未知页面'} onChange={
                  (e) => {
                    pageData.pageType = e;
                    methods.updateMoreState({
                      pageData: {
                        ...pageData,
                        used: false
                      }
                    })
                  }
                }>
                  {
                    typeList?.map((v: any, i: number) => {
                      return <Select.Option value={v.type} key={i}>{v.title}</Select.Option>
                    })
                  }

                </Select>
              </Form.Item>

              <Form.Item label="自动启用时间">
                <DatePicker key={'start'} defaultValue={(pageData.startTime ? moment(pageData.startTime) : undefined)} showTime onChange={(e: any) => {
                  pageData.startTime = e ? e.format(dateFormat) : ''
                  methods.updateMoreState({
                    pageData: {
                      ...pageData,
                    }
                  })
                }} />
              </Form.Item>
              <Form.Item label="自动关闭时间">
                <DatePicker key={'end'} defaultValue={(pageData.endTime ? moment(pageData.endTime) : undefined)} showTime onChange={(e: any) => {
                  pageData.endTime = e ? e.format(dateFormat) : ''
                  methods.updateMoreState({
                    pageData: {
                      ...pageData,
                    }
                  })
                }} />
              </Form.Item>

              <Form.Item label="页面背景">

                <div className="color-set-cont">
                  <Popover content={
                    <SketchPicker
                      color={dataField.bgColor}
                      onChange={
                        (e: any) => {
                          methods.updateModuleData({
                            ...dataField,
                            bgColor: e.hex
                          }, id)
                        }
                      }
                    />
                  } title="" trigger="click">
                    <p className="color-hover" style={
                      {
                        backgroundColor: dataField.bgColor
                      }
                    }></p>
                  </Popover>
                  <Button onClick={
                    () => {
                      dataField.bgColor = '#f9f9f9'
                      methods.updateModuleData({
                        ...dataField,
                        bgColor: '#f9f9f9'
                      }, id)
                    }
                  }>默认</Button>
                </div>



              </Form.Item>

              <Form.Item label="页面风格" >
                <Switch checkedChildren="Light" unCheckedChildren="Dark" onChange={
                  (e) => {
                    methods.updateModuleData({
                      ...dataField,
                      style: e ? 'light' : 'dark'
                    }, id)
                  }
                } checked={dataField.style === 'light'} />

              </Form.Item>
            </>
          }

          <Form.Item label="页面代码" >
            <Switch checkedChildren="显示" unCheckedChildren="隐藏" onChange={
              (e) => {
                setShowCode(e)
              }
            } checked={showCode} />


          </Form.Item>
          <Form.Item label="天音卡商城" >
            <Switch checkedChildren="是" unCheckedChildren="否" onChange={
              (e) => {
                methods.updateModuleData({
                  ...dataField,
                  SkytoneCard: e
                }, id)
              }
            } checked={dataField.SkytoneCard} />
          </Form.Item>
          {
            showCode && pageDataStringify && <Input.TextArea defaultValue={pageDataStringify} onBlur={
              (e) => {
                let value = e.target.value, newPageData: object = {}
                try {
                  newPageData = JSON.parse(value)
                  methods.updateMoreState({
                    ...newPageData
                  })
                } catch (err) {
                  // console.log(err)
                }

              }
            } autoSize={
              {
                minRows: 10,
                maxRows: 20
              }
            } />
          }
        </>
      }
    </div>
  )
})
