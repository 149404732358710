import React from 'react';
import {RouteComponentProps, Router} from '@reach/router';
import Activity from './activity';

const Promotion: React.FC<RouteComponentProps> = () => (
  <Router>
    <Activity path="activity/*" />
  </Router>
);
export default Promotion;
