import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './classification.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Classification } from "model/product/classification.model";

export interface ClassificationDetailsProps extends DetailsStateAndDispatchProps<Classification>,
  RouteComponentProps, StateProps, DispatchProps {
}

const ClassificationDetails: FC<ClassificationDetailsProps> = (props: any) => {
  return (
    <DetailsPage<Classification>
      title='会员详情'
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Classification>
        column={2}
        columns={props.columns}
        dataSource={props.entity}
      >
      </ProDescriptions>
    </DetailsPage>);
};

const mapStateToProps = ({ classification }: RootState) => ({
  entity: classification.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ClassificationDetails);
