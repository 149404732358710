import { BaseModel } from "../base.model";

export interface TrainList extends BaseModel {
    meetDescribe?: any;
    coverPicUrl?: string;
    involvedPlatforms?: any;
    meetId?: any;
    checkRooms?: any;
    applicationNumber?: any;
    participantNumber?: any;
    signAddress?:any;
    location?: string;
    meetType?: string;
    showType?: any;
    showUserDto?: any;
    showUser?:any;
}

export const defaultTrainList: Readonly<TrainList> = {};