import axios, { AxiosRequestConfig } from 'axios';

import { SERVER_API_URL } from 'config/constants';
import qs from 'qs';
import { getAccessToken } from '../common/auth/token-manager';
import { Storage } from 'utils/storage-util';
import moment from 'moment';
import crypto from 'crypto';

const TOKEN_URL = '/api/yumei-mall-auth/auth2/token';
const TIMEOUT = 1 * 60 * 1000;
axios.defaults.timeout = TIMEOUT;
axios.defaults.baseURL = SERVER_API_URL;
// const xTenant = Storage.session.get("x-tenant");

// saber:saber_secret 平台 wholesale:wholesale  团长   seller:seller 商家
// const getClientId = () => {
//   const accountClient = Storage.session.get("account-client");
//   if(accountClient === 'saber') return 'saber:saber_secret';
//   return accountClient +':'+ accountClient
// }

const setupAxiosInterceptors = (onUnauthenticated: any) => {
  const onRequestSuccess = async (config: any) => {
    const xTenant = await Storage.session.get("x-tenant");
    const env = process.env.REACT_APP_ENV || '';

    if (config.url.indexOf('/middleGround') !== -1) {
      let datetime = moment().format('Y-MM-DD HH:mm:ss');
      let appkey = 'backstage_management';
      let token = 'C0D1B04A3FDF9A1F';
      let sign = crypto.createHash('md5').update(appkey + token + datetime).digest('hex').toUpperCase();
      config.headers = {
        ...config.headers,
        datetime,
        appkey,
        sign
      };

      const prodEnvironment = ['pre', 'prod'].indexOf(env) !== -1;//是否正式环境
      const newUrl = config.url.split('/middleGround')?.[1] || '';
      if (!prodEnvironment) {
        //测试环境
        env !== 'local' && (config.url = 'https://oms-test.tinghao123.com/logistics' + newUrl);
        // config.url = 'https://prod-ordersplit.yuedaoec.com/logistics' + newUrl
      } else {
        //正式环境
        config.url = 'https://prod-ordersplit.yuedaoec.com/logistics' + newUrl
      };
    }

    if (TOKEN_URL !== config?.url) {
      // 非登录和刷新token接口，需要添加token到头。
      const token = await getAccessToken();

      if (token) {
        config.headers['Authorization'] = `Bearer ${token}`;
      }
    }

    config.headers['Content-Type'] = `application/json`;

    if (xTenant && xTenant !== 'merrige') config.headers['X-Tenant'] = xTenant;
    const urlArr: any = config.url && config.url.split('/');

    if (['auth', 'oauth2'].indexOf(urlArr[1]) > -1) {
      env === 'development' && console.log('测试环境 地址改为：https://uc.merrige.cn/api' + config.url)
      env === 'development' && (config.url = 'https://uc.merrige.cn/api' + config.url);
      const token = Storage.session.get('loginData')?.access_token;
      !!token && (config.headers['Authorization'] = `Bearer ${token}`);
    }

    return config;
  };

  const onRequestParamsSerializer = (config: any) => {
    if (config.method === 'get') {
      // 如果是get请求，且params是数组类型如arr=[1,2]，则转换成arr=1&arr=2,不转换会显示为arr[]=1&arr[]=2
      config.paramsSerializer = function (params: any) {
        return qs.stringify(params, { arrayFormat: 'repeat', allowDots: true })
      }
    }
    return config;
  };

  const onResponseSuccess = (response: any) => response;

  const onResponseError = async (err: any) => {
    console.log(err.response)
    //status：状态码
    // const status = err.status || (err.response ? err.response.status : 0);
    // console.log(err.response)
    if (Number(err.response.data.code) === 401) {
      console.log('11111')
      onUnauthenticated();
    }
    return Promise.reject(err);
  };

  axios.interceptors.request.use(onRequestSuccess);
  axios.interceptors.request.use(onRequestParamsSerializer);
  axios.interceptors.response.use(onResponseSuccess, onResponseError);
};

export default setupAxiosInterceptors;

