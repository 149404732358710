import { FC, useEffect, useState } from "react"
import { RouteComponentProps } from '@reach/router';
import { RootState } from 'reducers';
import { connect } from 'react-redux';
import { Col, Form, Row } from "antd";
import { createEntity, getEntity, reset, updateEntity } from "./activity.reducer";
import EditPage, { EditStateAndDispatchProps } from "layouts/page-wraper/edit-page-modal";
import { Activity } from "model/promotion/activity.model";
import ProForm, { ProFormDateTimePicker, ProFormDependency, ProFormList, ProFormSelect, ProFormSwitch, ProFormText } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";
import { searchProductList } from "pages/pim/product/product.reducer";
import ProductListEditControl from "pages/pim/product/product-list-edit-control";
import { fetchLevelList } from "pages/customerCore/level/level.reducer";
import Title from "antd/lib/typography/Title";
import SelectSearch from "components/select-search";
import { fetchStoreList } from "pages/dealer/unit/unit.reducer";
export interface ActivityEditProps extends EditStateAndDispatchProps<Activity>, StateProps, DispatchProps, RouteComponentProps {
}
const ActivityEdit: FC<ActivityEditProps> = props => {
  const { text } = props;
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();
  const [levelList, setLevelList] = useState<any>([]);

  useEffect(() => {
    fetchLevelList().then(e => setLevelList(e))
  }, [text]);

  const beforeUpdateEntity = (res?: any) => {
    return new Promise<any>((resolve, reject) => {
      res.items = res.items.concat().map((e: any) => {
        return {
          ...e,
          rule: {
            ...e.rule,
            ruleName: 'FixedPrice',
          },
          productRestriction: {
            ...e.productRestriction,
            ids: e.productRestriction.restrictionType === "Product" ? e.productRestriction.ids.map((s: any) => s + '') : [],
          }
        }
      });
      resolve(res)
      // console.log(res)
      // reject();
    })
  }

  return (
    <EditPage<Activity>
      title="编辑"
      form={form}
      {...props}
      beforeUpdateEntity={beforeUpdateEntity}
    >
      <Row>
        <Col span={7}>
          <ProFormText
            name="name"
            label="活动名称"
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormText
            name="code"
            label="活动编码"
          />
        </Col><Col span={1} />
        <Col span={7}>
          <ProFormSelect
            options={convertEnumToOptionType('promotionType')}
            name="promotionType"
            label="类型"
          />
        </Col><Col span={1} />
        <Col span='7'>
          <ProFormDateTimePicker
            name="startTime"
            label="开始时间"
          />
        </Col><Col span='1' />
        <Col span='7'>
          <ProFormDateTimePicker
            name="endTime"
            label="结束时间"
          />
        </Col><Col span='1' />
        <Col span={24}>
          <ProFormList
            name={'items'}
            creatorButtonProps={{
              creatorButtonText: '添加',
            }}
            initialValue={[{}]}
            actionRender={(field, action, defaultActionDom) => [defaultActionDom[1]]}
          >
            {(meta, itemIndex, action) => <Row>
              <Col span={24}><Title level={5}>促销明细</Title></Col>
              <ProFormText
                name="code"
                label="促销明细活动编码"
              />
              <Col span={1} />
              <ProFormText
                name="name"
                label="促销明细活动名称"
              />
              <Col span={1} />
              <ProFormSwitch
                name={['productRestriction', 'storeEnabled']}
                label="是否有商家限制"
                initialValue={false}
                fieldProps={{
                  checkedChildren: '是',
                  unCheckedChildren: '否',
                }}
              />
              <Col span={1} />
              <ProFormSwitch
                name={'orderRestrictionEnabled'}
                label="是否有订单限制"
                initialValue={false}
                fieldProps={{
                  checkedChildren: '是',
                  unCheckedChildren: '否',
                }}
              />
              <Col span={1} />
              <ProFormDependency name={['orderRestrictionEnabled']}>
                {({ orderRestrictionEnabled }) => {
                  return orderRestrictionEnabled && <>
                    <ProFormText
                      name={['orderRestriction', 'orderItemCount']}
                      label="商品数量"
                    />
                    <Col span={1} />
                    <ProFormSwitch
                      name={['orderRestriction', 'repeatableForItem']}
                      label="是否每满"
                      initialValue={false}
                      fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否',
                      }}
                    />
                    <Col span={1} />
                  </>
                }
                }
              </ProFormDependency>
              <Col span={24} />
              <ProFormSwitch
                name={'productRestrictionEnabled'}
                label="商品范围限制"
                initialValue={false}
                fieldProps={{
                  checkedChildren: '是',
                  unCheckedChildren: '否',
                }}
              />
              <Col span={1} />
              <ProFormDependency name={['productRestrictionEnabled']}>
                {({ productRestrictionEnabled }) => {
                  return productRestrictionEnabled && <>
                    <ProFormSwitch
                      name={['productRestriction', 'exclude']}
                      label="排除所选商品"
                      initialValue={false}
                      fieldProps={{
                        checkedChildren: '是',
                        unCheckedChildren: '否',
                      }}
                    />
                    <Col span={1} />
                    <ProForm.Item name={'storeId'}>
                      <SelectSearch name="storeId" valueType="number" width="md" label='商家' labelValue="name" defaultParams='id.equals' fetchFunc={fetchStoreList} searchParams='name.contains' />
                    </ProForm.Item>
                    <Col span={1} />
                    <Col span={24}>
                      <ProForm.Item label="选择商品" name={['productRestriction', 'ids']} >
                        <ProductListEditControl fetchFunc={searchProductList} hideProductIds />
                      </ProForm.Item>
                    </Col>
                  </>
                }
                }
              </ProFormDependency>
              <Col span={24}>
                <Row>
                  <Col span={7}>
                    <ProFormText
                      label="促销规则"
                      fieldProps={{ value: '固定价格' }}
                      disabled
                    />
                  </Col><Col span={1} />
                  <Col span={7}>
                    <ProFormSelect
                      options={convertEnumToOptionType('fixedPriceApportionType')}
                      name={['rule', "priceApportionType"]}
                      label="分摊规则"
                    />
                  </Col><Col span={1} />
                  {levelList.length > 0 && levelList.map((e: any) => {
                    return <>
                      <Col span={3}>
                        <ProFormText
                          name={['rule', 'priceMap', e.levelNo + '']}
                          label={e.levelName + '促销价'}
                        />
                      </Col><Col span={1} />
                    </>
                  })}
                </Row>
              </Col>
            </Row>}
          </ProFormList>
        </Col>
      </Row>
    </EditPage>);
};

const mapStateToProps = ({ activity }: RootState) => ({
  entity: activity.entity,
  updateSuccess: activity.updateSuccess,
  updating: activity.updating,
  createSuccess: activity.createSuccess
});

const mapDispatchToProps = {
  getEntity,
  createEntity,
  updateEntity,
  reset,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(ActivityEdit);
