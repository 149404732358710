import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './wallet.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Wallet } from "model/customerCore/wallet.model";
import ProTable, { ProColumns } from "@ant-design/pro-table";

export interface WalletDetailsProps extends DetailsStateAndDispatchProps<Wallet, 'walletAccountType' | 'walletStatus'>,
  RouteComponentProps, StateProps, DispatchProps {
}

const WalletDetails: FC<WalletDetailsProps> = (props: any) => {

  const detailsColumns: ProColumns<Wallet, 'walletAccountType'>[]  = [{
    dataIndex: 'accountType',
    valueType: 'walletAccountType',
    title: '钱包类型',
  }, {
    dataIndex: 'amount',
    title: '数量',
  },{
    dataIndex: 'expiredDate',
    title: '有效期',
  }]
  return (
    <DetailsPage<Wallet, 'walletAccountType' | 'walletStatus'>
      title='会员详情'
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Wallet, 'walletAccountType' | 'walletStatus'>
        column={2}
        columns={props.columns}
        dataSource={props.entity}
      />
      <ProTable<Wallet, Record<string, any>, 'walletAccountType'>
        className="express-table"
        columns={detailsColumns}
        rowKey="id"
        dataSource={props.entity?.walletDetails || []}
        options={false}
        search={false}
        pagination={false}
      />
    </DetailsPage>);
};

const mapStateToProps = ({ wallet }: RootState) => ({
  entity: wallet.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(WalletDetails);
