import { FC, ReactNode } from "react";
import { Form, Input } from "antd";
import { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import RateDetails from "./rate-details";
import { deleteEntity, getEntities, getEntity } from "./rate.reducer";
import { RouteComponentProps } from "@reach/router";
import ListViewPage from "layouts/page-wraper/list-view-page";
import RateEdit from "./rate-edit";
import { Rate } from "model/system/rate.model";
import { ProFormDatePicker, ProFormSelect } from "@ant-design/pro-form";
import { useEnumValue } from "common/enum/use-enum-value";


export interface RateListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}
const columns: ProColumns<Rate, 'CurrencyType'>[] = [{
  title: '货币类型',
  dataIndex: 'currencyType',
  valueType: 'CurrencyType'
}, {
  title: '汇率',
  dataIndex: 'exchangeRate',
  width: 120,
}, {
  title: '生效时间',
  dataIndex: 'startDate',
  width: 120,
}]
const RateList: FC<RateListProps> = (props) => {
  const { convertEnumToOptionType } = useEnumValue();

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          name="currencyType.equals"
          placeholder="货币类型"
          options={convertEnumToOptionType('CurrencyType')}
        />
        <ProFormDatePicker
          name="startDate.greaterThanOrEqual.YMD"
          placeholder="生效时间"
        />
      </Input.Group>
    </Form.Item>
  );
  return (
    <ListViewPage<Rate, 'CurrencyType'>
      columns={columns}
      quickFilterPanel={convertEnumToOptionType('CurrencyType').length > 0 && quickFilterPanel}
      detailPage={<RateDetails navigate={props.navigate} columns={columns} />}
      detailsIndex={false} 
      editPage={(params: any) => <RateEdit id={params.id} text={params.text} />}
      {...props}
    >
      {props.children}
    </ListViewPage>
  );
};

const mapStateToProps = ({ rate }: RootState) => ({
  entity: rate.entity,
  entities: rate.entities,
  loading: rate.loading,
  updateSuccess: rate.updateSuccess,
  createSuccess: rate.createSuccess,
  total: rate.total
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(RateList);