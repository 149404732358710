import update from 'immutability-helper';
import axios from 'axios';
import { REQUEST, SUCCESS } from "../../../reducers/action-type.util";
import { navigate } from '@reach/router'
import { deepCloneObject, createModuleId, modulesList  ,defaultLanguageMap} from 'model/decorate/decorate.model';
import {cloneDeep} from 'lodash';



interface InitialStateProps {
    insertPositionId: number | string
    pageConfig: ({ type: string, dataField: any, id?: any })[]
    pageConfigGlobal: {[key: string]: ({ type: string, dataField: any, id?: any })[]}
    pageData: { type: number | string, [key: string]: any }
    loading: boolean
    pageLoading: boolean
    settingModuleId: string // 设置中的模块id
    popupData?: { [key: string]: any }
    language: 'zh-CN' | 'zh-TW' | 'en' ,
    platform: string
};

const initialState: InitialStateProps = {
    insertPositionId: 'none',
    pageConfig: [],
    pageConfigGlobal: {},
    pageData: { type: 0, pageConfig: [] },
    loading: false,
    pageLoading: false,
    settingModuleId: 'none',
    popupData: {},
    language: 'zh-CN',
    platform:''
}

const apiUrl = '/api/yumei-mall-content';
const ACTION_TYPE = {
    GETDETAIL: 'decorate/getDetail',
    UPDATEINSERT: 'decorate/updateInsertId',
    INSERTMODULE: 'decorate/insertModule',
    UPDATEPAGECONFIG: 'decorate/updatePageConfig',
    UPDATEMORESTATE: 'decorate/updateMoreState',
    SAVE: 'decorate/save',
    CREATE: 'decorate/CREATE',
    UPDATESETTINGMODULE: 'decorate/updateSettingModule',
    UPDATEMODULEDATA: 'decorate/updateModuleData',
    DELETEMODULE: 'decorate/deleteModule',
    CHANGELANGUAGE: 'decorate/chanageLanguage',
    CHANGEPLATFORM: 'decorate/changePlatform',
}

export const decorateEdit:any = (state = initialState, action: { type: string, payload: any }) => {
    switch (action.type) {
        case ACTION_TYPE.UPDATEINSERT:
            return {
                ...state,
                insertPositionId: action.payload
            }
        case ACTION_TYPE.INSERTMODULE:
            const moduleData = {
                type: action.payload.type,
                dataField: {
                    ...(deepCloneObject(action.payload.defaultConfig)),
                    md_id: createModuleId(),
                },
                id: `${action.payload.type}_${+new Date()}`
            }
            let newPageConfig
            if (state.insertPositionId === '-1') {
                newPageConfig = [...state.pageConfig, moduleData]
            } else {
                let insertModule = state.pageConfig.filter((v: any) => `${v.id}` === state.insertPositionId)[0]
                let inserIndex = state.pageConfig.indexOf(insertModule)
                if (action.payload.isCopy) {
                    inserIndex++
                }
                newPageConfig = update(state.pageConfig,
                    {
                        $splice: [
                            [inserIndex, 0, moduleData]
                        ]
                    }
                )
            }
            state.pageConfigGlobal[state.language] = newPageConfig

            return {
                ...state,
                pageConfig: newPageConfig,
                insertPositionId: 'none'
            }

        case ACTION_TYPE.CHANGELANGUAGE:
            state.pageConfigGlobal[state.language] = cloneDeep(state.pageConfig)
            let changedPageConfig:any = [],newLanguage = action.payload;
            if(state.pageConfigGlobal[newLanguage] && state.pageConfigGlobal[newLanguage].length > 1 ){
                changedPageConfig = state.pageConfigGlobal[newLanguage]
           
            }else{
                let copyPageConfig = Object.values(state.pageConfigGlobal)[0];
                state.pageConfigGlobal[newLanguage] = cloneDeep(copyPageConfig)
                changedPageConfig = state.pageConfigGlobal[newLanguage]
                const configModule = changedPageConfig.filter((v: any) => v.type === 'config');
                if (configModule && configModule[0] && configModule[0].dataField) {
                    configModule[0].dataField.title += ' ' + newLanguage;
                }
            }
            changedPageConfig.forEach((v: any, i: number) => {
              v.id = `${v.type}_${i}_${new Date().getTime()}`
            })
            return {
                ...state,
                language:newLanguage,
                pageConfig: changedPageConfig,
            }    
        case ACTION_TYPE.CHANGEPLATFORM:
            return {
                ...state,
                platform: action.payload,
            }    
        case REQUEST(ACTION_TYPE.GETDETAIL):
            return {
                ...state,
                loading: true
            }
        case REQUEST(ACTION_TYPE.SAVE):

            return {
                ...state,
                pageLoading: true
            }
        case REQUEST(ACTION_TYPE.CREATE):
            return {
                ...state,
                pageLoading: true
            }
        case SUCCESS(ACTION_TYPE.SAVE):
            return {
                ...state,
                pageLoading: false,
            }
        case SUCCESS(ACTION_TYPE.CREATE):
            setTimeout(() => {
                navigate(`/decorate/platform/${action.payload.data.id}/edit`, {
                    replace: true
                })
            }, 1000)
            // message.success('页面保存成功');
            // console.log('id',requestData.id)

            return {
                ...state,
                pageLoading: false,
            }
        case SUCCESS(ACTION_TYPE.GETDETAIL):
            let pageConfigGlobal: any = {}
            if(action.payload.data.pageConfig instanceof Array){
                pageConfigGlobal[defaultLanguageMap[state.platform][0].language] = action.payload.data.pageConfig
            }else {
                pageConfigGlobal = action.payload.data.pageConfig;

            }
            const pageConfig = (pageConfigGlobal[state.language]).map((v: any, i: number) => {
                if(!v){
                    return {}
                }
                v.id = `${v.type}_${i}`
                //历史Goods模块处理
                if (v.type === 'goods' && !v.dataField.goodsIds) {
                    let goodsIds = v.dataField.goodsDetail.map((goods: any) => {
                        return goods.id
                    })
                    v.dataField = {
                        listStyle: v.dataField.listStyle,
                        goodsIds: goodsIds,
                    }
                }
                //历史goods-cube模块处理
                if (v.type === 'goods-cube' && !v.dataField.styleList) {
                    let goodsCubeModel = modulesList.filter((v: any) => v.type === 'goods-cube');
                    v.dataField = {
                        ...v.dataField,
                        styleList: goodsCubeModel[0].defaultConfig.styleList,
                        selected: goodsCubeModel[0].defaultConfig.selected,
                    }
                }
                //历史list_nav模块处理
                if (v.type === 'list_nav' && !v.dataField.nav[0].data_type) {
                    v.dataField.nav = v.dataField.nav.map((v: any) => {
                        v.data_type = 'filter'
                        return v
                    })
                }
                return v
            })
            delete action.payload.data.pageConfig;
            console.log('999999999999999999999999999')
            console.log({
                ...state,
                pageData: action.payload.data,
                pageConfig: pageConfig,
                loading: false,
                settingModuleId: 'none',
                pageConfigGlobal,
            })
            console.log('999999999999999999999999999')
            return {
                ...state,
                pageData: action.payload.data,
                pageConfig: pageConfig,
                loading: false,
                settingModuleId: 'none',
                pageConfigGlobal,
            }

          

        case ACTION_TYPE.UPDATEPAGECONFIG:
            state.pageConfigGlobal[state.language] =  action.payload

            return {
                ...state,
                pageConfig: action.payload
            }
        case ACTION_TYPE.UPDATEMORESTATE:
            if(action.payload && action.payload.pageConfig){
                state.pageConfigGlobal[state.language] = action.payload.pageConfig
            }
            return {
                ...state,
                ...action.payload,
                pageConfigGlobal:state.pageConfigGlobal
            }

        case ACTION_TYPE.UPDATEMODULEDATA:
            let updateModule = state.pageConfig.filter((v: any) => `${v.id}` === action.payload.id)[0],
                upDataIndex = state.pageConfig.indexOf(updateModule),
                pageConfigUpdate = state.pageConfig.filter((v: any) => v)
            if (upDataIndex !== -1) {
                updateModule = {
                    ...updateModule,
                    dataField: action.payload.dataField,
                }
                pageConfigUpdate[upDataIndex] = updateModule
                state.pageConfigGlobal[state.language][upDataIndex] = updateModule
            }
            return {
                ...state,
                pageConfig: pageConfigUpdate,
                // pageConfigGlobal:state.pageConfigGlobal
            }
        case ACTION_TYPE.DELETEMODULE:
            let deleteModule = state.pageConfig.filter((v: any) => `${v.id}` === action.payload.id)[0],
                deleteIndex = state.pageConfig.indexOf(deleteModule);
            if (deleteIndex !== -1) {
                delete state.pageConfig[deleteIndex]
            }
            state.pageConfig = state.pageConfig.filter((v: any) => v)
            state.pageConfigGlobal[state.language] = state.pageConfig
            return {
                ...state,
            }
    }
    return state
}

interface MoveModuleProps {
    originIndex: number
    atIndex: number
    moduleData: {}
}



//获取页面数据
export const getPageDetail = (id: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    const requestUrl = `${apiUrl}/miro-page/${id}`;
    return dispatch({
        type: ACTION_TYPE.GETDETAIL,
        payload: axios.get(requestUrl)
    })
}

//更新页面配置数据
export const updatePageConfig = (props: any) => (dispatch: (props: { type: string, payload: MoveModuleProps }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEPAGECONFIG,
        payload: props
    })
}

//更新插入位置索引
export const updateInsertPositionId = (id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    if (!id) return;
    return dispatch({
        type: ACTION_TYPE.UPDATEINSERT,
        payload: id
    })
}

//更新多项数据
export const updateMoreState = (props: { [key: string]: any }) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEMORESTATE,
        payload: props
    })
}

//插入数据
export const insertModule = (module: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.INSERTMODULE,
        payload: module
    })
}


//保存修改
export const savePageData = (pageData: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    const requestUrl = `${apiUrl}/miro-page`;
    let pageTitle = pageData.title
    pageData = deepCloneObject(pageData)
    for(let k in pageData.pageConfigGlobal){
        let pageConfig  = pageData.pageConfigGlobal[k];
        let configModule = pageConfig.filter((v: any) => v.type === 'config');
        let configIndex = pageConfig.indexOf(configModule[0]);
        if (configIndex !== -1) {
            delete pageConfig[configIndex]
            pageConfig = pageConfig.filter((v: any) => v)
        }
         else {
            configModule = [{
                type: 'config',
                id: 'config_0',
                dataField: {
                    bgColor: '#f5f5f5',
                    title: pageData.title,
                }
            }]
        }
        if (configModule && configModule[0]) {
            if(k === 'zh-CN' && pageConfig.length > 0){
                pageTitle = configModule[0].dataField.title
            }else if(k === defaultLanguageMap[pageData.platform][0].language){
                pageTitle = configModule[0].dataField.title
            }
            pageConfig.unshift(configModule[0])
        }
        pageConfig.forEach((v: any) => {
            delete v.id
        })
        pageData.pageConfigGlobal[k] = pageConfig
    }
    pageData.pageConfig = pageData.pageConfigGlobal
    pageData.title = pageTitle
    
    delete pageData.pageConfigGlobal
    delete pageData.platforms
    // pageData.pageConfig = JSON.stringify(pageData.pageConfig)
    if (pageData.id) {
        return dispatch({
            type: ACTION_TYPE.SAVE,
            payload: axios.put(requestUrl + '/' + pageData.id, pageData)
        })
    } else {
        return dispatch({
            type: ACTION_TYPE.CREATE,
            payload: axios.post(requestUrl, {
                pageType: pageData.pageType,
                title: pageTitle,
                pageConfig: pageData.pageConfig,
                isDefault: false,
                status: 1,
                used: false,
                sellerId: '0',
                startTime:pageData.startTime || '',
                endTime:pageData.endTime || ''
            })
        })

    }
}


//更新模块数据
export const updateModuleData = (dataField: any, id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.UPDATEMODULEDATA,
        payload: {
            dataField,
            id
        }
    })
}


//更新模块数据
export const deleteModule = (id: any) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.DELETEMODULE,
        payload: {
            id
        }
    })
}

//修改语言
export const changeLanguage = (language: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.CHANGELANGUAGE,
        payload: language
    })
}

//修改平台
export const changePlatform = (platform: string) => (dispatch: (props: { type: string, payload: any }) => any) => {
    return dispatch({
        type: ACTION_TYPE.CHANGEPLATFORM,
        payload: platform
    })
}
