import {BaseModel, DisableModel} from 'model/base.model';

export interface SealAuth extends BaseModel, DisableModel {
    
}

export const SealAuthAuthStatus = {
    '1':'生效',
    '0':'失效',
    '2':'已删除',
    '3':'待生效'
}

export const SealAuthStatus = {
    '1':'正常',
    '0':'删除',
}

export const defaultSealAuth: Readonly<SealAuth> = {
};
