import { FC, ReactNode, useEffect } from "react";
import { Form, Input, Tag, Image } from "antd";
import ProTable, { ProColumns } from "@ant-design/pro-table";
import { connect } from "react-redux";
import { RootState } from "reducers";
import SearchBox from "components/search-box";
import ListViewPage from "layouts/page-wraper/list-view-page";
import { deleteEntity, getEntities, getEntity, updateEntity, setShopWholesale } from "./store.reducer";
import { RouteComponentProps } from "@reach/router";
import { Seller, storeTypeMapList } from "model/store/seller.model";
import { ModalForm, ProFormSelect } from "@ant-design/pro-form";
import StoreDetails from "./store-details";
import StoreEdit from './store-edit';
import AuthButton from "common/auth/auth-button";
import { useEnumValue } from 'common/enum/use-enum-value';
import { internationalList } from "components/IntelFormText";
import { fetchLevelList } from "../marginRules/marginRules.reducer";

// 合约
export const contractInfoColumns: ProColumns<any, 'contractSignStatus'>[] = [{
  title: 'ID',
  dataIndex: 'id',
  valueType: 'text',
}, {
  title: '合同主题',
  dataIndex: 'subject',
  valueType: 'text',
}, {
  title: '合约名称',
  dataIndex: ['type', 'title'],
  valueType: 'text',
}, {
  title: '契约锁分类',
  dataIndex: ['type', 'categoryName'],
  valueType: 'text',
}, {
  title: '协议描述',
  dataIndex: ['type', 'description'],
  valueType: 'text',
}, {
  title: '是否线上',
  dataIndex: 'offline',
  valueType: 'text',
  render: (text) => {
    return text ? '是' : '否'
  }
}, {
  title: '合约状态',
  dataIndex: 'signStatus',
  valueType: 'contractSignStatus'
}, {
  title: '签署日期',
  dataIndex: 'lastModifiedDate',
  valueType: 'dateTime'
}, {
  title: '契约锁备注',
  dataIndex: 'remark',
  valueType: 'text'
}]

export interface StoreListProps
  extends StateProps, DispatchProps, RouteComponentProps {
  children?: ReactNode;
}

const StoreList: FC<StoreListProps> = (props) => {
  const { entity, seller } = props;
  const [form] = Form.useForm();
  const { convertEnumToOptionType } = useEnumValue();

  const columns: ProColumns<Seller, 'reviewerStatus'>[] = [{
    title: 'ID',
    dataIndex: 'id',
    valueType: 'text',
    width: 90,
  },{
    title: '店铺Logo',
    dataIndex: 'storeLogo',
    valueType: 'image',
    width: 60,
    render: (dom, record) => <Image width={40} src={record.storeLogo} />
  }, {
    title: '店铺编码',
    dataIndex: 'storeCode',
    valueType: 'text',
    width: 80,
  }, {
    title: '店铺名称',
    dataIndex: 'name',
    valueType: 'text',
    width: 80,
  },{
    title: '门店类型',
    dataIndex: 'storeType',
    width: 80,
    ellipsis: true,
    render: (text, record:any) => {
      let data = storeTypeMapList.find((item:any)=>item.value === record.storeType)
      return data?.label || '-'
    }
  }, {
    title: '是否积分商城店铺',
    dataIndex: 'isPointMall',
    width: 60,
    render: (text) => {
      return text === true ? '是' : '否'
    }
  }, {
    title: '自营',
    dataIndex: 'selfSeller',
    width: 40,
    render: (text) => {
      return text === true  ? '是' : '否'
    }
  }, {
    title: '6s中心',
    dataIndex: 'isCenter',
    width: 50,
    render: (text) => {
      return text === true  ? '是' : '否'
    }
  },{
    title: '品牌等级',
    dataIndex: 'levelName',
    width: 80,
    render: (data: any) => {
      return <span> {internationalList(data)} </span>
    }
  }, {
    title: '品牌',
    dataIndex: 'authorizedBrandsName',
    width: 150,
    render: (data: any) => {
      return data?.map((item: any) => {
        return <Tag color="#1890FF">
          {internationalList(item)}
        </Tag>
      })
    }
  }, {
    title: '联系人姓名',
    dataIndex: 'contactName',
    width: 80
  }, {
    title: '联系电话',
    dataIndex: 'contactPhone',
    width: 80
  }]

  useEffect(() => {
    form.setFieldsValue({ ...entity });
  }, [entity, form])

  const searchFields = [{
    label: '店铺名称',
    name: 'name.contains',
  }, {
    label: '店铺编码',
    name: 'storeCode.equals',
  }];

  const quickFilterPanel = (
    <Form.Item>
      <Input.Group compact>
        <ProFormSelect
          width="md"
          request={async (params) => {
            const unitRequest: any = await fetchLevelList();
            return unitRequest.map((item: any) => ({
              value: item.id,
              label: internationalList(item.levelName)
            }))
          }}
          name="levelId.equals"
          placeholder="品牌等级"
        />
        <div className="wd10"></div>
        <ProFormSelect
          width="md"
          options={[{ label: '是', value: 'true' }, { label: '否', value: 'false' }]}
          name="selfSeller.equals"
          placeholder="是否自营"
        />
         <div className="wd10"></div>
        <SearchBox
          width={360}
          label="店铺名称"
          fields={searchFields}
        />
      </Input.Group>
    </Form.Item>
  );

  return (
    <ListViewPage<Seller, 'reviewerStatus'>
      columns={columns}
      hideCreateButton={seller} 
      detailsIndex={0}
      detailPage={<StoreDetails navigate={props.navigate} columns={columns} />}
      editPage={(params: any) => <StoreEdit id={params.id} text={params.text} />}
      quickFilterPanel={convertEnumToOptionType('reviewerStatus').length > 0 && quickFilterPanel}
      rowActions={[(dom: ReactNode, record: Seller) =>
        <>
          <ModalForm
            title={'合约列表'}
            trigger={<AuthButton authority='' type="link" size="small">合约</AuthButton>}
            modalProps={{
              destroyOnClose: true,
            }}
            width={1000}
            className="customer-modal-form"
            onFinish={async () => { return true }}
          >
            <ProTable
              search={false}
              toolBarRender={false}
              pagination={false}
              dataSource={record.contractInfos}
              columns={contractInfoColumns}
            />
          </ModalForm>
        </>
      ]}
      {...props}
    >
      {props.children}

    </ListViewPage>
  );
};

const mapStateToProps = ({ store, authentication }: RootState) => ({
  entity: store.entity,
  entities: store.entities,
  loading: store.loading,
  updateSuccess: store.updateSuccess,
  createSuccess: store.createSuccess,
  total: store.total,
  seller: authentication.clientInfo.seller,
});

const mapDispatchToProps = {
  getEntity,
  getEntities,
  deleteEntity,
  updateEntity,
  setShopWholesale
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(StoreList);