import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './sealAuth.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { SealAuth } from "model/contract/sealAuth.model";

export interface SealAuthDetailsProps extends DetailsStateAndDispatchProps<SealAuth>,
  RouteComponentProps, StateProps, DispatchProps {
}
const SealAuthDetails: FC<SealAuthDetailsProps> = (props) => {
  return (
    <DetailsPage<SealAuth>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<SealAuth>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ sealAuth }: RootState) => ({
  entity: sealAuth.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(SealAuthDetails);
