import axios from 'axios';
import {FAILURE, REQUEST, SUCCESS} from 'reducers/action-type.util';

import {EnumValue} from 'model/base.model';

export const ACTION_TYPES = {
  FETCH_SETTING_LIST: 'setting/FETCH_SETTING_LIST',
};

export interface Map<T> {
  [index: string]: T;
}

const initialState = {
  loading: false,
  errorMessage: (null as unknown) as string, // Errors returned from server side
  values: {} as Map<EnumValue[]>,
};

export type SettingState = Readonly<typeof initialState>;

// Reducer
export const settingData = (state: SettingState = initialState, action: any): SettingState => {
    switch (action.type) {
        case REQUEST(ACTION_TYPES.FETCH_SETTING_LIST):
            return {
                ...state,
                loading: true,
                // errorMessage: '',
            };
        case FAILURE(ACTION_TYPES.FETCH_SETTING_LIST):
            return {
                ...state,
                errorMessage: action.payload,
                loading: false,
            };
        case SUCCESS(ACTION_TYPES.FETCH_SETTING_LIST):
            return {
                ...state,
                loading: false,
                values: {...(state?.values || {}), ...action.payload.data},
            };
        default:
            return state;
    }
};

export const getSetting = ()  => (dispatch: any) => {

    const requestUrl = `/api/tbt-mall-common/param/group/general`;
    return dispatch({
        type: ACTION_TYPES.FETCH_SETTING_LIST,
        payload: axios.get(requestUrl),
    });
};

