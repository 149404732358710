import React from 'react';
import {RouteComponentProps, Router} from "@reach/router";
import ActiveUserList from './user-list';
import ActiveUserDetails from "./user-details";
import ActiveUserEdit from "./user-edit";

const ActiveUser: React.FC<RouteComponentProps> = () => (
    <Router>
        <ActiveUserList path = "/"/>
        <ActiveUserDetails path = ":id"/>
        <ActiveUserEdit path = ":id/edit"/>
        <ActiveUserEdit path = "new"/>
    </Router>
);
export default ActiveUser;
