import { FC, useEffect, memo, useState } from 'react';
import { Button } from 'antd';
import { useDrag } from 'react-dnd';
import { modulesList, pageTypeMap, defalutModuleList, deepCloneObject } from 'model/decorate/examdecorate.model';
import { RootState } from 'reducers';
import { connect, } from 'react-redux';
import { insertModule, updateInsertPositionId } from '../examDecorate/examplatform-edit.reducer'
import './common.less';
interface ModulesListProps extends StateProps, DispatchProps {
  type?: number | string
}

interface DragButtonProps {
  data: { title: string, [key: string]: any }
  insertModule: (itme: any) => void
  updateInsertPositionId: (id: string) => void
}

interface DropResult {
  name: string
}
const DragButton: FC<DragButtonProps> = memo(({ data, insertModule, updateInsertPositionId }) => {

  const [{ isDragging }, drag] = useDrag(() => ({
    type: 'all',
    item: { ...data },
    end: (item, monitor) => {
      const dropResult = monitor.getDropResult<DropResult>()
      if (item && dropResult) {

        insertModule(deepCloneObject(item))
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
      handlerId: monitor.getHandlerId(),
    }),
  }), [data])
  useEffect(() => {
    if (isDragging) {
      updateInsertPositionId('-1');
    }
    else {
      updateInsertPositionId('none');
    }
  }, [isDragging, updateInsertPositionId])
  return <Button block={true} className="module-item" ref={drag} type="dashed">{data.title}</Button>
})

const ModulesList: FC<ModulesListProps> = memo(({ insertModule, pageData, updateInsertPositionId }) => {
  const [ButtonList, setButtonList] = useState([])
  useEffect(() => {
    if (!pageData || !pageData.title) return
    const modules = pageTypeMap[pageData.pageType] ? pageTypeMap[pageData.pageType].modules : defalutModuleList
    let ButtonList: any = modulesList.map((v, i) => {
      if (modules.indexOf(v.type) !== -1 && !v.isHide) {
        return <DragButton data={v} key={i} insertModule={insertModule} updateInsertPositionId={updateInsertPositionId} />
      }
      return false
    })
    setButtonList(ButtonList)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [modulesList, setButtonList, pageTypeMap, insertModule, updateInsertPositionId, pageData])
  return <div className="modules-list">
    {/* <p className="modules-title">可用模块</p> */}
    <div className="list-con">
      {ButtonList}
    </div>
  </div>
})

const mapStateToProps = ({ examDecorateEdit }: RootState) => ({
  pageData: examDecorateEdit.pageData,
  insertPositionId: examDecorateEdit.insertPositionId,
});
const mapDispatchToProps = {
  // moveModule
  insertModule,
  updateInsertPositionId
}
type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;
export default connect(mapStateToProps, mapDispatchToProps)(ModulesList);