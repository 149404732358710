import ProTable, { ProTableProps } from '@ant-design/pro-table';
import { Product } from '../../../model/product/product.model';
import ProductSelectionForm, { columns as productColumns } from './product-selection-form';
import React, { FC } from 'react';
import { fetchProductByIds } from './product.reducer';
import { Button, Form, message, Typography } from 'antd';
import { ModalForm, ProFormTextArea } from '@ant-design/pro-form';

export interface ProductListEditControlProps extends Omit<ProTableProps<Product, { productIds?: string[] }>, 'onChange'> {
  value?: string[];
  onChange?: (products?: string[]) => void;
  requestParams?: any;
  multiple?: boolean;
  fetchFunc?: (params: any) => any;
  hideProductIds?: boolean;
}

const ProductListEditControl: FC<ProductListEditControlProps> = props => {
  const { value, onChange, requestParams = {}, multiple = true, fetchFunc, hideProductIds = false, ...tableProps } = props;
  const [form] = Form.useForm<{ productIds?: string }>();

  return (
    <ProTable<Product, { productIds?: string[] }>
      rowKey="id"
      columns={productColumns}
      options={false}
      search={false}
      params={{ productIds: value || [] }}
      rowSelection={{}}
      // headerTitle="限下列商品参与活动"
      tableAlertOptionRender={({ selectedRowKeys, onCleanSelected }) =>
        <Button type="link" size="small"
          onClick={() => {
            const newValue = value?.concat().map(e => e + '');
            onChange?.((newValue || []).filter(item => selectedRowKeys?.indexOf(item + '' || '') < 0));
            onCleanSelected();
          }}>
          删除
        </Button>}
      toolBarRender={() => [
        <ProductSelectionForm
          trigger="添加"
          multiple={multiple}
          requestParams={requestParams}
          fetchFunc={fetchFunc}
          onFinish={(productIds) => {
            return new Promise<any>((resolve, reject) => {
              const newValue = value?.concat().filter(e => typeof (e) !== 'object');
              const newIds = (newValue || []).concat(productIds.filter(id => !(newValue || []).includes(id)));
              if (newIds.length > 200) {
                message.error('商品数量最多为200，请重新选择！');
                resolve(false);
              } else {
                onChange?.(newIds);
                resolve(true);
              };
            })

          }}
        />,
        !hideProductIds && <ModalForm<{ productIds?: string }>
          form={form}
          title="批量输入商品ids"
          width={800}
          trigger={<Button>快速添加</Button>}
          modalProps={{
            destroyOnClose: true,
          }}
          preserve={false}
          onVisibleChange={visible => visible && form.setFieldsValue({ productIds: (value || []).toString() })}
          onFinish={async (values) => {
            const newIds = (values.productIds || '').trim().split(',').map(item => item.trim()).filter(item => !!item);
            if (newIds.length > 200) {
              message.error('商品数量最多为200，请重新输入！');
              return false;
            } else {
              onChange?.(newIds);
              return true;
            };
          }}
        >
          <Typography.Text>批量粘贴商品Id到下面，以"，"分割，然后点击确定</Typography.Text>
          <Typography.Text italic>例如：1430438289763639297,1455712100170674178</Typography.Text>
          <ProFormTextArea
            name="productIds"
            allowClear={true}
            placeholder="1430438289763639297,1455712100170674178"
            rules={[{ pattern: RegExp('^\\d+(,(\\s)*\\d+)*$'), message: '数据格式不正确' }]}
          />
        </ModalForm>]}
      request={async (
        params: {
          productIds?: string[];
          pageSize?: number;
          current?: number;
        },
      ) => {
        const { productIds = [], pageSize = 20, current = 1 } = params;
        // const allData
        const ids = productIds.slice((current - 1) * pageSize, current * pageSize);
        const products = await fetchProductByIds(ids);
        return {
          data: products,
          // success 请返回 true，
          // 不然 table 会停止解析数据，即使有数据
          success: true,
          // 不传会使用 data 的长度，如果是分页一定要传
          total: productIds.length,
        };
      }}
      {...tableProps}
    />);
}

export default ProductListEditControl;