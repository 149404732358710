import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import { defaultAdvertisement, advertisementModel } from "model/market/advertisement.model";
import axios from 'axios';

const entity = 'level';
const apiUrl = '/api/yumei-mall-pay/pay/info';
const standardReducerImpl = new StandardReducerImpl<advertisementModel>(entity, defaultAdvertisement, apiUrl);
export type PayorderState = StandardState<advertisementModel>;
export const payorder = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;


// 导出列表
export const exportList = async (data:any) => {
  let parm = "?";
  Object.keys(data).forEach((key) => {
    parm += `${key}=${data[key]}&`;
  });
  const requestUrl = `/api/yumei-mall-pay/pay/info`;
  const res = await axios.get(requestUrl + parm);
  return res
};





// 获取支付方式
export const fetchPayList = async () => {
  const requestUrl = `/api/yumei-mall-pay/pay/type/pay-list`;
  return await axios.get(requestUrl);
};







