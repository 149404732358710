import axios from 'axios';
import { defaultReturnPackage, ReturnPackage } from 'model/procure/return-package.model';
import { Payload, PayloadResult } from 'reducers/redux-action.type';
import { StandardReducerImpl, StandardState, STANDARD_ACTION_TYPES } from 'reducers/standard.reducer';

const entity = 'package';
const apiUrl = '/api/yumei-mall-order/return-package-record';
const standardReducerImpl = new StandardReducerImpl<ReturnPackage>(entity, defaultReturnPackage, apiUrl);
export type OrderReturnPackageState = StandardState<ReturnPackage>;
export const orderReturnPackage = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

export const abandon = (id: string | number): Payload<ReturnPackage> | PayloadResult<ReturnPackage> => async dispatch => {
  const requestUrl = `${apiUrl}/cancel/${id}`;
  return await dispatch({
    type: `${entity}/${STANDARD_ACTION_TYPES.UPDATE}`,
    payload: axios.post(requestUrl),
  })
};

export const fetchOrderDetails = async (orderNo: string | number) => {
  const requestUrl = `/api/yumei-mall-order/order/orderNo/${orderNo}`;
  const res = await axios.get(requestUrl);
  return res.data;
}

export const fetchReturnPackageList = async (params?: any) => {
  const res = await axios.get(apiUrl, { params: { ...params } });
  return res;
}

export const fetchManualOrderList = async (params?: any) => {
  const res = await axios.get(`/api/yumei-mall-order/order`, {
    params: {
      'type.equals': 'Manual',
      queryType: 'All',
      ...params
    }
  });
  return res;
}

export const fetchReturnPackage = async (params?: any) => {
  
  const res = await axios.get(`/api/yumei-mall-order/return-package-record/after-sale?afterSaleId=${params.id}`);
  return res;
}