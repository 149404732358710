import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import AssortDetails from './assort-details';
import AssortEdit from './assort-edit';
import AssortList from './assort-list';

const Assort: React.FC<RouteComponentProps> = () => (
  <Router>
    <AssortList path="/" />
    <AssortDetails path=":id" />
    <AssortEdit path=":id/edit" />
    <AssortEdit path="new" />
  </Router>
);
export default Assort;
