import { defaultBrandLevel, BrandLevel } from 'model/dealer/brandLevel.model';
import { StandardReducerImpl, StandardState } from 'reducers/standard.reducer';
import axios from 'axios';

const entity = 'BrandLevel';
const apiUrl = '/api/yumei-mall-customer/customer/brand-level';
const standardReducerImpl = new StandardReducerImpl<BrandLevel>(entity, defaultBrandLevel, apiUrl);
export type DealerBrandLevelState = StandardState<BrandLevel>;
export const dealerBrandLevel = standardReducerImpl.buildStandardReducer();

export const getEntities = standardReducerImpl.getEntities;
export const getEntity = standardReducerImpl.getEntity;
export const createEntity = standardReducerImpl.createEntity;
export const updateEntity = standardReducerImpl.updateEntity;
export const deleteEntity = standardReducerImpl.deleteEntity;
export const reset = standardReducerImpl.reset;

// 查询所有等级
export const fetchBrandLevelList = async () => {
  const requestUrl = `/api/yumei-mall-pim/meta-data/enums`;
  const axiosResponse = await axios.get<any>(requestUrl, {});
  return axiosResponse.data;
}

