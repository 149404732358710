import { useEffect, useState } from "react";
import ProTable from "@ant-design/pro-table";
import { getAuthCertificate } from "../customer.reducer";
import moment from "moment";
import { internationalList } from '../../../../components/IntelFormText';

// tab数据
export const DataColumn: any[] = [
    {
        title: '授权书ID',
        dataIndex: 'certificateId'
    }, 
    {
        title: '店铺名称',
        dataIndex: 'storeName'
    }, 
    {
        title: '等级名称',
        dataIndex: 'levelName',
        render: (text: any, record:any) => {
            return  internationalList(record.levelName)
        }
    }, 
    {
        title: '生成时间',
        dataIndex: 'createdDate',
        valueType: 'text',
        render: (text: any, record:any) => {
            return  moment(record.createdDate).format('YYYY-MM-DD HH:mm:ss')
        }
    }, {
        title: '授权有效期',
        dataIndex: 'expireTime'
    }, 
    
]



const Table = (props: any) => {
    const [tableData, setTableData]: any = useState([]);

    useEffect(() => {
        getTableData(props.data)
    }, [props])

    const getTableData = (data?: any) => {
        getAuthCertificate({
            customerId: data?.id
        }).then((e: any) => {
            if (e.data.length > 0) {
                setTableData(e.data)
            }
        })
    }
    return (
        <ProTable
            search={false}
            toolBarRender={false}
            pagination={false}
            // dataSource={[bonusData]}
            dataSource={tableData}
            columns={DataColumn}
        />
    )
}

export default Table