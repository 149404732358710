import { Router, RouteComponentProps } from "@reach/router";
import React from 'react';
import OrderAfterSalesDetails from './order-after-sales-details';
import OrderAfterSalesEdit from './order-after-sales-edit';
import OrderAfterSalesList from './order-after-sales-list';

const OrderAfterSales: React.FC<RouteComponentProps> = () => (
  <Router>
    <OrderAfterSalesList path="/" />
    <OrderAfterSalesDetails path=":id" />
    <OrderAfterSalesEdit path=":id/edit" />
    <OrderAfterSalesEdit path="new" />
  </Router>
);
export default OrderAfterSales;
