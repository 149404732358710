import React, { FC } from "react"
import { RouteComponentProps } from '@reach/router';
import { getEntity } from './point.reducer';
import { connect } from 'react-redux';
import DetailsPage, { DetailsStateAndDispatchProps } from "layouts/page-wraper/details-page";
import { RootState } from "reducers";
import ProDescriptions from "@ant-design/pro-descriptions";
import { Point } from "model/product/point.model";

export interface PointDetailsProps extends DetailsStateAndDispatchProps<Point>,
  RouteComponentProps, StateProps, DispatchProps {
}
const PointDetails: FC<PointDetailsProps> = (props) => {
  return (
    <DetailsPage<Point>
      title={'详情'}
      hideEdit={true}
      {...props}
    >
      <ProDescriptions<Point>
        columns={props.columns as any}
        dataSource={props.entity}
        column={2}
      >
      </ProDescriptions>

    </DetailsPage>);
};

const mapStateToProps = ({ point }: RootState) => ({
  entity: point.entity,
});

const mapDispatchToProps = {
  getEntity,
};

type StateProps = ReturnType<typeof mapStateToProps>;
type DispatchProps = typeof mapDispatchToProps;

export default connect(mapStateToProps, mapDispatchToProps)(PointDetails);
